/**
 * Toast callback types
 */
export default {
  mounted      : 'mounted',
  destroyed    : 'destroyed',
  beforeDestroy: 'beforeDestroy',
  onInput      : 'onInput',
  onClick      : 'onClick',
  onHoverEnter : 'onHoverEnter',
  onHoverLeave : 'onHoverLeave',
  beforeShow   : 'beforeShow',
  shown        : 'shown',
  beforeHide   : 'beforeHide',
  hidden       : 'hidden'
}
