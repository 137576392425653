export default {
  data () {
    return {
      recaptchaValue: null
    }
  },
  methods: {
    onRecaptchaSubmit: function () {
      this.$refs.recaptcha.execute()
    },
    onRecaptchaVerify: function (response) {
      this.recaptchaValue = response
      this.submitForm()
    },
    onRecaptchaExpired: function () {
      this.recaptchaValue = null
      this.resetRecaptcha()
    },
    resetRecaptcha () {
      this.$refs.recaptcha.reset()
    }
  }
}
