<template>
  <v-layout
    v-if="featuredMenus && featuredMenus.Items.length > 0"
    :class="[blockClass]"
    align-center
    row
    wrap
  >
    <v-flex
      class="text-xs-left justify-center py-1"
      xs8
    >
      <h1
        v-if="_title"
        :class="[titleClass]"
        class="subheading font-weight-bold pl-1"
        :style="titleStyle"
      >
        <v-icon
          v-if="titleIcon"
          class="mr-2"
          dark
        >
          {{ titleIcon }}
        </v-icon>
        {{ _title }}
      </h1>
    </v-flex>

    <v-flex
      class="text-xs-right justify-center"
      xs4
    >
      <v-btn
        fab
        small
        depressed
        style="height: 28px; width: 28px;"
        class="ma-1"
        :disabled="swiperNavHasPrev()"
        @click="onPrev"
      >
        <v-icon x-large>
          arrow_left
        </v-icon>
      </v-btn>

      <v-btn
        fab
        small
        depressed
        style="height: 28px; width: 28px;"
        class="ma-1"
        :disabled="swiperNavHasNext()"
        @click="onNext"
      >
        <v-icon x-large>
          arrow_right
        </v-icon>
      </v-btn>
    </v-flex>

    <v-flex
      class="text-xs-center"
      xs12
    >
      <swiper
        key="featured-menu-block-swiper"
        ref="swiper"
        :options="swiperOptionsObj"
        style="width: 100%;"
      >
        <swiper-slide
          v-for="menu in featuredMenus.Items"
          :key="'featured-menu-block-swiper-' + menu.Id"
        >
          <suggested-cart-menu-item
            :menu="menu"
            v-bind.prop="$props"
          />
        </swiper-slide>
      </swiper>
    </v-flex>
  </v-layout>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import AppData                 from '@/mixins/appdata'
import { getContrastColor }    from '@/lib/color'
import CartCommon              from '@/mixins/orders/cartCommon'
import SuggestedCartMenuItem   from '@/components/common/suggestedCartProducts/SuggestedCartMenuItem'

export default {
  name      : 'SuggestedCartMenuBlock',
  components: {
    SuggestedCartMenuItem,
    swiper,
    swiperSlide
  },
  directives: {},
  mixins    : [AppData, CartCommon],
  props     : {
    titleIcon : String,
    titleStyle: String,
    titleClass: {
      type   : String,
      default: function () {
        return getContrastColor(this.$vuetify.theme['gfp-adv-pages-home-sections-featured-products-background'].base) === 'black'
          ? this.$vuetify.theme['gfp-adv-pages-home-sections-featured-products-title'].base === '#000000' ? 'black--text' : 'gfp-adv-pages-home-sections-featured-products-title--text'
          : 'grey--text text--darken-3'
      }
    },
    showDesc: {
      type   : Boolean,
      default: false
    },
    showPrice: {
      type   : Boolean,
      default: false
    },
    menuNameClass: {
      type   : String,
      default: 'body-1 gfp-adv-pages-home-sections-featured-products-product-text-title--text'
    },
    blockClass: {
      type   : String,
      default: 'pa-0 transparent gfp-adv-pages-home-sections-featured-products-title--text'
    },
    cardClass: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-featured-products-product-text-background'
    },
    cardMediaHeight: {
      type   : [String, Number],
      default: 100
    },
    cardTextHeight: {
      type   : [String],
      default: '35px'
    },
    cardButtonColor: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-featured-products-product-text-button'
    },
    cardButtonTitle: String,
    swiperOptions  : {
      type   : Object,
      default: () => ({})
    }
  },
  data () {
    return {}
  },
  computed: {
    _title () {
      return this.featuredMenus?.Lang[this.$i18n.locale]?.Label || ''
    },

    swiperOptionsObj () {
      const swiperDefaultOptions = {
        slidesPerView  : this.featuredMenusCount < 2 ? this.featuredMenusCount : 2,
        slidesPerColumn: 1,
        // slidesOffsetBefore: -15,
        spaceBetween   : 6,
        centeredSlides : false,
        breakpoints    : {
          600: {
            slidesPerView: this.featuredMenusCount < 2 ? this.featuredMenusCount : 2,
            spaceBetween : 6
          },
          320: {
            slidesPerView: 1,
            spaceBetween : 6
          }
        }
      }
      return { ...swiperDefaultOptions, ...this.swiperOptions }
    },

    featuredMenus () {
      return this.cartSuggestedProducts
    },

    featuredMenusCount () {
      return this.featuredMenus.Items.length
    }
  },
  watch: {
    'featuredMenus.Items': function () {
      this.getSwiperInstance().params.slidesPerView = this.featuredMenusCount < 2 ? this.featuredMenusCount : 2
    }
  },
  created () {},
  mounted () {},
  destroyed () {},
  methods: {
    ...{ getContrastColor },

    getSwiperInstance () {
      return this.$refs.swiper?.swiper
    },

    swiperNavHasNext () {
      return !!this.getSwiperInstance()?.isEnd || this.featuredMenus.Items.length <= 1
    },

    swiperNavHasPrev () {
      return !!this.getSwiperInstance()?.isBeginning || this.featuredMenus.Items.length <= 1
    },

    onNext () {
      if (!this.getSwiperInstance()) return

      this.getSwiperInstance().slideNext()
    },

    onPrev () {
      if (!this.getSwiperInstance()) return

      this.getSwiperInstance().slidePrev()
    }
  }
}
</script>

<style scoped>
.full-height .flex {
  display : flex;
}

.full-height .flex > .card {
  flex : 1 1 auto;
}
</style>
