<template>
  <v-layout
    row
    wrap
  >
    <!-- Payment Form -->

    <!-- Tips -->
    <payment-delivery-tip />

    <!-- Minimum Order Warning -->
    <payment-minimum-order-warning />

    <!-- Complete Order -->
    <v-flex xs12>
      <v-btn
        :disabled="disabled || loading"
        :loading="loading"
        block
        class="elevation-0 white--text"
        color="success"
        large
        @click="completeOrder"
      >
        {{ $t('Buttons.Payment') | sanitizeDiacritics }} {{ totalOrderAmount | currency }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>

import AppData                    from '@/mixins/appdata'
import CartCommon                 from '@/mixins/orders/cartCommon'
import PaymentGatewayCommon       from '@/mixins/payment/paymentGatewayCommon'
import PaymentMinimumOrderWarning from '@/components/cart/checkout/payment/common/PaymentMinimumOrderWarning.vue'
import PaymentDeliveryTip         from '@/components/cart/checkout/payment/common/PaymentDeliveryTip.vue'

export default {
  name      : 'GenericPaymentGateway',
  components: { PaymentDeliveryTip, PaymentMinimumOrderWarning },
  directives: {},
  mixins    : [AppData, CartCommon, PaymentGatewayCommon],
  props     : {},
  enums     : {},
  dataModel : null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    requestData () {
      this.submitData()
    },

    onRequestSuccess (response) {
      this.$router.replace({ name: 'CheckoutSuccess' })
    }
  }
}
</script>

<style scoped>

</style>
