<template>
  <v-layout
    v-if="dataStoreMenu"
    ref="categoriesTabs"
    v-resize="onResize"
    v-scroll="onScroll"
    row
    wrap
  >
    <v-flex
      id="categoriesListAffixContainer"
      ref="categoriesListContainer"
      xs12
    >
      <v-tabs
        color="gfp-adv-pages-catalog-sections-categories-tabs-background"
        show-arrows
        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-catalog-sections-categories-tabs-background'].base) === 'white'"
        slider-color="gfp-adv-pages-catalog-sections-categories-tabs-active"
      >
        <v-tab
          v-if="dataStoreMenu.categories && !allCategories"
          :to="{name: 'Menu'}"
          exact
          class="gfp-adv-pages-catalog-sections-categories-tabs-text--text"
        >
          {{ $t('Menu.All') }}
        </v-tab>
        <template v-for="item in dataStoreMenu.categories">
          <v-tab
            v-if="item.Status && item.Lang[$i18n.locale]"
            :key="item.Lang[$i18n.locale].Slug"
            :to="{path: '/' + $route.params.lang + '/catalog/' + item.Lang[$i18n.locale].Slug}"
            class="gfp-adv-pages-catalog-sections-categories-tabs-text--text"
          >
            {{ item.Lang[$i18n.locale].Name | sanitizeDiacritics }}
          </v-tab>
        </template>
      </v-tabs>
    </v-flex>
  </v-layout>
</template>

<script>
import AppData              from '../../mixins/appdata'
import { getContrastColor } from '@/lib/color'

export default {
  name      : 'CategoriesTabs',
  components: {},
  directives: {},
  mixins    : [AppData],
  props     : {
    lang         : String,
    slug         : String,
    allCategories: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      activeCategoryId: -1
    }
  },
  computed: {},
  watch   : {
    slug (newVal) {
      this.getCategoryDetails(this.getCategoryBySlug(newVal))
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getContrastColor,
    getCategoryBySlug (slug) {
      let category = null
      if (slug) category = this.dataStoreMenu?.categories.find(category => category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Slug === slug)
      return category
    },
    getCategoryDetails (item) {
      const id = item ? item.Id : item
      if (parseInt(this.activeCategoryId) === parseInt(id)) return
      this.activeCategoryId = id
      this.$bus.$emit('menu-category-selected', item)
    },
    onResize (e) {
      this.onScroll()
    },
    onScroll (e) {
      const height = this.appConfig?.LOCATION_DATA?.GallerySize?.MinHeight || 65
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
      if (this.$refs.categoriesTabs) {
        this.$refs.categoriesTabs.style = 'position: relative;'
        this.$refs.categoriesTabs.style = this.offsetTop > height ? 'z-index: 1; position: fixed; top: 64px; width: ' + this.$refs.categoriesTabs.clientWidth + 'px;' : 'position: relative;'
      }
    }
  }
}
</script>

<style scoped>

</style>
