export default {
  LOGIN            : 'login',
  LOGOUT           : 'logout',
  SIGN_UP          : 'sign_up',
  ADD_TO_CART      : 'add_to_cart',
  REMOVE_FROM_CART : 'remove_from_cart',
  VIEW_ITEM        : 'view_item',
  BEGIN_CHECKOUT   : 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_PAYMENT_INFO : 'add_payment_info',
  PURCHASE         : 'purchase',
  SEARCH           : 'search',
  ACCOUNT_DELETE   : 'account_delete',
  ACCOUNT_DISABLE  : 'account_disable',
  ACCOUNT_ENABLE   : 'account_enable'
}
