/**
 * Toast type,
 */
export default {
  SIMPLE : 'simple',
  SUCCESS: 'success',
  ERROR  : 'error',
  WARNING: 'warning',
  INFO   : 'info',
  ASYNC  : 'async',
  CONFIRM: 'confirm',
  PROMPT : 'prompt'
}
