<template>
  <div v-if="languages.length > 1">
    <v-menu
      bottom
      nudge-bottom="24"
    >
      <template #activator="{on}">
        <v-btn
          dark
          icon
          name="Change Language"
          small
          v-on="on"
        >
          <v-avatar size="24">
            <v-img :src="lang.ImageUrl" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list
        class="gfp-adv-common-toolbar-background"
        dense
        dark
      >
        <v-list-tile
          v-for="(lng, i) in languages"
          :key="i"
          :disabled="lng.Code === lang.Code"
          color="gfp-adv-common-toolbar-background"
          active-class="gfp-adv-common-toolbar-item-active"
          @click="switchLanguage(lng)"
        >
          <v-list-tile-avatar size="24">
            <v-img :src="lng.ImageUrl" />
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
              {{ lng.Name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { i18n }         from './lang'
import DataEventEnum    from '@/lib/services/enum/DataEventEnum'
import DataEventService from '@/lib/services/event/DataEventService'

export default {
  name      : 'LangSwitch',
  components: {},
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {}
  },
  computed: {
    routeName () {
      return this.$route.name
    },
    languages () {
      return (window.AppConfig && window.AppConfig.LOCATION_DATA && window.AppConfig.LOCATION_DATA.LanguageList) || []
    },
    lang () {
      return this.languages.find(l => l.Code === i18n.locale) || {}
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    switchLanguage (lang) {
      setTimeout(() => {
        const r = this.$router.resolve({
          name  : this.$route.name,
          params: { ...this.$route.params, ...{ lang: lang.Code } },
          query : this.$route.query
        })

        window.location.assign(r.href.replace(/\/+$/, ''))

        DataEventService.Emit(DataEventEnum.EVENT, { Event: DataEventEnum.LOCALE, Payload: lang.Code })
      }, 50)
    }
  }
}
</script>

<style scoped>

</style>
