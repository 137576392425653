<template>
  <v-container
    fluid
    pa-0
    class="gfp-adv-pages-home-background gfp-adv-pages-home-text--text"
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <!-- HEADER -->
        <page-header
          :address="AddressTitle"
          :images="appConfig.LOCATION_DATA.Gallery.home"
          :parallax="false"
          :phone1="appConfig.LOCATION_DATA.Telephone"
          :phone2="appConfig.LOCATION_DATA.Telephone2"
          :phone3="appConfig.LOCATION_DATA.Telephone3"
          :phone4="appConfig.LOCATION_DATA.Telephone4"
          :show-texts="appConfig.LOCATION_DATA.ShowRestaurantInfoHome"
          :title="HeaderTitle"
        />
      </v-flex>

      <v-flex xs12>
        <!-- ORDER NOW + CATEGORIES -->
        <categories-block :auto-fetch-data="false" />
      </v-flex>

      <v-flex xs12>
        <!-- MENU ITEMS -->
        <featured-menu-block :auto-fetch-data="false" />
      </v-flex>

      <v-flex xs12>
        <!-- CATEGORIES -->
        <featured-categories-block :auto-fetch-data="false" />
      </v-flex>

      <v-flex xs12>
        <!-- OFFERS -->
        <offers-block :auto-fetch-data="false" />
      </v-flex>

      <v-flex xs12>
        <!-- PARTNERS -->
        <partners-block :auto-fetch-data="false" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PageHeader              from '../common/PageHeader'
import CategoriesBlock         from '../common/CategoriesBlock'
import FeaturedMenuBlock       from '../common/FeaturedMenuBlock'
import FeaturedCategoriesBlock from '../common/FeaturedCategoriesBlock'
import OffersBlock             from '../common/OffersBlock'
import PartnersBlock           from '../common/PartnersBlock'
import AppData                 from '../../mixins/appdata'
import i18nRouteMeta           from '../../mixins/i18nRouteMeta'

export default {
  name: 'Home',

  components: {
    PageHeader,
    CategoriesBlock,
    FeaturedMenuBlock,
    FeaturedCategoriesBlock,
    OffersBlock,
    PartnersBlock
  },

  directives: {},

  mixins: [AppData, i18nRouteMeta],

  props: [],

  data () {
    return {}
  },

  computed: {
    HeaderTitle () {
      if (this.multipleLocations?.length) return (this.appConfig?.LOCATION_DATA?.Name || '') + (this.appConfig?.LOCATION_DATA?.City ? ' - ' + this.appConfig?.LOCATION_DATA?.City : '')

      return this.appConfig?.LOCATION_DATA?.Name || ''
    },

    AddressTitle () {
      return ''
    }
  },

  watch: {},

  beforeCreate () {},

  created () {},

  beforeMount () {},

  mounted () {
    window.API.get(window.APICall.dashboard)
      .then(response => {
        if (response.data.status === 200) {
          const data = response.data.data

          this.$set(this.dataStore, 'dashboard', data)
        }
      })
      .catch(e => {

      })

    if (!this.dataStoreMenu) {
      this.$bus.$emit('init-menu')
    }
  },

  beforeUpdate () {},

  updated () {},

  beforeDestroy () {},

  destroyed () {},

  methods: {}
}
</script>

<style scoped>

</style>
