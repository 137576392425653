<template>
  <v-layout
    justify-center
    row
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          card
          height="80"
          prominent
          color="gfp-adv-common-forgot-password-toolbar-background"
          style="min-height: 80px"
        >
          <v-icon
            color="gfp-adv-common-forgot-password-toolbar-icon"
            x-large
          >
            lock_open
          </v-icon>

          <v-toolbar-title class="pl-1">
            <div class="body-3 gfp-adv-common-forgot-password-toolbar-title--text">
              {{ $t('ForgotPasswordDialog.Title') }}
            </div>
            <div class="caption gfp-adv-common-forgot-password-toolbar-description--text">
              {{ $t('ForgotPasswordDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            :color="getContrastColor($vuetify.theme['gfp-adv-common-forgot-password-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="gfp-adv-common-forgot-password-body-background fill-height"
          style="overflow-y: auto; min-height: 235px;"
        >
          <v-form
            ref="resetForm"
            v-model="resetFormValid"
          >
            <v-container
              fluid
              grid-list-md
            >
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-if="errorMessages.error || errorMessages.success"
                  xs12
                >
                  <v-alert
                    :type="errorMessages.error ? 'error' : 'success'"
                    :value="true"
                    outline
                  >
                    {{ errorMessages.error ? errorMessages.error : errorMessages.success }}
                  </v-alert>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="email"
                    :error-messages="errorMessages.resetForm.email"
                    :hint="$t('Form.Email.Hint')"
                    :label="$t('Form.Email.Label')"
                    :rules="validationRules.resetForm.email"
                    color="gfp-adv-common-forgot-password-body-control"
                    prepend-icon="email"
                    required
                  />
                </v-flex>

                <v-flex xs12>
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="appConfig.LOCATION_DATA.RecaptchaKey"
                    size="invisible"
                    @expired="onRecaptchaExpired"
                    @verify="onRecaptchaVerify"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-btn
                    block
                    class="elevation-0"
                    color="gfp-adv-common-forgot-password-body-button-reset-background gfp-adv-common-forgot-password-body-button-reset-text--text"
                    large
                    @click="resetPassword()"
                  >
                    {{ $t('ForgotPasswordDialog.Button') | sanitizeDiacritics }}
                  </v-btn>
                </v-flex>

                <v-flex
                  class="text-xs-center gfp-adv-common-forgot-password-body-text--text"
                  xs12
                >
                  {{ $t('ForgotPasswordDialog.RememberLogin') }}
                  <a
                    class="gfp-adv-common-forgot-password-body-link--text"
                    @click="onCancelButtonClick(); $bus.$emit('show-login-dialog')"
                  >
                    {{ $t('Account.Login') }}
                  </a>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
// import { API, APICall } from '../../api/Api'
import VueRecaptcha         from 'vue-recaptcha'
import AppData              from '../../mixins/appdata'
import Recaptcha            from '../../mixins/recaptcha'
import { getContrastColor } from '@/lib/color'

export default {
  name      : 'ForgotPasswordDialog',
  components: {
    'vue-recaptcha': VueRecaptcha
  },
  directives: {},
  mixins    : [AppData, Recaptcha],
  props     : ['visible'],
  data () {
    return {
      email         : '',
      resetFormValid: false,
      errorMessages : {
        resetForm: {
          email: []
        },
        error  : '',
        success: ''
      },
      validationRules: {
        resetForm: {
          email: [
            v => !!v || this.$t('Form.Email.Errors.Mandatory'),
            v => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Form.Email.Errors.Valid')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
    getContrastColor,
    onCancelButtonClick () {
      this.$refs.resetForm.reset()
      this.clearApiErrorMessages()
      this.isVisible = false
    },

    resetPassword () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      if (this.$auth.isAuthenticated()) {
        this.logout()
      }
      this.clearApiErrorMessages()
      this.$refs.resetForm.validate()
      if (!this.resetFormValid) return

      this.onRecaptchaSubmit()
    },

    submitForm () {
      window.API.post(window.APICall.resetPassword, {
        email                 : this.email,
        'g-recaptcha-response': this.recaptchaValue
      })
        .then(response => {
          if (response.data.status === 200) {
            for (const key in response.data.data.Messages) {
              if (response.data.data.Messages.hasOwnProperty(key) && this.errorMessages.resetForm.hasOwnProperty(key)) {
                this.errorMessages.resetForm[key].push(response.data.data.Messages[key])
              }
            }

            if (response.data.data.Messages.Error) {
              this.errorMessages.error = response.data.data.Messages.Error
            } else if (response.data.data.Messages.Success) {
              this.errorMessages.success = response.data.data.Messages.Success
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Success,
                type : 'success',
                icon : ''
              })
              this.onCancelButtonClick()
              this.$bus.$emit('show-login-dialog')
            }
          }
        })
        .catch(e => {

        })
        .finally(() => {
          this.resetRecaptcha()
        })
    },

    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      this.errorMessages.error = ''
      this.errorMessages.success = ''
      for (const key in this.errorMessages.resetForm) {
        if (this.errorMessages.resetForm.hasOwnProperty(key)) {
          this.errorMessages.resetForm[key] = []
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
