<template>
  <v-layout
    row
    wrap
  >
    <!-- Category Menu Items -->
    <v-flex xs12>
      <v-container
        v-if="dataStoreMenu"
        fluid
        pa-0
      >
        <v-layout
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.breakpoint.xsOnly ? 'pa-0' : 'pt-0 pl-3 pr-0 pb-3' : 'pt-0 pl-3 pr-3 pb-3'"
          row
          wrap
        >
          <v-flex
            class="pl-1 pr-1 pb-1 mt-1"
            xs12
          >
            <v-text-field
              v-model="searchProduct"
              :label="$t('Menu.Search')"
              background-color="gfp-adv-pages-catalog-sections-search-background"
              box
              clearable
              color="gfp-adv-pages-catalog-sections-search"
              hide-details
              prepend-inner-icon="search"
              @input="onSearchProduct"
            />
            <v-divider />
          </v-flex>

          <v-flex
            class="pt-2"
            xs12
          >
            <v-container
              fluid
              pa-0
            >
              <v-layout
                row
                wrap
              >
                <template v-for="(item, index) in posMenuItemsActive">
                  <template v-if="item.Category.Lang && item.Category.Lang[$i18n.locale]">
                    <v-flex
                      v-if="item.Items.filter(m => m.Lang && m.Lang[$i18n.locale]).length > 0"
                      :key="index"
                      v-observe-visibility="allCategories ? false : {
                        callback: (isVisible, entry) => visibilityChanged(isVisible, entry, item.Category),
                        throttle: 0,
                        throttleOptions: {
                          leading: 'visible',
                        },
                        intersection: {
                          //root: ...,
                          rootMargin: '0px 0px 0px 0px',
                          threshold: 0.85,
                        },
                      }"
                      :class="index > 0 ? 'mt-4' : ''"
                      class="pl-1 pr-1 pb-1"
                      xs12
                    >
                      <div
                        v-if="getCategoryImage(item.Category)"
                        :id="item.Category.Lang[$i18n.locale].Slug"
                        v-lazy:background-image="getCategoryImage(item.Category)"
                        class="menuListCategory v-image__image--cover"
                        style="height: 200px; background-position: center center; background-size: cover;"
                      >
                        <v-container
                          fill-height
                          ma-0
                          pa-0
                          style="height: 200px;"
                        >
                          <v-layout
                            column
                            justify-end
                          >
                            <div
                              class="gfp-adv-pages-catalog-sections-catalog-category-title--text title pa-3"
                              :style="`background-color: ${hexToRGBa($vuetify.theme['gfp-adv-pages-catalog-sections-catalog-category-background'].base, 0.35)};`"
                            >
                              <div class="">
                                {{ item.Category.Lang[$i18n.locale].Name }}
                              </div>
                              <div
                                v-if="item.Category.Lang[$i18n.locale].Description"
                                class="caption pt-2"
                                v-html="item.Category.Lang[$i18n.locale].Description"
                              />
                            </div>
                          </v-layout>
                        </v-container>
                      </div>

                      <div
                        v-else
                        :id="item.Category.Lang[$i18n.locale].Slug"
                        class="gfp-adv-pages-catalog-sections-catalog-category-title-background gfp-adv-pages-catalog-sections-catalog-category-title--text title pa-3 menuListCategory"
                        style="background: none;"
                      >
                        <div class="">
                          {{ item.Category.Lang[$i18n.locale].Name }}
                        </div>
                        <div
                          v-if="item.Category.Lang[$i18n.locale].Description"
                          class="caption pt-2"
                          v-html="item.Category.Lang[$i18n.locale].Description"
                        />
                      </div>
                    </v-flex>

                    <template v-if="allCategories || item.Category.ObserveVisibility">
                      <template v-for="menu in item.Items">
                        <v-flex
                          v-if="menu.Active && menu.Lang && menu.Lang[$i18n.locale]"
                          :key="index + '-' + menu.Id + '-' + item.Category.Id"
                          :class="[{'xl6': !hasFeaturedMenus}]"
                          class="xs12 xl6 pa-1"
                        >
                          <v-list
                            class="mb-0 pb-0 pt-0"
                            dense
                            light
                            three-line
                          >
                            <v-list-tile
                              :id="item.Category.Lang[$i18n.locale].Slug + menu.Id"
                              :disabled="isMenuItemDisabled(menu)"
                              :inactive="!isUserEnabled || isMenuItemDisabled(menu)"
                              avatar
                              class="gfp-adv-pages-catalog-sections-catalog-product-background menu-item"
                              ripple
                              @click="showAddMenuOptionsDialog(menu, item.Category)"
                            >
                              <div
                                v-if="isMenuItemInCart(menu)"
                                style="width: 3px; height: 100%; position: absolute; margin-left: -8px;"
                                class="success"
                              />

                              <div
                                v-if="menu.Image && leftImage"
                                :style="$vuetify.breakpoint.smAndDown ? 'height: 90px; width: 125px;' : 'height: 90px; width: 150px;'"
                                class="mr-2"
                              >
                                <img
                                  v-lazy="menu.Image"
                                  style="border-radius: 6px; object-fit: cover;"
                                  :style="{
                                    height: '90px',
                                    width: $vuetify.breakpoint.smAndDown ? '125px' : '150px',
                                    filter: (isMenuItemDisabled(menu) ? 'grayscale(1)' : 'grayscale(0)')
                                  }"
                                >
                              </div>

                              <v-list-tile-content class="justify-start align-start pr-1">
                                <v-list-tile-title :style="{'padding-right': leftImage && quickAddTop ? '32px' : 0}">
                                  <span
                                    v-if="menu.Number"
                                    class="gfp-adv-pages-catalog-sections-catalog-product-number-background pa-1 mr-2 gfp-adv-pages-catalog-sections-catalog-product-number-text--text text-xs-center"
                                  >
                                    {{ menu.Number }}
                                  </span>

                                  <span
                                    v-if="menuItemQuantityInCart(menu)"
                                    class="font-weight-bold gfp-adv-pages-catalog-sections-catalog-product-quantity--text"
                                    style="font-size: 16px;"
                                  >
                                    <template v-if="menu.DetailsData.UnitId > 1">
                                      {{ formatUnitQuantity($i18n.locale, menuItemQuantityInCart(menu), getMeasurementUnitById(menu.DetailsData.UnitId)) }}
                                    </template>
                                    <template v-else>
                                      {{ menuItemQuantityInCart(menu) }} x
                                    </template>
                                  </span>

                                  <span
                                    class="font-weight-medium gfp-adv-pages-catalog-sections-catalog-product-title--text"
                                    :style="{'font-size': ($vuetify.breakpoint.xsOnly ? '13px' : '14px')}"
                                  >
                                    {{ menu.Lang[$i18n.locale].Name }}
                                  </span>
                                </v-list-tile-title>

                                <v-list-tile-sub-title
                                  v-if="menu.Lang[$i18n.locale].Description"
                                  style="flex: 0 0 auto"
                                  :style="{'padding-right': leftImage && quickAddTop ? '32px' : 0}"
                                  class="gfp-adv-pages-catalog-sections-catalog-product-description--text"
                                  v-html="menu.Lang[$i18n.locale].Description"
                                />

                                <div style="font-size: 14px; flex: 1 1 auto; display: flex; flex-flow: column nowrap; justify-content: flex-end; width: 100%;">
                                  <div>
                                    <template v-if="menu.SpecialStatus && menu.IsSpecial">
                                      <div
                                        :class="[isMenuItemDisabled(menu) ? 'grey--text lighten-1' : 'gfp-adv-pages-catalog-sections-catalog-product-price--text']"
                                        class="pt-1 white--text"
                                      >
                                        <span class="old-price grey--text text--darken-4">
                                          {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.Price)) : menu.Price | currency }}
                                        </span> {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.SpecialPrice)) : menu.SpecialPrice | currency }}
                                      </div>
                                    </template>
                                    <template v-else>
                                      <div
                                        :class="[isMenuItemDisabled(menu) ? 'grey--text lighten-1' : 'gfp-adv-pages-catalog-sections-catalog-product-price--text']"
                                        class="pt-1 white--text"
                                      >
                                        <template v-if="menu.DetailsData.UnitId > 1">
                                          {{ getMenuUnitBasePriceWithOptions(menu) | currency }}
                                          <span
                                            class="grey--text text-caption"
                                          >/ {{ getMeasurementUnitById(menu.DetailsData.UnitId).BaseUnit }}</span>
                                        </template>
                                        <template v-else>
                                          {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.Price)) : menu.Price | currency }}
                                        </template>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </v-list-tile-content>

                              <div
                                v-if="menu.Image && !leftImage"
                                :style="$vuetify.breakpoint.smAndDown ? 'height: 90px; width: 125px;' : 'height: 90px; width: 150px;'"
                              >
                                <img
                                  v-lazy="menu.Image"
                                  style="border-radius: 6px; object-fit: cover;"
                                  :style="{
                                    height: '90px',
                                    width: $vuetify.breakpoint.smAndDown ? '125px' : '150px',
                                    filter: (isMenuItemDisabled(menu) ? 'grayscale(1)' : 'grayscale(0)')
                                  }"
                                >
                              </div>

                              <template v-if="isQuickAddMenuItemEnabled">
                                <div :style="quickAddPositionStyle">
                                  <v-btn
                                    :disabled="isMenuItemDisabled(menu)"
                                    :class="[isMenuItemDisabled(menu) ? 'grey lighten-1' : 'gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-background']"
                                    class="gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-text--text pa-0"
                                    :style="quickAddButtonStyle"
                                    flat
                                    small
                                    ripple
                                    @click.native.stop="onQuickAddMenuItem(menu, item.Category)"
                                  >
                                    <v-progress-circular
                                      v-if="menu.QuickAddLoading"
                                      indeterminate
                                      size="16"
                                      width="2"
                                      color="gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-text"
                                      style="margin: 3px;"
                                    />
                                    <v-icon v-else>
                                      add
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </template>

                              <div :style="(leftImage ? isQuickAddMenuItemEnabled ? 'margin-right: 40px;' : 'margin-right: 5px;' : !menu.Image ? isQuickAddMenuItemEnabled ? 'margin-right: 40px;' : 'margin-right: 5px;' : tagsMarginStyle) + tagsPositionStyle ">
                                <template v-for="tag in menu.Tags">
                                  <v-tooltip
                                    v-if="tag.Visible && !tag.System"
                                    :key="`menu-tag-tooltip-${tag.Id}`"
                                    :color="tag.Color || '#666666'"
                                    bottom
                                  >
                                    <template #activator="{on}">
                                      <v-chip
                                        v-if="tag.Visible && !tag.System"
                                        :key="`menu-tag-${tag.Id}`"
                                        :color="tag.Color || '#666666'"
                                        small
                                        label
                                        class="menu-tag"
                                        :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`"
                                        v-on="on"
                                      >
                                        <v-icon
                                          v-if="tag.Icon && !tag.LabelOnly"
                                          small
                                        >
                                          {{ tag.Icon }}
                                        </v-icon>

                                        <span
                                          v-if="!tag.Icon || tag.LabelOnly"
                                          class="caption"
                                          :class="{'ml-1': !!tag.Icon && !tag.LabelOnly}"
                                        >
                                          {{ tag.Label }}
                                        </span>
                                      </v-chip>
                                    </template>

                                    <div :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`">
                                      <div class="font-weight-bold text-xs-center">
                                        {{ tag.Lang[$i18n.locale].Label }}
                                      </div>
                                      <div class="caption text-xs-left">
                                        {{ tag.Lang[$i18n.locale].Description }}
                                      </div>
                                    </div>
                                  </v-tooltip>
                                </template>
                              </div>
                            </v-list-tile>
                            <v-divider />
                          </v-list>
                        </v-flex>
                      </template>
                    </template>
                  </template>
                </template>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import MenuBlockMixin                            from './MenuBlockMixin'
import { getContrastColor, hexToRGB, hexToRGBa } from '@/lib/color'
import { formatUnitQuantity }                    from '../../lib/currency/currency'

export default {
  name  : 'MenuBlockLargeListItems',
  mixins: [MenuBlockMixin],
  props : {
    quickAddTop: {
      type   : Boolean,
      default: false
    },
    leftImage: {
      type   : Boolean,
      default: true
    }
  },
  computed: {
    tagsPositionStyle () {
      return 'position: absolute; bottom: 1px; right: -2px;'
    },
    tagsMarginStyle () {
      return this.$vuetify.breakpoint.smAndDown ? 'margin-right: 135px;' : 'margin-right: 160px;'
    },

    quickAddPositionStyle () {
      if (!this.quickAddTop) return 'position: absolute; bottom: 1px; right: -2px;'

      return 'position: absolute; top: 1px; right: -2px;'
    },
    quickAddButtonStyle () {
      if (!this.quickAddTop) return 'min-width: 30px; height: 30px; border-radius: 16px 0px 4px 0px;'

      return 'min-width: 30px; height: 30px; border-radius: 0px 4px 0px 16px;'
    }
  },
  methods: {
    getContrastColor,
    formatUnitQuantity,
    hexToRGBa,
    hexToRGB
  }
}
</script>

<style scoped>
/deep/ .price-tag {
  border-radius : 2px;
  min-width     : 55px;
  min-height    : 28px;
  text-align    : center;
}

/deep/ .v-list--three-line .v-list__tile {
  height : 104px !important;
}

/deep/ .menu-item a.v-list__tile,
/deep/ .menu-item div.v-list__tile {
  padding : 6px;
}

/deep/ .v-list--three-line .v-list__tile__sub-title {
  white-space        : normal;
  -webkit-line-clamp : 2;
  -webkit-box-orient : vertical;
  display            : -webkit-box;
}

.menu-tag {
  margin        : 0;
  margin-right  : 4px;
  margin-bottom : 6px;
  height        : 18px !important;
  border-radius : 3px;
}

/deep/ .menu-tag .v-chip__content {
  padding : 2px;
}
</style>
