import Vue                                         from 'vue'
import Router                                      from 'vue-router'
import Home                                        from '../components/home/Home'
import Menu                                        from '../components/menu/Menu'
import Checkout                                    from '../components/cart/checkout/Checkout'
import CheckoutDetails                             from '../components/cart/checkout/CheckoutDetails'
import CheckoutPayment                             from '../components/cart/checkout/CheckoutPayment'
import CheckoutSuccess                             from '../components/cart/checkout/CheckoutSuccess'
import Contact                                     from '../components/contact/Contact'
import About                                       from '../components/about/About'
import Account                                     from '../components/account/Account'
import AccountUserProfile                          from '../components/account/AccountUserProfile'
import AccountAddresses                            from '../components/account/AccountAddresses'
import AccountOrders                               from '../components/account/AccountOrders'
import Policies                                    from '../components/policies/Policies'
import PoliciesCookies                             from '../components/policies/PoliciesCookies'
import PoliciesCopyright                           from '../components/policies/PoliciesCopyright'
import PoliciesPayments                            from '../components/policies/PoliciesPayments'
import PoliciesPrivacy                             from '../components/policies/PoliciesPrivacy'
import PoliciesReturns                             from '../components/policies/PoliciesReturns'
import PoliciesTerms                               from '../components/policies/PoliciesTerms'
import PoliciesAllergens                           from '../components/policies/PoliciesAllergens'
import { getDefaultLocale, i18n, setI18nLanguage } from '@/lang/lang'

// Route Templates
/* function loadAsyncComponent (component) {
  return () => import(/!* webpackChunkName: "view-[request]" *!/ `@/${ component }.vue`)
}

const Home = loadAsyncComponent('components/home/Home')
*/

if (window.location.href.endsWith('//')) {
  window.location.href = window.location.href.replace(/\/+$/, '')
}

Vue.use(Router)

const authCheck = (to, from, next) => {
  let userInfo = Vue.prototype.$localStorage.get('userInfo')
  let isUser = false
  if (userInfo) {
    try {
      userInfo = JSON.parse(userInfo) ? JSON.parse(userInfo) : null
      isUser = !!(userInfo && userInfo.Info && userInfo.Info.Id)
    } catch (err) {
      userInfo = null
    }
  }

  if (!isUser) {
    Vue.prototype.$auth.storage.removeItem('vueauth_access_token')
    Vue.prototype.$localStorage.remove('userInfo')
  }

  if (to.meta.requiresAuth) {
    if (!isUser) {
      next({
        name  : 'Home',
        params: { lang: i18n.locale }
      })
    }
  }

  next()
}

const routes = [
  {
    path     : '/:lang',
    props    : true,
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [
      {
        name     : 'Home',
        path     : '',
        component: Home,
        meta     : {
          requiresAuth: false
        }
      },
      {
        name     : 'Menu',
        path     : 'catalog/:slug?/:menuItemSlug?',
        component: Menu,
        props    : true,
        meta     : {
          requiresAuth: false
        }
      },
      {
        name     : 'Order',
        path     : 'order',
        component: Checkout,
        redirect : { name: 'OrderStatus' },
        meta     : {
          requiresAuth: false
        },
        children: [
          {
            name     : 'OrderStatus',
            path     : 'status/:id?',
            component: CheckoutSuccess,
            meta     : {
              requiresAuth: false
            }
          }
        ]
      },
      {
        name     : 'Checkout',
        path     : 'checkout',
        component: Checkout,
        redirect : { name: 'CheckoutDetails' },
        meta     : {
          requiresAuth: false
        },
        children: [
          {
            name     : 'CheckoutDetails',
            path     : 'details',
            component: CheckoutDetails,
            props    : true,
            meta     : {
              requiresAuth: false
            }
          },
          {
            name     : 'CheckoutPayment',
            path     : 'payment',
            component: CheckoutPayment,
            meta     : {
              requiresAuth: false
            }
          },
          {
            name     : 'CheckoutSuccess',
            path     : 'success/:id?',
            component: CheckoutSuccess,
            meta     : {
              requiresAuth: false
            }
          }
        ]
      },
      {
        name     : 'Account',
        path     : 'account',
        component: Account,
        redirect : { name: 'AccountUserProfile' },
        meta     : {
          requiresAuth: true
        },
        children: [
          {
            name     : 'AccountUserProfile',
            path     : 'profile',
            component: AccountUserProfile,
            meta     : {
              requiresAuth: true
            }
          },
          {
            name     : 'AccountAddresses',
            path     : 'addresses',
            component: AccountAddresses,
            meta     : {
              requiresAuth: true
            }
          },
          {
            name     : 'AccountOrders',
            path     : 'orders',
            component: AccountOrders,
            meta     : {
              requiresAuth: true
            }
          }
        ]
      },
      {
        name     : 'Contact',
        path     : 'contact',
        component: Contact,
        meta     : {
          requiresAuth: false
        }
      },
      {
        name     : 'About',
        path     : 'about',
        component: About,
        meta     : {
          requiresAuth: false
        },
        beforeEnter: (to, from, next) => {
          next(window.AppConfig.PAGES.About.Pages[i18n.locale || 'el'].pages[to.name].status)
        }
      },
      {
        name     : 'Policies',
        path     : 'policies',
        component: Policies,
        redirect : { name: 'PoliciesTerms' },
        meta     : {
          requiresAuth: false
        },
        beforeEnter: (to, from, next) => {
          next(window.AppConfig.PAGES.Policies.Pages[i18n.locale || 'el'].pages[to.name].Status)
        },
        children: [
          {
            name     : 'PoliciesTerms',
            path     : 'terms',
            component: PoliciesTerms,
            meta     : {
              requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
              next(window.AppConfig.PAGES.Policies.Pages[i18n.locale || 'el'].pages[to.name].Status)
            }
          },
          {
            name     : 'PoliciesPrivacy',
            path     : 'privacy',
            component: PoliciesPrivacy,
            meta     : {
              requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
              next(window.AppConfig.PAGES.Policies.Pages[i18n.locale || 'el'].pages[to.name].Status)
            }
          },
          {
            name     : 'PoliciesCopyright',
            path     : 'copyright',
            component: PoliciesCopyright,
            meta     : {
              requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
              next(window.AppConfig.PAGES.Policies.Pages[i18n.locale || 'el'].pages[to.name].Status)
            }
          },
          {
            name     : 'PoliciesCookies',
            path     : 'cookies',
            component: PoliciesCookies,
            meta     : {
              requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
              next(window.AppConfig.PAGES.Policies.Pages[i18n.locale || 'el'].pages[to.name].Status)
            }
          },
          {
            name     : 'PoliciesPayments',
            path     : 'payments',
            component: PoliciesPayments,
            meta     : {
              requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
              next(window.AppConfig.PAGES.Policies.Pages[i18n.locale || 'el'].pages[to.name].Status)
            }
          },
          {
            name     : 'PoliciesReturns',
            path     : 'returns',
            component: PoliciesReturns,
            meta     : {
              requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
              next(window.AppConfig.PAGES.Policies.Pages[i18n.locale || 'el'].pages[to.name].Status)
            }
          },
          {
            name     : 'PoliciesAllergens',
            path     : 'allergens',
            component: PoliciesAllergens,
            meta     : {
              requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
              next(window.AppConfig.PAGES.Policies.Pages[i18n.locale || 'el'].pages[to.name].Status)
            }
          }
        ]
      }
    ]
  },
  {
    name    : 'StripePaymentSuccess',
    path    : '/payment/stripe/success',
    redirect: to => {
      const language = i18n.languages.find(lang => lang.code === to.query.lang)
      return {
        name  : 'CheckoutSuccess',
        params: {
          lang    : language?.locale ?? process.env.VUE_APP_I18N_LOCALE,
          redirect: true
        },
        query: {}
      }
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    name    : 'VivaPaymentSuccess',
    path    : '/payment/viva/success',
    redirect: to => {
      const language = i18n.languages.find(lang => lang.code === to.query.lang)
      return {
        name  : 'CheckoutSuccess',
        params: {
          lang    : language?.locale ?? process.env.VUE_APP_I18N_LOCALE,
          redirect: true
        },
        query: {}
      }
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    name    : 'VivaPaymentFailure',
    path    : '/payment/viva/failure',
    redirect: to => {
      const language = i18n.languages.find(lang => lang.code === to.query.lang)
      return {
        name  : 'CheckoutPayment',
        params: {
          lang    : language?.locale ?? process.env.VUE_APP_I18N_LOCALE,
          redirect: true,
          error   : true
        },
        query: {}
      }
    },
    meta: {
      requiresAuth: false
    }
  }
]

const createRouter = () => new Router({
  mode  : 'history', // 'hash'
  base  : process.env.VUE_APP_PUBLIC_PATH,
  routes: [],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

const router = createRouter()

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
Router.prototype.replace = function replace (location) {
  return routerReplace.call(this, location).catch(error => error)
}

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher

  routes.push({
    name    : 'Default',
    path    : '*',
    redirect: '/' + getDefaultLocale() || 'el'
  })
  router.addRoutes(routes)
}

router.beforeEach((to, from, next) => {
  const lang = to.params.lang || i18n.locale || getDefaultLocale()
  const langExists = i18n.messages.hasOwnProperty(lang)

  if (langExists) {
    setI18nLanguage(lang)
    authCheck(to, from, next)
  } else {
    next({
      replace: true,
      name   : to.matched.length > 0 ? to.name : 'Home',
      params : { lang: i18n.locale }
    })
  }
})

export default router
