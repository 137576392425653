export const formatCurrency = (locale, currency, value, decimals = 2) => {
  let val = value ? String(value)?.replaceAll(',', '') || String(0) : String(0)
  val = parseFloat(parseFloat(val).toFixed(decimals))

  return new Intl.NumberFormat(locale, {
    style   : 'currency',
    currency: currency
  }).format(val)
}

export const formatDecimal = (locale, value, decimals = 2, suffix = '') => {
  let val = value ? String(value)?.replaceAll(',', '') || String(0) : String(0)
  val = parseFloat(parseFloat(val).toFixed(decimals))

  return new Intl.NumberFormat(locale, {
    style                : 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(val) + (!!suffix && (suffix))
}

export const formatUnitQuantity = (locale, value, measurementUnit, withSuffix = true) => {
  const rate = measurementUnit?.Rate || 1000
  const rateUnit = measurementUnit?.RateUnit || ''
  const baseUnit = measurementUnit?.BaseUnit || ''

  let val = parseFloat(value ? String(value)?.replaceAll(',', '') || String(0) : String(0))
  const suffix = withSuffix ? (val >= rate) ? baseUnit : rateUnit : ''
  if (val >= rate) val = convertRateUnitToBaseUnit(val, measurementUnit)
  val = parseFloat(parseFloat(val).toFixed(4))

  return new Intl.NumberFormat(locale, {
    style                   : 'decimal',
    minimumFractionDigits   : 0,
    maximumFractionDigits   : 2,
    maximumSignificantDigits: 4
  }).format(val) + (suffix && (' ' + suffix))
}

export const convertRateUnitToBaseUnit = (value, measurementUnit) => {
  return parseFloat(parseFloat(value).toFixed(4)) * (measurementUnit?.Divisor || 1)
}
export const convertBaseUnitToRateUnit = (value, measurementUnit) => {
  return parseFloat(parseFloat(value).toFixed(4)) / (measurementUnit?.Divisor || 1)
}

export const formatUnit = (locale, value, unit) => {
  let val = value ? String(value)?.replaceAll(',', '') || String(0) : String(0)
  val = parseFloat(parseFloat(val).toFixed(2))

  /*
    Intl.NumberFormat currently supports the following so-called simple units:

    angle: degree
    area: acre, hectare
    concentration: percent
    digital: bit, byte, kilobit, kilobyte, megabit, megabyte, gigabit, gigabyte, terabit, terabyte, petabyte
    duration: millisecond, second, minute, hour, day, week, month, year
    length: millimeter, centimeter, meter, kilometer, inch, foot, yard, mile, mile-scandinavian
    mass: gram, kilogram, ounce, pound, stone
    temperature: celsius, fahrenheit
    volume: liter, milliliter, gallon, fluid-ounce
   */

  return new Intl.NumberFormat(locale, {
    style   : 'unit',
    notation: 'compact',
    unit    : unit
  }).format(val)
}

export const getSymbolFromCurrency = (currencyCode) => {
  const code = String(currencyCode).toUpperCase()
  return currencySymbolMap.hasOwnProperty(code) ? currencySymbolMap[code] : undefined
}

export const getIconFromCurrency = (currencyCode) => {
  const code = String(currencyCode).toUpperCase()
  return currencyIconMap.hasOwnProperty(code) ? currencyIconMap[code] : currencyIconMap.DEFAULT
}

export const currencyIconMap = {
  DEFAULT: 'far fa-money-bill-alt',
  EUR    : 'euro_symbol',
  USD    : 'fas fa-dollar-sign',
  ARS    : 'fas fa-dollar-sign',
  AUD    : 'fas fa-dollar-sign',
  BBD    : 'fas fa-dollar-sign',
  BMD    : 'fas fa-dollar-sign',
  BND    : 'fas fa-dollar-sign',
  BSD    : 'fas fa-dollar-sign',
  CAD    : 'fas fa-dollar-sign',
  CLP    : 'fas fa-dollar-sign',
  COP    : 'fas fa-dollar-sign',
  CUC    : 'fas fa-dollar-sign',
  CVE    : 'fas fa-dollar-sign',
  FJD    : 'fas fa-dollar-sign',
  GYD    : 'fas fa-dollar-sign',
  HKD    : 'fas fa-dollar-sign',
  KYD    : 'fas fa-dollar-sign',
  LRD    : 'fas fa-dollar-sign',
  MXN    : 'fas fa-dollar-sign',
  NAD    : 'fas fa-dollar-sign',
  NZD    : 'fas fa-dollar-sign',
  SBD    : 'fas fa-dollar-sign',
  SGD    : 'fas fa-dollar-sign',
  SRD    : 'fas fa-dollar-sign',
  SVC    : 'fas fa-dollar-sign',
  TVD    : 'fas fa-dollar-sign',
  XCD    : 'fas fa-dollar-sign',
  GBP    : 'fas fa-pound-sign',
  EGP    : 'fas fa-pound-sign',
  FKP    : 'fas fa-pound-sign',
  GGP    : 'fas fa-pound-sign',
  GIP    : 'fas fa-pound-sign',
  IMP    : 'fas fa-pound-sign',
  JEP    : 'fas fa-pound-sign',
  LBP    : 'fas fa-pound-sign',
  SHP    : 'fas fa-pound-sign',
  SSP    : 'fas fa-pound-sign',
  SYP    : 'fas fa-pound-sign'

}

export const currencySymbolMap = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BRL: 'R$',
  BSD: '$',
  BTC: '฿',
  BTN: 'Nu.',
  BWP: 'P',
  BYR: 'Br',
  BYN: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: 'лв',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  YER: '﷼',
  ZAR: 'R',
  ZWD: 'Z$'
}

export default formatCurrency
