<template>
  <v-container
    fluid
    pa-0
    class="gfp-adv-pages-contact-background gfp-adv-pages-contact-text--text"
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-map
          :map.sync="mapObj"
          :store-delivery-areas="{
            Circles: restaurantDeliveryAreaCircles,
            Polygons: restaurantDeliveryAreaPolygons
          }"
          show-store-marker
          show-store-infowin
        />
      </v-flex>

      <v-flex
        lg10
        offset-lg1
        offset-xs0
        xs12
      >
        <v-container
          fluid
          pa-0
        >
          <v-layout
            :class="$vuetify.breakpoint.xsOnly ? 'pa-2' : 'pa-4'"
            row
            wrap
          >
            <v-flex
              v-if="$vuetify.breakpoint.smAndUp"
              class="hidden-xs-only"
              md3
              sm4
            >
              <need-help-block />

              <delivery-areas-block
                v-if="showDeliveryAreas"
                class="mt-3"
                @click:area="onAreaClick"
              />

              <working-hours class="mt-3" />
            </v-flex>

            <v-flex
              md9
              sm8
              xs12
            >
              <v-container
                fluid
                grid-list-lg
                pt-0
              >
                <v-layout
                  row
                  wrap
                >
                  <v-form
                    ref="contactForm"
                    v-model="contactFormValid"
                  >
                    <v-flex
                      v-if="$vuetify.breakpoint.xsOnly"
                      class="hidden-sm-and-up"
                      xs12
                    >
                      <need-help-block />

                      <delivery-areas-block
                        v-if="showDeliveryAreas"
                        @click:area="onAreaClick"
                      />

                      <working-hours />
                    </v-flex>
                    <v-flex xs12>
                      <v-layout
                        row
                        wrap
                      >
                        <v-flex xs12>
                          <v-subheader class="gfp-adv-pages-contact-form-section-title--text gfp-adv-pages-contact-form-section-background subheading">
                            <v-icon
                              class="pr-2"
                              color="gfp-adv-pages-contact-form-section-icon"
                            >
                              account_circle
                            </v-icon>
                            {{ $t('Contact.Subtitle') }}
                          </v-subheader>
                        </v-flex>
                        <v-flex
                          md6
                          xs12
                        >
                          <v-text-field
                            v-model="contactForm.Firstname"
                            :hint="$t('Form.Firstname.Hint')"
                            :label="$t('Form.Firstname.Label')"
                            :rules="validationRules.contactForm.Firstname"
                            color="gfp-adv-pages-contact-form-fields"
                            prepend-icon="account_circle"
                            required
                          />
                        </v-flex>
                        <v-flex
                          md6
                          xs12
                        >
                          <v-text-field
                            v-model="contactForm.Lastname"
                            :hint="$t('Form.Lastname.Hint')"
                            :label="$t('Form.Lastname.Label')"
                            :rules="validationRules.contactForm.Lastname"
                            color="gfp-adv-pages-contact-form-fields"
                            prepend-icon="account_circle"
                            required
                          />
                        </v-flex>
                        <v-flex
                          md6
                          xs12
                        >
                          <v-text-field
                            v-model="contactForm.Email"
                            :hint="$t('Form.Email.Hint')"
                            :label="$t('Form.Email.Label')"
                            :rules="validationRules.contactForm.Email"
                            color="gfp-adv-pages-contact-form-fields"
                            prepend-icon="email"
                            required
                          />
                        </v-flex>
                        <v-flex
                          md6
                          xs12
                        >
                          <v-text-field
                            v-model="contactForm.Telephone"
                            :hint="$t('Form.Telephone.Hint')"
                            :label="$t('Form.Telephone.Label')"
                            :rules="validationRules.contactForm.Telephone"
                            color="gfp-adv-pages-contact-form-fields"
                            mask="### ## ## ### ######"
                            prepend-icon="phone"
                            required
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-textarea
                            v-model="contactForm.Comments"
                            :hint="$t('Form.Comments.Hint')"
                            :label="$t('Form.Comments.Label')"
                            :rules="validationRules.contactForm.Comments"
                            color="gfp-adv-pages-contact-form-fields"
                            prepend-icon="chat"
                            required
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-subheader class="gfp-adv-pages-contact-form-section-title--text gfp-adv-pages-contact-form-section-background subheading">
                            <v-icon
                              class="pr-2"
                              color="gfp-adv-pages-contact-form-section-icon"
                            >
                              more
                            </v-icon>

                            {{ $t('Form.Terms.Title') }}
                          </v-subheader>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout
                            class="pa-0 ma-0"
                            row
                            wrap
                          >
                            <v-checkbox
                              v-model="contactForm.TermsCondition"
                              :rules="validationRules.contactForm.Terms_Condition"
                              class="pa-0 ma-0"
                              color="success"
                              false-value="0"
                              required
                              true-value="1"
                            >
                              <template #label>
                                <div>
                                  {{ $t('Form.Terms.Agree') }}
                                  <router-link
                                    :to="{name: 'PoliciesTerms', query: {t: 'lp'}}"
                                    target="_blank"
                                    @click.native.stop
                                  >
                                    {{ $t('PoliciesTerms.Meta.Title') }}
                                  </router-link>
                                </div>
                              </template>
                            </v-checkbox>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12>
                          <v-btn
                            :disabled="submitButton"
                            :loading="submitButton"
                            block
                            class="gfp-adv-pages-contact-form-button-text--text elevation-0"
                            color="gfp-adv-pages-contact-form-button-background"
                            large
                            @click="sendMessage"
                          >
                            {{ $t('Contact.Button') | sanitizeDiacritics }}
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      class="caption gfp-adv-pages-contact-form-text--text text-xs-justify"
                      xs12
                      v-html="$t('Form.Terms.Note')"
                    />
                  </v-form>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WorkingHours                     from '@/components/common/WorkingHours'
import NeedHelpBlock                    from '@/components/common/NeedHelpBlock'
import DeliveryAreasBlock               from '@/components/common/DeliveryAreasBlock'
import AppData                          from '@/mixins/appdata'
import i18nRouteMeta                    from '@/mixins/i18nRouteMeta'
import { gmapApi }                      from 'vue2-google-maps'
import mapbox                           from 'mapbox-gl'
import turfCircle                       from '@turf/circle'
import VMap                             from '@/components/common/maps/VMap'
import MapsCommon, { MapsProviderEnum } from '@/mixins/maps/mapsCommon'
import AddressCommon                    from '@/mixins/maps/addressCommon'

export default {
  name      : 'Contact',
  components: {
    VMap,
    DeliveryAreasBlock,
    WorkingHours,
    NeedHelpBlock
  },
  directives: {},
  mixins    : [AppData, MapsCommon, AddressCommon, i18nRouteMeta],
  props     : [],
  data () {
    return {
      mapObj                : null,
      mapBoxGeocoder        : null,
      selectedMapBoxLocation: null,
      mapBoxSearch          : '',
      mapKey                : '',
      showSelectedArea      : false,
      selectedArea          : null,
      submitButton          : false,
      contactFormValid      : false,
      infoWinOpen           : true,
      contactForm           : {
        Firstname      : '',
        Lastname       : '',
        Telephone      : '',
        Email          : '',
        Comments       : '',
        NewsletterEmail: '0',
        NewsletterSms  : '0',
        NewsletterPhone: '0',
        TermsCondition : '0'
      },
      validationRules: {
        contactForm: {
          Firstname: [
            v => !!v || this.$t('Form.Firstname.Errors.Mandatory'),
            v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Firstname.Errors.Valid')
          ],
          Lastname: [
            v => !!v || this.$t('Form.Lastname.Errors.Mandatory'),
            v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Lastname.Errors.Valid')
          ],
          Telephone: [
            v => !!v || this.$t('Form.Telephone.Errors.Mandatory'),
            v => v && v.length >= 8 && v.length <= 16 || this.$t('Form.Telephone.Errors.Valid')
          ],
          Email: [
            v => !!v || this.$t('Form.Email.Errors.Mandatory'),
            v => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Form.Email.Errors.Valid')
          ],
          Comments: [
            v => !!v || this.$t('Form.Comments.Errors.Mandatory')
          ],
          Terms_Condition: [
            v => !!v && v != '0' || this.$t('Form.Terms.Errors.Mandatory')
          ]
        }
      }
    }
  },
  computed: {
    google () {
      return gmapApi()
    },

    AddressTitle () {
      const street = this.appConfig.LOCATION_DATA.Street.trim() ? this.appConfig.LOCATION_DATA.Street.trim() : ''
      const city = this.appConfig.LOCATION_DATA.City.trim() ? street ? ', ' + this.appConfig.LOCATION_DATA.City.trim() : this.appConfig.LOCATION_DATA.City.trim() : ''
      return street + city
    },

    restaurantDeliveryAreaCircles () {
      const locationPolygons = this.appConfig.LOCATION_DATA.DeliveryAreas
      const circles = []

      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas) return circles

      for (const key in locationPolygons) {
        if (locationPolygons.hasOwnProperty(key)) {
          const area = locationPolygons[key]
          if (area.type === 'circle' && this.selectedArea?.areaId === area.areaId) {
            circles.push(area)
          }
        }
      }
      return circles
    },

    restaurantDeliveryAreaPolygons () {
      const locationPolygons = this.appConfig.LOCATION_DATA.DeliveryAreas
      const polys = []

      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas) return polys

      for (const key in locationPolygons) {
        if (locationPolygons.hasOwnProperty(key)) {
          const area = locationPolygons[key]
          if (area.type === 'shape' && this.selectedArea?.areaId === area.areaId) {
            polys.push(area)
          }
        }
      }
      return polys
    },

    restaurantDeliveryAreaCirclesMapBox () {
      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas || !this.selectedArea) return []

      const circles = []
      if (this.selectedArea.type === 'circle') {
        const obj = JSON.parse(JSON.stringify(this.selectedArea))
        circles.push(obj)
      }

      return circles
    },

    restaurantDeliveryAreaPolygonsMapBox () {
      if (!this.appConfig.LOCATION_DATA.HasDeliveryAreas || !this.selectedArea) return []

      const polys = []
      if (this.selectedArea.type === 'shape') {
        const obj = JSON.parse(JSON.stringify(this.selectedArea))
        obj.vertices = obj.vertices.map(area => [area.lng, area.lat])
        polys.push(obj)
      }
      return polys
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onAreaClick (area) {
      this.selectedArea = area
      this.showSelectedArea = !!area

      if (this.interactiveMapsProvider.Name === MapsProviderEnum.Google) {
        this.centerZoomMapGoogle()
      } else if (this.interactiveMapsProvider.Name === MapsProviderEnum.MapBox) {
        this.centerZoomMapMapBox()
      }
    },

    centerZoomMapGoogle () {
      const mapObj = this.mapObj?.Instance
      if (!mapObj) return

      // eslint-disable-next-line
      let latlngbounds = new google.maps.LatLngBounds()
      const maxZoom = 17

      if (this.appConfig.LOCATION_DATA.HasDeliveryAreas && (this.restaurantDeliveryAreaPolygons.length || this.restaurantDeliveryAreaCircles.length)) {
        for (let area = 0; area < this.restaurantDeliveryAreaPolygons.length; area++) {
          for (let i = 0; i < this.restaurantDeliveryAreaPolygons[area].vertices.length; i++) {
            // eslint-disable-next-line
            let latlng = new google.maps.LatLng(this.restaurantDeliveryAreaPolygons[area].vertices[i].lat, this.restaurantDeliveryAreaPolygons[area].vertices[i].lng)
            this.restaurantDeliveryAreaPolygons[area].vertices[i].latlng = latlng
            latlngbounds.extend(latlng)
          }
        }

        for (let i = 0; i < this.restaurantDeliveryAreaCircles.length; i++) {
          // eslint-disable-next-line
          let latlng = new google.maps.LatLng(this.restaurantDeliveryAreaCircles[i].circle.center.lat, this.restaurantDeliveryAreaCircles[i].circle.center.lng)
          let radius = this.restaurantDeliveryAreaCircles[i].circle.radius
          // eslint-disable-next-line
          latlngbounds = new google.maps.Circle({
            center: latlng,
            radius: radius
          }).getBounds()
        }
      } else {
        // eslint-disable-next-line no-undef
        latlngbounds.extend(new google.maps.LatLng(this.markerStore.lat, this.markerStore.lng))
      }

      if (mapObj.$mapCreated) {
        mapObj.$mapCreated.then((map) => {
          this.google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
            if (this.getZoom() > maxZoom) this.setZoom(maxZoom)
          })
          map.fitBounds(latlngbounds)
          map.panToBounds(latlngbounds)
        })
      } else if (mapObj.$mapPromise) {
        mapObj.$mapPromise.then((map) => {
          this.google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
            if (this.getZoom() > maxZoom) this.setZoom(maxZoom)
          })
          map.fitBounds(latlngbounds)
          map.panToBounds(latlngbounds)
        })
      }
    },

    centerZoomMapMapBox () {
      const bounds = new mapbox.LngLatBounds()
      const maxZoom = 17

      if (this.appConfig.LOCATION_DATA.HasDeliveryAreas && this.restaurantDeliveryAreaPolygonsMapBox.length) {
        for (let area = 0; area < this.restaurantDeliveryAreaPolygonsMapBox.length; area++) {
          const vertices = this.restaurantDeliveryAreaPolygonsMapBox[area].vertices
          for (let i = 0; i < vertices.length; i++) {
            bounds.extend(vertices[i])
          }
        }

        this.mapObj.Instance.fitBounds(bounds, {
          animate: false,
          maxZoom: maxZoom,
          padding: 20
        })
      } else if (this.appConfig.LOCATION_DATA.HasDeliveryAreas && this.restaurantDeliveryAreaCirclesMapBox.length) {
        // let radius = 0
        for (let i = 0; i < this.restaurantDeliveryAreaCirclesMapBox.length; i++) {
          // radius += this.restaurantDeliveryAreaCirclesMapBox[i].circle.radius
          const bound = [this.restaurantDeliveryAreaCirclesMapBox[i].circle.center.lng, this.restaurantDeliveryAreaCirclesMapBox[i].circle.center.lat]
          bounds.extend(bound)

          const circleBound = turfCircle(bound, this.restaurantDeliveryAreaCirclesMapBox[i].circle.radius / 1000, { units: 'kilometers' }).geometry.coordinates[0]
          circleBound.forEach(b => {
            bounds.extend(b)
          })
        }

        this.mapObj.Instance.fitBounds(bounds, {
          animate: false,
          maxZoom: maxZoom,
          padding: 20
        })
      } else {
        this.mapObj.Instance.jumpTo({
          animate: false,
          center : [this.markerStore.lng, this.markerStore.lat],
          zoom   : 17
        })
      }

      const poly = this.restaurantDeliveryAreaPolygonsMapBox[0]

      if (this.mapObj.Instance.getLayer('polygon_fill_1')) this.mapObj.Instance.removeLayer('polygon_fill_1')
      if (this.mapObj.Instance.getLayer('polygon_outline_1')) this.mapObj.Instance.removeLayer('polygon_outline_1')
      if (this.mapObj.Instance.getSource('polygon_1')) this.mapObj.Instance.removeSource('polygon_1')

      if (poly) {
        this.mapObj.Instance.addSource('polygon_1', {
          type: 'geojson',
          data: {
            type    : 'Feature',
            geometry: {
              type       : 'Polygon',
              coordinates: [poly.vertices]
            }
          }
        })
        this.mapObj.Instance.addLayer({
          id    : 'polygon_fill_1',
          type  : 'fill',
          source: 'polygon_1',
          layout: {},
          paint : {
            'fill-color'  : poly.color,
            'fill-opacity': 0.15
          }
        })
        this.mapObj.Instance.addLayer({
          id    : 'polygon_outline_1',
          type  : 'line',
          source: 'polygon_1',
          layout: {},
          paint : {
            'line-color': poly.color,
            'line-width': 3
          }
        })
      }

      const circ = this.restaurantDeliveryAreaCirclesMapBox[0]

      if (this.mapObj.Instance.getLayer('circle_fill_1')) this.mapObj.Instance.removeLayer('circle_fill_1')
      if (this.mapObj.Instance.getLayer('circle_outline_1')) this.mapObj.Instance.removeLayer('circle_outline_1')
      if (this.mapObj.Instance.getSource('circle_1')) this.mapObj.Instance.removeSource('circle_1')

      if (circ) {
        this.mapObj.Instance.addSource('circle_1', {
          type: 'geojson',
          data: {
            type    : 'Feature',
            geometry: {
              type       : 'Point',
              coordinates: [circ.circle.center.lng, circ.circle.center.lat]
            }
          }
        })
        this.mapObj.Instance.addLayer({
          id    : 'circle_fill_1',
          type  : 'circle',
          source: 'circle_1',
          layout: {},
          paint : {
            'circle-radius': [
              'interpolate', ['exponential', 2], ['zoom'],
              0, 0,
              15.6, 1024
            ],
            // 'circle-radius'      : circ.circle.radius / 12,
            'circle-color'       : circ.color,
            'circle-opacity'     : 0.15,
            'circle-stroke-width': 3,
            'circle-stroke-color': circ.color
          }
        })
      }
    },

    sendMessage () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      this.$refs.contactForm.validate()

      if (this.contactFormValid) {
        this.submitButton = true

        window.API.post(window.APICall.contactSend, this.contactForm)
          .then(response => {
            if (response.data.status === 200) {
              if (response.data.data.Success) {
                this.$refs.contactForm.reset()
                this.$bus.$emit('show-snackbar', {
                  title: '',
                  body : response.data.data.Success,
                  type : 'success',
                  icon : ''
                })
              } else if (response.data.data.Error) {
                this.$bus.$emit('show-snackbar', {
                  title: '',
                  body : response.data.data.Error,
                  type : 'error',
                  icon : ''
                })
              }
            }
          })
          .catch(e => {

          })
          .finally(() => {
            this.submitButton = false
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
