<template>
  <v-card
    v-if="menu"
    :class="[cardClass]"
    height="100%"
    style="cursor: pointer; border: 0; border-radius: 8px;"
    @click.stop="onFeaturedMenuClick(menu)"
  >
    <div>
      <div
        v-if="menu.Image"
        :style="'height:' + (cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px')"
        class="gfp-adv-pages-home-sections-featured-products-product-image-background"
      >
        <div
          v-lazy:background-image="{src: menu.Image, loading: getContrastLogo(LOGO_TYPE.LAZY, LOGO_COLOR.DARK)}"
          :style="{height: cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px'}"
          class="v-image__image--cover gfp-adv-pages-home-sections-featured-products-product-image-background"
          style="background-position: center center; border-radius: 8px 8px 0px 0px;"
        />
      </div>
      <div
        v-else
        :style="'height:' + (cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px') + ';background-size: 200px; background:url('+getContrastLogo(LOGO_TYPE.ICONS, LOGO_COLOR.DARK).find(i => i.sizes==='180x180').href+') center center no-repeat;'"
        class="gfp-adv-pages-home-sections-featured-products-product-image-background"
        style="border-radius: 8px 8px 0px 0px;"
      />

      <div :style="quickAddPositionStyle">
        <v-btn
          class="gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-background gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-text--text pa-0"
          :style="quickAddButtonStyle"
          flat
          small
          ripple
          @click.stop="onQuickAddMenuClick(menu)"
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="16"
            width="2"
            color="gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-text"
            style="margin: 3px;"
          />
          <v-icon v-else>
            add
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-card-title
      :style="'height:' + cardTextHeight"
      class="pa-2 overflow-hidden justify-center align-center gfp-adv-pages-home-sections-featured-products-product-text-background"
      primary-title
    >
      <div :class="[menuNameClass]">
        {{ menu.Lang[$i18n.locale].Name | truncate(50) }}

        <div
          v-if="showDesc"
          class="gfp-adv-pages-home-sections-featured-products-product-text-description--text caption"
          v-html="menu.Lang[$i18n.locale].Description"
        />
      </div>
    </v-card-title>
  </v-card>
</template>

<script>

import { getContrastColor } from '@/lib/color'
import AppData              from '@/mixins/appdata'
import MenuBlockMixin       from '@/components/menu/MenuBlockMixin'

export default {
  name      : 'SuggestedCartMenuItem',
  components: {},
  directives: {},
  mixins    : [AppData, MenuBlockMixin],
  props     : {
    menu: {
      type   : Object,
      default: undefined
    },
    showDesc: {
      type   : Boolean,
      default: false
    },
    showPrice: {
      type   : Boolean,
      default: false
    },
    menuNameClass: {
      type   : String,
      default: 'body-1 gfp-adv-pages-home-sections-featured-products-product-text-title--text'
    },
    blockClass: {
      type   : String,
      default: 'pt-0 pb-2 px-2 gfp-adv-pages-home-sections-featured-products-background gfp-adv-pages-home-sections-featured-products-title--text'
    },
    cardClass: {
      type   : String,
      default: 'transparent'
    },
    cardMediaHeight: {
      type   : [String, Number],
      default: 100
    },
    cardTextHeight: {
      type   : [String],
      default: '35px'
    },
    cardButtonColor: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-featured-products-product-text-button'
    },
    cardButtonTitle: String
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    _cardButtonTitle () {
      return this.cardButtonTitle ? this.cardButtonTitle : this.$t('FeaturedMenuBlock.CardButtonTitle')
    },

    quickAddPositionStyle () {
      return 'position: absolute; top: 64px; right: -8px'
    },

    quickAddButtonStyle () {
      return 'min-width: 30px; height: 30px; border-radius: 16px 0px 0px 0px;'
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.$bus.$on('save:menuOptionsItem', this.saveMenuOptions)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('save:menuOptionsItem', this.saveMenuOptions)
  },
  destroyed () {},
  methods: {
    getContrastColor,

    getMenuCategoryById (categoryId) {
      const items = this.dataStoreMenu?.items || []
      const category = items.find(item => item.Category.Id === categoryId)?.Category

      return category
    },

    saveMenuOptions (menu) {
      if (menu.Id === this.menu.Id) {
        this.loading = true
        this.$bus.$once('cart:add:response', (menu) => {
          if (menu?.Id === this.menu?.Id) {
            this.loading = false
          }
        })
      }
    },

    onFeaturedMenuClick (menu) {
      if (!menu || this.loading) return

      this.$bus.$emit('show-menu-options-dialog', {
        menuItem: menu,
        editMode: false
      })
    },

    onQuickAddMenuClick (menu) {
      const menuCategory = this.getMenuCategoryById(menu?.CategoryId)
      if (!menu || !menuCategory || this.loading) return

      if (this.isQuickAddMenuItemEnabled) {
        this.loading = true
        this.onQuickAddMenuItem(menu, menuCategory)
        this.$bus.$once('cart:add:response', (menu) => {
          if (menu?.Id === this.menu?.Id) {
            this.loading = false
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
