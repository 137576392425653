import turfCircle                                     from '@turf/circle'
import turfBooleanContains                            from '@turf/boolean-contains'
import { point as turfPoint, polygon as turfPolygon } from '@turf/helpers'
import { MapsProviderEnum }                           from '@/mixins/maps/mapsCommon'

const clearAddressData = {
  City       : '',
  Comment    : '',
  DoorBell   : '',
  Floor      : '',
  Formatted  : '',
  Id         : '',
  IsDefault  : true,
  Lat        : '',
  Lng        : '',
  Nickname   : '',
  Number     : '',
  PlaceId    : '',
  Postcode   : '',
  State      : '',
  Street     : '',
  Country    : '',
  CountryCode: '',
  Provider   : ''
}

export default {
  data () {
    return {
      selectedCity    : null,
      selectedStreet  : null,
      selectedLocation: null,
      mapCenter       : {
        lat: window?.AppConfig?.LOCATION_DATA?.Lat || -1,
        lng: window?.AppConfig?.LOCATION_DATA?.Lng || -1
      },
      markerStore: {
        lat: window?.AppConfig?.LOCATION_DATA?.Lat || -1,
        lng: window?.AppConfig?.LOCATION_DATA?.Lng || -1
      },
      markerAddress: {
        lat: window?.AppConfig?.LOCATION_DATA?.Lat || -1,
        lng: window?.AppConfig?.LOCATION_DATA?.Lng || -1
      }
    }
  },
  methods: {
    initMapCenter (address) {
      this.selectedLocation = this.selectedStreet = this.selectedCity = address ? this.addressToPlaceObject(address) : null
      this.mapCenter = this.markerAddress = this.markerStore = {
        lat: window?.AppConfig?.LOCATION_DATA?.Lat || -1,
        lng: window?.AppConfig?.LOCATION_DATA?.Lng || -1
      }
    },

    isCustomAddress (address) {
      return address && parseFloat(address.Lng) === 0 && parseFloat(address.Lat) === 0
    },

    placeToAddressObject (place) {
      return {
        ...this.address,
        ...{
          Provider   : place?.Provider || '',
          PlaceId    : place?.Id || '',
          Formatted  : place?.Name || '',
          Lat        : place?.LatLng?.lat || '',
          Lng        : place?.LatLng?.lng || '',
          City       : place?.Properties?.City || '',
          Street     : place?.Properties?.Street || '',
          Country    : place?.Properties?.Country || '',
          CountryCode: place?.Properties?.CountryCode || '',
          Number     : place?.Properties?.Number || '',
          Postcode   : place?.Properties?.Postcode || ''
        }
      }
    },

    addressToPlaceObject (address) {
      return {
        Provider: address.Provider || MapsProviderEnum.Google,
        Id      : address?.PlaceId || '',
        Name    : address?.Formatted || '',
        Center  : [parseFloat(address?.Lng || -1), parseFloat(address?.Lat || -1)],
        LatLng  : {
          lng: parseFloat(address?.Lng || -1),
          lat: parseFloat(address?.Lat || -1)
        },
        Properties: {
          Number     : address?.Number || '',
          Postcode   : address?.Postcode || '',
          City       : address?.City || '',
          Street     : address?.Street || '',
          Country    : address?.Country || '',
          CountryCode: address?.CountryCode || ''
        },
        Raw: address.Raw || null
      }
    },

    setAddressFromPlace (place, noLimits = false) {
      if (!place) return
      if (!noLimits) {
        this.validateAddress(place)
      } else {
        this.setCustomerAddressFields(place)
      }
    },

    clearSelectedAddress () {
      if (this.$refs.addressForm) this.$refs.addressForm.reset()
      this.clearCustomerAddressFields()
    },

    clearCustomerAddressFields () {
      this.selectedLocation = this.selectedCity = this.selectedStreet = null
      this.address = this.defaultEmptyAddress()
      this.initMapCenter()
    },

    defaultEmptyAddress () {
      return JSON.parse(JSON.stringify(clearAddressData))
    },

    setCustomerAddressFields (place) {
      if (!place) return

      this.selectedLocation = this.selectedCity = this.selectedStreet = place
      this.address = this.placeToAddressObject(place)
    },

    getAddressLatLng (address) {
      return {
        lng: parseFloat(address?.Lng || address?.lng || -1),
        lat: parseFloat(address?.Lat || address?.lng || -1)
      }
    },

    getAddressFormatted (address, withCountry = false) {
      const country = address.Country || address.CountryCode || ''
      const numberSeparator = address.Street ? ' ' : ''
      const citySeparator = address.Street || address.Number ? ', ' : ''
      const postcodeSeparator = address.Street || address.Number || address.City ? ' ' : ''
      const countrySeparator = address.Street || address.Number || address.City || address.Postcode ? ', ' : ''

      let retVal = ''
      retVal += address.Street ? address.Street : ''
      retVal += address.Number ? numberSeparator + address.Number : ''
      retVal += address.City ? citySeparator + address.City : ''
      retVal += address.Postcode ? postcodeSeparator + address.Postcode : ''
      retVal += country && withCountry ? countrySeparator + country : ''

      return retVal
    },

    getAddress () {

    },

    toMapBoxPoint (point) {
      return [parseFloat(point?.lng || -1), parseFloat(point?.lat || -1)]
    },

    notifyUserOutOfBoundsAddress () {
      this.$bus.$emit('show-snackbar', {
        title: this.$t('Checkout.Error.Address.OutOfBounds.Title'),
        body : this.$t('Checkout.Error.Address.OutOfBounds.Body'),
        type : 'error',
        icon : ''
      })
    },

    async validateAddress (place) {
      const result = await this.isPlaceInsideDeliveryAreas(place)

      if (result) {
        this.setCustomerAddressFields(place)
      } else {
        this.clearSelectedAddress()
        this.notifyUserOutOfBoundsAddress()
      }
    },

    async isPlaceInsideDeliveryCountries (place) {
      if (!place) return false

      try {
        const response = await window.API.post(window.APICall.addressValidate, {
          Lat: place?.LatLng?.lat || -1,
          Lng: place?.LatLng?.lng || -1
        })
        return response?.data?.status === 'success'
      } catch (e) {
        return false
      }
    },

    async isPlaceInsideDeliveryAreas (place) {
      if (!place) return

      // Check if point belongs to custom address
      if (this.acceptCustomAddresses && place?.LatLng?.lat === 0 && place?.LatLng?.lng === 0) return true

      if (!window.AppConfig?.LOCATION_DATA?.HasDeliveryAreas) {
        try {
          return await this.isPlaceInsideDeliveryCountries(place)
        } catch (e) {
          return false
        }
      }

      let i = 0
      for (i = 0; i < this.locationAreaCircles.length; i++) {
        const options = {
          steps: 10,
          units: 'kilometers'
        }
        const result = turfBooleanContains(turfCircle(this.toMapBoxPoint(this.locationAreaCircles[i].center), this.locationAreaCircles[i].radius, options), turfPoint(place.Center))
        if (result) return true
      }

      for (i = 0; i < this.locationAreaPolygons.length; i++) {
        const result = turfBooleanContains(turfPolygon([this.locationAreaPolygons[i]]), turfPoint(place.Center))
        if (result) return true
      }

      return false
    }
  },
  computed: {
    onlyWarningForAddresses () {
      return this.appConfig?.LOCATION_DATA?.HasOnlyWarningForAddresses ?? false
    },

    acceptCustomAddresses () {
      return this.appConfig?.LOCATION_DATA?.AcceptCustomAddresses || false
    },

    acceptLatLngAddresses () {
      return this.appConfig?.LOCATION_DATA?.AcceptLatLngAddresses || false
    },

    locationDeliveryCountries () {
      return window.AppConfig?.LOCATION_DATA?.DeliveryCountries || []
    },

    locationDeliveryCountriesStr () {
      return this.locationDeliveryCountries.join(',')
    },

    locationAreaPolygons () {
      const deliveryAreas = window.AppConfig?.LOCATION_DATA?.DeliveryAreas || []
      const retVal = []

      for (const key in deliveryAreas) {
        if (deliveryAreas.hasOwnProperty(key)) {
          const obj = deliveryAreas[key]
          if (obj.type === 'shape') {
            const vertices = obj.vertices.map(v => this.toMapBoxPoint(v))
            vertices.push(vertices[0])
            retVal.push(vertices)
          }
        }
      }

      return retVal
    },

    locationAreaCircles () {
      const deliveryAreas = window.AppConfig?.LOCATION_DATA?.DeliveryAreas || []
      const retVal = []

      for (const key in deliveryAreas) {
        if (deliveryAreas.hasOwnProperty(key)) {
          const obj = deliveryAreas[key]
          if (obj.type === 'circle') {
            const circle = JSON.parse(JSON.stringify(obj.circle))
            circle.radius = circle.radius / 1000
            retVal.push(circle)
          }
        }
      }

      return retVal
    }
  }
}
