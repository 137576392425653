export default {
  data () {
    return {}
  },
  methods: {
    menuItemToGAItem (item) {
      const name = item.Lang && item.Lang[this.$i18n.locale].Name || ''
      const category = item.CategoryName || ''
      const price = parseFloat(item.PriceFmt) || 0.0
      const discount = item.SpecialStatus && item.IsSpecial ? parseFloat(item.PriceFmt) - parseFloat(item.SpecialPriceFmt) : 0.0
      const quantity = parseInt(item.Quantity) || 0
      const variant = item.Options.map && item.Options.map(option => option.Lang && option.Lang[this.$i18n.locale] && option.Lang[this.$i18n.locale].Name).join(', ') || item.Options[this.$i18n.locale] && item.Options[this.$i18n.locale].join(', ') || ''
      const currency = window?.AppConfig?.APP_SETTINGS.Currency?.name || 'EUR'

      return {
        id     : item.Id,
        item_id: item.Id,

        name     : name,
        item_name: name,

        brand     : '',
        item_brand: '',

        category     : category,
        item_category: category,

        price: price,

        discount: discount,

        quantity: quantity,

        variant     : variant,
        item_variant: variant,

        currency: currency
      }
    }
  },
  computed: {}
}
