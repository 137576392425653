<template>
  <v-layout
    v-if="featuredMenus && featuredMenus.length > 0"
    :class="[blockClass]"
    align-center
    class="full-height"
    row
    wrap
  >
    <v-flex
      v-if="_title"
      class="text-xs-center justify-center pa-1"
      xs12
    >
      <h1
        :class="[titleClass]"
        class="py-3"
        :style="titleStyle"
      >
        <v-icon
          v-if="titleIcon"
          class="mr-2"
          dark
        >
          {{ titleIcon }}
        </v-icon>
        {{ _title }}
      </h1>
    </v-flex>

    <v-flex
      v-if="swiper"
      class="text-xs-center"
      xs12
    >
      <swiper
        key="featured-menu-block-swiper"
        :options="swiperOptionsObj"
        style="width: 100%;"
      >
        <swiper-slide
          v-for="menu in featuredMenus"
          :key="'featured-menu-block-swiper-' + menu.Id"
        >
          <v-card
            :class="cardClass"
            height="100%"
          >
            <div
              v-if="menu.Image"
              :style="'height:' + (cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px')"
              class="gfp-adv-pages-home-sections-featured-products-product-image-background"
            >
              <div
                v-lazy:background-image="{src: menu.Image, loading: getContrastLogo(LOGO_TYPE.LAZY, LOGO_COLOR.DARK)}"
                :style="{height: cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px'}"
                class="v-image__image--cover gfp-adv-pages-home-sections-featured-products-product-image-background"
                style="background-position: center center;"
              />
            </div>
            <div
              v-else
              :style="'height:' + (cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px') + ';background-size: 200px; background:url('+getContrastLogo(LOGO_TYPE.ICONS, LOGO_COLOR.DARK).find(i => i.sizes==='180x180').href+') center center no-repeat;'"
              class="gfp-adv-pages-home-sections-featured-products-product-image-background"
            />

            <v-card-title
              :style="'min-height:' + cardTextHeight"
              class="justify-center align-start"
              primary-title
            >
              <div>
                <h3
                  :class="menuNameClass"
                  class="mb-0"
                >
                  {{ menu.Lang[$i18n.locale].Name }}
                </h3>
                <div
                  v-if="showDesc"
                  class="gfp-adv-pages-home-sections-featured-products-product-text-description--text"
                  v-html="menu.Lang[$i18n.locale].Description"
                />
              </div>
            </v-card-title>

            <v-card-actions
              v-if="_cardButtonTitle"
              class="justify-center pb-4"
            >
              <v-btn
                :color="cardButtonColor"
                outline
                @click="onFeaturedMenuClick(menu)"
              >
                {{ _cardButtonTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </swiper-slide>
      </swiper>
    </v-flex>

    <template
      v-for="menu in featuredMenus"
      v-else
    >
      <v-flex
        :key="'featured-menu-block-item-' + menu.Id"
        class="text-xs-center pb-4"
        xs12
      >
        <v-card
          :class="cardClass"
          width="100%"
        >
          <div
            v-if="menu.Image"
            :style="'height:' + (cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px')"
            class="white"
          >
            <v-img
              :alt="menu.Name"
              :height="cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px'"
              :lazy-src="getContrastLogo(LOGO_TYPE.LAZY, LOGO_COLOR.DARK)"
              :src="menu.Image"
            />
          </div>
          <div
            v-else
            :style="'height:' + (cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px') + ';background-size: 200px; background:url('+getContrastLogo(LOGO_TYPE.ICONS, LOGO_COLOR.DARK).find(i => i.sizes==='180x180').href+') center center no-repeat;'"
            class="white"
          />
          <v-divider />
          <v-card-title
            :style="'min-height:' + cardTextHeight"
            class="justify-center align-start"
            primary-title
          >
            <div>
              <h3
                :class="menuNameClass"
                class="mb-0"
              >
                {{ menu.Lang[$i18n.locale].Name }}
              </h3>
              <div
                v-if="showDesc"
                class="gfp-adv-pages-home-sections-featured-products-product-text-description--text"
                v-html="menu.Lang[$i18n.locale].Description"
              />
            </div>
          </v-card-title>
          <v-card-actions
            v-if="_cardButtonTitle"
            class="justify-center pb-4"
          >
            <v-btn
              :color="cardButtonColor"
              outline
              @click="onFeaturedMenuClick(menu)"
            >
              {{ _cardButtonTitle }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import AppData                 from '../../mixins/appdata'
import { getContrastColor }    from '@/lib/color'

export default {
  name      : 'FeaturedMenuBlock',
  components: {
    swiper,
    swiperSlide
  },
  directives: {},
  mixins    : [AppData],
  props     : {
    autoFetchData: {
      type   : Boolean,
      default: true
    },
    title     : String,
    titleIcon : String,
    titleStyle: String,
    titleClass: {
      type   : String,
      default: function () {
        return this.$route.name === 'Home'
          ? getContrastColor(this.$vuetify.theme['gfp-adv-pages-home-sections-featured-products-background'].base) === 'white'
            ? this.$vuetify.theme['gfp-adv-pages-home-sections-featured-products-title'].base === '#ffffff' ? 'white--text' : 'gfp-adv-pages-home-sections-featured-products-title--text'
            : 'grey--text text--darken-3'
          : this.$route.name === 'Menu'
            ? getContrastColor(this.$vuetify.theme['gfp-adv-pages-catalog-background'].base) === 'white'
              ? 'white--text'
              : 'grey--text text--darken-3'
            : 'grey--text text--darken-3'
      }
    },
    showDesc: {
      type   : Boolean,
      default: true
    },
    menuNameClass: {
      type   : String,
      default: 'headline gfp-adv-pages-home-sections-featured-products-product-text-title--text'
    },
    blockClass: {
      type   : String,
      default: 'pt-3 pb-3 pl-4 pr-4 gfp-adv-pages-home-sections-featured-products-background gfp-adv-pages-home-sections-featured-products-title--text'
    },
    cardClass: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-featured-products-product-text-background'
    },
    cardMediaHeight: {
      type   : [String, Number],
      default: 300
    },
    cardTextHeight: {
      type   : [String],
      default: '100px'
    },
    cardButtonColor: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-featured-products-product-text-button'
    },
    cardButtonTitle: String,
    swiper         : {
      type   : Boolean,
      default: true
    },
    swiperOptions: {
      type   : Object,
      default: () => ({})
    }
  },
  data () {
    return {}
  },
  computed: {
    _title () {
      return this.title ? this.title : this.$t('FeaturedMenuBlock.Title')
    },

    _cardButtonTitle () {
      return this.cardButtonTitle ? this.cardButtonTitle : this.$t('FeaturedMenuBlock.CardButtonTitle')
    },

    swiperOptionsObj () {
      const swiperDefaultOptions = {
        slidesPerView  : this.featuredMenusCount < 4 ? this.featuredMenusCount : 4,
        slidesPerColumn: 1,
        spaceBetween   : 24,
        loop           : false,
        autoplay       : {
          delay               : 2500,
          disableOnInteraction: true
        },
        breakpoints: {
          1264: {
            slidesPerView: this.featuredMenusCount < 3 ? this.featuredMenusCount : 3,
            spaceBetween : 24
          },
          960: {
            slidesPerView: this.featuredMenusCount < 2 ? this.featuredMenusCount : 2,
            spaceBetween : 24
          },
          600: {
            slidesPerView: 1,
            spaceBetween : 24
          }
        }
      }
      return { ...swiperDefaultOptions, ...this.swiperOptions }
    },

    featuredMenus () {
      const randomizeData = true
      const data = JSON.parse(JSON.stringify(this.dataStoreFeaturedMenus || []))
      const retVal = data.filter(menu => menu.Lang && menu.Lang[this.$i18n.locale] && menu.Category.Lang && menu.Category.Lang[this.$i18n.locale])

      if (randomizeData) {
        return retVal
          .map(a => [Math.random(), a])
          .sort((a, b) => a[0] - b[0])
          .map(a => a[1])
      }
      return retVal
    },

    featuredMenusCount () {
      return this.featuredMenus.length
    }
  },
  watch: {},
  created () {
    if (this.autoFetchData) {
      window.API.get(window.APICall.featuredMenu)
        .then(response => {
          if (response.data.status === 200) {
            this.$set(this.dataStore, 'featuredMenus', response.data.data)
          }
        })
        .catch(e => {

        })
    }
  },
  mounted () {},
  destroyed () {},
  methods: {
    ...{ getContrastColor },

    getItemById (menuItemId, categorySlug) {
      let item = null
      if (menuItemId && categorySlug) {
        const category = Object.entries(this.dataStoreMenu.items).find((obj) => obj[1].Category.Lang[this.$i18n.locale] && obj[1].Category.Lang[this.$i18n.locale].Slug.toString() === categorySlug.toString())[1]
        if (category) item = category.Items.find(menu => menu.Id.toString() === menuItemId.toString())
      }
      return item
    },

    onFeaturedMenuClick (menu) {
      if (this.$route.name === 'Menu') {
        if (this.$route.params.slug === menu.Category.Lang[this.$i18n.locale].Slug) {
          this.$router.push({ name: 'Menu', params: {} }).finally(() => {
            this.$router.replace({
              name  : 'Menu',
              params: { slug: menu.Category.Lang[this.$i18n.locale].Slug, menuId: menu.Id, hashId: menu.Category.Lang[this.$i18n.locale].Slug + menu.Id }
            })
          })
        } else {
          this.$router.push({
            name  : 'Menu',
            params: { slug: menu.Category.Lang[this.$i18n.locale].Slug, menuId: menu.Id, hashId: menu.Category.Lang[this.$i18n.locale].Slug + menu.Id }
          })
        }
      } else {
        const menuItem = this.getItemById(menu.Id, menu.Category.Lang[this.$i18n.locale].Slug)
        if (menuItem) this.$bus.$emit('show-menu-options-dialog', { menuItem: menuItem, editMode: false })
      }
    }
  }
}
</script>

<style scoped>
.full-height .flex {
  display : flex;
}

.full-height .flex > .card {
  flex : 1 1 auto;
}
</style>
