export const GoogleGeocoderProvider = (options = {}) => {
  const defaultOptions = {
    autocomplete: true,
    accessToken : '',
    language    : 'el',
    forwardTypes: ['geocode'], // ['geocode', '(cities)', 'address']
    reverseTypes: ['geocode'], // ['geocode', '(cities)', 'address']
    country     : 'gr',
    components  : []
  }
  options = { ...defaultOptions, ...options }

  const validTypes = ['geocode', 'address', 'city', 'postcode']
  const googleValidTypes = ['geocode', 'address', '(cities)', 'postal_code']
  options.forwardTypes = options.forwardTypes.map(type => {
    const index = validTypes.findIndex(item => item === type)
    return googleValidTypes[index] || type
  })

  const service = new window.google.maps.places.AutocompleteService()
  const geocoder = new window.google.maps.Geocoder()
  const sessionToken = new window.google.maps.places.AutocompleteSessionToken()

  // eslint-disable-next-line no-unused-vars
  const geocodeSearch = async (query) => {
    try {
      const result = await new Promise((resolve, reject) => {
        const displaySuggestions = (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) reject(status)
          resolve(predictions)
        }

        service.getPlacePredictions(
          {
            sessionToken         : sessionToken,
            input                : query,
            types                : options.forwardTypes,
            componentRestrictions: { country: options.country || [] },
            language             : options.language,
            fields               : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'business_status', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset_minutes', 'vicinity']
          },
          displaySuggestions
        )
      }).catch(err => {
        throw err
      })

      const places = result || []

      if (!options.autocomplete) return places.length ? getProperties(places[0]) : null

      return places.map(place => getProperties(place))
    } catch (e) {
      if (!options.autocomplete) return null
      return []
    }
  }

  const placeDetails = async (placeId) => {
    try {
      const htmlInputElement = options?.input
      const placesService = new window.google.maps.places.PlacesService(htmlInputElement)

      const place = await new Promise((resolve, reject) => {
        placesService.getDetails({
          placeId: placeId,
          fields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'business_status', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset_minutes', 'vicinity']
        },
        (place, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) reject(status)

          resolve(place)
        })
      })

      return place ? getProperties(place) : null
    } catch (e) {
      return null
    }
  }

  const addressDetails = async (address) => {
    return null
  }

  // eslint-disable-next-line no-unused-vars
  const geocodeReverse = async (location) => {
    try {
      const result = await new Promise((resolve, reject) => {
        geocoder.geocode({
          location: {
            lat: location.lat,
            lng: location.lng
          }
        }, (results, status) => {
          if (status === 'OK') return resolve(results)

          reject()
        })
      })

      const places = result || []
      return places.length ? getProperties(places[0]) : getProperties(null, location)
    } catch (e) {
      return null
    }
  }

  const getProperties = (place, latLng) => {
    return {
      Provider: 'google',
      Id      : place?.place_id || '',
      Name    : place?.formatted_address || place?.description || '',
      Center  : [place?.geometry?.location?.lng() || place?.geometry?.location?.lng || latLng?.lng || -1, place?.geometry?.location?.lat() || place?.geometry?.location?.lat || latLng?.lat || -1] || [],
      LatLng  : {
        lng: place?.geometry?.location?.lng() || place?.geometry?.location?.lng || latLng?.lng || -1,
        lat: place?.geometry?.location?.lat() || place?.geometry?.location?.lat || latLng?.lat || -1
      },
      Properties: {
        Number     : place?.address_components?.find(item => item.types.includes('street_number'))?.short_name || '',
        Postcode   : place?.address_components?.find(item => item.types.includes('postal_code'))?.short_name || '',
        City       : place?.address_components?.find(item => item.types.includes('locality'))?.long_name || place?.address_components?.find(item => item.types.includes('postal_town'))?.long_name || place?.address_components?.find(item => item.types.includes('administrative_area_level_2'))?.short_name || '',
        Street     : place?.address_components?.find(item => item.types.includes('route'))?.long_name || '',
        Country    : place?.address_components?.find(item => item.types.includes('country'))?.long_name || '',
        CountryCode: place?.address_components?.find(item => item.types.includes('country'))?.short_name || ''
      },
      Raw: place
    }
  }

  return {
    geocodeSearch,
    geocodeReverse,
    placeDetails,
    addressDetails
  }
}
