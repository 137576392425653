<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      v-if="src || images.length === 1"
      xs12
    >
      <v-img
        :dark="dark"
        :gradient="gradient"
        :height="heightCalculated"
        :min-height="minHeightCalculated"
        :max-height="maxHeightCalculated"
        :light="light"
        :src="jumbotronSrc"
      >
        <slot>
          <v-container
            v-if="showTexts"
            fill-height
          >
            <v-layout
              column
              justify-center
            >
              <div class="phoneLinks">
                <v-card
                  :color="logo ? 'gfp-toolbar gfp-primary-text--text' : 'transparent'"
                  class="d-inline-block"
                  flat
                  style="opacity: 0.90; border-radius: 8px;"
                >
                  <v-card-title
                    v-if="logo || title"
                    class="pa-0 pa-sm-6 pb-sm-0"
                  >
                    <img
                      v-if="logo"
                      :alt="title"
                      :src="logo"
                      class="pa-1"
                    >
                    <h1
                      v-else-if="title"
                      :class="$vuetify.breakpoint.xsOnly ? 'headline' : 'display-1'"
                      class="white--text"
                    >
                      {{ title }}
                    </h1>
                  </v-card-title>

                  <v-card-text class="pa-0 pt-1">
                    <h4
                      v-if="address"
                      class="white--text pb-2"
                      :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                    >
                      <v-icon
                        v-if="addressIcon"
                        class="mr-2"
                        dark
                      >
                        {{ addressIcon }}
                      </v-icon>
                      {{ address }}
                    </h4>

                    <h4
                      v-if="phone1"
                      class="white--text pb-2"
                      :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                    >
                      <span class="mr-2">
                        <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                          <v-chip
                            v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'label'"
                            label
                            small
                          >
                            {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value }}
                          </v-chip>

                          <img
                            v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'img'"
                            :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value"
                            class="d-inline-block"
                            height="24"
                            style="top: 5px; position: relative;"
                            width="24"
                          >

                          <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'icon'">
                            <v-icon dark> {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value : 'phone' }}</v-icon>
                          </template>
                        </template>

                        <template v-else>
                          <v-icon dark>phone</v-icon>
                        </template>
                      </span>
                      <a
                        :href="'tel:' + phone1"
                        class="phoneLinks"
                      >
                        {{ phone1 }}
                      </a>
                    </h4>

                    <h4
                      v-if="phone2"
                      class="white--text pb-2"
                      :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                    >
                      <span class="mr-2">
                        <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                          <v-chip
                            v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'label'"
                            label
                            small
                          >
                            {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value }}
                          </v-chip>

                          <img
                            v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'img'"
                            :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value"
                            class="d-inline-block"
                            height="24"
                            style="top: 5px; position: relative;"
                            width="24"
                          >

                          <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'icon'">
                            <v-icon dark> {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value : 'phone' }}</v-icon>
                          </template>
                        </template>

                        <template v-else>
                          <v-icon dark>phone</v-icon>
                        </template>
                      </span>
                      <a
                        :href="'tel:' + phone2"
                        class="phoneLinks"
                      >
                        {{ phone2 }}
                      </a>
                    </h4>

                    <h4
                      v-if="phone3"
                      class="white--text pb-2"
                      :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                    >
                      <span class="mr-2">
                        <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                          <v-chip
                            v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'label'"
                            label
                            small
                          >
                            {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value }}
                          </v-chip>

                          <img
                            v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'img'"
                            :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value"
                            class="d-inline-block"
                            height="24"
                            style="top: 5px; position: relative;"
                            width="24"
                          >

                          <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'icon'">
                            <v-icon dark> {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value : 'phone' }}</v-icon>
                          </template>
                        </template>

                        <template v-else>
                          <v-icon dark>phone</v-icon>
                        </template>
                      </span>
                      <a
                        :href="'tel:' + phone3"
                        class="phoneLinks"
                      >
                        {{ phone3 }}
                      </a>
                    </h4>

                    <h4
                      v-if="phone4"
                      class="white--text pb-2"
                      :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                    >
                      <span class="mr-2">
                        <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                          <v-chip
                            v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'label'"
                            label
                            small
                          >
                            {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value }}
                          </v-chip>

                          <img
                            v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'img'"
                            :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value"
                            class="d-inline-block"
                            height="24"
                            style="top: 5px; position: relative;"
                            width="24"
                          >

                          <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'icon'">
                            <v-icon dark> {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value : 'phone' }}</v-icon>
                          </template>
                        </template>

                        <template v-else>
                          <v-icon dark>phone</v-icon>
                        </template>
                      </span>
                      <a
                        :href="'tel:' + phone4"
                        class="phoneLinks"
                      >
                        {{ phone4 }}
                      </a>
                    </h4>
                  </v-card-text>
                </v-card>
              </div>
            </v-layout>
          </v-container>
        </slot>
      </v-img>
    </v-flex>

    <v-flex
      v-else-if="images.length > 0"
      xs12
    >
      <swiper
        key="header-swiper"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(image, index) in images"
          :key="'header-swiper-item' + index"
        >
          <v-img
            :dark="dark"
            :gradient="gradient"
            :height="heightCalculated"
            :min-height="minHeightCalculated"
            :max-height="maxHeightCalculated"
            :light="light"
            :src="image"
          >
            <slot v-if="showTexts">
              <v-container fill-height>
                <v-layout
                  column
                  justify-center
                >
                  <div class="pl-4 phoneLinks">
                    <v-card
                      :color="logo ? 'gfp-toolbar gfp-primary-text--text' : 'transparent'"
                      class="d-inline-block"
                      flat
                      style="opacity: 0.90; border-radius: 16px;"
                    >
                      <v-card-title
                        v-if="logo || title"
                        class="pa-0 pa-sm-6 pb-sm-0"
                      >
                        <img
                          v-if="logo"
                          :alt="title"
                          :src="logo"
                          class="pa-1"
                        >
                        <h1
                          v-else-if="title"
                          :class="$vuetify.breakpoint.xsOnly ? 'headline' : 'display-1'"
                          class="white--text"
                        >
                          {{ title }}
                        </h1>
                      </v-card-title>

                      <v-card-text class="pa-0 pt-1">
                        <h4
                          v-if="address"
                          class="white--text pb-2"
                          :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                        >
                          <v-icon
                            v-if="addressIcon"
                            class="mr-2"
                            dark
                          >
                            {{ addressIcon }}
                          </v-icon>
                          {{ address }}
                        </h4>

                        <h4
                          v-if="phone1"
                          class="white--text pb-2"
                          :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                        >
                          <span class="mr-2">
                            <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                              <v-chip
                                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'label'"
                                label
                                small
                              >
                                {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value }}
                              </v-chip>

                              <img
                                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'img'"
                                :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value"
                                class="d-inline-block"
                                height="24"
                                style="top: 5px; position: relative;"
                                width="24"
                              >

                              <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'icon'">
                                <v-icon dark> {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value : 'phone' }}</v-icon>
                              </template>
                            </template>

                            <template v-else>
                              <v-icon dark>phone</v-icon>
                            </template>
                          </span>
                          <a
                            :href="'tel:' + phone1"
                            class="phoneLinks"
                          >
                            {{ phone1 }}
                          </a>
                        </h4>

                        <h4
                          v-if="phone2"
                          class="white--text pb-2"
                          :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                        >
                          <span class="mr-2">
                            <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                              <v-chip
                                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'label'"
                                label
                                small
                              >
                                {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value }}
                              </v-chip>

                              <img
                                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'img'"
                                :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value"
                                class="d-inline-block"
                                height="24"
                                style="top: 5px; position: relative;"
                                width="24"
                              >

                              <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'icon'">
                                <v-icon dark> {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value : 'phone' }}</v-icon>
                              </template>
                            </template>

                            <template v-else>
                              <v-icon dark>phone</v-icon>
                            </template>
                          </span>
                          <a
                            :href="'tel:' + phone2"
                            class="phoneLinks"
                          >
                            {{ phone2 }}
                          </a>
                        </h4>

                        <h4
                          v-if="phone3"
                          class="white--text pb-2"
                          :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                        >
                          <span class="mr-2">
                            <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                              <v-chip
                                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'label'"
                                label
                                small
                              >
                                {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value }}
                              </v-chip>

                              <img
                                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'img'"
                                :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value"
                                class="d-inline-block"
                                height="24"
                                style="top: 5px; position: relative;"
                                width="24"
                              >

                              <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'icon'">
                                <v-icon dark> {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value : 'phone' }}</v-icon>
                              </template>
                            </template>

                            <template v-else>
                              <v-icon dark>phone</v-icon>
                            </template>
                          </span>
                          <a
                            :href="'tel:' + phone3"
                            class="phoneLinks"
                          >
                            {{ phone3 }}
                          </a>
                        </h4>

                        <h4
                          v-if="phone4"
                          class="white--text pb-2"
                          :class="$vuetify.breakpoint.xsOnly ? 'subheading' : 'title'"
                        >
                          <span class="mr-2">
                            <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                              <v-chip
                                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'label'"
                                label
                                small
                              >
                                {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value }}
                              </v-chip>

                              <img
                                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'img'"
                                :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value"
                                class="d-inline-block"
                                height="24"
                                style="top: 5px; position: relative;"
                                width="24"
                              >

                              <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'icon'">
                                <v-icon dark> {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value : 'phone' }}</v-icon>
                              </template>
                            </template>

                            <template v-else>
                              <v-icon dark>phone</v-icon>
                            </template>
                          </span>
                          <a
                            :href="'tel:' + phone4"
                            class="phoneLinks"
                          >
                            {{ phone4 }}
                          </a>
                        </h4>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-layout>
              </v-container>
            </slot>
          </v-img>
        </swiper-slide>
      </swiper>
    </v-flex>
  </v-layout>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import AppData                 from '@/mixins/appdata'

export default {
  name      : 'PageHeader',
  components: {
    swiper,
    swiperSlide
  },
  directives: {},
  mixins    : [AppData],
  props     : {
    dark: {
      type   : Boolean,
      default: true
    },
    light: {
      type   : Boolean,
      default: false
    },
    showTexts: {
      type   : Boolean,
      default: true
    },
    src: {
      type   : String,
      default: ''
    },
    titleClass: {
      type   : String,
      default: 'white--text'
    },
    phoneClass: {
      type   : String,
      default: 'white--text'
    },
    iconsClass: {
      type   : String,
      default: 'white--text'
    },
    images: {
      type   : Array,
      default: () => {
        return []
      }
    },
    swiperOptions: {
      type   : Object,
      default: () => {
        return {
          slidesPerView: 1,
          spaceBetween : 0,
          loop         : true,
          autoplay     : {
            delay               : 4000,
            disableOnInteraction: false
          },
          effect: 'fade'
        }
      }
    },
    gradient: {
      type   : String,
      default: 'to bottom, rgba(0,0,0, 0), rgba(0,0,0, .4)'
    },
    height: {
      type   : [String, Number],
      default: '100%'
    },
    minHeight: {
      type   : [String, Number],
      default: '65px'
    },
    maxHeight: {
      type   : [String, Number],
      default: '345px'
    },
    title      : String,
    logo       : String,
    address    : String,
    addressIcon: {
      type   : String,
      default: 'location_on'
    },
    phoneIcon: {
      type   : String,
      default: 'phone'
    },
    phone1: String,
    phone2: String,
    phone3: String,
    phone4: String
  },
  data () {
    return {}
  },
  computed: {
    heightCalculated () {
      const heightStr = this.height.toString().includes('px') || this.height.toString().includes('%') ? this.height : this.height + 'px'
      return heightStr
    },
    minHeightCalculated () {
      const heightVal = this.appConfig?.LOCATION_DATA?.GallerySize?.MinHeight || this.minHeight
      const heightStr = heightVal.toString().includes('px') || heightVal.toString().includes('%') ? heightVal : heightVal + 'px'
      return heightStr
    },
    maxHeightCalculated () {
      const heightStr = this.maxHeight.toString().includes('px') || this.maxHeight.toString().includes('%') ? this.maxHeight : this.maxHeight + 'px'
      return heightStr
    },
    jumbotronSrc () {
      if (this.src) {
        return this.src
      } else if (this.images.length === 1) {
        return this.images[0]
      } else {
        return ''
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>
/deep/ .phoneLinks a {
  text-decoration : none;
  color           : #FFF;
}
</style>
