<template>
  <div>
    <v-data-iterator
      :items="items"
      :no-data-text="$t('Account.UserOrders.Pagination.NoDataText')"
      :no-results-text="$t('Account.UserOrders.Pagination.NoResultsText')"
      :pagination.sync="pagination"
      :rows-per-page-items="rowsPerPageItems"
      :rows-per-page-text="$t('Account.UserOrders.Pagination.RowsPerPageText')"

      :total-items="totalItems"
      row
      wrap
    >
      <div slot="no-data">
        <div class="text-xs-center grey--text">
          <v-icon
            class="mb-3 grey--text text--lighten-2"
            size="96"
          >
            remove_shopping_cart
          </v-icon>
          <div class="title mb-2">
            {{ $t('Account.UserOrders.Pagination.NoDataText') }}
          </div>
          <div class="subheading">
            {{ $t('Account.UserOrders.Pagination.NoDataSubText') }}
          </div>
        </div>
      </div>
      <v-list
        slot="item"
        slot-scope="props"
        class="mb-1 mt-0 pb-0 pt-0"
        three-line
      >
        <v-list-tile
          :class="parseInt(props.item.status_id) !== 19 ? 'gfp-adv-pages-account-orders-list-item-background' : 'red lighten-5'"
          avatar
          ripple
          @click="getOrderDetails(props.item)"
        >
          <div
            class="caption pr-3"
            style="display: grid !important;"
          >
            <v-chip
              :class="parseInt(props.item.status_id) !== 19 ? 'green' : 'red'"
              class="white--text"
              disabled
              label
              style="display: inline-block; text-align: center; min-width: 100px;"
            >
              <strong>{{ '#' + props.item.order_id }}</strong>
            </v-chip>

            <v-chip
              v-if="parseInt(props.item.order_type) === 2"
              class="light-blue light-blue--text text--lighten-4"
              disabled
              label
              style="display: inline-block; text-align: center; min-width: 100px;"
            >
              <v-icon size="20">
                directions_run
              </v-icon>
              <span class="pl-2">
                <strong>{{ convertMinutesToDisplayString(parseInt(props.item.order_type_time)) }}</strong>
              </span>
            </v-chip>

            <v-chip
              v-if="parseInt(props.item.order_type) === 1"
              class="purple purple--text text--lighten-3"
              disabled
              label
              style="display: inline-block; text-align: center; min-width: 100px;"
            >
              <v-icon size="20">
                directions_bike
              </v-icon>
              <span class="pl-2">
                <strong>{{ convertMinutesToDisplayString(parseInt(props.item.order_type_time)) }}</strong>
              </span>
            </v-chip>
          </div>

          <v-list-tile-content class="gfp-adv-pages-account-orders-list-item-title--text">
            <template v-for="address in customerInfo.Addresses">
              <v-list-tile-title
                v-if="address.Id == props.item.address_id"
                :key="address.Id"
                class="caption"
              >
                {{ getAddressFormatted(address) }}
              </v-list-tile-title>
            </template>

            <v-list-tile-sub-title class="caption gfp-adv-pages-account-orders-list-item-subtitle--text">
              {{ props.item.date_added + ' - ' + props.item.time_added }}
            </v-list-tile-sub-title>

            <v-list-tile-sub-title>
              <span
                v-if="props.item.payment === 'cod'"
                class="gfp-adv-pages-account-orders-list-item-price--text caption pr-1"
              >
                {{ props.item.order_total | currency }} {{ $t('Cart.PaymentType.Cash') | uppercase | sanitizeDiacritics }}
              </span>
              <span
                v-if="props.item.payment !== 'cod'"
                class="gfp-adv-pages-account-orders-list-item-price--text caption pr-1"
              >
                {{ props.item.order_total | currency }} {{ $t('Cart.PaymentType.Card') | uppercase | sanitizeDiacritics }}
              </span>
            </v-list-tile-sub-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-btn icon>
              <v-icon class="gfp-adv-pages-account-orders-list-item-button-view--text">
                remove_red_eye
              </v-icon>
            </v-btn>

            <v-btn
              v-if="canReorder(props.item)"
              icon
              @click.stop="reOrder(props.item)"
            >
              <v-icon class="gfp-adv-pages-account-orders-list-item-button-reorder--text">
                add_shopping_cart
              </v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider />
      </v-list>
    </v-data-iterator>

    <account-order-details-dialog
      :order-details-dialog-visible.sync="orderDetailsDialogVisible"
      :selected-order="selectedOrder"
    />
  </div>
</template>

<script>
import AppData                   from '../../mixins/appdata'
import Watchable                 from '../../mixins/watchable'
import OrderTypeTime             from '../../mixins/orders/orderTypeTime'
import AccountOrderDetailsDialog from './AccountOrderDetailsDialog'
import AddressCommon             from '@/mixins/maps/addressCommon'

export default {
  name      : 'AccountOrders',
  components: {
    AccountOrderDetailsDialog,
    'account-order-details-dialog': AccountOrderDetailsDialog
  },
  directives: {},
  mixins    : [AppData, AddressCommon, Watchable, OrderTypeTime],
  props     : {
    customerInfo: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      selectedOrder            : null,
      orderDetailsDialogVisible: false,
      rowsPerPageItems         : [5, 10, 20],
      pagination               : {
        descending : true,
        page       : 1,
        rowsPerPage: 5
      },
      totalItems: 0,
      items     : []
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.getData()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    canReorder (order) {
      const allowedOrderSourceTypes = [1, 10, 6, 7, 8, 9]
      return allowedOrderSourceTypes.includes(parseInt(order?.source_type_id))
    },

    reOrder (order) {
      window.API.post(window.APICall.accountReOrder, {
        Id: order.order_id
      })
        .then(response => {
          if (response.data.status === 200) {
            this.orderDetailsDialogVisible = false
            this.$router.push({ name: 'CheckoutDetails' })
          }
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$bus.$emit('logout', true)
            location.reload()
          }
        })
    },

    getOrderDetails (order) {
      window.API.post(window.APICall.accountOrderDetails, {
        Id: order.order_id
      })
        .then(response => {
          if (response.data.status === 200) {
            this.selectedOrder = response.data.data
            this.orderDetailsDialogVisible = true
          }
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$bus.$emit('logout', true)
            location.reload()
          }
        })
    },

    getData () {
      // eslint-disable-next-line camelcase
      const customer_id = this.dataStore.userInfo.Info.Id
      const page = this.pagination.page
      const limit = this.pagination.rowsPerPage

      window.API.post(window.APICall.accountOrders, {
        customer_id: customer_id,
        page       : page,
        limit      : limit
      })
        .then(response => {
          if (response.data.status === 200) {
            this.totalItems = response.data.data.count
            this.items = response.data.data.count > 0 ? response.data.data.items : []
            this.watch('pagination', this.getData)
          }
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$bus.$emit('logout', true)
            location.reload()
          }
        })
    }
  }
}
</script>

<style scoped>
.v-list__tile__title, .v-list__tile__sub-title {
  white-space : normal;
}
</style>
