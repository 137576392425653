<template>
  <v-container
    v-if="order"
    :class="[{'pa-0': $vuetify.breakpoint.xsOnly}]"
    fluid
    grid-list-lg
    pt-0
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        pt-0
      >
        <v-stepper
          value="2"
          alt-labels
          class="elevation-0 mt-2 gfp-adv-pages-checkout-steps-background"
          :dark="getContrastColor($vuetify.theme['gfp-adv-pages-checkout-steps-background'].base) === 'white'"
        >
          <v-stepper-header>
            <v-stepper-step
              step="1"
              complete
              color="green"
            >
              {{ $t('Checkout.Details.Title') }}
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              step="2"
              color="blue"
            >
              {{ $t('Checkout.Payment.Title') }}
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-flex>

      <template v-if="isQrOrderWithCustomerData">
        <v-flex xs12>
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
                <v-icon
                  class="pr-2"
                  color="gfp-adv-pages-checkout-details-header-icon"
                >
                  account_circle
                </v-icon>
                {{ $t('Checkout.Details.Info') }}
              </v-subheader>
            </v-flex>
            <v-flex
              sm6
              xs12
            >
              <v-text-field
                v-model="order.Firstname"
                :label="$t('Form.Firstname.Label')"
                disabled
                prepend-icon="account_circle"
              />
            </v-flex>
            <v-flex
              sm6
              xs12
            >
              <v-text-field
                v-model="order.Lastname"
                :label="$t('Form.Lastname.Label')"
                disabled
                prepend-icon="account_circle"
              />
            </v-flex>
            <v-flex
              sm6
              xs12
            >
              <v-text-field
                v-model="order.Email"
                :label="$t('Form.Email.Label')"
                disabled
                prepend-icon="email"
              />
            </v-flex>
            <v-flex
              sm6
              xs12
            >
              <v-text-field
                v-model="order.Telephone"
                :label="$t('Form.Telephone.Label')"
                disabled
                prepend-icon="phone"
              />
            </v-flex>
            <v-flex
              class="text-xs-right"
              xs12
            >
              <v-btn
                class="elevation-0"
                color="blue-grey"
                dark
                @click="changeData"
              >
                {{ $t('Buttons.ChangeInfo') | sanitizeDiacritics }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>

      <v-flex
        v-if="dataStore.cart && dataStore.cart.OrderType==='1' && order.Address && !isQrOrder"
        xs12
      >
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
              <v-icon
                class="pr-2"
                color="gfp-adv-pages-checkout-details-header-icon"
              >
                location_on
              </v-icon>
              {{ $t('Checkout.Details.Address') }}
            </v-subheader>
          </v-flex>
          <v-flex xs12>
            <v-card height="100%">
              <v-card-text>
                <v-img
                  v-if="isCustomAddress(order.Address)"
                  height="300"
                  position="center center"
                  src="/img/map/map-placeholder.png"
                  style="border: 1px solid #e2e2e2;"
                  width="100%"
                />
                <v-map
                  v-else-if="order"
                  :map-center="{lat: parseFloat(order.Address.Lat), lng: parseFloat(order.Address.Lng)}"
                  :address-marker="{lat: parseFloat(order.Address.Lat), lng: parseFloat(order.Address.Lng)}"
                  show-address-marker
                  show-store-marker
                  map-static
                  width="1080"
                  height="250"
                />
              </v-card-text>
              <v-card-title
                class="pt-0 align-start"
                style="min-height: 100px"
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    class="pl-2 pr-2 pa-0"
                    style="min-height: 34px"
                    xs12
                  >
                    <v-chip
                      v-if="order.Address.Nickname"
                      class="ml-0"
                      color="pink"
                      label
                      small
                      text-color="white"
                    >
                      {{ order.Address.Nickname }}
                    </v-chip>
                  </v-flex>
                  <v-flex
                    class="pa-2"
                    xs12
                  >
                    <h5>{{ getAddressFormatted(order.Address) }}</h5>
                  </v-flex>

                  <v-flex
                    class="pl-2 pr-2 pa-0"
                    xs12
                  >
                    <span
                      v-if="order.Address.Floor"
                      class="pr-2"
                      style="white-space: nowrap;"
                    >
                      <h5 class="d-inline-block">
                        {{ $t('Account.UserAddresses.Floor') }}:
                      </h5> {{ order.Address.Floor }}
                    </span>
                    <span
                      v-if="order.Address.DoorBell"
                      style="white-space: nowrap;"
                    >
                      <h5 class="d-inline-block">
                        {{ $t('Account.UserAddresses.DoorBell') }}:
                      </h5> {{ order.Address.DoorBell }}
                    </span>
                    <span
                      v-if="order.Address.Comment"
                      class="d-block"
                      style="white-space: nowrap;"
                    >
                      <h5 class="d-inline-block">
                        {{ $t('Account.UserAddresses.Comment') }}:
                      </h5> {{ order.Address.Comment }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-card-actions class="justify-end">
                <v-btn
                  class="elevation-0"
                  color="blue-grey"
                  dark
                  @click="changeData"
                >
                  {{ $t('Buttons.ChangeAddress') | sanitizeDiacritics }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex
        v-if="!isQrOrder"
        xs12
      >
        <v-textarea
          v-model="orderComment"
          :hint="$t('Form.Comments.Hint')"
          :label="$t('Form.Comments.Label')"
          auto-grow
          prepend-icon="chat"
          rows="1"
        />
      </v-flex>
      <v-flex
        v-if="isCollection || (isQrOrder && (isCollection || isDelivery))"
        xs12
      >
        <v-subheader class="white--text orange darken-2 caption pa-1">
          <v-icon
            class="pr-2"
            color="gfp-adv-pages-checkout-details-header-icon"
          >
            info
          </v-icon>
          <div
            class="font-weight-regular"
            style="width: 100%;"
          >
            <template v-if="isQrOrder">
              <div class="font-weight-bold">
                {{ $t(`Order.QrSourceType.${ QrData.type }.Title`) }} #{{ QrData.value }}
              </div>
            </template>
            <template v-if="isCollection">
              {{ $t('Cart.OrderType.Warning') }}&nbsp;{{ $t('Cart.OrderType.Minutes', {min: collectionTime}) }}
            </template>
            <template v-if="isDelivery && isQrOrder">
              {{ $t('Order.InternalDelivery') }}&nbsp;{{ $t('Cart.OrderType.Minutes', {min: isCollection ? collectionTime : deliveryTime}) }}
            </template>
          </div>
        </v-subheader>
      </v-flex>

      <!-- Payment Methods-->
      <v-flex xs12>
        <select-payment-method
          v-model="selectedPaymentMethod"
          :payments="payments"
        />
      </v-flex>

      <!-- Payment Gateways-->
      <v-flex xs12>
        <generic-payment-gateway v-if="selectedPaymentMethod.code === 'cod' || selectedPaymentMethod.code === 'delivery_card'" />

        <viva3ds-payment-gateway v-if="selectedPaymentMethod.code === 'viva_card_3ds'" />

        <viva-smart-checkout-payment-gateway v-if="selectedPaymentMethod.code === 'viva_card_smart_checkout'" />

        <everypay-payment-gateway v-if="selectedPaymentMethod.code === 'everypay_card'" />

        <stripe-element-payment-gateway v-if="selectedPaymentMethod.code === 'stripe'" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>import AppData                 from '@/mixins/appdata'
import OrderTypeTime                   from '@/mixins/orders/orderTypeTime'
import CartCommon                      from '@/mixins/orders/cartCommon'
import SelectPaymentMethod             from '@/components/cart/checkout/payment/common/SelectPaymentMethods.vue'
import GenericPaymentGateway           from '@/components/cart/checkout/payment/GenericPaymentGateway.vue'
import StripeElementPaymentGateway     from '@/components/cart/checkout/payment/StripeElementPaymentGateway.vue'
import VivaSmartCheckoutPaymentGateway from '@/components/cart/checkout/payment/VivaSmartCheckoutPaymentGateway.vue'
import Viva3dsPaymentGateway           from '@/components/cart/checkout/payment/Viva3dsPaymentGateway.vue'
import EverypayPaymentGateway          from '@/components/cart/checkout/payment/EverypayPaymentGateway.vue'
import { getContrastColor }            from '@/lib/color'
import { CapacitorService }            from '@/lib/services/Capacitor'
import VMap                            from '@/components/common/maps/VMap.vue'
import MapsCommon                      from '@/mixins/maps/mapsCommon'
import AddressCommon                   from '@/mixins/maps/addressCommon'

const isDeviceAvailable = CapacitorService.isPluginAvailable('Device')
const isAppAvailable = CapacitorService.isPluginAvailable('App') && typeof CapacitorService.Plugins.App?.getInfo === 'function'

export default {
  name      : 'CheckoutPayment',
  components: {
    VMap,
    EverypayPaymentGateway,
    Viva3dsPaymentGateway,
    VivaSmartCheckoutPaymentGateway,
    StripeElementPaymentGateway,
    GenericPaymentGateway,
    SelectPaymentMethod
  },
  directives: {},
  mixins    : [AppData, CartCommon, MapsCommon, AddressCommon, OrderTypeTime],
  props     : [],
  data () {
    return {
      order   : null,
      payments: []
    }
  },
  computed: {},

  watch: {
    selectedPaymentMethod: {
      handler (newVal, oldVal) {
        if (newVal?.code === oldVal?.code || !newVal) return
        if (!this.selectedPaymentMethod?.tip && this.dataStore?.cart?.CartTotals?.tip_amount?.amount_raw) {
          this.$bus.$emit('cart-tip-remove')
        }
      },
      immediate: false
    }
  },

  created () {
    this.getData()
  },

  mounted () {},

  destroyed () {},

  methods: {
    getContrastColor,
    changeData () {
      this.$router.replace({ name: 'CheckoutDetails' })
    },

    async getData () {
      let appSupportsVivaSmartCheckout = true
      if (CapacitorService.isNative && (isDeviceAvailable || isAppAvailable)) {
        const deviceInfo = isDeviceAvailable ? await CapacitorService.Plugins.Device.getInfo() : null
        const appInfo = isAppAvailable ? await CapacitorService.Plugins.App.getInfo() : null
        const appBuild = parseInt(deviceInfo?.appBuild || appInfo?.build || 1)
        appSupportsVivaSmartCheckout = appBuild >= 3
      }

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.checkoutPayment, {})
        .then(response => {
          if (response.data && response.data.status === 200) {
            this.$set(this.dataStore, 'cart', response.data.data.Cart)
            this.order = response.data.data.Cart.Order

            if (appSupportsVivaSmartCheckout) {
              this.payments = response.data.data.Payments
            } else {
              this.payments = response.data.data.Payments.filter(payment => payment.code !== 'viva_card_smart_checkout')
            }

            if (this.selectedPaymentMethod.init) {
              const paymentMethod = this.payments.find(payment => payment.code === this.selectedPaymentMethod.code) || null
              if (paymentMethod) this.selectedPaymentMethod = paymentMethod
            }

            if (response.data.data.PaymentCancel) {
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.PaymentCancel,
                type : 'error',
                icon : ''
              })
            }
          }
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$router.replace({ name: 'Menu' })
            location.reload()
          }
        })
      )
    }
  }
}
</script>

<style scoped>
/deep/ .v-input--radio-group--row .v-input__control {
  width : 100%;
}

/deep/ .v-input--radio-group--row .v-list--two-line {
  width : 100%;
}

/deep/ .v-stepper__step {
  padding : 16px;
}

@media only screen and (max-width : 959px) {
  /deep/ .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display : block;
  }
}
</style>
