<template>
  <v-layout
    justify-center
    row
  >
    <v-dialog
      v-model="isVisible"
      :max-width="width"
      lazy
      persistent
    >
      <v-card color="gfp-adv-common-confirm-body-background">
        <v-toolbar
          :class="[titleClass]"
          card
          class="pl-1"
          color="gfp-adv-common-confirm-toolbar-background"
          height="80"
          prominent
        >
          <v-icon
            color="gfp-adv-common-confirm-toolbar-icon"
            large
          >
            {{ titleIcon }}
          </v-icon>

          <v-toolbar-title class="pl-1">
            <div
              class="headline gfp-adv-common-confirm-toolbar-title--text"
              v-html="htmlTitle"
            />
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            dark
            icon
            :color="getContrastColor($vuetify.theme['gfp-adv-common-confirm-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider v-if="htmlContent" />

        <v-card-text
          v-if="htmlContent"
          class="subheading pa-4 gfp-adv-common-confirm-body-text--text"
          v-html="htmlContent"
        />

        <v-divider />

        <v-card-actions class="gfp-adv-common-confirm-actions-background">
          <v-btn
            v-if="cancelButton"
            :class="[cancelButtonClass]"
            class="elevation-0"
            @click.native="onCancelButtonClick"
          >
            {{ cancelButtonText }}
          </v-btn>

          <v-spacer />

          <v-btn
            v-if="noButton"
            :class="[noButtonClass]"
            class="elevation-0"
            @click.native="onNoButtonClick"
          >
            {{ noButtonText }}
          </v-btn>

          <v-btn
            v-if="yesButton"
            :class="[yesButtonClass]"
            class="elevation-0"
            @click.native="onYesButtonClick"
          >
            {{ yesButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import i18n                 from '../../lang/lang'
import { getContrastColor } from '@/lib/color'

export default {
  name : 'ConfirmDialog',
  props: {
    visible: {
      type   : Boolean,
      default: false
    },
    htmlTitle: {
      type   : String,
      default: 'Είστε Σίγουροι ?'
    },
    titleClass: {
      type   : String,
      default: ''
    },
    titleIcon: {
      type   : String,
      default: 'live_help'
    },
    htmlContent: {
      type   : String,
      default: ''
    },
    yesButton: {
      type   : Boolean,
      default: true
    },
    yesButtonEvent: {
      type   : String,
      default: 'confirm-dialog-yes'
    },
    yesButtonText: {
      type   : String,
      default: i18n.t('Buttons.Yes')
    },
    yesButtonClass: {
      type   : String,
      default: 'outline gfp-adv-common-confirm-actions-button-yes-background gfp-adv-common-confirm-actions-button-yes-text--text'
    },
    noButton: {
      type   : Boolean,
      default: true
    },
    noButtonEvent: {
      type   : String,
      default: 'confirm-dialog-no'
    },
    noButtonText: {
      type   : String,
      default: i18n.t('Buttons.No')
    },
    noButtonClass: {
      type   : String,
      default: 'btn--outline gfp-adv-common-confirm-actions-button-no-background--text'
    },
    cancelButton: {
      type   : Boolean,
      default: false
    },
    cancelButtonEvent: {
      type   : String,
      default: 'confirm-dialog-cancel'
    },
    cancelButtonText: {
      type   : String,
      default: i18n.t('Buttons.Cancel')
    },
    cancelButtonClass: {
      type   : String,
      default: 'btn--outline gfp-adv-common-confirm-actions-button-cancel-background--text'
    },
    width: {
      type   : Number,
      default: 500
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    getContrastColor,
    onYesButtonClick () {
      this.isVisible = false
      this.$emit(this.yesButtonEvent)
    },
    onNoButtonClick () {
      this.isVisible = false
      this.$emit(this.noButtonEvent)
    },
    onCancelButtonClick () {
      this.isVisible = false
      this.$emit(this.cancelButtonEvent)
    }
  }
}
</script>
