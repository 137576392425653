<template>
  <v-item-group v-model="model">
    <v-item
      v-for="(amount, index) in amounts"
      :key="`delivery-tip-btn-${index}`"
      :disabled="IsLoading"
      :value="amount"
    >
      <v-btn
        slot-scope="{active, toggle}"
        :class="active ? textColorActive : textColor"
        :color="active ? buttonColorActive : buttonColor"
        :disabled="IsLoading"
        :loading="active && IsLoading"
        :value="amount"
        :small="small"
        class="mt-1 ml-1 pa-1"
        depressed
        @click="toggle"
      >
        {{ amount | currency }}

        <v-icon
          :color="active ? iconColorActive : iconColor"
          class="ml-2"
          small
        >
          {{ active ? 'close' : 'add' }}
        </v-icon>
      </v-btn>
    </v-item>
  </v-item-group>
</template>

<script>
import CartCommon from '@/mixins/orders/cartCommon'

export default {
  name      : 'DeliveryTip',
  components: {},
  directives: {},
  mixins    : [CartCommon],
  props     : {
    amounts: {
      type   : Array,
      default: function () { return this.$t('Cart.Tip.Amounts') }
    },
    buttonColorActive: {
      type   : String,
      default: 'gfp-adv-common-cart-tip-button-background-active'
    },
    buttonColor: {
      type   : String,
      default: 'gfp-adv-common-cart-tip-button-background'
    },
    textColorActive: {
      type   : String,
      default: 'gfp-adv-common-cart-tip-button-text-active--text'
    },
    textColor: {
      type   : String,
      default: 'gfp-adv-common-cart-tip-button-text--text'
    },
    iconColorActive: {
      type   : String,
      default: 'gfp-adv-common-cart-tip-button-icon-active'
    },
    iconColor: {
      type   : String,
      default: 'gfp-adv-common-cart-tip-button-icon'
    },
    small: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {
      value: null
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.value = val
      }
    }
  },
  watch: {
    'dataStore.cart.CartTotals.tip_amount': function (tip) {
      if (!tip) {
        this.value = null
        return
      }

      if (this.value === parseFloat(tip.amount_raw)) return

      this.value = parseFloat(tip.amount_raw)
    },
    model (newVal, oldVal) {
      if (parseFloat(newVal) === parseFloat(oldVal) || parseFloat(newVal) === parseFloat(this.dataStore?.cart?.CartTotals?.tip_amount?.amount_raw ?? 0)) return

      if (newVal) {
        this.addTip(newVal)
      } else {
        this.removeTip()
      }
    }
  },
  beforeCreate () {},
  created () {
    this.$bus.$on('cart-tip-loading', this.onCartIsLoading)
    this.value = this.dataStore?.cart?.CartTotals?.tip_amount?.amount_raw ? parseFloat(this.dataStore?.cart?.CartTotals?.tip_amount?.amount_raw) : null
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('cart-tip-loading', this.onCartIsLoading)
  },
  destroyed () {},
  methods: {
    addTip (tipAmount) {
      if (!parseFloat(tipAmount)) return
      this.$bus.$emit('cart-tip-add', parseFloat(tipAmount))
    },

    removeTip () {
      this.$bus.$emit('cart-tip-remove')
    },

    onCartIsLoading (val) {
      this.IsLoading = val
    }
  }
}
</script>

<style scoped>

</style>
