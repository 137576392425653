<template>
  <div class="snotifyToast__buttons">
    <button
      v-for="(button, index) in buttons"
      :key="index"
      :class="[{'snotifyToast__buttons--bold': button.bold}, button.class]"
      type="button"
      @click="button.action && button.action(id, value)"
    >
      {{ button.text }}
    </button>
  </div>
</template>

<script>
export default {
  props: ['buttons', 'value', 'id']
}
</script>
