<template>
  <v-container
    v-resize="onResize"
    fluid
    pt-1
  >
    <v-layout
      row
      wrap
    >
      <!-- Category Menu Item -->
      <v-flex xs12>
        <v-card
          v-if="dataStoreMenu && selectedMenuItem && selectedMenuItem.Lang && selectedMenuItem.Lang[$i18n.locale]"
          color="transparent"
          flat
        >
          <template v-if="!selectedMenuItem.Image || !UseMenuLargeImage">
            <v-toolbar
              :height="toolbarHeight"
              :style="'min-height:'+ toolbarHeight + 'px'"
              card
              class="menu-image"
              color="grey lighten-3 grey--text text--darken-2 title py-2"
              light
              prominent
            >
              <div
                v-if="selectedMenuItem.Image && !UseMenuLargeImage && $vuetify.breakpoint.smAndUp"
                style="width: 180px; height: 100px;"
              >
                <img
                  style="width: 180px; height: 100px; border-radius: 5px; object-fit: cover;"
                  :src="selectedMenuItem.Image"
                >
              </div>

              <v-toolbar-title ref="toolbarTitle">
                <div class="title">
                  <template v-if="selectedMenuItem.Lang">
                    <strong>{{ selectedMenuItem.Lang[$i18n.locale].Name }}</strong>
                  </template>
                </div>

                <div
                  v-if="selectedMenuItem.Lang && (selectedMenuItem.Lang[$i18n.locale].Description || selectedMenuItem.Description)"
                  class="caption pt-2"
                  v-html="selectedMenuItem.Lang[$i18n.locale].Description || selectedMenuItem.Description"
                />
              </v-toolbar-title>

              <v-spacer />

              <div class="title">
                <strong
                  v-if="selectedMenuItem.Quantity > 1"
                  class="orange--text"
                >
                  {{ selectedMenuItem.Quantity }} x
                </strong>
                <template v-if="selectedMenuItem.SpecialStatus && selectedMenuItem.IsSpecial">
                  <span class="old-price">
                    {{ selectedMenuItem.PriceFmt | currency }}
                  </span> {{ selectedMenuItem.SpecialPriceFmt | currency }}
                </template>
                <template v-else>
                  {{ selectedMenuItem.PriceFmt | currency }}
                </template>
              </div>
            </v-toolbar>

            <v-divider />
          </template>

          <v-card-text
            ref="dialogOptionsContent"
            :style="isCatalogOnly ? selectedMenuItem.Options && selectedMenuItem.Options.length <= 0 && selectedMenuItem.Image ? 'padding-top: 0; padding-bottom:0;' : '' : ''"
            style="min-height: 200px;"
            class="fill-height pa-0"
          >
            <v-img
              v-if="selectedMenuItem.Image && UseMenuLargeImage"
              :src="selectedMenuItem.Image"
              max-height="350"
              style="border-radius: 0 0 8px 8px;"
            >
              <v-container
                fill-height
                fluid
              >
                <v-layout
                  align-end
                  row
                  wrap
                >
                  <v-flex xs12>
                    <div
                      v-if="selectedMenuItem.Lang"
                      class="pa-2 d-inline-block align-end justify-end"
                      style="background-color: rgba(255,255,255,0.75); border-radius: 5px;"
                    >
                      <div class="title grey--text text--darken-2">
                        <template v-if="selectedMenuItem.Lang">
                          <strong>{{ selectedMenuItem.Lang[$i18n.locale].Name }}</strong>
                        </template>
                      </div>

                      <div class="title pt-2">
                        <strong
                          v-if="selectedMenuItem.Quantity > 1"
                          class="orange--text"
                        >
                          {{ selectedMenuItem.Quantity }} x
                        </strong>

                        <template v-if="selectedMenuItem.SpecialStatus && selectedMenuItem.IsSpecial">
                          <span class="old-price">
                            {{ selectedMenuItem.PriceFmt | currency }}
                          </span> {{ selectedMenuItem.SpecialPriceFmt | currency }}
                        </template>
                        <template v-else>
                          {{ selectedMenuItem.PriceFmt | currency }}
                        </template>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>

            <div class="ma-0">
              <div
                v-if="selectedMenuItem.Image && UseMenuLargeImage && selectedMenuItem.Lang && (selectedMenuItem.Lang[$i18n.locale].Description || selectedMenuItem.Description)"
                class="subheading my-2 mx-1"
                v-html="selectedMenuItem.Lang[$i18n.locale].Description || selectedMenuItem.Description"
              />

              <template v-if="selectedMenuItem.Options && selectedMenuItem.Options.length <= 0 && isCatalogOnly">
                <div
                  v-if="!selectedMenuItem.Image"
                  class="d-flex align-center justify-center"
                >
                  <v-img
                    :alt="appConfig.LOCATION_DATA.Name"
                    :src="getContrastLogo(LOGO_TYPE.MAIN)"
                    max-width="250"
                    style="opacity: 0.25;"
                  />
                </div>
              </template>

              <template v-else>
                <v-form>
                  <v-container
                    fluid
                    grid-list-xs
                    ma-0
                    pa-0
                  >
                    <v-layout
                      row
                      wrap
                    >
                      <template v-for="item in selectedMenuItem.Options">
                        <v-flex
                          v-if="selectedMenuItem.Lang && selectedMenuItem.Lang[$i18n.locale]"
                          v-show="item.Visible !== false"
                          :key="item.Id"
                          xs12
                        >
                          <v-flex
                            subheading
                            xs12
                          >
                            <template v-if="item.DisplayType === 'separator'">
                              <div
                                v-if="item.DisplayName"
                                class="text-xs-center separator-title font-weight-bold mt-2 pl-1"
                              >
                                {{ item.Lang[$i18n.locale].Name }}
                              </div>
                              <v-divider v-else />
                            </template>

                            <div
                              v-else
                              class="font-weight-bold mt-2 pl-1"
                            >
                              {{ item.Lang[$i18n.locale].Name }}
                            </div>

                            <v-alert
                              v-if="item.Error"
                              :value="true"
                              class="mx-2 pa-1 caption"
                              color="error"
                              icon="warning"
                              outline
                            >
                              {{ item.Error.Message }}
                            </v-alert>
                          </v-flex>
                          <v-flex xs12>
                            <v-container
                              fluid
                              grid-list-xs
                              ma-0
                              pa-0
                            >
                              <v-layout
                                row
                                wrap
                              >
                                <template v-if="item.DisplayType === 'checkbox'">
                                  <template v-for="option in item.OptionValues">
                                    <v-flex
                                      v-if="option.Lang && option.Lang[$i18n.locale]"
                                      v-show="option.Visible!==false"
                                      :key="item.Id + '-checkbox-' + option.Id"
                                      class="xs12 sm6"
                                    >
                                      <v-card
                                        class="ma-1"
                                        style="cursor: pointer;"
                                        flat
                                        @click="onCheckBoxClick(option)"
                                      >
                                        <v-card-text class="pa-2">
                                          <div class="option-value-row">
                                            <div class="option-value-row-control">
                                              <v-checkbox
                                                v-model="option.Selected"
                                                :disabled="(option.Mandatory && option.Selected) || option.Disabled"
                                                :required="option.Mandatory"
                                                readonly
                                                class="mt-0 pt-0"
                                                color="success"
                                                hide-details
                                              >
                                                <template #label>
                                                  <span
                                                    :class="{'font-weight-bold': option.Selected}"
                                                    style="font-size: 14px; line-height: 17px;"
                                                    v-html="option.Lang[$i18n.locale].Name"
                                                  />
                                                </template>
                                              </v-checkbox>
                                            </div>

                                            <div class="option-value-row-price">
                                              <div
                                                v-if="option.ShowPrice"
                                                :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                                class="caption right pr-1"
                                                style="margin-top: 2px;"
                                              >
                                                {{ option.PriceFmt | currency }}
                                              </div>
                                            </div>
                                          </div>
                                        </v-card-text>
                                      </v-card>
                                    </v-flex>
                                  </template>
                                </template>

                                <v-flex xs12>
                                  <v-radio-group
                                    v-if="item.DisplayType === 'radio'"
                                    v-model="item.DefaultValueId"
                                    :mandatory="item.Required"
                                    class="pa-0 ma-0"
                                    hide-details
                                    row
                                    @change="onRadioChange"
                                  >
                                    <template v-for="option in item.OptionValues">
                                      <v-flex
                                        v-if="option.Lang && option.Lang[$i18n.locale]"
                                        v-show="option.Visible!==false"
                                        :key="item.Id + '-radio-' + option.Id"
                                        class="xs12 sm6"
                                      >
                                        <v-card
                                          class="ma-1"
                                          style="cursor: pointer;"
                                          flat
                                          @click="onRadioClick(item, option)"
                                        >
                                          <v-card-text class="pa-2">
                                            <div class="option-value-row">
                                              <div class="option-value-row-control">
                                                <v-radio
                                                  :value="option.Id"
                                                  class="mt-0 pt-0"
                                                  color="success"
                                                  hide-details
                                                  placeholder=""
                                                >
                                                  <span
                                                    slot="label"
                                                    :class="{'font-weight-bold': option.Selected}"
                                                    style="font-size: 14px; line-height: 17px;"
                                                    v-html="option.Lang[$i18n.locale].Name"
                                                  />
                                                </v-radio>
                                              </div>

                                              <div class="option-value-row-price">
                                                <span
                                                  v-if="option.ShowPrice"
                                                  :class="[parseFloat(option.Price)!==0 ? (option.Mandatory && option.Selected || option.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                                  class="caption right pt-2 pr-1"
                                                  style="min-height: 24px; line-height: 10px;"
                                                >
                                                  {{ option.PriceFmt | currency }}
                                                </span>
                                              </div>
                                            </div>
                                          </v-card-text>
                                        </v-card>
                                      </v-flex>
                                    </template>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-flex>
                      </template>

                      <v-flex
                        v-if="!isCatalogOnly"
                        xs12
                      >
                        <v-textarea
                          v-model="selectedMenuItem.Comment"
                          class="mx-2"
                          :hint="$t('Form.MenuComments.Hint')"
                          :label="$t('Form.MenuComments.Label')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </template>
            </div>
          </v-card-text>

          <v-card-actions
            v-if="!isCatalogOnly"
            class="pa-2 mt-3"
          >
            <template v-if="dataStore.cart">
              <v-alert
                :dismissible="false"
                :value="dataStore.cart.IsClosed"
                class="ma-0 caption white text-xs-center"
                outline
                style="width: 100%; position:sticky;"
                type="error"
              >
                <span
                  style="width:100%;"
                  v-html="$t('Common.Error.StoreClosed')"
                />
              </v-alert>

              <v-layout
                v-if="!dataStore.cart.IsClosed"
                row
                wrap
              >
                <v-flex
                  v-if="!!menuItemSelectedOptionsText && showSelectedMenuOptions"
                  xs12
                >
                  <div class="caption px-1 pt-1 pb-2">
                    <strong>{{ $t('MenuOptionsDialog.SelectedOptions') }}:</strong> {{ menuItemSelectedOptionsText }}
                  </div>
                </v-flex>

                <v-flex
                  class="text-xs-left pa-1 no-wrap"
                  xs5
                >
                  <span class="hidden-xs-only pr-2">
                    {{ $t('Buttons.Quantity').toUpperCase() | sanitizeDiacritics }}
                  </span>

                  <v-btn
                    :disabled="quantity<=parseInt(selectedMenuItem.MinimumQuantity)"
                    class="grey--text elevation-0"
                    outline
                    style="min-width: 40px; max-width: 40px;"
                    @click="decrementQuantity"
                  >
                    -
                  </v-btn>

                  <v-btn
                    class="grey--text elevation-0 ml-1"
                    disabled
                    outline
                    style="min-width: 50px; max-width: 120px; background-color: white !important;"
                  >
                    {{ selectedMenuItem.Quantity }}
                  </v-btn>

                  <v-btn
                    :disabled="quantity>=parseInt(selectedMenuItem.StockQuantity) && selectedMenuItem.SubtractStock"
                    class="grey--text elevation-0 ml-1"
                    outline
                    style="min-width: 40px; max-width: 40px;"
                    @click="incrementQuantity"
                  >
                    +
                  </v-btn>
                </v-flex>

                <v-flex
                  class="text-xs-right py-1 pl-1"
                  xs7
                >
                  <v-btn
                    :disabled="submitButton"
                    :loading="submitButton"
                    class="elevation-0 white--text pa-2"
                    color="success"
                    style="min-width: 155px;"
                    @click="onAddToCartClick"
                  >
                    <div class="mr-2 flex text-xs-left">
                      {{ $t('Buttons.Add') | sanitizeDiacritics }}
                    </div>
                    <div class="flex text-xs-right">
                      {{ menuItemTotal | currency }}
                    </div>
                  </v-btn>
                </v-flex>
              </v-layout>
            </template>
          </v-card-actions>

          <v-card-actions
            v-else-if="isCatalogOnly && hasLandingPage && hasMultipleLocations"
            class="pa-2"
          >
            <v-spacer />

            <v-btn
              :href="landingPage"
              class="success white--text"
              flat
            >
              {{ $t('CategoriesBlock.Title') | sanitizeDiacritics }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import AppData          from '@/mixins/appdata'
import MenuItemCommon   from '@/mixins/menu/menuItemCommon'
import DataEventService from '@/lib/services/event/DataEventService'
import DataEventEnum    from '@/lib/services/enum/DataEventEnum'

export default {
  name      : 'MenuItemBlock',
  components: {},
  directives: {},
  mixins    : [AppData, MenuItemCommon],
  props     : {
    menuItemSlug: {
      type    : String,
      required: false
    },
    slug: {
      type    : String,
      required: false
    },
    hasFeaturedMenus: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedMenuItem: null,
      toolbarVisible  : false,
      toolbarColor    : 'white',
      toolbarHeight   : 110
    }
  },
  computed: {
    showSelectedMenuOptions () {
      return this?.appConfig?.LOCATION_DATA?.ShowSelectedMenuOptions || false
    },

    menuItemSelectedOptionsText () {
      return this.selectedMenuItem.Options
        ?.map(option => option.OptionValues
          ?.filter(optionValue => optionValue.Selected)
          ?.map(optionValue => optionValue?.Lang[this.$i18n.locale]?.Name || optionValue?.Name)?.join(', '))
        ?.filter(item => item.length)?.join(', ')
    }
  },
  watch: {
    dataStoreMenu () {
      this.getData()
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {
    this.$bus.$on('cart:add:response', this.onItemAddedToCart)
    this.menuItemViewEventGA()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {
    this.$bus.$off('cart:add:response', this.onItemAddedToCart)
  },
  methods: {
    getData () {
      if (!this.dataStoreMenu) return

      const menuItemObj = this.getMenuItemBySlug(this.menuItemSlug, this.slug)

      if (!menuItemObj) return

      if (menuItemObj?.DetailsData) {
        this.setMenuItemData(menuItemObj.DetailsData)
      } else {
        window.API.get(`${ window.APICall.menuOptions }${ menuItemObj.Id }`)
          .then(response => {
            if (response.data.status === 200) {
              this.setMenuItemData(response.data.data)
            }
          })
          .catch(() => {})
      }
    },

    setMenuItemData (data) {
      this.selectedMenuItem = JSON.parse(JSON.stringify(data))
      this.submitButton = false
      this.selectedMenuItem.Quantity = this.quantity
      this.$set(this.selectedMenuItem, 'OptionsCopy', JSON.parse(JSON.stringify(this.selectedMenuItem.Options)))
      this.onRadioChange()
      this.onCheckBoxChange()
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
      })
    },

    getMenuItemBySlug (menuSlug, categorySlug) {
      let item = null

      if (menuSlug && categorySlug && this.dataStoreMenu) {
        const menuSlugArray = menuSlug.split('-')
        const menuItemId = menuSlugArray[menuSlugArray.length - 1]
        const category = Object.entries(this.dataStoreMenu.items).find((obj) => obj[1].Category.Lang[this.$i18n.locale] && String(obj[1].Category.Lang[this.$i18n.locale].Slug) === String(categorySlug))[1]
        if (category) item = category.Items.find(menu => menu.Id.toString() === menuItemId.toString())
      }
      return item
    },

    onAddToCartClick () {
      if (!this.isRequiredOptionsValid()) return

      this.submitButton = true
      this.$bus.$emit('save:menuOptionsItem', this.selectedMenuItem)
    },

    onItemAddedToCart () {
      this.submitButton = false
    },

    menuItemViewEventGA () {
      const item = this.menuItemToGAItem(this.selectedMenuItem)
      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.VIEW_ITEM,
        Payload: {
          items   : [item],
          value   : (item.price - item.discount) * item.quantity,
          currency: item.currency
        }
      })
    },

    calculateToolbarHeight () {
      this.$nextTick(() => {
        const toolbarTitle = this.$refs.toolbarTitle
        if (toolbarTitle) {
          this.toolbarHeight = this.selectedMenuItem.Image && this.UseMenuLargeImage && this.$vuetify.breakpoint.smAndUp ? toolbarTitle.clientHeight + 70 : toolbarTitle.clientHeight < 110 && this.selectedMenuItem.Image && !this.UseMenuLargeImage && this.$vuetify.breakpoint.smAndUp ? 120 : toolbarTitle.clientHeight + 25
        }
      })
    },

    onResize () {
      this.calculateToolbarHeight()
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__title {
  white-space : normal !important;
}

/deep/ .menu-image > .v-toolbar__content {
  padding-left : 12px !important;
}

/deep/ .v-input--radio-group--row .v-input__control {
  width : 100%;
}

/deep/ .v-input--checkbox .v-icon:not(.success--text), /deep/ .v-input--radio-group .v-icon:not(.success--text) {
  color : rgba(0, 0, 0, 0.15) !important;
}

.separator-title {
  position   : relative;
  z-index    : 1;
  overflow   : hidden;
  text-align : center;
}

.separator-title:before, .separator-title:after {
  position         : absolute;
  top              : 51%;
  overflow         : hidden;
  width            : 48%;
  height           : 1px;
  content          : '\a0';
  background-color : #cccccc;
  margin-left      : 2%;
}

.separator-title:before {
  margin-left : -50%;
  text-align  : right;
}

.option-value-row {
  display         : flex;
  width           : 100%;
  justify-content : space-between;
  align-items     : center;
}

.option-value-row-control {
  overflow      : hidden;
  min-width     : 0;
  text-overflow : ellipsis;
  margin-right  : 6px;
}

.option-value-row-price {
  text-align : right;
}
</style>
