<template>
  <v-layout
    v-if="featuredMenus && featuredMenus.Items.length > 0"
    :class="[blockClass]"
    align-center
    row
    wrap
  >
    <v-flex xs12>
      <v-list
        class="pa-0 mt-1 mb-1"
        dense
        light
        two-line
      >
        <v-list-tile
          :class="cardClass"
          ripple
          @click="showProductsDialog"
        >
          <v-icon
            size="24"
            class="left mr-3 gfp-adv-common-cart-coupon-icon--text"
          >
            mdi-cart-plus
          </v-icon>

          <v-list-tile-content class="justify-center pt-1">
            <v-list-tile-title>
              <div class="subheading gfp-adv-common-cart-coupon-text--text font-weight-medium">
                {{ _title }}
              </div>
            </v-list-tile-title>

            <v-list-tile-sub-title class="gfp-adv-common-cart-items-product-text-description--text">
              {{ _desc }}
            </v-list-tile-sub-title>
          </v-list-tile-content>

          <v-list-tile-action
            style="min-width: 0;"
            class="ml-2"
          >
            <v-btn
              :class="cardButtonColor"
              small
              flat
              icon
              @click="showProductsDialog"
            >
              <v-icon>add_circle_outline</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-flex>

    <div class="">
      <div class="forgot-to-add-dialog" />

      <v-dialog
        v-model="dialogVisible"
        width="500"
        attach=".forgot-to-add-dialog"
        persistent
        hide-overlay
      >
        <v-card class="fill-height">
          <v-toolbar
            card
            class="gfp-adv-common-cart-toolbar-background"
            height="50"
            prominent
          >
            <v-icon color="gfp-adv-common-cart-toolbar-icon">
              mdi-cart-plus
            </v-icon>

            <v-toolbar-title class="pl-1">
              <div class="subheading gfp-adv-common-cart-toolbar-title--text">
                <strong>{{ _title }}</strong>
              </div>
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              dark
              icon
              :color="getContrastColor($vuetify.theme['gfp-adv-common-cart-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
              @click="onCancelButtonClick"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider />

          <v-card-text
            class="pa-1"
            style="overflow-y: auto; height: calc(100vh - 55px)"
          >
            <v-layout
              row
              wrap
            >
              <v-flex class="xs12 pa-1">
                <v-list
                  class="mb-0 pb-0 pt-0"
                  dense
                  light
                  three-line
                >
                  <template v-for="(menu, menuIndex) in featuredMenus.Items">
                    <forgot-to-add-cart-menu-item
                      :key="`menu-${menu.Id}-${menuIndex}`"
                      :menu="menu"
                    />
                    <v-divider
                      :key="`menu-divider-${menu.Id}-${menuIndex}`"
                      class="mb-2"
                    />
                  </template>
                </v-list>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import AppData                 from '@/mixins/appdata'
import { getContrastColor }    from '@/lib/color'
import CartCommon              from '@/mixins/orders/cartCommon'
import ForgotToAddCartMenuItem from '@/components/common/forgotToAddCartProducts/ForgotToAddCartMenuItem.vue'

export default {
  name      : 'ForgotToAddCartMenuBlock',
  components: { ForgotToAddCartMenuItem },
  directives: {},
  mixins    : [AppData, CartCommon],
  props     : {
    titleStyle: String,
    titleClass: {
      type   : String,
      default: function () {
        return getContrastColor(this.$vuetify.theme['gfp-adv-pages-home-sections-featured-products-background'].base) === 'black'
          ? this.$vuetify.theme['gfp-adv-pages-home-sections-featured-products-title'].base === '#000000' ? 'black--text' : 'gfp-adv-pages-home-sections-featured-products-title--text'
          : 'grey--text text--darken-3'
      }
    },
    showDesc: {
      type   : Boolean,
      default: false
    },
    showPrice: {
      type   : Boolean,
      default: false
    },
    menuNameClass: {
      type   : String,
      default: 'body-1 gfp-adv-pages-home-sections-featured-products-product-text-title--text'
    },
    blockClass: {
      type   : String,
      default: 'pa-0 transparent gfp-adv-pages-home-sections-featured-products-title--text'
    },
    cardClass: {
      type   : String,
      default: 'gfp-adv-common-cart-items-product-background'
    },
    cardButtonColor: {
      type   : String,
      default: 'gfp-adv-common-cart-button-remove--text'
    },
    cardButtonTitle: String,
    swiperOptions  : {
      type   : Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  computed: {
    _title () {
      return this.featuredMenus?.Lang[this.$i18n.locale]?.Label || ''
    },
    _desc () {
      return this.featuredMenus?.Lang[this.$i18n.locale]?.Description || ''
    },

    featuredMenus () {
      return this.cartForgotToAddProducts
    }
  },
  watch  : {},
  created () {},
  mounted () {},
  destroyed () {},
  methods: {
    getContrastColor,

    showProductsDialog () {
      this.dialogVisible = true
    },

    onCancelButtonClick () {
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
.full-height .flex {
  display : flex;
}

.full-height .flex > .card {
  flex : 1 1 auto;
}

/deep/ .forgot-to-add-dialog .v-dialog__content {
  align-items     : start !important;
  justify-content : end !important;
}

/deep/ .forgot-to-add-dialog .v-dialog:not(.v-dialog--fullscreen) {
  max-height : 100% !important;
}

/deep/ .forgot-to-add-dialog .v-dialog.v-dialog--active {
  margin : 0 !important;
  height : 100% !important;
}

/deep/ .price-tag {
  border-radius : 2px;
  min-width     : 55px;
  min-height    : 28px;
  text-align    : center;
}

/deep/ .v-list--three-line .v-list__tile {
  height : 104px !important;
}

/deep/ .v-list--three-line .v-list__tile__sub-title {
  white-space        : normal;
  -webkit-line-clamp : 2;
  -webkit-box-orient : vertical;
  display            : -webkit-box;
}

/deep/ .menu-item a.v-list__tile,
/deep/ .menu-item div.v-list__tile {
  padding : 6px;
}

.menu-tag {
  margin        : 0;
  margin-right  : 4px;
  margin-bottom : 6px;
  height        : 18px !important;
  border-radius : 3px;
}

/deep/ .menu-tag .v-chip__content {
  padding : 2px;
}
</style>
