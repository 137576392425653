import i18n       from '../lang/lang'
import greekUtils from '../components/common/greek-utils'

export default {
  data () {
    return {}
  },
  metaInfo () {
    const returnObj = {
      title        : this.meta.title,
      titleTemplate: '%s' + (this.meta.loc ? ' | ' + this.meta.loc : ''),
      link         : [
        { vmid: 'canonical', rel: 'canonical', href: this.meta.url }
      ],
      meta: [
        { vmid: 'application-name', name: 'application-name', content: this.meta.loc },
        { vmid: 'og:url', property: 'og:url', content: this.meta.url },

        // Facebook / Open Graph1
        { vmid: 'og:title', property: 'og:title', content: this.meta.title + ' | ' + this.meta.loc },
        { vmid: 'og:site_name', property: 'og:site_name', content: this.meta.loc },
        { vmid: 'og:type', property: 'og:type', content: 'website' },
        { vmid: 'og:url', property: 'og:url', content: this.meta.url },

        // Twitter Card
        { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
        { vmid: 'twitter:site', name: 'twitter:site', content: this.meta.url },
        { vmid: 'twitter:title', name: 'twitter:title', content: this.meta.title + ' | ' + this.meta.loc },

        // Google / Schema.org markup:
        { vmid: 'google:name', itemprop: 'name', content: this.meta.title + ' | ' + this.meta.loc }
      ]
    }

    if (this.meta.desc) {
      returnObj.meta.push({ vmid: 'description', name: 'description', content: this.meta.desc })
      returnObj.meta.push({ vmid: 'og:description', property: 'og:description', content: this.meta.desc })
      returnObj.meta.push({ vmid: 'twitter:description', name: 'twitter:description', content: this.meta.desc })
      returnObj.meta.push({ vmid: 'google:description', itemprop: 'description', content: this.meta.desc })
    }

    if (this.meta.img) {
      returnObj.meta.push({ vmid: 'og:image', property: 'og:image', content: this.meta.img })
      returnObj.meta.push({ vmid: 'twitter:image:src', name: 'twitter:image:src', content: this.meta.img })
      returnObj.meta.push({ vmid: 'google:image', itemprop: 'image', content: this.meta.img })
    }

    if (window.AppConfig && window.AppConfig.LOCATION_DATA && window.AppConfig.LOCATION_DATA.Icons) {
      window.AppConfig.LOCATION_DATA.Icons.forEach(icon => {
        if (icon.tag === 'link') returnObj.link.push({ vmid: icon.rel, rel: icon.rel, href: icon.href, type: icon.type })
        if (icon.tag === 'meta') returnObj.meta.push({ vmid: icon.rel, name: icon.rel, content: icon.href })
      })
    }

    return returnObj
  },
  methods: {
    changeLanguage (lang) {
      const route = Object.assign({}, this.$route)
      route.params.lang = lang
      this.$router.push(route)
    }
  },
  watch   : {},
  computed: {
    meta () {
      const locationName = i18n.locale === 'el' ? window.AppConfig.LOCATION_DATA.Name : greekUtils.toGreeklish(window.AppConfig.LOCATION_DATA.Name)
      const locationArea = i18n.locale === 'el' ? window.AppConfig.LOCATION_DATA.City : greekUtils.toGreeklish(window.AppConfig.LOCATION_DATA.City)
      return {
        title: this.$te(this.$route.name + '.Meta.Title') ? this.$t(this.$route.name + '.Meta.Title') : this.title ? this.title : '',
        desc : this.$te(this.$route.name + '.Meta.Desc') ? this.$t(this.$route.name + '.Meta.Desc', {
          locationName: locationName,
          locationArea: locationArea
        }) : this.desc ? this.desc : this.$t('Home.Meta.Desc', { locationName: locationName, locationArea: locationArea }),
        loc: locationName + (locationArea ? ', ' + locationArea : ''),
        url: window.AppConfig.LOCATION_DATA.SiteUrl + this.$route.fullPath,
        img: this.$te(this.$route.name + '.Meta.Img') ? this.$t(this.$route.name + '.Meta.Img') : this.img ? this.img : this.$t('Home.Meta.Img')
      }
    }
  },
  mounted () {},
  destroyed () {}
}
