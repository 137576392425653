import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAvatar } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.languages.length > 1)?_c('div',[_c(VMenu,{attrs:{"bottom":"","nudge-bottom":"24"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"dark":"","icon":"","name":"Change Language","small":""}},on),[_c(VAvatar,{attrs:{"size":"24"}},[_c(VImg,{attrs:{"src":_vm.lang.ImageUrl}})],1)],1)]}}],null,false,1801767553)},[_c(VList,{staticClass:"gfp-adv-common-toolbar-background",attrs:{"dense":"","dark":""}},_vm._l((_vm.languages),function(lng,i){return _c(VListTile,{key:i,attrs:{"disabled":lng.Code === _vm.lang.Code,"color":"gfp-adv-common-toolbar-background","active-class":"gfp-adv-common-toolbar-item-active"},on:{"click":function($event){return _vm.switchLanguage(lng)}}},[_c(VListTileAvatar,{attrs:{"size":"24"}},[_c(VImg,{attrs:{"src":lng.ImageUrl}})],1),_c(VListTileContent,[_c(VListTileTitle,{staticClass:"gfp-adv-common-toolbar-item-text--text"},[_vm._v(" "+_vm._s(lng.Name)+" ")])],1)],1)}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }