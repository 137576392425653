const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const capacitorInVersionParam = urlParams.get('cv') === '5'
const capacitorInUserAgent = window.navigator.userAgent.includes('capacitor/5')
const useLatestCapacitor = capacitorInUserAgent || capacitorInVersionParam
const Capacitor = useLatestCapacitor ? require('@capacitor/core').Capacitor : require('capacitor-core-legacy').Capacitor
const Plugins = require('capacitor-core-legacy').Plugins

export const CapacitorService = {
  isPluginAvailable: (name) => Capacitor.isPluginAvailable(name),
  isNativePlatform : () => useLatestCapacitor ? Capacitor.isNativePlatform() : Capacitor.isNative,
  isNative         : useLatestCapacitor ? Capacitor.isNativePlatform() : Capacitor.isNative,
  Plugins          : {
    SplashScreen: useLatestCapacitor ? require('@capacitor/splash-screen').SplashScreen : Plugins.SplashScreen,
    Device      : useLatestCapacitor ? require('@capacitor/device').Device : Plugins.Device,
    Browser     : useLatestCapacitor ? require('@capacitor/browser').Browser : Plugins.Browser,
    App         : useLatestCapacitor ? require('@capacitor/app').App : Plugins.App,
    InAppBrowser: window?.cordova?.InAppBrowser || null
  }
}
