import { GoogleGeocoderProvider } from '@/lib/maps/geocoder/GoogleGeocoderProvider'
import { MapBoxGeocoderProvider } from '@/lib/maps/geocoder/MapBoxGeocoderProvider'

const GeocoderProviderFactory = (provider, options = {}) => {
  if (provider === 'google') return new GoogleGeocoderProvider(options)
  if (provider === 'mapbox') return new MapBoxGeocoderProvider(options)
  return null
}

export default GeocoderProviderFactory
