import 'vuetify/src/stylus/app.styl'
import './components/common/vue-snotify/styles/material.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import Vue                              from 'vue'
import { i18n, setupI18n }              from './lang/lang'
import Vuetify                          from 'vuetify/lib'
import colors                           from 'vuetify/es5/util/colors'
import el                               from 'vuetify/es5/locale/el'
import en                               from 'vuetify/es5/locale/en'
import greekUtils                       from './components/common/greek-utils'
import App                              from './components/App'
import router, { resetRouter }          from './router/router'
import VueAffix                         from 'vue-affix'
import VueFilters                       from './filters/'
import DataStore                        from './data-store/'
import Meta                             from 'vue-meta'
import Snotify                          from './components/common/vue-snotify/index'
import AppSettings                      from './settings/AppSettings'
import { API, APICall }                 from './api/Api'
import VueAuthenticate                  from 'vue-authenticate'
import Storage                          from 'vue-web-storage'
import VueAxios                         from 'vue-axios'
import axios                            from 'axios'
// eslint-disable-next-line
import { EventBus }                     from './events/eventBus'
import VueLazyload                      from 'vue-lazyload'
import VueObserveVisibility             from 'vue-observe-visibility'
import { setupGoogleAnalytics }         from './lib/services/GoogleAnalytics'
import DataEventEnum                    from '@/lib/services/enum/DataEventEnum'
import DataEventService                 from '@/lib/services/event/DataEventService'
import PromiseQueue                     from '@/lib/async/PromiseQueue'
import { CapacitorService }             from '@/lib/services/Capacitor'
import { setupOneSignal }               from '@/lib/services/OneSignal'
import * as VueGoogleMaps               from 'vue2-google-maps'
import MapsCommon, { MapsProviderEnum } from '@/mixins/maps/mapsCommon'

import 'mapbox-gl/dist/mapbox-gl.css'
import 'v-mapbox/dist/v-mapbox.css'

const isSplashScreenAvailable = CapacitorService.isPluginAvailable('SplashScreen')
const isBrowserAvailable = CapacitorService.isPluginAvailable('Browser')

require('@openfonts/roboto_greek')

window.Vue = null
window.AppConfig = {}
window.DataStore = {}
window.API = API
window.APICall = APICall
window.EventBus = EventBus
window.LastXSession = Vue.$localStorage.get('x-session', '')
window.CartPromiseQueue = new PromiseQueue({
  concurrency: 1,
  cooldown   : 0
})

API.get(APICall.config).then(response => {
  if (response.status === 200 && response.data) {
    if (response.data.status === 200) {
      // --------------------------------------------------------------------------
      // TODO: Remove for loop when data from API respects floats
      response.data.data.LOCATION_DATA.DeliveryAreas.forEach((area, a) => {
        response.data.data.LOCATION_DATA.DeliveryAreas[a].areaId = parseFloat(area.areaId)
        response.data.data.LOCATION_DATA.DeliveryAreas[a].circle.center.lat = parseFloat(area.circle.center.lat)
        response.data.data.LOCATION_DATA.DeliveryAreas[a].circle.center.lng = parseFloat(area.circle.center.lng)
        response.data.data.LOCATION_DATA.DeliveryAreas[a].circle.radius = parseFloat(area.circle.radius)

        area.vertices.forEach((vert, v) => {
          response.data.data.LOCATION_DATA.DeliveryAreas[a].vertices[v].lat = parseFloat(vert.lat)
          response.data.data.LOCATION_DATA.DeliveryAreas[a].vertices[v].lng = parseFloat(vert.lng)
          if (!response.data.data.LOCATION_DATA.DeliveryAreas[a].vertices[v].hasOwnProperty('latlng')) {
            response.data.data.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng = {
              lat: parseFloat(vert.lat),
              lng: parseFloat(vert.lng)
            }
          }
          response.data.data.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng.lat = parseFloat(vert.latlng.lat)
          response.data.data.LOCATION_DATA.DeliveryAreas[a].vertices[v].latlng.lng = parseFloat(vert.latlng.lng)
        })
      })
      // --------------------------------------------------------------------------

      window.AppConfig = response.data.data
      Object.assign(AppSettings, response.data.data.APP_SETTINGS)
      Vue.$localStorage.set('x-session', AppSettings.XSession || '')

      if (!appInIframe() || (appInIframe() && response.data.data.APP_SETTINGS.AllowIFrame)) {
        if (!Array.isArray(window.AppConfig.LOCATION_DATA.LanguageList)) {
          window.AppConfig.LOCATION_DATA.LanguageList = Object.values(window.AppConfig.LOCATION_DATA.LanguageList)
        }
        setupI18n()
        resetRouter()
        setupGoogleAnalytics(window.AppConfig.APP_SETTINGS, router)
        setupOneSignal(window.AppConfig.APP_SETTINGS)
        initVue()
        appendJsonLD()
      }
    }
  } else {
    window.location.replace('https://getfoodpro.com')
  }
}).catch(e => {
  // eslint-disable-next-line no-console
  console.error(e)

  window.location.replace('https://getfoodpro.com')
})

function interceptClickEvent (e) {
  let href
  if (e.target.tagName === 'A') {
    href = e.target.getAttribute('href')
    if (CapacitorService.isNative && isBrowserAvailable && !href.includes(window.location.hostname)) {
      e.preventDefault()
      CapacitorService.Plugins.Browser.open({ url: href })
    }
  }
}

function appInIframe () {
  try {
    return window.self !== window.top && window.location !== window.parent.location
  } catch (e) {
    return true
  }
}

function appendJsonLD () {
  if (!window.AppConfig.JSON_LD) return
  const jsonldScript = document.createElement('script')
  jsonldScript.setAttribute('type', 'application/ld+json')
  jsonldScript.textContent = window.AppConfig.JSON_LD || ''
  document.body.appendChild(jsonldScript)
}

function initVue () {
  Vue.use(VueObserveVisibility)
  Vue.use(VueLazyload, {
    observer     : true,
    lazyComponent: true
  })
  Vue.use(Storage, {
    prefix : 'gfp_',
    drivers: ['session', 'local'] // default 'local'
  })
  Vue.use(VueAxios, axios)
  /*
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  axios.defaults.headers.common['X-Session'] = AppSettings.XSession || ''
  axios.defaults.headers.common['X-Referer'] = process.env.VUE_APP_API_X_REFERER || ''
  axios.defaults.timeout = parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000
  axios.defaults.responseType = 'json'
  axios.defaults.crossDomain = true
  axios.defaults.withCredentials = true
   */
  Vue.use(VueAuthenticate, {
    baseUrl    : process.env.VUE_APP_API_URL || '', // API domain
    tokenName  : 'access_token',
    storageType: 'sessionStorage', // 'cookieStorage',
    providers  : AppSettings.SocialProviders,

    bindRequestInterceptor: function () {
      const sourceTypeId = Vue.$sessionStorage.get('QRData') ? Vue.$sessionStorage.get('QRData')?.source || '1' : window?.Vue?.store?.isMobileApp ? '10' : '1'

      this.$http.interceptors.request.use((config) => {
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        config.headers['X-Session'] = Vue.$localStorage.get('x-session', AppSettings.XSession || '')
        config.headers['X-Source'] = sourceTypeId
        config.headers['X-Referer'] = process.env.VUE_APP_API_X_REFERER || ''
        config.timeout = parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000
        config.responseType = 'json'
        config.crossDomain = true
        config.withCredentials = true
        return config
      })
    }
  })
  Vue.use(VueFilters)
  Vue.use(DataStore)
  Vue.use(VueAffix)
  Vue.use(Meta)
  Vue.use(greekUtils)

  Vue.use(Snotify, {
    config: {
      timeout        : 3500,
      showProgressBar: true,
      pauseOnHover   : true,
      titleMaxLength : 28,
      bodyMaxLength  : 150,
      backdrop       : -1
    },
    options: {
      maxOnScreen  : 3,
      maxAtPosition: 8,
      maxHeight    : 300,
      newOnTop     : true,
      position     : 'centerTop'
    }
  })

  // eslint-disable-next-line
  const vm = window.Vue = new Vue({
    el    : '#app',
    i18n,
    mixins: [MapsCommon],
    data () {
      return {
        store: {
          pages                : {},
          isMobileApp          : false,
          isFacebookApp        : false,
          isQRScanApp          : false,
          isSelfOrderingApp    : false,
          isLandingPage        : false,
          selectedPaymentMethod: null,
          orderComment         : '',
          tmpOrderType         : '1',
          cart                 : null,
          qrData               : null,
          dashboard            : {}
        }
      }
    },
    async beforeCreate () {
      const providers = window.AppConfig?.LOCATION_DATA?.Maps?.Site || {}
      const providersArray = Object.values(providers)
      const googleProvider = providersArray?.find(provider => provider.Name === MapsProviderEnum.Google && provider.Key)

      if (googleProvider) {
        Vue.use(VueGoogleMaps)
        VueGoogleMaps.loadGmapApi({
          key      : googleProvider.Key,
          libraries: 'places,geometry',
          language : i18n.locale || process.env.VUE_APP_I18N_LOCALE
          // v        : 3.38
        })
      }
    },
    created () {
      window.Vue = this

      Vue.use(Vuetify, {
        theme   : setVuetifyTheme(),
        iconfont: 'md',
        options : {
          customProperties: true
        },
        lang: {
          locales: {
            el,
            en
          },
          current: i18n.locale || process.env.VUE_APP_I18N_LOCALE
        }
      })

      const userInfo = JSON.parse(this.$localStorage.get('userInfo')) ? JSON.parse(this.$localStorage.get('userInfo')) : null
      const isUser = !!(userInfo && userInfo.Info && userInfo.Info.Id)

      this.$set(this.dataStore, 'isAuthenticated', isUser)
      this.$set(this.dataStore, 'userInfo', this.$localStorage.get('userInfo') === 'null' ? null : JSON.parse(this.$localStorage.get('userInfo')))

      if (AppSettings.XSession !== window.LastXSession) {
        this.$set(this.dataStore, 'isAuthenticated', false)
        this.$set(this.dataStore, 'userInfo', null)
        this.$auth.storage.removeItem('vueauth_access_token')
        this.$localStorage.remove('userInfo')
      }

      let appType = ''

      if (this.$route && this.$route.query.t) {
        appType = String(this.$route.query.t).trim().toLowerCase()
      } else if (this.$sessionStorage.get('t')) {
        appType = String(this.$sessionStorage.get('t', '')).trim().toLowerCase()
      }

      const acceptedAppType = ['ma', 'fb', 'qr', 'so', 'lp']

      if (appType && acceptedAppType.includes(appType.toLowerCase())) {
        this.$sessionStorage.set('t', appType)

        if (appType === 'ma') {
          this.dataStore.isMobileApp = true
        } else if (appType === 'fb') {
          this.dataStore.isFacebookApp = true
        } else if (appType === 'qr') {
          this.dataStore.isQRScanApp = true
          this.handleQrData()
        } else if (appType === 'so') {
          this.dataStore.isSelfOrderingApp = true
        } else if (appType === 'lp') {
          this.dataStore.isLandingPage = true
        }

        const url = new URL(window.location)
        if (url && url.searchParams) {
          url.searchParams.delete('t')
          history.replaceState(null, null, url)
        }
      } else {
        this.$sessionStorage.remove('t')
        this.$sessionStorage.remove('d')
        this.$sessionStorage.remove('QRData')
        this.dataStore.qrData = null
        redirectToLandingPage()
      }

      let paymentMethods = window.AppConfig.APP_SETTINGS.Payments || []
      paymentMethods = window.AppConfig.APP_SETTINGS.Payments = paymentMethods.map(payment => {
        return {
          init: true,
          code: payment.Code,
          name: payment.Title,
          lang: {
            el: {
              name  : 'Με Αντικαταβολή',
              locale: 'el'
            },
            en: {
              name  : 'Cash on delivery',
              locale: 'en'
            }
          },
          priority: payment.Priority,
          status  : payment.Status ? '1' : '0',
          data    : {
            code               : payment.Code,
            title              : payment.Title,
            payment            : '',
            minimum_order_total: payment.MinOrderTotal,
            order_total        : 0
          },
          tip: false
        }
      })
      const defaultPaymentMethod = paymentMethods.find(payment => payment.Code === 'cod') || paymentMethods.length ? paymentMethods[0] : null
      this.dataStore.selectedPaymentMethod = defaultPaymentMethod

      if (CapacitorService.isNative) {
        this.dataStore.isMobileApp = true
        if (isSplashScreenAvailable) CapacitorService.Plugins.SplashScreen.hide()
        document.addEventListener('click', interceptClickEvent)
      }

      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.INIT,
        Payload: {
          Locale: this.$i18n.locale,
          User  : this.dataStore.userInfo,
          Method: this.dataStore.userInfo ? this.dataStore.userInfo.Info.SocialProvider || 'Email' : 'Guest'
        }
      })

      this.initMenu()
      this.$bus.$on('init-menu', this.initMenu)
    },
    mounted () {},
    beforeDestroy () {
      this.$bus.$off('init-menu', this.initMenu)

      document.removeEventListener('click', interceptClickEvent)
    },
    destroyed () {},
    methods: {
      initMenu () {
        window.API.get(window.APICall.menu)
          .then(response => {
            if (response.data.status === 200) {
              const data = response.data.data
              if (data.items.length >= 1) data.categories[0].ObserveVisibility = data.items[0].Category.ObserveVisibility = true
              if (data.items.length >= 2) data.categories[1].ObserveVisibility = data.items[1].Category.ObserveVisibility = true

              window.menu = data
              this.$set(this.dataStore, 'menu', Date.now())
              this.$bus.$emit('menu-result-success')
            }
          })
          .catch(() => {})
      },

      getUrlParameter (name, url) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
        const regex = new RegExp('[\\?&]' + name + '=([^&]*)')
        const results = regex.exec(url)
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
      },

      handleQrData () {
        const qrSourceType = this.$t('Order.QrSourceType')
        const barcodeRegex = `(GFP)(${ Object.keys(qrSourceType).join('|') })([A-Za-zΑ-Ωα-ωίϊΐόάέύϋΰήώΊΪΌΆΈΎΫΉΏ0-9 -/:]*)(GFP)`
        const data = String(this.getUrlParameter('d', this.$route.fullPath) || this.$route.params.d || this.$sessionStorage.get('d', '')).trim().toUpperCase()
        const isValid = new RegExp(barcodeRegex, 'gi').test(data)
        if (!isValid) {
          redirectToLandingPage()
          return
        }

        const regexResult = new RegExp(barcodeRegex, 'gi').exec(data)
        const type = regexResult.length === 5 && regexResult[2]
        const value = regexResult.length === 5 && regexResult[3]
        if (!type || !this.$t('Order.QrSourceType').hasOwnProperty(type) || !value) return

        this.$sessionStorage.set('d', data)
        this.$sessionStorage.set('QRData', {
          type  : type,
          value : value,
          source: this.$t(`Order.QrSourceType.${ type }.Id`)
        })
        this.dataStore.qrData = {
          type  : type,
          value : value,
          source: this.$t(`Order.QrSourceType.${ type }.Id`)
        }
        this.$router.replace({ name: 'Menu' })

        // eslint-disable-next-line no-console
        console.log('QR RESULT ::', this.$t(`Order.QrSourceType.${ type }.Title`), `(${ type }) :: ${ value }`)
      }
    },
    router,
    render: h => h(App)
  })

  function redirectToLandingPage () {
    if (window.AppConfig.APP_SETTINGS.HasLandingPage && window.AppConfig.APP_SETTINGS.LandingPageUrl) {
      if (
        window.AppConfig.APP_SETTINGS.RedirectToLandingPage ||
        window.AppConfig.APP_SETTINGS.QrOnly
      ) {
        window.location.replace(window.AppConfig.APP_SETTINGS.LandingPageUrl)
      }
    }
  }

  function setVuetifyTheme () {
    const activeTheme = window.AppConfig.LOCATION_DATA.Theme.active

    // Basic Theme
    const controls = activeTheme.controls
    const site = activeTheme.site

    const basicTheme = {
      controls: {
        primary  : controls?.find(c => c.id === 'primary')?.value || '#1976d2',
        secondary: controls?.find(c => c.id === 'secondary')?.value || '#424242',
        accent   : controls?.find(c => c.id === 'accent')?.value || '#82B1FF',
        error    : controls?.find(c => c.id === 'error')?.value || '#FF5252',
        info     : controls?.find(c => c.id === 'info')?.value || '#2196F3',
        success  : controls?.find(c => c.id === 'success')?.value || '#4CAF50',
        warning  : controls?.find(c => c.id === 'warning')?.value || '#FFC107'
      },

      site: {
        'gfp-primary'              : { base: site?.find(c => c.id === 'gfp-primary')?.value || colors.red.accent4 },
        'gfp-primary-text'         : { base: site?.find(c => c.id === 'gfp-primary-text')?.value || colors.shades.white },
        'gfp-toolbar'              : { base: site?.find(c => c.id === 'gfp-toolbar')?.value || colors.grey.darken4 },
        'gfp-footer'               : { base: site?.find(c => c.id === 'gfp-footer')?.value || colors.grey.lighten3 },
        'gfp-footer-text'          : { base: site?.find(c => c.id === 'gfp-footer-text')?.value || colors.grey.darken3 },
        'gfp-footer-copyright'     : { base: site?.find(c => c.id === 'gfp-footer-copyright')?.value || colors.grey.darken4 },
        'gfp-footer-copyright-text': { base: site?.find(c => c.id === 'gfp-footer-copyright-text')?.value || colors.shades.white },
        'gfp-delivery-button'      : { base: site?.find(c => c.id === 'gfp-delivery-button')?.value || colors.purple.base },
        'gfp-collection-button'    : { base: site?.find(c => c.id === 'gfp-collection-button')?.value || colors.blue.darken3 }
      }
    }

    // Advanced Theme
    const advanced = activeTheme?.advanced || {}

    const advancedThemeGlobal = {
      'gfp-adv-global-background'       : { base: advanced['global.background.color'] || '#fafafa' },
      'gfp-adv-global-text'             : { base: advanced['global.text.color'] || colors.grey.darken3 },
      'gfp-adv-global-button-background': { base: advanced['global.button.background.color'] || basicTheme.site['gfp-primary'].base },
      'gfp-adv-global-button-text'      : { base: advanced['global.button.text.color'] || basicTheme.site['gfp-primary-text'].base }
    }

    const advancedTheme = {
      // Global
      ...advancedThemeGlobal,

      // Common
      // Toolbar
      'gfp-adv-common-toolbar-background' : { base: advanced['common.toolbar.background.color'] || basicTheme.site['gfp-toolbar'].base },
      'gfp-adv-common-toolbar-text'       : { base: advanced['common.toolbar.text.color'] || colors.shades.white },
      'gfp-adv-common-toolbar-item-text'  : { base: advanced['common.toolbar.item.text.color'] || colors.shades.white },
      'gfp-adv-common-toolbar-item-icon'  : { base: advanced['common.toolbar.item.icon.color'] || colors.shades.white },
      'gfp-adv-common-toolbar-item-active': { base: advanced['common.toolbar.item.active.color'] || basicTheme.site['gfp-primary'].base },

      // Footer
      'gfp-adv-common-footer-mobile-background'      : { base: advanced['common.footer.mobile.background.color'] || colors.shades.white },
      'gfp-adv-common-footer-mobile-text-background' : { base: advanced['common.footer.mobile.text.background.color'] || colors.shades.white },
      'gfp-adv-common-footer-mobile-text-title'      : { base: advanced['common.footer.mobile.text.title.color'] || colors.grey.darken4 },
      'gfp-adv-common-footer-mobile-text-description': { base: advanced['common.footer.mobile.text.description.color'] || colors.grey.darken1 },
      'gfp-adv-common-footer-social-background'      : { base: advanced['common.footer.social.background.color'] || colors.grey.darken3 },
      'gfp-adv-common-footer-social-text'            : { base: advanced['common.footer.social.text.color'] || colors.shades.white },
      'gfp-adv-common-footer-social-button'          : { base: advanced['common.footer.social.button.color'] || colors.shades.white },
      'gfp-adv-common-footer-links-background'       : { base: advanced['common.footer.links.background.color'] || basicTheme.site['gfp-footer'].base },
      'gfp-adv-common-footer-links-text'             : { base: advanced['common.footer.links.text.color'] || basicTheme.site['gfp-footer-text'].base },
      'gfp-adv-common-footer-copyright-background'   : { base: advanced['common.footer.copyright.background.color'] || basicTheme.site['gfp-footer-copyright'].base },
      'gfp-adv-common-footer-copyright-text'         : { base: advanced['common.footer.copyright.text.color'] || basicTheme.site['gfp-footer-copyright-text'].base },

      // Cart
      'gfp-adv-common-cart-background': { base: advanced['common.cart.background.color'] || colors.shades.white },
      'gfp-adv-common-cart-text'      : { base: advanced['common.cart.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },

      'gfp-adv-common-cart-toolbar-background'           : { base: advanced['common.cart.toolbar.background.color'] || colors.grey.lighten4 },
      'gfp-adv-common-cart-toolbar-title'                : { base: advanced['common.cart.toolbar.title.color'] || colors.grey.darken2 },
      'gfp-adv-common-cart-toolbar-icon'                 : { base: advanced['common.cart.toolbar.icon.color'] || colors.grey.darken2 },
      'gfp-adv-common-cart-toolbar-icon-badge-background': { base: advanced['common.cart.toolbar.icon.badge.background.color'] || basicTheme.site['gfp-primary'].base },
      'gfp-adv-common-cart-toolbar-icon-badge-text'      : { base: advanced['common.cart.toolbar.icon.badge.text.color'] || basicTheme.site['gfp-primary-text'].base },

      'gfp-adv-common-cart-button-buynow-background': { base: advanced['common.cart.button.buyNow.background.color'] || basicTheme.controls.success },
      'gfp-adv-common-cart-button-buynow-text'      : { base: advanced['common.cart.button.buyNow.text.color'] || colors.shades.white },

      'gfp-adv-common-cart-button-ordertype-delivery-background'  : { base: advanced['common.cart.button.orderType.delivery.background.color'] || basicTheme.site['gfp-delivery-button'].base },
      'gfp-adv-common-cart-button-ordertype-delivery-text'        : { base: advanced['common.cart.button.orderType.delivery.text.color'] || colors.shades.white },
      'gfp-adv-common-cart-button-ordertype-collection-background': { base: advanced['common.cart.button.orderType.collection.background.color'] || basicTheme.site['gfp-collection-button'].base },
      'gfp-adv-common-cart-button-ordertype-collection-text'      : { base: advanced['common.cart.button.orderType.collection.text.color'] || colors.shades.white },

      'gfp-adv-common-cart-button-remove': { base: advanced['common.cart.button.remove.color'] || colors.red.base },

      'gfp-adv-common-cart-coupon-text'             : { base: advanced['common.cart.coupon.text.color'] || colors.grey.darken1 },
      'gfp-adv-common-cart-coupon-icon'             : { base: advanced['common.cart.coupon.icon.color'] || colors.grey.darken1 },
      'gfp-adv-common-cart-coupon-button-background': { base: advanced['common.cart.coupon.button.background.color'] || colors.grey.base },
      'gfp-adv-common-cart-coupon-button-text'      : { base: advanced['common.cart.coupon.button.text.color'] || colors.shades.white },

      'gfp-adv-common-cart-tip-text'                    : { base: advanced['common.cart.tip.text.color'] || colors.grey.darken1 },
      'gfp-adv-common-cart-tip-icon'                    : { base: advanced['common.cart.tip.icon.color'] || colors.grey.darken1 },
      'gfp-adv-common-cart-tip-button-background'       : { base: advanced['common.cart.tip.button.background.color'] || colors.grey.lighten2 },
      'gfp-adv-common-cart-tip-button-background-active': { base: advanced['common.cart.tip.button.background.active.color'] || colors.green.base },
      'gfp-adv-common-cart-tip-button-text'             : { base: advanced['common.cart.tip.button.text.color'] || colors.shades.black },
      'gfp-adv-common-cart-tip-button-text-active'      : { base: advanced['common.cart.tip.button.text.active.color'] || colors.shades.white },
      'gfp-adv-common-cart-tip-button-icon'             : { base: advanced['common.cart.tip.button.icon.color'] || colors.grey.darken1 },
      'gfp-adv-common-cart-tip-button-icon-active'      : { base: advanced['common.cart.tip.button.icon.active.color'] || colors.grey.lighten2 },

      'gfp-adv-common-cart-items-category-background': { base: advanced['common.cart.items.category.background.color'] || '#e1e1e1' },
      'gfp-adv-common-cart-items-category-text'      : { base: advanced['common.cart.items.category.text.color'] || colors.grey.darken1 },

      'gfp-adv-common-cart-items-total-background': { base: advanced['common.cart.items.total.background.color'] || '#e1e1e1' },
      'gfp-adv-common-cart-items-total-text'      : { base: advanced['common.cart.items.total.text.color'] || colors.grey.darken4 },

      'gfp-adv-common-cart-items-product-text-price'         : { base: advanced['common.cart.items.product.text.price.color'] || colors.grey.darken1 },
      'gfp-adv-common-cart-items-product-text-description'   : { base: advanced['common.cart.items.product.text.description.color'] || colors.grey.base },
      'gfp-adv-common-cart-items-product-text-title'         : { base: advanced['common.cart.items.product.text.title.color'] || colors.grey.darken3 },
      'gfp-adv-common-cart-items-product-text-title-quantity': { base: advanced['common.cart.items.product.text.title.quantity.color'] || colors.orange.base },
      'gfp-adv-common-cart-items-product-background'         : { base: advanced['common.cart.items.product.background.color'] || colors.grey.lighten4 },

      // Product Dialog
      'gfp-adv-common-product-dialog-toolbar-background'       : { base: advanced['common.productDialog.toolbar.background.color'] || colors.shades.white },
      'gfp-adv-common-product-dialog-toolbar-title'            : { base: advanced['common.productDialog.toolbar.title.color'] || colors.grey.darken2 },
      'gfp-adv-common-product-dialog-toolbar-quantity'         : { base: advanced['common.productDialog.toolbar.quantity.color'] || colors.orange.base },
      'gfp-adv-common-product-dialog-toolbar-price'            : { base: advanced['common.productDialog.toolbar.price.color'] || colors.grey.darken4 },
      'gfp-adv-common-product-dialog-body-background'          : { base: advanced['common.productDialog.body.background.color'] || colors.grey.lighten3 },
      'gfp-adv-common-product-dialog-body-description'         : { base: advanced['common.productDialog.body.description.color'] || colors.grey.darken4 },
      'gfp-adv-common-product-dialog-body-options-title'       : { base: advanced['common.productDialog.body.options.title.color'] || colors.grey.darken4 },
      'gfp-adv-common-product-dialog-body-option-background'   : { base: advanced['common.productDialog.body.options.option.background.color'] || colors.shades.white },
      'gfp-adv-common-product-dialog-body-option-text'         : { base: advanced['common.productDialog.body.options.option.text.color'] || colors.grey.darken1 },
      'gfp-adv-common-product-dialog-body-option-control'      : { base: advanced['common.productDialog.body.options.option.control.color'] || basicTheme.controls.success },
      'gfp-adv-common-product-dialog-body-comments'            : { base: advanced['common.productDialog.body.comments.color'] || basicTheme.controls.primary },
      'gfp-adv-common-product-dialog-actions-background'       : { base: advanced['common.productDialog.actions.background.color'] || colors.shades.white },
      'gfp-adv-common-product-dialog-actions-text'             : { base: advanced['common.productDialog.actions.text.color'] || colors.grey.darken2 },
      'gfp-adv-common-product-dialog-actions-button-background': { base: advanced['common.productDialog.actions.button.background.color'] || basicTheme.controls.success },
      'gfp-adv-common-product-dialog-actions-button-title'     : { base: advanced['common.productDialog.actions.button.title.color'] || colors.shades.white },
      'gfp-adv-common-product-dialog-actions-quantity-title'   : { base: advanced['common.productDialog.actions.quantity.title.color'] || colors.grey.darken1 },
      'gfp-adv-common-product-dialog-actions-quantity-button'  : { base: advanced['common.productDialog.actions.quantity.button.color'] || colors.grey.base },

      // Login Dialog
      'gfp-adv-common-login-toolbar-background'             : { base: advanced['common.login.toolbar.background.color'] || colors.shades.white },
      'gfp-adv-common-login-toolbar-icon'                   : { base: advanced['common.login.toolbar.icon.color'] || basicTheme.controls.primary },
      'gfp-adv-common-login-toolbar-title'                  : { base: advanced['common.login.toolbar.title.color'] || colors.grey.darken4 },
      'gfp-adv-common-login-toolbar-description'            : { base: advanced['common.login.toolbar.description.color'] || colors.grey.darken3 },
      'gfp-adv-common-login-body-background'                : { base: advanced['common.login.body.background.color'] || colors.grey.lighten3 },
      'gfp-adv-common-login-body-title'                     : { base: advanced['common.login.body.title.color'] || colors.grey.darken2 },
      'gfp-adv-common-login-body-text'                      : { base: advanced['common.login.body.text.color'] || colors.grey.darken3 },
      'gfp-adv-common-login-body-icon'                      : { base: advanced['common.login.body.icon.color'] || colors.grey.lighten1 },
      'gfp-adv-common-login-body-link'                      : { base: advanced['common.login.body.link.color'] || colors.grey.darken1 },
      'gfp-adv-common-login-body-control'                   : { base: advanced['common.login.body.control.color'] || basicTheme.controls.primary },
      'gfp-adv-common-login-body-button-login-background'   : { base: advanced['common.login.body.button.login.background.color'] || basicTheme.controls.success },
      'gfp-adv-common-login-body-button-login-text'         : { base: advanced['common.login.body.button.login.text.color'] || colors.shades.white },
      'gfp-adv-common-login-body-button-register-background': { base: advanced['common.login.body.button.register.background.color'] || colors.orange.lighten1 },
      'gfp-adv-common-login-body-button-register-text'      : { base: advanced['common.login.body.button.register.text.color'] || colors.shades.white },

      // Register Dialog
      'gfp-adv-common-register-toolbar-background'             : { base: advanced['common.register.toolbar.background.color'] || colors.shades.white },
      'gfp-adv-common-register-toolbar-icon'                   : { base: advanced['common.register.toolbar.icon.color'] || basicTheme.controls.primary },
      'gfp-adv-common-register-toolbar-title'                  : { base: advanced['common.register.toolbar.title.color'] || colors.grey.darken4 },
      'gfp-adv-common-register-toolbar-description'            : { base: advanced['common.register.toolbar.description.color'] || colors.grey.darken3 },
      'gfp-adv-common-register-body-background'                : { base: advanced['common.register.body.background.color'] || colors.grey.lighten3 },
      'gfp-adv-common-register-body-title'                     : { base: advanced['common.register.body.title.color'] || colors.grey.darken2 },
      'gfp-adv-common-register-body-text'                      : { base: advanced['common.register.body.text.color'] || colors.grey.darken3 },
      'gfp-adv-common-register-body-link'                      : { base: advanced['common.register.body.link.color'] || basicTheme.controls.success },
      'gfp-adv-common-register-body-control'                   : { base: advanced['common.register.body.control.color'] || basicTheme.controls.primary },
      'gfp-adv-common-register-body-button-register-background': { base: advanced['common.register.body.button.register.background.color'] || colors.orange.lighten1 },
      'gfp-adv-common-register-body-button-register-text'      : { base: advanced['common.register.body.button.register.text.color'] || colors.shades.white },

      // Forgot Password Dialog
      'gfp-adv-common-forgot-password-toolbar-background'          : { base: advanced['common.forgotPassword.toolbar.background.color'] || colors.shades.white },
      'gfp-adv-common-forgot-password-toolbar-icon'                : { base: advanced['common.forgotPassword.toolbar.icon.color'] || basicTheme.controls.primary },
      'gfp-adv-common-forgot-password-toolbar-title'               : { base: advanced['common.forgotPassword.toolbar.title.color'] || colors.grey.darken4 },
      'gfp-adv-common-forgot-password-toolbar-description'         : { base: advanced['common.forgotPassword.toolbar.description.color'] || colors.grey.darken3 },
      'gfp-adv-common-forgot-password-body-background'             : { base: advanced['common.forgotPassword.body.background.color'] || colors.grey.lighten3 },
      'gfp-adv-common-forgot-password-body-text'                   : { base: advanced['common.forgotPassword.body.text.color'] || colors.grey.darken1 },
      'gfp-adv-common-forgot-password-body-link'                   : { base: advanced['common.forgotPassword.body.link.color'] || basicTheme.controls.success },
      'gfp-adv-common-forgot-password-body-control'                : { base: advanced['common.forgotPassword.body.control.color'] || basicTheme.controls.primary },
      'gfp-adv-common-forgot-password-body-button-reset-background': { base: advanced['common.forgotPassword.body.button.reset.background.color'] || colors.orange.lighten1 },
      'gfp-adv-common-forgot-password-body-button-reset-text'      : { base: advanced['common.forgotPassword.body.button.reset.text.color'] || colors.shades.white },

      // GDPR Dialog
      'gfp-adv-common-gdpr-toolbar-background'          : { base: advanced['common.gdpr.toolbar.background.color'] || colors.shades.white },
      'gfp-adv-common-gdpr-toolbar-icon'                : { base: advanced['common.gdpr.toolbar.icon.color'] || basicTheme.controls.primary },
      'gfp-adv-common-gdpr-toolbar-title'               : { base: advanced['common.gdpr.toolbar.title.color'] || colors.grey.darken4 },
      'gfp-adv-common-gdpr-toolbar-description'         : { base: advanced['common.gdpr.toolbar.description.color'] || colors.grey.darken3 },
      'gfp-adv-common-gdpr-body-background'             : { base: advanced['common.gdpr.body.background.color'] || colors.grey.lighten3 },
      'gfp-adv-common-gdpr-body-text'                   : { base: advanced['common.gdpr.body.text.color'] || colors.grey.darken1 },
      'gfp-adv-common-gdpr-body-control'                : { base: advanced['common.gdpr.body.control.color'] || basicTheme.controls.primary },
      'gfp-adv-common-gdpr-body-button-check-background': { base: advanced['common.gdpr.body.button.check.background.color'] || colors.orange.lighten1 },
      'gfp-adv-common-gdpr-body-button-check-text'      : { base: advanced['common.gdpr.body.button.check.text.color'] || colors.shades.white },

      // Confirm Dialog
      'gfp-adv-common-confirm-toolbar-background'              : { base: advanced['common.confirm.toolbar.background.color'] || colors.red.base },
      'gfp-adv-common-confirm-toolbar-icon'                    : { base: advanced['common.confirm.toolbar.icon.color'] || colors.shades.white },
      'gfp-adv-common-confirm-toolbar-title'                   : { base: advanced['common.confirm.toolbar.title.color'] || colors.shades.white },
      'gfp-adv-common-confirm-toolbar-description'             : { base: advanced['common.confirm.toolbar.description.color'] || colors.shades.white },
      'gfp-adv-common-confirm-body-background'                 : { base: advanced['common.confirm.body.background.color'] || colors.shades.white },
      'gfp-adv-common-confirm-body-text'                       : { base: advanced['common.confirm.body.text.color'] || colors.grey.darken1 },
      'gfp-adv-common-confirm-body-link'                       : { base: advanced['common.confirm.body.link.color'] || basicTheme.controls.success },
      'gfp-adv-common-confirm-actions-background'              : { base: advanced['common.confirm.actions.background.color'] || colors.shades.white },
      'gfp-adv-common-confirm-actions-button-cancel-background': { base: advanced['common.confirm.actions.button.cancel.background.color'] || colors.grey.darken1 },
      'gfp-adv-common-confirm-actions-button-cancel-text'      : { base: advanced['common.confirm.actions.button.cancel.text.color'] || colors.shades.white },
      'gfp-adv-common-confirm-actions-button-no-background'    : { base: advanced['common.confirm.actions.button.no.background.color'] || basicTheme.controls.success },
      'gfp-adv-common-confirm-actions-button-no-text'          : { base: advanced['common.confirm.actions.button.no.text.color'] || colors.shades.white },
      'gfp-adv-common-confirm-actions-button-yes-background'   : { base: advanced['common.confirm.actions.button.yes.background.color'] || colors.red.darken1 },
      'gfp-adv-common-confirm-actions-button-yes-text'         : { base: advanced['common.confirm.actions.button.yes.text.color'] || colors.shades.white },

      // Sidebar
      // Need Help
      'gfp-adv-common-sidebar-needhelp-toolbar-background': { base: advanced['common.sidebar.needHelp.toolbar.background.color'] || colors.grey.lighten4 },
      'gfp-adv-common-sidebar-needhelp-toolbar-title'     : { base: advanced['common.sidebar.needHelp.toolbar.title.color'] || colors.grey.darken2 },
      'gfp-adv-common-sidebar-needhelp-toolbar-icon'      : { base: advanced['common.sidebar.needHelp.toolbar.icon.color'] || colors.grey.darken2 },
      'gfp-adv-common-sidebar-needhelp-body-background'   : { base: advanced['common.sidebar.needHelp.body.background.color'] || colors.shades.white },
      'gfp-adv-common-sidebar-needhelp-body-title'        : { base: advanced['common.sidebar.needHelp.body.title.color'] || colors.grey.darken2 },
      'gfp-adv-common-sidebar-needhelp-body-icon'         : { base: advanced['common.sidebar.needHelp.body.icon.color'] || colors.grey.darken2 },
      'gfp-adv-common-sidebar-needhelp-body-label'        : { base: advanced['common.sidebar.needHelp.body.icon.label'] || colors.grey.lighten2 },
      'gfp-adv-common-sidebar-needhelp-body-text'         : { base: advanced['common.sidebar.needHelp.body.text.color'] || colors.grey.base },

      // Delivery Areas
      'gfp-adv-common-sidebar-delivery-areas-toolbar-background'     : { base: advanced['common.sidebar.deliveryAreas.toolbar.background.color'] || colors.grey.lighten4 },
      'gfp-adv-common-sidebar-delivery-areas-toolbar-title'          : { base: advanced['common.sidebar.deliveryAreas.toolbar.title.color'] || colors.grey.darken2 },
      'gfp-adv-common-sidebar-delivery-areas-toolbar-icon'           : { base: advanced['common.sidebar.deliveryAreas.toolbar.icon.color'] || colors.grey.darken2 },
      'gfp-adv-common-sidebar-delivery-areas-body-background'        : { base: advanced['common.sidebar.deliveryAreas.body.background.color'] || colors.shades.white },
      'gfp-adv-common-sidebar-delivery-areas-body-list-area-title'   : { base: advanced['common.sidebar.deliveryAreas.body.list.area.title.color'] || colors.grey.darken4 },
      'gfp-adv-common-sidebar-delivery-areas-body-list-area-selected': { base: advanced['common.sidebar.deliveryAreas.body.list.area.selected.color'] || colors.grey.lighten4 },
      'gfp-adv-common-sidebar-delivery-areas-body-list-area-text'    : { base: advanced['common.sidebar.deliveryAreas.body.list.area.text.color'] || colors.grey.darken3 },

      // Working Hours
      'gfp-adv-common-sidebar-working-hours-toolbar-background'          : { base: advanced['common.sidebar.workingHours.toolbar.background.color'] || colors.grey.lighten4 },
      'gfp-adv-common-sidebar-working-hours-toolbar-title'               : { base: advanced['common.sidebar.workingHours.toolbar.title.color'] || colors.grey.darken2 },
      'gfp-adv-common-sidebar-working-hours-toolbar-icon'                : { base: advanced['common.sidebar.workingHours.toolbar.icon.color'] || colors.grey.darken2 },
      'gfp-adv-common-sidebar-working-hours-body-background'             : { base: advanced['common.sidebar.workingHours.body.background.color'] || colors.shades.white },
      'gfp-adv-common-sidebar-working-hours-body-text'                   : { base: advanced['common.sidebar.workingHours.body.text.color'] || colors.grey.darken3 },
      'gfp-adv-common-sidebar-working-hours-body-today-open-background'  : { base: advanced['common.sidebar.workingHours.body.todayOpen.background.color'] || basicTheme.controls.info },
      'gfp-adv-common-sidebar-working-hours-body-today-open-text'        : { base: advanced['common.sidebar.workingHours.body.todayOpen.text.color'] || colors.shades.white },
      'gfp-adv-common-sidebar-working-hours-body-today-closed-background': { base: advanced['common.sidebar.workingHours.body.todayClosed.background.color'] || colors.red.base },
      'gfp-adv-common-sidebar-working-hours-body-today-closed-text'      : { base: advanced['common.sidebar.workingHours.body.todayClosed.text.color'] || colors.shades.white },

      'gfp-adv-common-cookies-text'             : { base: advanced['common.cookies.text.color'] || colors.shades.white },
      'gfp-adv-common-cookies-link'             : { base: advanced['common.cookies.link.color'] || '#4caf50' },
      'gfp-adv-common-cookies-background'       : { base: advanced['common.cookies.background.color'] || '#212121' },
      'gfp-adv-common-cookies-button-background': { base: advanced['common.cookies.button.background.color'] || '#4caf50' },
      'gfp-adv-common-cookies-button-hover'     : { base: advanced['common.cookies.button.hover.color'] || '#278d2b' },
      'gfp-adv-common-cookies-button-text'      : { base: advanced['common.cookies.button.text.color'] || colors.shades.white },

      // Pages
      // Home
      'gfp-adv-pages-home-background': { base: advanced['pages.home.background.color'] || advancedThemeGlobal['gfp-adv-global-background'].base },
      'gfp-adv-pages-home-text'      : { base: advanced['pages.home.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },

      'gfp-adv-pages-home-sections-categories-background': { base: advanced['pages.home.sections.categories.background.color'] || basicTheme.site['gfp-primary'].base },
      'gfp-adv-pages-home-sections-categories-title'     : { base: advanced['pages.home.sections.categories.title.color'] || basicTheme.site['gfp-primary-text'].base },
      'gfp-adv-pages-home-sections-categories-text'      : { base: advanced['pages.home.sections.categories.text.color'] || basicTheme.site['gfp-primary-text'].base },
      'gfp-adv-pages-home-sections-categories-button'    : { base: advanced['pages.home.sections.categories.button.color'] || basicTheme.site['gfp-primary-text'].base },

      'gfp-adv-pages-home-sections-featured-products-background'              : { base: advanced['pages.home.sections.featuredProducts.background.color'] || basicTheme.site['gfp-toolbar'].base },
      'gfp-adv-pages-home-sections-featured-products-title'                   : { base: advanced['pages.home.sections.featuredProducts.title.color'] || colors.shades.white },
      'gfp-adv-pages-home-sections-featured-products-product-image-background': { base: advanced['pages.home.sections.featuredProducts.product.image.background.color'] || colors.shades.white },
      'gfp-adv-pages-home-sections-featured-products-product-text-title'      : { base: advanced['pages.home.sections.featuredProducts.product.text.title.color'] || basicTheme.site['gfp-primary-text'].base },
      'gfp-adv-pages-home-sections-featured-products-product-text-description': { base: advanced['pages.home.sections.featuredProducts.product.text.description.color'] || basicTheme.site['gfp-primary-text'].base },
      'gfp-adv-pages-home-sections-featured-products-product-text-button'     : { base: advanced['pages.home.sections.featuredProducts.product.text.button.color'] || basicTheme.site['gfp-primary-text'].base },
      'gfp-adv-pages-home-sections-featured-products-product-text-background' : { base: advanced['pages.home.sections.featuredProducts.product.text.background.color'] || basicTheme.site['gfp-primary'].base },

      'gfp-adv-pages-home-sections-featured-categories-background'               : { base: advanced['pages.home.sections.featuredCategories.background.color'] || colors.grey.lighten3 },
      'gfp-adv-pages-home-sections-featured-categories-title'                    : { base: advanced['pages.home.sections.featuredCategories.title.color'] || colors.grey.darken4 },
      'gfp-adv-pages-home-sections-featured-categories-category-image-background': { base: advanced['pages.home.sections.featuredCategories.category.image.background.color'] || colors.shades.white },
      'gfp-adv-pages-home-sections-featured-categories-category-text-title'      : { base: advanced['pages.home.sections.featuredCategories.category.text.title.color'] || colors.grey.darken4 },
      'gfp-adv-pages-home-sections-featured-categories-category-text-button'     : { base: advanced['pages.home.sections.featuredCategories.category.text.button.color'] || colors.grey.darken4 },
      'gfp-adv-pages-home-sections-featured-categories-category-text-background' : { base: advanced['pages.home.sections.featuredCategories.category.text.background.color'] || colors.shades.white },

      'gfp-adv-pages-home-sections-offers-background'             : { base: advanced['pages.home.sections.offers.background.color'] || basicTheme.site['gfp-primary'].base },
      'gfp-adv-pages-home-sections-offers-title'                  : { base: advanced['pages.home.sections.offers.title.color'] || basicTheme.site['gfp-primary-text'].base },
      'gfp-adv-pages-home-sections-offers-description'            : { base: advanced['pages.home.sections.offers.description.color'] || basicTheme.site['gfp-primary-text'].base },
      'gfp-adv-pages-home-sections-offers-offer-image-background' : { base: advanced['pages.home.sections.offers.offer.ribbon.background.color'] || colors.shades.white },
      'gfp-adv-pages-home-sections-offers-offer-ribbon-background': { base: advanced['pages.home.sections.offers.offer.ribbon.text.color'] || colors.red.accent4 },
      'gfp-adv-pages-home-sections-offers-offer-ribbon-text'      : { base: advanced['pages.home.sections.offers.offer.image.background.color'] || colors.shades.white },
      'gfp-adv-pages-home-sections-offers-offer-text-title'       : { base: advanced['pages.home.sections.offers.offer.text.title.color'] || colors.grey.darken4 },
      'gfp-adv-pages-home-sections-offers-offer-text-background'  : { base: advanced['pages.home.sections.offers.offer.text.background.color'] || colors.shades.white },

      'gfp-adv-pages-home-sections-partners-background': { base: advanced['pages.home.sections.partners.background.color'] || colors.shades.white },
      'gfp-adv-pages-home-sections-partners-title'     : { base: advanced['pages.home.sections.partners.title.color'] || colors.grey.darken4 },

      // Catalog
      'gfp-adv-pages-catalog-background': { base: advanced['pages.catalog.background.color'] || advancedThemeGlobal['gfp-adv-global-background'].base },
      'gfp-adv-pages-catalog-text'      : { base: advanced['pages.catalog.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },

      'gfp-adv-pages-catalog-sections-categories-background'     : { base: advanced['pages.catalog.sections.categories.background.color'] || colors.shades.white },
      'gfp-adv-pages-catalog-sections-categories-active'         : { base: advanced['pages.catalog.sections.categories.active.color'] || colors.grey.lighten3 },
      'gfp-adv-pages-catalog-sections-categories-text'           : { base: advanced['pages.catalog.sections.categories.text.color'] || colors.grey.darken1 },
      'gfp-adv-pages-catalog-sections-categories-tabs-background': { base: advanced['pages.catalog.sections.categories.tabs.background.color'] || basicTheme.controls.secondary },
      'gfp-adv-pages-catalog-sections-categories-tabs-active'    : { base: advanced['pages.catalog.sections.categories.tabs.active.color'] || basicTheme.site['gfp-primary'].base },
      'gfp-adv-pages-catalog-sections-categories-tabs-text'      : { base: advanced['pages.catalog.sections.categories.tabs.text.color'] || colors.shades.white },

      'gfp-adv-pages-catalog-sections-catalog-category-background'      : { base: advanced['pages.catalog.sections.catalog.category.background.color'] || colors.shades.black },
      'gfp-adv-pages-catalog-sections-catalog-category-title'           : { base: advanced['pages.catalog.sections.catalog.category.title.color'] || colors.shades.white },
      'gfp-adv-pages-catalog-sections-catalog-category-title-background': { base: advanced['pages.catalog.sections.catalog.category.background.color'] || colors.grey.lighten1 },

      'gfp-adv-pages-catalog-sections-catalog-product-background'                 : { base: advanced['pages.catalog.sections.catalog.product.background.color'] || colors.grey.lighten4 },
      'gfp-adv-pages-catalog-sections-catalog-product-number-background'          : { base: advanced['pages.catalog.sections.catalog.product.number.background.color'] || colors.grey.lighten2 },
      'gfp-adv-pages-catalog-sections-catalog-product-number-text'                : { base: advanced['pages.catalog.sections.catalog.product.number.text.color'] || colors.grey.darken2 },
      'gfp-adv-pages-catalog-sections-catalog-product-quantity'                   : { base: advanced['pages.catalog.sections.catalog.product.quantity.color'] || colors.orange.base },
      'gfp-adv-pages-catalog-sections-catalog-product-title'                      : { base: advanced['pages.catalog.sections.catalog.product.title.color'] || colors.grey.darken3 },
      'gfp-adv-pages-catalog-sections-catalog-product-description'                : { base: advanced['pages.catalog.sections.catalog.product.description.color'] || colors.grey.darken1 },
      'gfp-adv-pages-catalog-sections-catalog-product-price'                      : { base: advanced['pages.catalog.sections.catalog.product.price.color'] || basicTheme.controls.success },
      'gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-text'      : { base: advanced['pages.catalog.sections.catalog.product.quickAddButton.text.color'] || colors.shades.white },
      'gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-background': { base: advanced['pages.catalog.sections.catalog.product.quickAddButton.background.color'] || basicTheme.controls.success },

      'gfp-adv-pages-catalog-sections-search'           : { base: advanced['pages.catalog.sections.search.color'] || basicTheme.controls.success },
      'gfp-adv-pages-catalog-sections-search-background': { base: advanced['pages.catalog.sections.search.background.color'] || colors.shades.white },

      // Contact
      'gfp-adv-pages-contact-background'             : { base: advanced['pages.contact.background.color'] || advancedThemeGlobal['gfp-adv-global-background'].base },
      'gfp-adv-pages-contact-text'                   : { base: advanced['pages.contact.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },
      'gfp-adv-pages-contact-form-section-background': { base: advanced['pages.contact.form.section.background.color'] || basicTheme.controls.primary },
      'gfp-adv-pages-contact-form-section-icon'      : { base: advanced['pages.contact.form.section.icon.color'] || colors.shades.white },
      'gfp-adv-pages-contact-form-section-title'     : { base: advanced['pages.contact.form.section.title.color'] || colors.shades.white },
      'gfp-adv-pages-contact-form-button-background' : { base: advanced['pages.contact.form.button.background.color'] || basicTheme.controls.success },
      'gfp-adv-pages-contact-form-button-text'       : { base: advanced['pages.contact.form.button.text.color'] || colors.shades.white },
      'gfp-adv-pages-contact-form-fields'            : { base: advanced['pages.contact.form.fields.color'] || basicTheme.controls.primary },
      'gfp-adv-pages-contact-form-text'              : { base: advanced['pages.contact.form.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },

      // Account
      'gfp-adv-pages-account-background'                                            : { base: advanced['pages.account.background.color'] || advancedThemeGlobal['gfp-adv-global-background'].base },
      'gfp-adv-pages-account-text'                                                  : { base: advanced['pages.account.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },
      'gfp-adv-pages-account-tabs-background'                                       : { base: advanced['pages.account.tabs.background.color'] || basicTheme.controls.secondary },
      'gfp-adv-pages-account-tabs-text'                                             : { base: advanced['pages.account.tabs.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-tabs-active'                                           : { base: advanced['pages.account.tabs.active.color'] || basicTheme.site['gfp-primary'].base },
      'gfp-adv-pages-account-profile-controls'                                      : { base: advanced['pages.account.profile.controls.color'] || basicTheme.controls.primary },
      'gfp-adv-pages-account-profile-button-edit'                                   : { base: advanced['pages.account.profile.button.edit.color'] || colors.lightGreen.base },
      'gfp-adv-pages-account-profile-button-edit-text'                              : { base: advanced['pages.account.profile.button.edit.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-profile-button-cancel'                                 : { base: advanced['pages.account.profile.button.cancel.color'] || colors.grey.base },
      'gfp-adv-pages-account-profile-button-cancel-text'                            : { base: advanced['pages.account.profile.button.cancel.text.color'] || colors.grey.base },
      'gfp-adv-pages-account-profile-button-save'                                   : { base: advanced['pages.account.profile.button.save.color'] || basicTheme.controls.success },
      'gfp-adv-pages-account-profile-button-save-text'                              : { base: advanced['pages.account.profile.button.save.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-profile-button-manage'                                 : { base: advanced['pages.account.profile.button.manage.color'] || colors.grey.base },
      'gfp-adv-pages-account-profile-button-manage-text'                            : { base: advanced['pages.account.profile.button.manage.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-profile-button-delete'                                 : { base: advanced['pages.account.profile.button.delete.color'] || colors.red.base },
      'gfp-adv-pages-account-profile-button-delete-text'                            : { base: advanced['pages.account.profile.button.delete.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-profile-button-disable'                                : { base: advanced['pages.account.profile.button.disable.color'] || colors.orange.base },
      'gfp-adv-pages-account-profile-button-disable-text'                           : { base: advanced['pages.account.profile.button.disable.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-profile-button-enable'                                 : { base: advanced['pages.account.profile.button.enable.color'] || colors.green.base },
      'gfp-adv-pages-account-profile-button-enable-text'                            : { base: advanced['pages.account.profile.button.enable.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-profile-button-export'                                 : { base: advanced['pages.account.profile.button.export.color'] || colors.blue.base },
      'gfp-adv-pages-account-profile-button-export-text'                            : { base: advanced['pages.account.profile.button.export.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-address-card-background'                               : { base: advanced['pages.account.addresses.card.background.color'] || colors.grey.lighten4 },
      'gfp-adv-pages-account-address-card-text'                                     : { base: advanced['pages.account.addresses.card.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },
      'gfp-adv-pages-account-address-button-add'                                    : { base: advanced['pages.account.addresses.button.add.color'] || basicTheme.controls.success },
      'gfp-adv-pages-account-address-button-add-text'                               : { base: advanced['pages.account.addresses.button.add.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-address-button-edit'                                   : { base: advanced['pages.account.addresses.button.edit.color'] || colors.grey.base },
      'gfp-adv-pages-account-address-button-delete'                                 : { base: advanced['pages.account.addresses.button.delete.color'] || colors.red.base },
      'gfp-adv-pages-account-address-dialog-toolbar-background'                     : { base: advanced['pages.account.addresses.addressDialog.toolbar.background.color'] || colors.shades.white },
      'gfp-adv-pages-account-address-dialog-toolbar-title'                          : { base: advanced['pages.account.addresses.addressDialog.toolbar.title.color'] || colors.grey.darken2 },
      'gfp-adv-pages-account-address-dialog-toolbar-description'                    : { base: advanced['pages.account.addresses.addressDialog.toolbar.description.color'] || colors.grey.base },
      'gfp-adv-pages-account-address-dialog-body-background'                        : { base: advanced['pages.account.addresses.addressDialog.body.background.color'] || colors.grey.lighten3 },
      'gfp-adv-pages-account-address-dialog-body-text'                              : { base: advanced['pages.account.addresses.addressDialog.body.text.color'] || colors.grey.darken4 },
      'gfp-adv-pages-account-address-dialog-body-controls'                          : { base: advanced['pages.account.addresses.addressDialog.body.controls.color'] || basicTheme.controls.primary },
      'gfp-adv-pages-account-address-dialog-actions-background'                     : { base: advanced['pages.account.addresses.addressDialog.actions.background.color'] || colors.shades.white },
      'gfp-adv-pages-account-address-dialog-actions-text'                           : { base: advanced['pages.account.addresses.addressDialog.actions.text.color'] || colors.grey.darken2 },
      'gfp-adv-pages-account-address-dialog-actions-button-cancel-background'       : { base: advanced['pages.account.addresses.addressDialog.actions.button.cancel.background.color'] || colors.grey.darken1 },
      'gfp-adv-pages-account-address-dialog-actions-button-cancel-text'             : { base: advanced['pages.account.addresses.addressDialog.actions.button.cancel.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-address-dialog-actions-button-save-background'         : { base: advanced['pages.account.addresses.addressDialog.actions.button.save.background.color'] || basicTheme.controls.success },
      'gfp-adv-pages-account-address-dialog-actions-button-save-text'               : { base: advanced['pages.account.addresses.addressDialog.actions.button.save.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-select-address-dialog-toolbar-background'              : { base: advanced['pages.account.addresses.selectAddressDialog.toolbar.background.color'] || colors.shades.white },
      'gfp-adv-pages-account-select-address-dialog-toolbar-title'                   : { base: advanced['pages.account.addresses.selectAddressDialog.toolbar.title.color'] || colors.grey.darken2 },
      'gfp-adv-pages-account-select-address-dialog-toolbar-description'             : { base: advanced['pages.account.addresses.selectAddressDialog.toolbar.description.color'] || colors.grey.base },
      'gfp-adv-pages-account-select-address-dialog-body-background'                 : { base: advanced['pages.account.addresses.selectAddressDialog.body.background.color'] || colors.grey.lighten3 },
      'gfp-adv-pages-account-select-address-dialog-body-text'                       : { base: advanced['pages.account.addresses.selectAddressDialog.body.text.color'] || colors.grey.darken4 },
      'gfp-adv-pages-account-select-address-dialog-body-list-item-background'       : { base: advanced['pages.account.addresses.selectAddressDialog.body.list.item.background.color'] || colors.shades.white },
      'gfp-adv-pages-account-select-address-dialog-body-list-item-title'            : { base: advanced['pages.account.addresses.selectAddressDialog.body.list.item.title.color'] || colors.grey.darken1 },
      'gfp-adv-pages-account-select-address-dialog-body-list-item-address-title'    : { base: advanced['pages.account.addresses.selectAddressDialog.body.list.item.address.title.color'] || colors.grey.darken3 },
      'gfp-adv-pages-account-select-address-dialog-body-list-item-address-text'     : { base: advanced['pages.account.addresses.selectAddressDialog.body.list.item.address.text.color'] || colors.grey.darken1 },
      'gfp-adv-pages-account-select-address-dialog-actions-background'              : { base: advanced['pages.account.addresses.selectAddressDialog.actions.background.color'] || colors.shades.white },
      'gfp-adv-pages-account-select-address-dialog-actions-text'                    : { base: advanced['pages.account.addresses.selectAddressDialog.actions.text.color'] || colors.grey.darken2 },
      'gfp-adv-pages-account-select-address-dialog-actions-button-cancel-background': { base: advanced['pages.account.addresses.selectAddressDialog.actions.button.cancel.background.color'] || colors.grey.darken1 },
      'gfp-adv-pages-account-select-address-dialog-actions-button-cancel-text'      : { base: advanced['pages.account.addresses.selectAddressDialog.actions.button.cancel.text.color'] || colors.shades.white },
      'gfp-adv-pages-account-orders-list-item-background'                           : { base: advanced['pages.account.orders.list.item.background.color'] || colors.grey.lighten3 },
      'gfp-adv-pages-account-orders-list-item-title'                                : { base: advanced['pages.account.orders.list.item.title.color'] || colors.grey.darken1 },
      'gfp-adv-pages-account-orders-list-item-subtitle'                             : { base: advanced['pages.account.orders.list.item.subtitle.color'] || colors.grey.base },
      'gfp-adv-pages-account-orders-list-item-price'                                : { base: advanced['pages.account.orders.list.item.price.color'] || colors.blueGrey.darken2 },
      'gfp-adv-pages-account-orders-list-item-button-view'                          : { base: advanced['pages.account.orders.list.item.button.view.color'] || basicTheme.controls.success },
      'gfp-adv-pages-account-orders-list-item-button-reorder'                       : { base: advanced['pages.account.orders.list.item.button.reorder.color'] || colors.blue.base },

      // checkout
      'gfp-adv-pages-checkout-background'                             : { base: advanced['pages.checkout.background.color'] || advancedThemeGlobal['gfp-adv-global-background'].base },
      'gfp-adv-pages-checkout-text'                                   : { base: advanced['pages.checkout.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },
      'gfp-adv-pages-checkout-steps-background'                       : { base: advanced['pages.checkout.steps.background.color'] || colors.grey.lighten4 },
      'gfp-adv-pages-checkout-details-user-alert'                     : { base: advanced['pages.checkout.details.user.color'] || basicTheme.controls.info },
      'gfp-adv-pages-checkout-details-user-alert-text'                : { base: advanced['pages.checkout.details.user.text.color'] || basicTheme.controls.info },
      'gfp-adv-pages-checkout-details-user-alert-link'                : { base: advanced['pages.checkout.details.user.link.color'] || basicTheme.controls.success },
      'gfp-adv-pages-checkout-details-header-background'              : { base: advanced['pages.checkout.details.header.background.color'] || basicTheme.controls.primary },
      'gfp-adv-pages-checkout-details-header-title'                   : { base: advanced['pages.checkout.details.header.title.color'] || colors.shades.white },
      'gfp-adv-pages-checkout-details-header-icon'                    : { base: advanced['pages.checkout.details.header.icon.color'] || colors.shades.white },
      'gfp-adv-pages-checkout-details-address-card-background'        : { base: advanced['pages.checkout.details.address.card.background.color'] || colors.shades.white },
      'gfp-adv-pages-checkout-details-address-card-text'              : { base: advanced['pages.checkout.details.address.card.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },
      'gfp-adv-pages-checkout-details-address-card-button-add'        : { base: advanced['pages.checkout.details.address.card.button.add.color'] || basicTheme.controls.success },
      'gfp-adv-pages-checkout-details-address-card-button-add-text'   : { base: advanced['pages.checkout.details.address.card.button.add.text.color'] || colors.shades.white },
      'gfp-adv-pages-checkout-details-address-card-button-change'     : { base: advanced['pages.checkout.details.address.card.button.change.color'] || colors.blueGrey.base },
      'gfp-adv-pages-checkout-details-address-card-button-change-text': { base: advanced['pages.checkout.details.address.card.button.change.text.color'] || colors.shades.white },

      // Informational
      'gfp-adv-pages-informational-background'             : { base: advanced['pages.informational.background.color'] || advancedThemeGlobal['gfp-adv-global-background'].base },
      'gfp-adv-pages-informational-text'                   : { base: advanced['pages.informational.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },
      'gfp-adv-pages-informational-content-background'     : { base: advanced['pages.informational.content.background.color'] || colors.shades.white },
      'gfp-adv-pages-informational-content-list-background': { base: advanced['pages.informational.content.list.background.color'] || colors.shades.white },
      'gfp-adv-pages-informational-content-list-text'      : { base: advanced['pages.informational.content.list.text.color'] || advancedThemeGlobal['gfp-adv-global-text'].base },
      'gfp-adv-pages-informational-content-list-active'    : { base: advanced['pages.informational.content.list.active.color'] || colors.grey.base },
      'gfp-adv-pages-informational-content-tabs-background': { base: advanced['pages.informational.content.tabs.background.color'] || basicTheme.controls.secondary },
      'gfp-adv-pages-informational-content-tabs-text'      : { base: advanced['pages.informational.content.tabs.text.color'] || colors.shades.white },
      'gfp-adv-pages-informational-content-tabs-active'    : { base: advanced['pages.informational.content.tabs.active.color'] || basicTheme.site['gfp-primary'].base }
    }

    const vuetifyTheme = {
      ...basicTheme.controls,
      ...basicTheme.site,
      ...advancedTheme
    }

    /*
    const fn = v => v.base
    console.log(Object.fromEntries(Object.entries(advancedTheme).map(([k, v], i) => [k, fn(v, k, i)])))
     */

    return vuetifyTheme
  }

  window.callAS = function (event, data) {
    if (window.fromJS) window.fromJS(event, data)
  }

  window.fromAS = function (event, data) {
    if (window.Vue) window.Vue.$bus.$emit(event, data)
  }

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}
