export default {
  Account: {
    Addresses: 'Адреси',
    Gdpr     : 'Лични данни (GDPR)',
    Login    : 'Вход',
    Logout   : 'Изход',
    Manage   : {
      Delete: {
        ConfirmationDialog: {
          Body   : 'Ще получите ПИН в имейла си, за да потвърдите акаунта си окончателно изтриване.',
          Cancel : 'Отмяна',
          Confirm: 'Потвърждение',
          Title  : 'ПИН за пратка'
        },
        DeleteDialog: {
          Body   : 'Въведете ПИН, който получавате в имейла си, за да потвърдите окончателното си изтриване на вашия акаунт.',
          Cancel : 'Отмяна',
          Confirm: 'Окончателно изтриване на акаунта',
          Title  : 'ВЪВЕДЕНИЕ ПИН'
        },
        Title: 'Изтриване'
      },
      Disable: {
        ConfirmationDialog: {
          Body   : 'Ще получите ПИН в имейла си, за да потвърдите акаунта си.',
          Cancel : 'Отмяна',
          Confirm: 'Потвърждение',
          Send   : 'Мисия',
          Title  : 'ПИН за пратка'
        },
        DisableDialog: {
          Body   : 'Въведете ПИН, който получавате в имейла си, за да потвърдите акаунта си.',
          Cancel : 'Отмяна',
          Confirm: 'Деактивиране на акаунта',
          Title  : 'ВЪВЕДЕНИЕ ПИН'
        },
        Title  : 'Деактивиране',
        Warning: 'Вашият акаунт е деактивиран.'
      },
      Enable: {
        ConfirmationDialog: {
          Body   : 'Ще получите ПИН в имейла си, за да потвърдите активирането на вашия акаунт.',
          Cancel : 'Отмяна',
          Confirm: 'Потвърждение',
          Title  : 'ПИН за пратка'
        },
        EnableDialog: {
          Body   : 'Въведете ПИН, който получавате в имейла си, за да потвърдите активирането на вашия акаунт.',
          Cancel : 'Отмяна',
          Confirm: 'Активиране на акаунт',
          Title  : 'ВЪВЕДЕНИЕ ПИН'
        },
        Title    : 'Активиране',
        TitleLong: 'Активиране на акаунт'
      },
      Export: {
        ConfirmationDialog: {
          Body   : 'Ще получите ПИН в имейла си, за да потвърдите експортирането на данните от вашия акаунт.',
          Cancel : 'Отмяна',
          Confirm: 'Потвърждение',
          Title  : 'ПИН за пратка'
        },
        ExportDialog: {
          Body   : 'Въведете ПИН, който получавате в имейла си, за да потвърдите износа на данните на вашия акаунт.',
          Cancel : 'Отмяна',
          Confirm: 'Експортиране на данни',
          Title  : 'ВЪВЕДЕНИЕ ПИН'
        },
        Title: 'Експорт'
      },
      FieldPin: {
        Hint : 'ПИН трябва да бъде 6 числа',
        Label: 'ВЪВЕДЕНИЕ ПИН'
      },
      Title: 'Управление на профила'
    },
    Orders       : 'Поръчки',
    Profile      : 'Профил',
    Register     : 'Регистрирай се',
    Title        : 'моята сметка',
    UserAddresses: {
      AddEditDialog: {
        Add: {
          Subtitle: 'Създаване на нов адрес',
          Title   : 'Добавяне'
        },
        Default: 'Адрес по подразбиране',
        Edit   : {
          Subtitle: 'Изменение на информацията за адреса',
          Title   : 'Обработка на адреси'
        },
        UseCustom: 'Не намирате ли адреса си на картата?'
      },
      Comment      : 'Инструкции',
      ConfirmDelete: {
        Body : 'Искате ли постоянно да изтриете адреса?',
        Title: 'Изтриване на адрес?'
      },
      Default      : 'По подразбиране',
      DoorBell     : 'Камбана',
      Floor        : 'Под',
      NoDataSubText: 'Създайте нов адрес',
      NoDataText   : 'Няма адреси',
      SelectDialog : {
        Subtitle: 'Изберете адрес',
        Title   : 'Избор на адрес'
      }
    },
    UserOrders: {
      Unit: {
        Label: 'Measurement Unit',
        Hint : 'Select product measurement unit',
        Icon : 'mdi-counter',
        Error: {
          Required: 'You must select measurement unit.'
        },
        Units: [
          {
            Id      : 1,
            Name    : 'Item (Itm)',
            BaseUnit: 'Itm',
            RateUnit: 'Itm',
            Rate    : 1,
            Divisor : 1.000000
          },
          {
            Id      : 2,
            Name    : 'Kilogram (kg)',
            BaseUnit: 'kg',
            RateUnit: 'g',
            Rate    : 1000,
            Divisor : 0.001000
          },
          {
            Id      : 3,
            Name    : 'Litre (L)',
            BaseUnit: 'L',
            RateUnit: 'ml',
            Rate    : 1000,
            Divisor : 0.001000
          }
        ]
      },
      Details: {
        Date    : 'Дата',
        Subtitle: 'Подробности за поръчката',
        Time    : 'Време',
        Title   : 'Поръчка'
      },
      Pagination: {
        NoDataSubText  : 'Направете нова поръчка',
        NoDataText     : 'Няма поръчки',
        NoResultsText  : 'Не са намерени поръчки',
        RowsPerPageText: 'Поръчки на страница'
      },
      Status: {
        0: {
          Desc : 'Плащането на вашата поръчка е в процеса на обработка.',
          Info : 'Ще бъдете информирани за развитието на вашата поръчка.',
          Title: 'Плащане'
        },
        11: {
          Desc : 'Поръчката е изпратена до магазина за приемане.',
          Info : 'Ще бъдете информирани за развитието на вашата поръчка.',
          Title: 'Получени'
        },
        12: {
          Desc : 'Поръчката е висяща',
          Info : '-',
          Title: 'В очакване'
        },
        13: {
          Desc : 'Поръчката е подготвена.',
          Info : '-',
          Title: 'Се подготвя'
        },
        14: {
          Desc : 'Поръчката беше доставена.',
          Info : '-',
          Title: 'Доставени'
        },
        15: {
          Desc : 'Поръчката е изпълнена.',
          Info : '-',
          Title: 'Свършен'
        },
        19: {
          Desc  : 'Поръчката беше анулирана от магазина.',
          Info  : 'За каквато и да е информация или разяснение, моля, свържете се с магазина.',
          Refund: 'Възстановяване на сумата ({orderTotal}) на вашата поръчка',
          Title : 'Отменен'
        },
        20: {
          Desc : '-',
          Info : '-',
          Title: 'Отпечатани'
        },
        21: {
          Desc : 'Поръчката беше приета от магазина.',
          Info : 'Скоро ще получите информационен имейл с вашата поръчка.',
          Title: 'Приемлив'
        },
        Title: 'Състояние на поръчката'
      },
      Type: {
        1: 'Прогнозно време за доставка {time}',
        2: 'Прогнозно време за получаване {time}'
      }
    },
    UserProfile: {
      ChangePassword: 'Промяна на паролата',
      Info          : 'Данни',
      Notifications : 'Актуализации'
    }
  },
  Buttons: {
    Add          : 'Добавяне',
    AddAddress   : 'Добавяне',
    Cancel       : 'Отмяна',
    Change       : 'Промяна',
    ChangeAddress: 'Смяна на адрес',
    ChangeInfo   : 'Промяна на данните',
    Close        : 'Закриване',
    Complete     : 'Интеграция',
    Confirm      : 'Потвърждение',
    Delete       : 'Изтриване',
    Edit         : 'Обработка',
    More         : '| Повече ▼',
    Next         : 'Следващия',
    No           : 'Не',
    Payment      : 'Плащане',
    Quantity     : 'Количество',
    Save         : 'Запазете',
    Update       : 'Актуализация',
    Yes          : 'Да'
  },
  Cart: {
    Buy   : 'Купува',
    Coupon: {
      Button: 'Приложение за ваучер',
      Label : 'Въведете кода на купона',
      Name  : 'Купон',
      Title : 'Имате ли купон?'
    },
    Empty: {
      Subtitle: 'Добавете продукти от списъка',
      Title   : 'Празна кошница'
    },
    Error: {
      MenuItemOption: {
        Between: {
          Message: 'Моля, направете от {MinChoices} до {MaxChoices} опции | Моля, от {MinChoices} до {MaxChoices} опция | Моля, направете от {MinChoices} до {MaxChoices} опции'
        },
        MaxChoices: {
          Message: 'Моля, направете до {MaxChoices} опции | Моля, направете до {MaxChoices} опция | Моля, направете до {MaxChoices} опции'
        },
        MinChoices: {
          Message: 'Моля, {MinChoices} Опции | Моля, опция {MinChoices} | Моля, направете {MinChoices} опции'
        },
        MinChoicesRequired: {
          Message: 'Моля, поне {MinChoices} Опции | Моля, поне {MinChoices} опция | Моля, поне {MinChoices} опции'
        },
        MinimumQuantity: {
          Body : 'Запасът за продукта \'{menuItem}\' е по -малък от минималната сума ({minimumQuantity}).',
          Title: '-'
        }
      },
      Stock: {
        Invalid: {
          Body : 'Няма налични запаси за продукта \'{menuItem}\'.',
          Title: '-'
        },
        MinimumQuantity: {
          Body : 'Запасът за продукта \'{menuItem}\' е по -малък от минималната сума ({minimumQuantity}).',
          Title: '-'
        }
      }
    },
    OrderType: {
      Collection: 'Касова бележка',
      Delivery  : 'Разпределение',
      Minutes   : 'до {min}',
      Warning   : 'Получаване на поръчка от магазина'
    },
    PaymentType: {
      Card: 'Карта',
      Cash: 'Пари в брой'
    },
    Tip: {
      Amounts: [
        0.5,
        1.0,
        1.5,
        2.0,
        2.5,
        3.0,
        3.5,
        4.0,
        4.5,
        5.0
      ],
      Icon : 'Изкупи',
      Title: 'Бакшиш'
    },
    Title: 'Моята кошница'
  },
  CategoriesBlock: {
    ButtonTitle: 'Вижте списъка',
    Title      : 'Поръчай сега!'
  },
  Checkout: {
    Details: {
      Address      : 'Адрес за доставка',
      Info         : 'Данни',
      Notifications: {
        Confirm             : 'Моля, потвърдете, че вашият адрес е завършен правилно.',
        ConfirmLatLngAddress: 'Моля, потвърдете, че борът е на правилното място на картата и че адресът ви е завършен правилно.',
        Login               : 'Имате ли вече акаунт?',
        Logout              : 'Добре дошли обратно {user}. Нали?'
      },
      Title: 'Информация за клиента'
    },
    Error: {
      Address: {
        OutOfBounds: {
          Body : 'Адресът не е в границите на дистрибуция на магазина.',
          Title: '-'
        },
        Select: {
          Body : 'Трябва да изберете или добавите адрес.',
          Title: '-'
        }
      },
      Generic: {
        Body : 'Моля, проверете дали сте попълнили правилно полетата.',
        Title: '-'
      },
      InvalidCard: {
        Body : 'Моля, проверете артикулите и опитайте отново или изберете друг начин на плащане.',
        Title: 'Неуспешно плащане'
      },
      PaymentMethod: {
        Body : 'Моля, изберете начин на плащане.',
        Title: '-'
      },
      PaymentMethodMinimumOrder: {
        Body   : 'Имате нужда от {minimumOrderTotalDiff} все още за минималната поръчка. Моля, добавете още продукти към вашата количка.',
        BodyAlt: 'Имате нужда от {minimumOrderTotalDiff} за минималната поръчка.',
        Title  : 'Минимална поръчка'
      },
      AutoApplyCouponMinimumOrder: {
        Body   : 'You need {minimumOrderTotalDiff} more for a {discount} discount coupon. Please add more products to your cart.',
        BodyAlt: 'With {minimumOrderTotalDiff} more you enable {discount} discount',
        Title  : 'Minimum Order'
      }
    },
    Payment: {
      Form: {
        CardCVV   : 'CVV',
        CardHolder: 'Име',
        CardMonth : 'Месец на годност',
        CardNumber: 'номер на карта',
        CardYear  : 'Година на изтичане'
      },
      PaymentMethod            : 'Начин на плащане',
      PaymentMethodMinimumOrder: 'Минимална поръчка',
      SecurityMessage          : '128-битова защита на транзакцията за криптиране.',
      AdBlockerWarning         : 'Make sure that you have turned off any Ad Blocker that you may use.',
      Subtitle                 : 'Карта',
      Title                    : 'Плащане'
    },
    Success: {
      Warning: 'Магазинът получи вашата поръчка и скоро ще бъдете информирани за нейната еволюция.'
    },
    Title: 'Разгледайте'
  },
  Common: {
    Error: {
      StoreClosed: 'This e-shop is unavailable to take orders at the moment.',
      Generic    : {
        Body : 'Моля, проверете дали сте попълнили правилно полетата.',
        Title: '-'
      }
    },
    Login: {
      Fail   : 'Неприемлива връзка! Моля, опитайте отново.',
      Success: 'Вие сте успешно влезли'
    },
    Logout: {
      Fail   : '-',
      Success: 'Сте били прекъснати успешно'
    },
    Misc: {
      And: '&'
    }
  },
  Contact: {
    Button: 'Мисия',
    Meta  : {
      Desc : 'Свържете се с магазина {locationName} в района {locationArea}.',
      Title: 'Контакт'
    },
    MoreTitle: 'Повече информация',
    Subtitle : 'Информация за контакт',
    Title    : 'Контакт'
  },
  CookieLaw: {
    AcceptButton: 'Приемане',
    Message     : 'Уебсайтът използва бисквитки, за да оптимизира потребителското изживяване. Използвайки този сайт, вие приемате използването на бисквитки. За повече информация вижте'
  },
  Demo: {
    Alert  : 'ВНИМАНИЕ: Това е демо и няма да бъде изпълнена поръчка!',
    Warning: {
      Body : 'Това е демонстрация и някои функции са ограничени!',
      Title: 'Внимание'
    }
  },
  FeaturedCategoriesBlock: {
    CardButtonTitle: 'Вижте всички продукти',
    Title          : 'Популярни категории'
  },
  FeaturedMenuBlock: {
    CardButtonTitle: 'Искам го!',
    Title          : 'Предложени продукти'
  },
  Footer: {
    Gemi: {
      Label: 'G.E.MI.',
      Desc : 'G.E.MI. Reg. Num.'
    },
    CreditCards: 'Сигурни плащания',
    MobileApp  : {
      Android: {
        Title: 'Изтеглете мобилното приложение за Android'
      },
      IOS: {
        Title: 'Изтеглете iOS мобилно приложение'
      },
      SubTitle: 'Поръчайте сега от вашия мобилен (iOS или Android) лесно и бързо.',
      Title   : 'Изтеглете приложението'
    },
    SocialTitle: 'Влезте при нас в социалните медии!',
    Store      : {
      About    : 'За нас',
      Location : 'Къде се намираме',
      OpenHours: 'Работно време'
    },
    Stores: 'Магазини'
  },
  ForgotPasswordDialog: {
    Button       : 'Нулиране на паролата',
    RememberLogin: 'Спомняте ли си паролата?',
    Subtitle     : 'Ще ви изпратим имейл с нова парола.',
    Title        : 'Напомнете паролата'
  },
  Form: {
    AddressComments: {
      Hint : 'Допълнителни инструкции за адреса.',
      Label: 'Адресни инструкции'
    },
    AutoComplete: {
      Errors: {
        Mandatory: 'Моля, попълнете адреса си',
        Valid    : 'Адресът трябва да бъде в границите на дистрибуция на магазина'
      },
      Hint : 'Въведете вашия адрес.',
      Label: 'Въведете вашия адрес'
    },
    City: {
      Errors: {
        Mandatory: 'Моля, попълнете района',
        Valid    : 'Районът трябва да е от 2 до 120 знака'
      },
      Hint : 'Въведете адресна област.',
      Label: '■ площ'
    },
    Comments: {
      Errors: {
        Mandatory: 'Моля, попълнете съобщението си'
      },
      Hint : 'Въведете вашето съобщение.',
      Label: 'Коментари'
    },
    DoorBell: {
      Hint : 'Име, изброено в камбаната',
      Label: 'Камбана'
    },
    Email: {
      Errors: {
        Mandatory: 'Моля, попълнете електронната поща',
        Valid    : 'Електронната поща трябва да е валидна'
      },
      Hint : 'Въведете имейла си.',
      Label: 'Имейл адрес'
    },
    Firstname: {
      Errors: {
        Mandatory: 'Моля, попълнете името',
        Valid    : 'Името трябва да е от 2 до 32 знака'
      },
      Hint : 'Въведете вашето име.',
      Label: 'Име'
    },
    Floor: {
      Hint : 'напр. 1 -ви, приземен етаж, мезонет, къща и др.',
      Label: 'Под'
    },
    FriendlyName: {
      Hint : 'Например къща, офис и т.н.',
      Label: 'Приятелско име'
    },
    Lastname: {
      Errors: {
        Mandatory: 'Моля, попълнете прилагателното',
        Valid    : 'Прилагателното трябва да е от 2 до 32 знака'
      },
      Hint : 'Въведете фамилното си име.',
      Label: 'Фамилия'
    },
    MenuComments: {
      Hint : 'Допълнителни инструкции и коментари за продукта',
      Label: 'Коментари'
    },
    Notifications: {
      Email: 'Чрез имейл',
      Phone: 'Чрез телефон',
      Push : 'Чрез натискане на известия',
      Sms  : 'През SMS',
      Title: 'Актуализации за оферти'
    },
    Number: {
      Errors: {
        Mandatory: 'Моля, попълнете номера',
        Valid    : 'Номерът трябва да е от 1 до 10 знака'
      },
      Hint : 'Въведете номера на адреса.',
      Label: 'Номер'
    },
    Password: {
      Errors: {
        Mandatory: 'Моля, попълнете кода',
        Valid    : 'Кодът трябва да е от 6 до 32 знака'
      },
      Hint : 'Въведете паролата си.',
      Label: 'Парола'
    },
    PasswordConfirm: {
      Errors: {
        Mandatory: 'Моля, попълнете кода',
        Match    : 'Двата кода трябва да са еднакви',
        Valid    : 'Кодът трябва да е от 6 до 32 знака'
      },
      Hint : 'Въведете паролата си за потвърждение.',
      Label: 'Проверка на паролата'
    },
    PasswordCurrent: {
      Errors: {
        Mandatory: 'Моля, попълнете кода',
        Valid    : 'Кодът трябва да е от 6 до 32 знака'
      },
      Hint : 'Въведете предишния си код.',
      Label: 'Предишна парола'
    },
    PasswordNew: {
      Errors: {
        Mandatory: 'Моля, попълнете кода',
        Valid    : 'Кодът трябва да е от 6 до 32 знака'
      },
      Hint : 'Въведете новия си код.',
      Label: 'Нова парола'
    },
    PasswordNewConfirm: {
      Errors: {
        Mandatory: 'Моля, попълнете кода',
        Match    : 'Двата кода трябва да са еднакви',
        Valid    : 'Кодът трябва да е от 6 до 32 знака'
      },
      Hint : 'Въведете новата си парола за потвърждение.',
      Label: 'Потвърждение на новата парола'
    },
    Postcode: {
      Errors: {
        Mandatory: 'Моля, попълнете компютър',
        Valid    : 'настолен компютър трябва да е валиден'
      },
      Hint : 'напр. 153 54',
      Label: 'настолен компютър'
    },
    Street: {
      Errors: {
        Mandatory: 'Моля, попълнете пътя',
        Valid    : 'Пътят трябва да бъде от 2 до 120 знака'
      },
      Hint : 'Въведете адреса на адреса.',
      Label: 'Улица'
    },
    Telephone: {
      Errors: {
        Mandatory: 'Моля, попълнете телефона',
        Valid    : 'Телефонът трябва да е от 8 до 16 номера'
      },
      Hint : 'Въведете телефона си.',
      Label: 'Телефон'
    },
    Terms: {
      Agree : 'Съгласен съм с условията, определени от този сайт.',
      Errors: {
        Mandatory: 'Трябва да се съгласите с условията'
      },
      Note : '<Span Class = "Font-Weight-Bold"> ЗАБЕЛЕЖКА: </ant> Като се свържете с нас чрез формата по-горе, който приемате да се свържете с вас по имейл и / или телефон.',
      Title: 'Правила и условия'
    }
  },
  GdprDialog: {
    Button  : 'Контрол',
    Subtitle: 'Въведете имейла си, за да проверите акаунта си.',
    Title   : 'Управление на личните данни'
  },
  Home: {
    Meta: {
      Desc : 'Вижте директорията и поръчайте онлайн от магазина {locationName} в зоната {locationArea}, лесно, бързо и с изключителни оферти.',
      Img  : '-',
      Title: 'Първоначално'
    },
    Title: 'Първоначално'
  },
  LoginDialog: {
    Facebook      : 'Вход с Facebook',
    ForgotPassword: 'Забравихте ли паролата си?',
    Google        : 'Вход с Google',
    LoginButton   : 'Вход',
    NoSubtitle    : 'Създайте своя акаунт бързо и лесно и достъп до ексклузивни оферти!',
    NoTitle       : 'Не, нямам акаунт',
    RegisterButton: 'Създай акаунт',
    Subtitle      : 'Ако сега имате вход за акаунт, регистрирайте се иначе',
    Title         : 'Имате ли акаунт?',
    YesTitle      : 'Да, вече имам акаунт!'
  },
  Menu: {
    All   : 'всичко',
    Search: 'Търсене на продукт',
    Title : 'Списък'
  },
  NeedHelpBlock: {
    Subtitle: 'Обади ни се сега!',
    Title   : 'Трябва ти помощ;'
  },
  OffersBlock: {
    BlockButtonTitle: 'Вижте всички оферти',
    Subtitle        : 'Изберете офертата, която ви подхожда!',
    Title           : 'Оферти'
  },
  Order: {
    InternalDelivery: 'Поръчката ще бъде доставена приблизително',
    OrderTypeTime   : {
      Day: {
        Suffix: 'ден',
        Title : 'ден дни'
      },
      Hour: {
        Suffix: 'О.',
        Title : 'време часа'
      },
      Minute: {
        Suffix: 'l',
        Title : 'тънък минути'
      }
    },
    QrSourceType: {
      BTH: {
        Id   : '7',
        Title: 'Киоск',
        Type : 'Bth'
      },
      HRM: {
        Id   : '8',
        Title: 'Стая',
        Type : 'HRM'
      },
      SNB: {
        Id   : '9',
        Title: 'Стол на палубата',
        Type : 'SNB'
      },
      TBL: {
        Id   : '6',
        Title: 'Таблица',
        Type : 'Tbl'
      }
    }
  },
  Pagination: {
    NoDataText     : 'Няма данни',
    NoResultsText  : 'Няма намерени резултати',
    RowsPerPageText: 'Записи на страница'
  },
  PartnersBlock: {
    Title: 'Нашите сътрудници'
  },
  Policies: {
    Title: 'правила и условия'
  },
  PoliciesAllergens: {
    Meta: {
      Desc : 'Съхранявайте алергени на каталога {locationName} в района {locationArea}.',
      Title: 'Алерген'
    }
  },
  PoliciesCookies: {
    Meta: {
      Desc : 'Съхранявайте политиката на уебсайта на бисквитките {locationName} в района {locationArea}.',
      Title: 'Политика за бисквитки'
    }
  },
  PoliciesCopyright: {
    Meta: {
      Desc : 'Политика за защита на магазина {locationName} в района {locationArea}.',
      Title: 'Политика за защита'
    }
  },
  PoliciesPayments: {
    Meta: {
      Desc : 'Уебсайт за плащане на плащания {locationName} в района {locationArea}.',
      Title: 'Начини за плащане'
    }
  },
  PoliciesPrivacy: {
    Meta: {
      Desc : 'Политика за поверителност {locationName} в района {locationArea}.',
      Title: 'Политика за поверителност'
    }
  },
  PoliciesReturns: {
    Meta: {
      Desc : 'Политика за връщане на пари {locationName} в района {locationArea}.',
      Title: 'Политика за ретротация'
    }
  },
  PoliciesTerms: {
    Meta: {
      Desc : 'Условия за ползване на уебсайта {locationName} в района {locationArea}.',
      Title: 'Условия за ползване'
    }
  },
  PushNotifications: {
    notifyButton: {
      dialog: {
        blocked: {
          message: 'Следвайте тези инструкции, за да позволите да се предприемат известия:',
          title  : 'Премахване на блокиране на известия'
        },
        main: {
          button: {
            subscribe  : 'РЕГИСТРИРАЙ СЕ',
            unsubscribe: 'Премахване'
          },
          title: 'Управление на предупреждение'
        }
      },
      message: {
        action: {
          resubscribed: 'Вие сте се регистрирали да получавате известия',
          subscribed  : 'Благодаря за регистрацията!',
          unsubscribed: 'Няма да получавате известия отново'
        },
        prenotify: 'Щракнете, за да се регистрирате, за да получите известия'
      },
      tip: {
        state: {
          blocked     : 'Сте блокирали да получавате известия',
          subscribed  : 'Вие сте се регистрирали да получавате известия',
          unsubscribed: 'Регистрирайте се за получаване на известия'
        }
      }
    },
    promptOptions: {
      acceptButtonText    : 'Да',
      actionMessage       : 'Бихме искали да ви покажем сигнали с нови и оферти',
      cancelButtonText    : 'Не',
      emailLabel          : 'Email Address',
      smsLabel            : 'Mobile Phone Number',
      updateMessage       : 'Update your push notification subscription preferences.',
      negativeUpdateButton: 'Cancel',
      positiveUpdateButton: 'Save Preferences',
      confirmMessage      : 'Thank You!'
    },
    welcomeNotification: {
      message: 'Благодаря за регистрацията!'
    }
  },
  RegisterDialog: {
    Button       : 'Регистрирай се',
    RememberLogin: 'Имате ли вече акаунт?',
    Subtitle     : 'Като се регистрирате, получавате достъп до ексклузивни оферти',
    Terms        : {
      Agree : 'Като се регистрирате, вие се съгласявате с условията, определени от този сайт, включително използването на бисквитки.',
      Errors: {
        Mandatory: 'Трябва да се съгласите с условията'
      },
      Title: 'Правила и условия'
    },
    Title: 'Пишете сега!'
  },
  WorkingHoursBlock: {
    Closed    : 'Затворен',
    Collection: 'Касова бележка',
    Delivery  : 'График на разпространение',
    Open      : 'Открито',
    Title     : 'Работно време'
  },
  MenuOptionsDialog: {
    SelectedOptions: 'Your Selections'
  }
}
