import AppSettings from '../settings/AppSettings'
import { i18n }    from '../lang/lang'
import axios       from 'axios'
import Vue         from 'vue'
import Storage     from 'vue-web-storage'

Vue.use(Storage, {
  prefix : 'gfp_',
  drivers: ['session', 'local']
})

export const API = axios.create({
  baseURL        : process.env.VUE_APP_API_URL || '',
  timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000,
  responseType   : 'json',
  crossDomain    : true,
  withCredentials: true,
  headers        : {
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// Add a request interceptor
API.interceptors.request.use((request) => {
  const sessionId = Vue.$localStorage.get('x-session', AppSettings.XSession || '')
  const sourceTypeId = Vue.$sessionStorage.get('QRData') ? Vue.$sessionStorage.get('QRData')?.source || '1' : window?.Vue?.store?.isMobileApp ? '10' : '1'
  const referer = process.env.VUE_APP_API_X_REFERER || ''
  const locale = i18n.locale

  request.headers['X-Session'] = sessionId
  request.headers['X-Source'] = sourceTypeId
  request.headers['X-Referer'] = referer
  request.headers['Accept-Language'] = locale
  return request
}, function (error) {
  return Promise.reject(error)
})

export const APICall = {
  config              : 'config',
  dashboard           : 'dashboard',
  actionCategories    : 'actionCategories',
  featuredMenu        : 'featuredMenu',
  featuredCategories  : 'featuredCategories',
  specialOffers       : 'specialOffers',
  partners            : 'partners',
  workingHours        : 'workingHours',
  menu                : 'menu',
  menuOptions         : 'cart/options/',
  cart                : 'cart',
  cartAdd             : 'cart/add',
  cartRemove          : 'cart/remove',
  cartCoupon          : 'cart/coupon',
  cartTip             : 'cart/tip',
  cartOrderType       : 'cart/orderType',
  resetPassword       : 'auth/reset',
  authStatus          : 'auth/status',
  authGoogle          : 'auth/google',
  authFacebook        : 'auth/facebook',
  authGdpr            : 'auth/gdpr',
  accountPin          : 'account/pin',
  accountDisable      : 'account/disable',
  accountEnable       : 'account/enable',
  accountDelete       : 'account/delete',
  accountExport       : 'account/export',
  accountAddress      : 'account/address',
  accountAddressDelete: 'account/address/delete',
  accountDetails      : 'account/details',
  accountOrders       : 'account/orders',
  accountReOrder      : 'account/reorder',
  accountOrderDetails : 'account/order/details',
  checkoutAddress     : 'checkout/address',
  checkoutDetails     : 'checkout/details',
  checkoutPayment     : 'checkout/payment',
  checkoutSuccess     : 'checkout/success',
  checkoutOrderStatus : 'checkout/success/status',
  payment             : {
    stripeIntent: 'payment/stripe/intent'
  },
  pagesAll       : 'pages',
  pageById       : 'pages/',
  contactSend    : 'contact/send',
  addressValidate: 'address/validate',
  logout         : 'auth/logout'
}
