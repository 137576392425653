<template>
  <v-layout
    v-if="featuredCategories && featuredCategories.length > 0"
    :class="blockClass"
    align-center
    class="full-height"
    row
    wrap
  >
    <v-flex
      v-if="_title"
      class="text-xs-center justify-center pa-2"
      xs12
    >
      <h1 class="pb-2 gfp-adv-pages-home-sections-featured-categories-title--text">
        <v-icon
          v-if="titleIcon"
          class="mr-2"
          color="gfp-adv-pages-home-sections-featured-categories-title--text"
          dark
        >
          {{ titleIcon }}
        </v-icon>
        {{ _title }}
      </h1>
    </v-flex>

    <v-flex
      class="text-xs-center"
      xs12
    >
      <swiper
        key="featured-categories-block-swiper"
        :options="_swiperOptions"
        style="width: 100%;"
      >
        <template v-for="category in featuredCategories">
          <swiper-slide :key="'featured-categories-block-swiper-' + category.Id">
            <v-card
              :class="cardClass"
              height="100%"
            >
              <div
                v-if="category.Image"
                v-lazy:background-image="{src: category.Image, loading: getContrastLogo(LOGO_TYPE.LAZY, LOGO_COLOR.DARK)}"
                :style="{height: cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px'}"
                class="v-image__image--cover gfp-adv-pages-home-sections-featured-categories-category-image-background"
                style="background-position: center center;"
              />
              <div
                v-else
                class="gfp-adv-pages-home-sections-featured-categories-category-image-background"
                :style="'height:' + (cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px') + ';background-size: 200px; background:url('+getContrastLogo(LOGO_TYPE.ICONS, LOGO_COLOR.DARK).find(i => i.sizes==='180x180').href+') center center no-repeat;'"
              />

              <v-divider />

              <v-card-title
                class="justify-center align-start"
                primary-title
                style="min-height: 100px;"
              >
                <div>
                  <h3 class="headline mb-0 gfp-adv-pages-home-sections-featured-categories-category-text-title--text">
                    {{ category.Lang[$i18n.locale].Name }}
                  </h3>
                  <div
                    class="gfp-adv-pages-home-sections-featured-categories-category-text-title--text"
                    v-html="category.Lang[$i18n.locale].Description"
                  />
                </div>
              </v-card-title>
              <v-card-actions
                v-if="_cardButtonTitle"
                class="justify-center pb-4"
              >
                <v-btn
                  :color="cardButtonColor"
                  :to="{name: 'Menu', params: {slug: category.Lang[$i18n.locale].Slug || 'slug'}}"
                  outline
                >
                  {{ _cardButtonTitle }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </swiper-slide>
        </template>
      </swiper>
    </v-flex>
  </v-layout>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import AppData                 from '../../mixins/appdata'

export default {
  name      : 'FeaturedCategoriesBlock',
  components: {
    swiper,
    swiperSlide
  },
  directives: {},
  mixins    : [AppData],
  props     : {
    autoFetchData: {
      type   : Boolean,
      default: true
    },
    title     : String,
    titleIcon : String,
    blockClass: {
      type   : String,
      default: 'pt-3 pb-3 pl-4 pr-4 gfp-adv-pages-home-sections-featured-categories-background'
    },
    cardClass: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-featured-categories-category-text-background'
    },
    cardMediaHeight: {
      type   : [String, Number],
      default: 250
    },
    cardButtonColor: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-featured-categories-category-text-button'
    },
    cardButtonTitle: String,
    swiperOptions  : {
      type   : Object,
      default: () => {
        return {
          slidesPerView: 3,
          spaceBetween : 24,
          loop         : false,
          autoplay     : {
            delay               : 2500,
            disableOnInteraction: true
          },
          breakpoints: {
            1264: {
              slidesPerView: 3,
              spaceBetween : 24
            },
            960: {
              slidesPerView: 2,
              spaceBetween : 24
            },
            600: {
              slidesPerView: 1,
              spaceBetween : 24
            }
          }
        }
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    featuredCategories () {
      const randomizeData = true
      const data = this.dataStoreFeaturedCategories || []
      const retVal = data.filter(category => category.Lang && category.Lang[this.$i18n.locale])

      if (randomizeData) {
        return retVal
          .map(a => [Math.random(), a])
          .sort((a, b) => a[0] - b[0])
          .map(a => a[1])
      }

      return retVal
    },
    _title () {
      return this.title ? this.title : this.$t('FeaturedCategoriesBlock.Title')
    },
    _cardButtonTitle () {
      return this.cardButtonTitle ? this.cardButtonTitle : this.$t('FeaturedCategoriesBlock.CardButtonTitle')
    },
    _swiperOptions () {
      if (this.swiperOptions.slidesPerView > this.featuredCategories.length) this.swiperOptions.slidesPerView = this.featuredCategories.length
      if (this.swiperOptions.breakpoints[1264].slidesPerView > this.featuredCategories.length) this.swiperOptions.breakpoints[1264].slidesPerView = this.featuredCategories.length
      if (this.swiperOptions.breakpoints[960].slidesPerView > this.featuredCategories.length) this.swiperOptions.breakpoints[960].slidesPerView = this.featuredCategories.length
      if (this.swiperOptions.breakpoints[600].slidesPerView > this.featuredCategories.length) this.swiperOptions.breakpoints[600].slidesPerView = this.featuredCategories.length
      return this.swiperOptions
    }
  },
  watch: {},
  created () {
    if (this.autoFetchData) {
      window.API.get(window.APICall.featuredCategories)
        .then(response => {
          if (response.data.status === 200) {
            this.$set(this.dataStore, 'featuredCategories', response.data.data)
          }
        })
        .catch(e => {

        })
    }
  },
  mounted () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
.full-height .flex {
  display : flex;
}

.full-height .flex > .card {
  flex : 1 1 auto;
}
</style>
