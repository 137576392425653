import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VItemGroup,{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.amounts),function(amount,index){return _c(VItem,{key:("delivery-tip-btn-" + index),attrs:{"disabled":_vm.IsLoading,"value":amount},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return _c(VBtn,{staticClass:"mt-1 ml-1 pa-1",class:active ? _vm.textColorActive : _vm.textColor,attrs:{"color":active ? _vm.buttonColorActive : _vm.buttonColor,"disabled":_vm.IsLoading,"loading":active && _vm.IsLoading,"value":amount,"small":_vm.small,"depressed":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm._f("currency")(amount))+" "),_c(VIcon,{staticClass:"ml-2",attrs:{"color":active ? _vm.iconColorActive : _vm.iconColor,"small":""}},[_vm._v(" "+_vm._s(active ? 'close' : 'add')+" ")])],1)}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }