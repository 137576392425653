export const MapsProviderEnum = {
  Google: 'google',
  MapBox: 'mapbox'
}

export default {
  data () {
    return {}
  },
  methods : {},
  computed: {
    interactiveMapsProvider () {
      return this.appConfig?.LOCATION_DATA?.Maps?.Site?.InteractiveMapsProvider || null
    },
    staticMapsProvider () {
      return this.appConfig?.LOCATION_DATA?.Maps?.Site?.StaticMapsProvider || null
    },
    geocodeProvider () {
      return this.appConfig?.LOCATION_DATA?.Maps?.Site?.GeocodeProvider || this.interactiveMapsProvider || this.staticMapsProvider || null
    }
  }
}
