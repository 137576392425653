<template>
  <v-layout
    v-if="dataStore.partners && dataStore.partners.length > 0"
    :class="blockClass"
    align-center
    row
    wrap
  >
    <v-flex
      v-if="_title"
      class="text-xs-center pa-2"
      xs12
    >
      <h1 class="pb-2 gfp-adv-pages-home-sections-partners-title--text">
        {{ _title }}
      </h1>
    </v-flex>

    <v-flex
      class="text-xs-center"
      xs12
    >
      <swiper
        key="partners-block-swiper"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="partner in dataStore.partners"
          :key="'partners-block-swiper-' + partner.id"
        >
          <div>
            <img
              :alt="partner.name"
              :src="partner.image"
            >
          </div>
        </swiper-slide>
      </swiper>
    </v-flex>
  </v-layout>
</template>

<script>
import AppData                 from '../../mixins/appdata'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name      : 'PartnersBlock',
  components: {
    swiper,
    swiperSlide
  },
  directives: {},
  mixins    : [AppData],
  props     : {
    autoFetchData: {
      type   : Boolean,
      default: true
    },
    title     : String,
    blockClass: {
      type   : String,
      default: 'pt-3 pb-3 pl-4 pr-4 gfp-adv-pages-home-sections-partners-background'
    },
    swiperOptions: {
      type   : Object,
      default: () => {
        return {
          slidesPerView: 4,
          spaceBetween : 24,
          loop         : true,
          autoplay     : {
            delay               : 2500,
            disableOnInteraction: true
          },
          breakpoints: {
            1264: {
              slidesPerView: 3,
              spaceBetween : 24
            },
            960: {
              slidesPerView: 2,
              spaceBetween : 24
            },
            600: {
              slidesPerView: 1,
              spaceBetween : 24
            }
          }
        }
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    _title () {
      return this.title ? this.title : this.$t('PartnersBlock.Title')
    }
  },
  watch: {},
  created () {
    if (this.autoFetchData) {
      window.API.get(window.APICall.partners)
        .then(response => {
          if (response.data.status === 200) {
            this.$set(this.dataStore, 'partners', response.data.data)
          }
        })
        .catch(e => {

        })
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
