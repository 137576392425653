<template>
  <v-dialog
    v-model="isVisible"
    :overlay="false"
    fullscreen
    lazy
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card
      v-if="selectedOrder"
      ref="cardOrderdetails"
    >
      <v-toolbar
        ref="cardOrderToolbar"
        card
        class="pl-1 secondary pa-1"
        height="80"
        prominent
        style="min-height: 80px;"
      >
        <v-avatar
          v-if="getOrderSourceTypeById(selectedOrder.SourceTypeId)"
          class="blue-grey lighten-1"
        >
          <v-icon
            v-if="parseInt(selectedOrder.SourceTypeId) < 100"
            dark
            medium
          >
            {{ getOrderSourceTypeById(selectedOrder.SourceTypeId).logo }}
          </v-icon>
          <img
            v-else
            :src="getOrderSourceTypeById(selectedOrder.SourceTypeId).logo"
          >
        </v-avatar>

        <v-toolbar-title class="pl-1">
          <div class="body-3 grey--text text--lighten-3">
            {{ $t('Account.UserOrders.Details.Title') }} #{{ selectedOrder.OrderId }}
          </div>
          <div class="caption grey--text  text--lighten-1">
            {{ $t('Account.UserOrders.Details.Subtitle') }}
          </div>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          dark
          icon
          @click="isVisible = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-0 ma-0 fill-height grey lighten-4"
        style="overflow-y: auto; height: calc(100vh - 160px);"
      >
        <v-layout
          ma-0
          pa-0
          row
          wrap
        >
          <v-flex
            md6
            order-md1
            order-xs2
            xs12
          >
            <v-card
              class="grey lighten-4"
              flat
              height="100%"
            >
              <v-card-text
                ref="cardOrderdetailsLeft"
                class="pb-0"
                style="overflow-y: auto;"
              >
                <template v-for="(category, index) in selectedOrder.Details.CartItemsWithCategories">
                  <div
                    v-if="category.Category && category.Category.Lang && category.Category.Lang[$i18n.locale]"
                    :key="index"
                    :style="'border-left: #' + (category.Category.Color ? category.Category.Color : 'e1e1e1') + ' 3px solid'"
                    class="mt-2"
                    style="background-color: #e1e1e1;"
                  >
                    <v-subheader class="subheading pl-3 pt-2 pb-2">
                      {{ category.Category.Lang[$i18n.locale].Name }}
                    </v-subheader>
                  </div>

                  <template v-for="(item, i) in category.Items">
                    <template v-if="item.Lang && item.Lang[$i18n.locale]">
                      <div :key="`div-${index}-${i}`">
                        <div
                          class="body-2 pa-1"
                          style="display: table;"
                        >
                          <div style="display: table-row-group; vertical-align: middle;">
                            <span
                              class="left orange--text mr-1"
                              style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                              :style="`min-width:${item.UnitId > 1 ? 48 : 28}px`"
                            >
                              <template v-if="item.UnitId > 1">
                                {{ formatUnitQuantity($i18n.locale, item.UnitQuantity, getMeasurementUnitById(item.UnitId)) }}
                              </template>
                              <template v-else>
                                {{ item.Quantity }} x
                              </template>
                            </span>
                            <span style="display: table-cell; vertical-align: middle; width: 100%;">
                              {{ item.Lang[$i18n.locale].Name }}
                            </span>
                            <span
                              class="right ml-2"
                              style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                            >
                              {{ item.SubTotal | currency }}
                            </span>
                          </div>
                        </div>

                        <div
                          v-if="item.OptionsWithGroups && Array.isArray(item.OptionsWithGroups) && item.OptionsWithGroups.length"
                          class="grey--text text--darken-1 ml-1 caption"
                        >
                          <template v-for="(optionGroup, idx) in item.OptionsWithGroups">
                            <div
                              v-if="optionGroup.group[$i18n.locale]"
                              :key="idx"
                              style="display: table-row-group; vertical-align: middle;"
                            >
                              <div
                                style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                                :style="`min-width:${item.UnitId > 1 ? 48 : 28}px`"
                              />
                              <div style="display: table-cell; vertical-align: middle; white-space: nowrap; width: 100%;">
                                <div
                                  v-if="optionGroup.display"
                                  class="caption font-weight-bold pt-2 pb-1 grey--text text--darken-2 ml-1"
                                >
                                  {{ optionGroup.group[$i18n.locale] }}
                                </div>
                                <template v-for="(option, odx) in optionGroup.options[$i18n.locale]">
                                  <div
                                    :key="odx"
                                    style="margin-left: 1px !important;"
                                  >
                                    • <span v-html="option" />
                                  </div>
                                </template>
                              </div>
                            </div>
                          </template>
                        </div>

                        <div
                          v-if="item.Comment"
                          class="black--text ml-1 caption"
                        >
                          {{ $t('Form.MenuComments.Label') }}:
                          <span class="grey--text text--darken-1">
                            {{ item.Comment }}
                          </span>
                        </div>
                      </div>
                      <v-divider
                        :key="`divider-${index}-${i}`"
                        class="my-2"
                      />
                    </template>
                  </template>
                </template>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex
            md6
            order-md2
            order-xs1
            xs12
          >
            <v-card
              flat
              height="100%"
            >
              <v-card-text
                ref="cardOrderdetailsRight"
                style="overflow-y: auto;"
              >
                <v-container
                  fluid
                  grid-list-xs
                >
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex xs12>
                      <v-img
                        v-if="parseInt(selectedOrder.AddressId) !== 0 && selectedOrder.Details.CustomerAddress && isCustomAddress(selectedOrder.Details.CustomerAddress)"
                        height="350"
                        position="center center"
                        src="/img/map/map-placeholder.png"
                        style="border: 1px solid #e2e2e2;"
                        width="100%"
                      />
                      <v-map
                        v-else-if="parseInt(selectedOrder.AddressId) !== 0 && selectedOrder.Details.CustomerAddress && mapVisible"
                        :map-center="mapCenter"
                        :address-marker="markerAddress"
                        show-address-marker
                        show-store-marker
                        map-static
                        width="876"
                        height="350"
                      />
                    </v-flex>
                    <v-flex
                      v-if="parseInt(selectedOrder.AddressId) !== 0 && selectedOrder.Details.CustomerAddress && mapVisible"
                      mb-2
                      mt-2
                      xs12
                    >
                      <v-divider />
                    </v-flex>

                    <v-flex
                      v-if="parseInt(selectedOrder.AddressId) !== 0 && selectedOrder.Details.CustomerAddress && selectedOrder.Details.CustomerAddress.Nickname"
                      xs12
                    >
                      <v-subheader class="ml-0 pl-0">
                        {{ selectedOrder.Details.CustomerAddress.Nickname }}
                      </v-subheader>
                    </v-flex>
                    <v-flex
                      v-if="parseInt(selectedOrder.AddressId) !== 0 && selectedOrder.Details.CustomerAddress && selectedOrder.Details.CustomerAddress.Address && selectedOrder.Details.CustomerAddress.Number"
                      sm6
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span class="black--text">
                          {{ $t('Form.Street.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Details.CustomerAddress.Address + ' ' + selectedOrder.Details.CustomerAddress.Number }}
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="parseInt(selectedOrder.AddressId) !== 0 && selectedOrder.Details.CustomerAddress && (selectedOrder.Details.CustomerAddress.City || selectedOrder.Details.CustomerAddress.Postcode)"
                      sm6
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span
                          v-if="selectedOrder.Details.CustomerAddress.City"
                          class="black--text"
                        >
                          {{ $t('Form.City.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Details.CustomerAddress.City }}

                        <span
                          v-if="selectedOrder.Details.CustomerAddress.Postcode"
                          :class="[{'ml-2': !!selectedOrder.Details.CustomerAddress.City}]"
                          class="black--text"
                        >
                          {{ $t('Form.Postcode.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Details.CustomerAddress.Postcode }}
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="parseInt(selectedOrder.AddressId) !== 0 && selectedOrder.Details.CustomerAddress && selectedOrder.Details.CustomerAddress.DoorBell"
                      sm6
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span class="black--text">
                          {{ $t('Form.DoorBell.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Details.CustomerAddress.DoorBell }}
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="parseInt(selectedOrder.AddressId) !== 0 && selectedOrder.Details.CustomerAddress && selectedOrder.Details.CustomerAddress.Floor"
                      sm6
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span class="black--text">
                          {{ $t('Form.Floor.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Details.CustomerAddress.Floor }}
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="selectedOrder.Details.CustomerAddress && selectedOrder.Details.CustomerAddress.Comment"
                      sm6
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span class="black--text">
                          {{ $t('Form.Comments.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Details.CustomerAddress.Comment }}
                      </div>
                    </v-flex>
                    <v-flex
                      mt-2
                      sm6
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span class="black--text">
                          {{ $t('Form.Firstname.Label') | uppercase | sanitizeDiacritics }}:
                        </span>
                        <span class="grey--text text--darken-3">
                          {{ selectedOrder.FirstName }} {{ selectedOrder.LastName }}
                        </span>
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="selectedOrder.Telephone"
                      mt-2
                      sm6
                      xs12
                    >
                      <div
                        class="grey--text text--darken-1"
                        style="white-space: nowrap;"
                      >
                        <span class="black--text">
                          {{ $t('Form.Telephone.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Telephone | phone }}
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="selectedOrder.Telephone2"
                      mt-2
                      sm6
                      xs12
                    >
                      <div
                        class="grey--text text--darken-1"
                        style="white-space: nowrap;"
                      >
                        <span class="black--text">
                          {{ $t('Form.Telephone.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Telephone2 | phone }}
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="selectedOrder.Telephone3"
                      mt-2
                      sm6
                      xs12
                    >
                      <div
                        class="grey--text text--darken-1"
                        style="white-space: nowrap;"
                      >
                        <span class="black--text">
                          {{ $t('Form.Telephone.Label') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.Telephone3 | phone }}
                      </div>
                    </v-flex>

                    <v-flex
                      mt-2
                      xs12
                    >
                      <v-divider />
                    </v-flex>

                    <v-flex
                      mt-2
                      sm6
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span class="black--text">
                          {{ $t('Account.UserOrders.Details.Time') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.TimeAdded }}
                      </div>
                    </v-flex>
                    <v-flex
                      mt-2
                      sm6
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span class="black--text">
                          {{ $t('Account.UserOrders.Details.Date') | uppercase | sanitizeDiacritics }}:
                        </span> {{ selectedOrder.DateAdded }}
                      </div>
                    </v-flex>
                    <v-flex
                      mt-2
                      xs12
                    >
                      <v-divider />
                    </v-flex>

                    <v-flex
                      v-if="selectedOrder.Comment"
                      mt-2
                      xs12
                    >
                      <div class="grey--text text--darken-1">
                        <span class="black--text">
                          {{ selectedOrder.Details.CustomercommentsLabel }}
                        </span> {{ selectedOrder.Comment }}
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="selectedOrder.Comment"
                      mt-2
                      xs12
                    >
                      <v-divider />
                    </v-flex>

                    <v-flex
                      sm4
                      xs12
                    >
                      <v-chip
                        :class="getOrderStatusColorClass(selectedOrder)"
                        class="lighten-1 white--text elevation-0 ml-1 mr-1"
                        style="min-width: 120px; text-transform: uppercase;"
                      >
                        <v-avatar
                          :class="getOrderStatusColorClass(selectedOrder)"
                          class="darken-1"
                        >
                          <v-icon>{{ parseInt(selectedOrder.StatusId) === 19 ? 'close' : 'done' }}</v-icon>
                        </v-avatar>
                        {{ $t('Account.UserOrders.Status.' + selectedOrder.StatusId + '.Title') | uppercase | sanitizeDiacritics }}
                      </v-chip>
                    </v-flex>
                    <v-flex
                      sm4
                      xs12
                    >
                      <v-chip
                        v-if="parseInt(selectedOrder.OrderType) === 2"
                        class="light-blue white--text elevation-0"
                        style="min-width: 135px;"
                      >
                        <v-avatar class="light-blue darken-4">
                          <v-icon class="white--text">
                            directions_run
                          </v-icon>
                        </v-avatar>
                        <span>{{ $t('Cart.OrderType.Collection') | uppercase | sanitizeDiacritics }}</span>
                        <span class="pl-2">
                          {{ convertMinutesToDisplayString(selectedOrder.OrderTypeTime) }}
                        </span>
                      </v-chip>
                      <v-chip
                        v-if="selectedOrder.OrderType=='1'"
                        class="purple white--text elevation-0"
                        style="min-width: 135px;"
                      >
                        <v-avatar class="purple darken-4">
                          <v-icon class="white--text">
                            directions_bike
                          </v-icon>
                        </v-avatar>
                        <span>{{ $t('Cart.OrderType.Delivery') | uppercase | sanitizeDiacritics }}</span>
                        <span class="pl-2">
                          {{ convertMinutesToDisplayString(selectedOrder.OrderTypeTime) }}
                        </span>
                      </v-chip>
                    </v-flex>
                    <v-flex
                      sm4
                      xs12
                    >
                      <v-chip
                        v-if="selectedOrder.Payment==='cod'"
                        class="amber darken-1 white--text elevation-0"
                        style="min-width: 135px;"
                      >
                        <v-avatar class="amber darken-3">
                          <v-icon class="white--text">
                            euro_symbol
                          </v-icon>
                        </v-avatar>
                        {{ $t('Cart.PaymentType.Cash') | uppercase | sanitizeDiacritics }}
                      </v-chip>
                      <v-chip
                        v-if="selectedOrder.Payment!=='cod'"
                        class="blue-grey white--text elevation-0"
                        style="min-width: 135px;"
                      >
                        <v-avatar class="blue-grey darken-4">
                          <v-icon class="white--text">
                            credit_card
                          </v-icon>
                        </v-avatar>
                        {{ $t('Cart.PaymentType.Card') | uppercase | sanitizeDiacritics }}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions
        ref="cardOrderFooter"
        class="secondary pa-2"
        style="min-height: 80px;"
      >
        <v-container
          fluid
          grid-list-xs
          class="pa-0"
        >
          <v-layout
            row
            style="align-items: center;"
            wrap
          >
            <v-flex
              md6
              xs12
            >
              <template v-for="(item, i) in selectedOrder.Details.CartTotals">
                <div
                  :key="i"
                  class="subheading white--text"
                >
                  <template v-if="item.Lang && item.Lang[$i18n.locale]">
                    <template v-if="item.Code === 'coupon'">
                      {{ item.Lang[$i18n.locale].Name }} {{ item.Title }}
                    </template>
                    <template v-else>
                      {{ item.Lang[$i18n.locale].Name }}
                    </template>
                  </template>
                  <template v-else>
                    {{ item.Title }}
                  </template>
                  <span class="right pr-4">
                    {{ item.Value | currency }}
                  </span>
                </div>
              </template>
            </v-flex>

            <v-btn
              v-if="canReorder(selectedOrder)"
              :style="$vuetify.breakpoint.xsOnly ? 'bottom: 85px;' : 'bottom: 100px;'"
              bottom
              class="d-inline"
              color="success"
              dark
              fab
              fixed
              right
              @click.stop="reOrder()"
            >
              <v-icon>
                add_shopping_cart
              </v-icon>
            </v-btn>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppData                from '../../mixins/appdata'
import OrderTypeTime          from '../../mixins/orders/orderTypeTime'
import { formatUnitQuantity } from '@/lib/currency/currency'
import VMap                   from '@/components/common/maps/VMap.vue'
import MapsCommon             from '@/mixins/maps/mapsCommon'
import AddressCommon          from '@/mixins/maps/addressCommon'

export default {
  name      : 'AccountOrderDetailsDialog',
  components: { VMap },
  mixins    : [AppData, MapsCommon, AddressCommon, OrderTypeTime],
  props     : ['selectedOrder', 'orderDetailsDialogVisible'],
  data () {
    return {
      mapVisible: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.orderDetailsDialogVisible
      },
      set (val) {
        this.$emit('update:orderDetailsDialogVisible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.mapVisible = false
        this.$nextTick(() => {
          this.setAddress()
        })
      } else {
        this.mapVisible = false
      }
    }
  },
  updated () {
    if (typeof window === 'undefined') return
    window.dispatchEvent(new Event('resize'))
  },
  methods: {
    formatUnitQuantity,
    canReorder (order) {
      const allowedOrderSourceTypes = [1, 10, 6, 7, 8, 9]
      return allowedOrderSourceTypes.includes(parseInt(order?.SourceTypeId))
    },

    reOrder () {
      window.API.post(window.APICall.accountReOrder, {
        Id: this.selectedOrder.OrderId
      })
        .then(response => {
          if (response.data.status === 200) {
            this.visible = false
            this.$router.push({ name: 'CheckoutDetails' })
          }
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$bus.$emit('logout', true)
            location.reload()
          }
        })
    },

    getOrderSourceTypeById (id) {
      for (const category in this.appConfig.SOURCE_TYPES) {
        for (const sourceType in this.appConfig.SOURCE_TYPES[category]) {
          if (parseInt(this.appConfig.SOURCE_TYPES[category][sourceType].source_type_id) === parseInt(id)) {
            return this.appConfig.SOURCE_TYPES[category][sourceType]
          }
        }
      }

      return null
    },
    getOrderStatusColorClass (order) {
      const orderStatusId = parseInt(order.StatusId)
      if (orderStatusId === 12) return 'grey'
      if (orderStatusId === 11) return 'grey'
      if (orderStatusId === 21) return 'green'
      if (orderStatusId === 15) return 'light-green'
      if (orderStatusId === 19) return 'red'
      return ''
    },

    setAddress () {
      if (parseInt(this.selectedOrder.AddressId) !== 0 && this.selectedOrder.Details && this.selectedOrder.Details.CustomerAddress && parseInt(this.selectedOrder.OrderType) === 1) {
        this.initMapCenter()

        setTimeout(() => {
          this.mapCenter = this.markerAddress = {
            lat: parseFloat(this.selectedOrder.Details.CustomerAddress.Lat),
            lng: parseFloat(this.selectedOrder.Details.CustomerAddress.Lng)
          }
          this.mapVisible = true
        }, 250)
      } else {
        this.mapVisible = false
      }
    }
  }
}
</script>
