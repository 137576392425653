import DataEventService from '@/lib/services/event/DataEventService'
import DataEventEnum    from '@/lib/services/enum/DataEventEnum'

export default {
  name: 'PaymentGatewayCommon',

  data () {
    return {
      loading : false,
      disabled: false
    }
  },

  methods: {
    completeOrder () {
      if (this.isDemoMode() || !this.isValidPayment()) return

      this.requestData()
    },

    submitData (data = {}) {
      this.loading = true
      const defaultData = {
        Payment  : this.selectedPaymentMethod.code,
        TipAmount: this.tipAmount,
        Comment  : this.isQrOrder ? this.$t(`Order.QrSourceType.${ this.QrData.type }.Title`) + ' ' + `#${ this.QrData.value }` : this.orderComment
      }
      const payload = { ...defaultData, ...(data || {}) }

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.checkoutPayment, payload).then(response => {
        if (response.data && response.data.status === 200) {
          this.$bus.$emit('update-cart')

          if (response.data.data.Messages.Error) {
            this.$bus.$emit('show-snackbar', {
              title  : '',
              body   : response.data.data.Messages.Error,
              type   : 'error',
              icon   : '',
              timeout: 5000
            })
            this.onRequestError(response)
            this.loading = false
          } else {
            this.onRequestSuccess(response)
            this.setShippingInfoGA()
            this.setPaymentInfoGA()
          }
        }
      })
        .catch(e => {
          this.onRequestError()
          this.loading = false
        })
        .finally(() => {})
      )
    },

    requestData () {
      this.submitData()
    },

    onRequestSuccess (response) {
      this.$router.replace({ name: 'CheckoutSuccess' })
    },

    onRequestError (response) { },

    isDemoMode () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
      }

      return this.appSettings.IsDemo
    },

    isValidPayment () {
      let isValid = true

      if (this.selectedPaymentMethod) {
        if (!this.orderTotalAmountValid) {
          this.setPaymentTypeErrorMessage(this.selectedPaymentMethod)
          isValid = false
        }
      } else {
        // Please select payment type
        this.$bus.$emit('show-snackbar', {
          title: this.$t('Checkout.Error.PaymentMethod.Title'),
          body : this.$t('Checkout.Error.PaymentMethod.Body'),
          type : 'error',
          icon : ''
        })
        isValid = false
      }

      return isValid
    },

    setPaymentTypeErrorMessage (payment) {
      if (!payment) return

      const paymentMethodName = payment.lang && payment.lang[this.$i18n.locale] ? payment.lang[this.$i18n.locale].name : payment.name
      const minimumOrderTotal = this.$options.filters.currency((payment.data && payment.data.minimum_order_total) ? payment.data.minimum_order_total : 0)
      const minimumOrderTotalDiff = this.$options.filters.currency(this.orderMinTotalAmountDiffPayment(payment))

      this.$bus.$emit('show-snackbar', {
        title: this.$t('Checkout.Error.PaymentMethodMinimumOrder.Title'),
        body : this.$t('Checkout.Error.PaymentMethodMinimumOrder.Body', {
          paymentMethodName    : paymentMethodName,
          minimumOrderTotal    : minimumOrderTotal,
          minimumOrderTotalDiff: minimumOrderTotalDiff
        }),
        type   : 'error',
        icon   : '',
        timeout: 5000
      })
    },

    setShippingInfoGA () {
      let shippingTierName = 'Unknown'

      if (parseInt(this.dataStore.cart.OrderType) === 2) {
        shippingTierName = this.$t('Cart.OrderType.Collection')
      } else if (parseInt(this.dataStore.cart.OrderType) === 1) {
        shippingTierName = this.$t('Cart.OrderType.Delivery')
      }

      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.ADD_SHIPPING_INFO,
        Payload: {
          value        : parseFloat(this.selectedPaymentMethod?.data?.order_total || '0') || 0.0,
          currency     : window?.AppConfig?.APP_SETTINGS?.Currency?.name || 'EUR',
          shipping_tier: shippingTierName
        }
      })
    },

    setPaymentInfoGA () {
      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.ADD_PAYMENT_INFO,
        Payload: {
          value       : parseFloat(this.selectedPaymentMethod?.data?.order_total || '0') || 0.0,
          currency    : window?.AppConfig?.APP_SETTINGS?.Currency?.name || 'EUR',
          payment_type: this.selectedPaymentMethod?.lang && this.selectedPaymentMethod?.lang[this.$i18n.locale] && this.selectedPaymentMethod?.lang[this.$i18n.locale]?.name || this.selectedPaymentMethod?.lang?.data?.title || this.selectedPaymentMethod?.name || ''
        }
      })
    }
  },

  computed: {
    totalOrderAmount () {
      return this.dataStore?.cart?.CartTotals?.order_total?.amount || ''
    },

    tipAmount () {
      return this.dataStore?.cart?.CartTotals?.tip_amount?.amount_raw ? parseFloat(this.dataStore?.cart?.CartTotals?.tip_amount?.amount_raw) : 0
    }
  }
}
