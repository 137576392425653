<template>
  <v-layout
    justify-center
    row
  >
    <login-dialog
      :visible.sync="loginDialogVisible"
    />
    <register-dialog
      :visible.sync="registerDialogVisible"
    />
    <forgot-password-dialog
      :visible.sync="forgotPasswordDialogVisible"
    />
    <gdpr-dialog
      :visible.sync="gdprDialogVisible"
    />
  </v-layout>
</template>

<script>
import LoginDialog          from './LoginDialog'
import RegisterDialog       from './RegisterDialog'
import ForgotPasswordDialog from './ForgotPasswordDialog'
import GdprDialog           from './GdprDialog'
import DataEventEnum        from '@/lib/services/enum/DataEventEnum'
import DataEventService     from '@/lib/services/event/DataEventService'

export default {
  name      : 'Auth',
  components: {
    'gdpr-dialog'           : GdprDialog,
    'login-dialog'          : LoginDialog,
    'register-dialog'       : RegisterDialog,
    'forgot-password-dialog': ForgotPasswordDialog
  },
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {
      loginDialogVisible         : false,
      registerDialogVisible      : false,
      forgotPasswordDialogVisible: false,
      gdprDialogVisible          : false
    }
  },
  computed: {},
  watch   : {},
  created () {
    this.$bus.$on('logout', this.onLogout)
    this.$bus.$on('show-login-dialog', this.onShowLoginDialog)
    this.$bus.$on('show-register-dialog', this.onShowRegisterDialog)
    this.$bus.$on('show-forgot-password-dialog', this.onShowForgotPasswordDialog)
    this.$bus.$on('show-gdpr-dialog', this.onShowGdprDialog)

    // let userInfo = this.$auth.storage.getItem('userInfo')
    let userInfo = this.$localStorage.get('userInfo')
    try {
      if (userInfo) userInfo = JSON.parse(userInfo) ? JSON.parse(userInfo) : null
    } catch (err) {
      userInfo = null
    }

    const isUser = userInfo && userInfo.Info && userInfo.Info.Id

    if (!isUser) {
      // this.$auth.storage.removeItem('userInfo')
      this.$localStorage.remove('userInfo')
      this.$auth.storage.removeItem('vueauth_access_token')
      this.$set(this.dataStore, 'isAuthenticated', false)
      this.$set(this.dataStore, 'userInfo', null)
    } else {
      this.$set(this.dataStore, 'userInfo', userInfo)
    }
  },
  mounted () {},
  destroyed () {
    this.$bus.$off('logout', this.onLogout)
    this.$bus.$off('show-login-dialog', this.onShowLoginDialog)
    this.$bus.$off('show-register-dialog', this.onShowRegisterDialog)
    this.$bus.$off('show-forgot-password-dialog', this.onShowForgotPasswordDialog)
    this.$bus.$off('show-gdpr-dialog', this.onShowGdprDialog)
  },
  methods: {
    onLogout: function (withoutNotification) {
      /*
      this.$auth.logout({
        logoutUrl: (this.$auth.options.baseUrl + 'auth/logout'),
        url      : (this.$auth.options.baseUrl + 'auth/logout')
      }).then(() => {
        this.logoutUser(withoutNotification)
      }).catch((e) => {
        this.logoutUser(withoutNotification)
      })
      */
      window.API.post(window.APICall.logout)
        .finally(() => {
          this.logoutUser(withoutNotification)
        })
    },

    onShowLoginDialog () {
      this.loginDialogVisible = true
    },

    onShowRegisterDialog () {
      this.registerDialogVisible = true
    },

    onShowForgotPasswordDialog () {
      this.forgotPasswordDialogVisible = true
    },

    onShowGdprDialog () {
      this.gdprDialogVisible = true
    },

    logoutUser (withoutNotification) {
      const user = this.dataStore.userInfo
      this.$set(this.dataStore, 'isAuthenticated', false)
      this.$set(this.dataStore, 'userInfo', null)
      this.$localStorage.remove('userInfo')
      this.$auth.storage.removeItem('vueauth_access_token')

      if (this.$router.currentRoute.meta.requiresAuth || this.$router.currentRoute.name === 'CheckoutSuccess') this.$router.push({ name: 'Home' })
      if (!withoutNotification) {
        this.$bus.$emit('show-snackbar', {
          title: '',
          body : this.$t('Common.Logout.Success'),
          type : 'success',
          icon : ''
        })
      }
      this.$bus.$emit('user-logged-out', user)
      DataEventService.Emit(DataEventEnum.EVENT, { Event: DataEventEnum.LOGOUT, Payload: user })
    }
  }
}
</script>

<style scoped>

</style>
