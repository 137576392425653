<template>
  <v-flex
    v-if="selectedPaymentMethodSupportsTips"
    xs12
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
          <v-icon
            class="pr-2"
            color="gfp-adv-pages-checkout-details-header-icon"
          >
            {{ $t('Cart.Tip.Icon') }}
          </v-icon>
          {{ $t('Cart.Tip.Title') }}
        </v-subheader>
      </v-flex>

      <v-flex xs12>
        <delivery-tip />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>

import AppData     from '@/mixins/appdata'
import DeliveryTip from '@/components/cart/DeliveryTip.vue'
import CartCommon  from '@/mixins/orders/cartCommon'

export default {
  name      : 'PaymentDeliveryTip',
  components: { DeliveryTip },
  directives: {},
  mixins    : [AppData, CartCommon],
  props     : {},
  enums     : {},
  dataModel : null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
