<template>
  <v-container
    fluid
    pa-0
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <!-- HEADER -->
        <page-header
          :images="appConfig.LOCATION_DATA.Gallery.about"
          :title="title"
        />
      </v-flex>

      <v-flex
        lg10
        offset-lg1
        offset-xs0
        xs12
      >
        <v-container
          fluid
          pa-0
        >
          <v-layout
            :class="$vuetify.breakpoint.xsOnly ? 'pa-2' : 'pa-4'"
            row
            wrap
          >
            <v-flex
              class="hidden-xs-only"
              md3
              sm4
            >
              <need-help-block />
              <working-hours class="mt-3" />
            </v-flex>

            <v-flex
              md9
              sm8
              xs12
            >
              <v-container
                fluid
                grid-list-lg
                pt-0
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    class="hidden-sm-and-up"
                    xs12
                  >
                    <working-hours />
                  </v-flex>
                  <v-flex xs12>
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        v-html="content"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PageHeader    from '../common/PageHeader'
import WorkingHours  from '../common/WorkingHours'
import NeedHelpBlock from '../common/NeedHelpBlock'
import AppData       from '../../mixins/appdata'
import i18nRouteMeta from '../../mixins/i18nRouteMeta'

export default {
  name      : 'Contact',
  components: {
    PageHeader,
    WorkingHours,
    NeedHelpBlock
  },
  directives: {},
  mixins    : [AppData, i18nRouteMeta],
  props     : [],
  data () {
    return {
      title: this.$t('Footer.Store.About')
    }
  },
  computed: {
    content () {
      if (this.dataStore.pages) {
        if (this.dataStore.pages.About) {
          return this.dataStore.pages.About.Content
        }
      }
      return ''
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    window.API.get(window.APICall.pageById + 'About')
      .then(response => {
        if (response.data.status === 200) {
          this.$set(this.dataStore.pages, 'About', response.data.data)
        }
      })
      .catch(() => {})
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
