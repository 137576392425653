<template>
  <v-form
    ref="profileForm"
    v-model="profileFormValid"
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        v-if="!dataStore.userInfo.Info.Status"
        sm6
        xs12
      >
        <v-alert
          :dismissible="false"
          :value="true"
          class="caption"
          outline
          style="width: 100%; height: 36px;"
          type="error"
        >
          <span style="width: 100%;">
            {{ $t('Account.Manage.Disable.Warning') }}
          </span>
        </v-alert>
      </v-flex>

      <v-flex
        v-if="!dataStore.userInfo.Info.Status"
        sm6
        xs12
      >
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          class="right"
          color="success"
          dark
          @click="accountEnable"
        >
          <v-icon
            dark
            left
          >
            lock_open
          </v-icon>
          {{ $t('Account.Manage.Enable.TitleLong') }}
        </v-btn>
      </v-flex>

      <v-flex xs12>
        <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading subheading mb-2">
          <v-icon
            class="pr-2"
            color="gfp-adv-pages-checkout-details-header-icon"
          >
            account_circle
          </v-icon>
          {{ $t('Account.UserProfile.Info') }}
        </v-subheader>
      </v-flex>

      <v-flex
        sm6
        xs12
      >
        <v-text-field
          v-model="customer.Info.Firstname"
          :disabled="!editMode"
          :error-messages="errorMessages.profileForm.first_name"
          :hint="$t('Form.Firstname.Hint')"
          :label="$t('Form.Firstname.Label')"
          :rules="validationRules.profileForm.first_name"
          :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
          color="gfp-adv-pages-account-profile-controls"
          prepend-icon="account_circle"
          required
        />
      </v-flex>
      <v-flex
        sm6
        xs12
      >
        <v-text-field
          v-model="customer.Info.Lastname"
          :disabled="!editMode"
          :error-messages="errorMessages.profileForm.last_name"
          :hint="$t('Form.Lastname.Hint')"
          :label="$t('Form.Lastname.Label')"
          :rules="validationRules.profileForm.last_name"
          :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
          color="gfp-adv-pages-account-profile-controls"
          prepend-icon="account_circle"
          required
        />
      </v-flex>
      <v-flex
        sm6
        xs12
      >
        <v-text-field
          v-model="customer.Info.Email"
          :disabled="true"
          :error-messages="errorMessages.profileForm.email"
          :hint="$t('Form.Email.Hint')"
          :label="$t('Form.Email.Label')"
          :rules="validationRules.profileForm.email"
          :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
          color="gfp-adv-pages-account-profile-controls"
          prepend-icon="email"
          required
        />
      </v-flex>
      <v-flex
        sm6
        xs12
      >
        <v-text-field
          v-model="customer.Info.Telephone"
          :disabled="!editMode"
          :error-messages="errorMessages.profileForm.telephone"
          :hint="$t('Form.Telephone.Hint')"
          :label="$t('Form.Telephone.Label') + ' 1'"
          :rules="validationRules.profileForm.telephone"
          :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
          color="gfp-adv-pages-account-profile-controls"
          mask="### ## ## ### ######"
          prepend-icon="phone"
          required
        />
      </v-flex>
      <v-flex
        sm6
        xs12
      >
        <v-text-field
          v-model="customer.Info.Telephone2"
          :disabled="!editMode"
          :error-messages="errorMessages.profileForm.telephone1"
          :hint="$t('Form.Telephone.Hint')"
          :label="$t('Form.Telephone.Label') + ' 2'"
          :rules="validationRules.profileForm.telephone2"
          :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
          color="gfp-adv-pages-account-profile-controls"
          mask="### ## ## ### ######"
          prepend-icon="phone"
        />
      </v-flex>
      <v-flex
        sm6
        xs12
      >
        <v-text-field
          v-model="customer.Info.Telephone3"
          :disabled="!editMode"
          :error-messages="errorMessages.profileForm.telephone2"
          :hint="$t('Form.Telephone.Hint')"
          :label="$t('Form.Telephone.Label') + ' 3'"
          :rules="validationRules.profileForm.telephone3"
          :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
          color="gfp-adv-pages-account-profile-controls"
          mask="### ## ## ### ######"
          prepend-icon="phone"
        />
      </v-flex>

      <v-flex xs12>
        <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading subheading mb-2 mt-3">
          <v-icon
            class="pr-2"
            color="gfp-adv-pages-checkout-details-header-icon"
          >
            notifications
          </v-icon>
          {{ $t('Account.UserProfile.Notifications') }}
        </v-subheader>
      </v-flex>

      <v-flex xs12>
        <v-subheader class="pl-1 gfp-adv-pages-account-text--text">
          {{ $t('Form.Notifications.Title') }}
        </v-subheader>
        <v-layout align-center>
          <v-checkbox
            v-model="customer.Info.Newsletter"
            :disabled="!editMode"
            :label="$t('Form.Notifications.Email')"
            :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
            color="success"
            hide-details
          />

          <v-checkbox
            v-model="customer.Info.SMS"
            :disabled="!editMode"
            :label="$t('Form.Notifications.Sms')"
            :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
            color="success"
            hide-details
          />

          <v-checkbox
            v-model="customer.Info.Push"
            :disabled="!editMode"
            :label="$t('Form.Notifications.Push')"
            :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
            color="success"
            hide-details
          />
        </v-layout>
      </v-flex>

      <template v-if="editMode">
        <v-flex xs12>
          <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading subheading mb-2 mt-3">
            <v-icon
              class="pr-2"
              color="gfp-adv-pages-checkout-details-header-icon"
            >
              lock
            </v-icon>
            {{ $t('Account.UserProfile.ChangePassword') }}
          </v-subheader>
        </v-flex>
        <v-flex
          v-if="!customer.Info.SocialProvider"
          xs12
        >
          <v-text-field
            v-model="customer.Info.OldPass"
            :append-icon="p1 ? 'visibility' : 'visibility_off'"
            :disabled="!editMode"
            :error-messages="errorMessages.profileForm.old_password"
            :hint="$t('Form.PasswordCurrent.Hint')"
            :label="$t('Form.PasswordCurrent.Label')"
            :rules="validationRules.profileForm.old_password"
            :type="p1 ? 'password' : 'text'"
            :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
            color="gfp-adv-pages-account-profile-controls"
            prepend-icon="lock_open"
            @click:append="p1 = !p1"
          />
        </v-flex>
        <v-flex
          sm6
          xs12
        >
          <v-text-field
            v-model="customer.Info.NewPass"
            :append-icon="p2 ? 'visibility' : 'visibility_off'"
            :disabled="!editMode || (customer.Info.SocialProvider ? false : !customer.Info.OldPass)"
            :error-messages="errorMessages.profileForm.password"
            :hint="$t('Form.PasswordNew.Hint')"
            :label="$t('Form.PasswordNew.Label')"
            :rules="validationRules.profileForm.new_password"
            :type="p2 ? 'password' : 'text'"
            :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
            color="gfp-adv-pages-account-profile-controls"
            prepend-icon="lock_outline"
            @click:append="p2 = !p2"
          />
        </v-flex>
        <v-flex
          sm6
          xs12
        >
          <v-text-field
            v-model="customer.Info.NewPassConfirm"
            :append-icon="p3 ? 'visibility' : 'visibility_off'"
            :disabled="!editMode || (customer.Info.SocialProvider ? false : !customer.Info.OldPass)"
            :hint="$t('Form.PasswordNewConfirm.Hint')"
            :label="$t('Form.PasswordNewConfirm.Label')"
            :rules="validationRules.profileForm.new_password_confirm"
            :type="p3 ? 'password' : 'text'"
            :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-background'].base) === 'white'"
            color="gfp-adv-pages-account-profile-controls"
            prepend-icon="lock_outline"
            @click:append="p3 = !p3"
          />
        </v-flex>
      </template>

      <v-flex
        class="text-xs-right mt-3"
        xs12
      >
        <v-divider class="mb-3" />
        <template v-if="!editMode">
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            color="gfp-adv-pages-account-profile-button-edit"
            class="gfp-adv-pages-account-profile-button-edit-text--text"
            large
            @click="editMode=true"
          >
            <v-icon
              dark
              left
            >
              edit
            </v-icon>
            {{ $t('Buttons.Edit') | sanitizeDiacritics }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            color="gfp-adv-pages-account-profile-button-cancel"
            class="gfp-adv-pages-account-profile-button-cancel-text--text"
            large
            outline
            @click="closeEditMode"
          >
            {{ $t('Buttons.Cancel') | sanitizeDiacritics }}
          </v-btn>
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="submitButton"
            :loading="submitButton"
            color="gfp-adv-pages-account-profile-button-save"
            class="gfp-adv-pages-account-profile-button-save-text--text"
            large
            @click="saveData"
          >
            {{ $t('Buttons.Save') | sanitizeDiacritics }}
          </v-btn>
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            color="gfp-adv-pages-account-profile-button-manage"
            class="gfp-adv-pages-account-profile-button-manage-text--text"
            large
            @click="manageAccountVisible=!manageAccountVisible"
          >
            {{ $t('Account.Manage.Title') }}
          </v-btn>
        </template>

        <template v-if="manageAccountVisible && editMode">
          <v-flex
            class="text-xs-right mt-3"
            xs12
          >
            <v-divider />
          </v-flex>
          <v-flex xs12>
            <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading subheading mb-2 mt-3">
              <v-icon
                class="pr-2"
                color="gfp-adv-pages-checkout-details-header-icon"
              >
                lock
              </v-icon>
              {{ $t('Account.Manage.Title') }}
            </v-subheader>
          </v-flex>
          <v-flex
            class="text-xs-right mt-3"
            xs12
          >
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              color="gfp-adv-pages-account-profile-button-delete"
              class="gfp-adv-pages-account-profile-button-delete-text--text"
              @click="accountDelete"
            >
              <v-icon
                dark
                left
              >
                delete
              </v-icon>
              {{ $t('Account.Manage.Delete.Title') }}
            </v-btn>
            <v-btn
              v-if="dataStore.userInfo.Info.Status"
              :block="$vuetify.breakpoint.xsOnly"
              color="gfp-adv-pages-account-profile-button-disable"
              class="gfp-adv-pages-account-profile-button-disable-text--text"
              @click="accountDisable"
            >
              <v-icon
                dark
                left
              >
                lock
              </v-icon>
              {{ $t('Account.Manage.Disable.Title') }}
            </v-btn>
            <v-btn
              v-if="!dataStore.userInfo.Info.Status"
              :block="$vuetify.breakpoint.xsOnly"
              color="gfp-adv-pages-account-profile-button-enable"
              class="gfp-adv-pages-account-profile-button-enable-text--text"
              @click="accountEnable"
            >
              <v-icon
                dark
                left
              >
                lock_open
              </v-icon>
              {{ $t('Account.Manage.Enable.Title') }}
            </v-btn>
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              color="gfp-adv-pages-account-profile-button-export"
              class="gfp-adv-pages-account-profile-button-export-text--text"
              @click="accountExport"
            >
              <v-icon
                dark
                left
              >
                system_update_alt
              </v-icon>
              {{ $t('Account.Manage.Export.Title') }}
            </v-btn>
          </v-flex>
        </template>
      </v-flex>

      <confirm-input-dialog
        :html-content="confirmDialog.content"
        :html-title="confirmDialog.title"
        :input="confirmDialog.input"
        :input-value.sync="confirmDialog.pin"
        :no-button-class="confirmDialog.noButtonClass"
        :no-button-text="confirmDialog.noButtonText"
        :visible.sync="confirmDialog.visible"
        :yes-button-class="confirmDialog.yesButtonClass"
        :yes-button-text="confirmDialog.yesButtonText"
        yes-button-event="on-confirm-yes"
        @on-confirm-yes="confirmDialog.callback"
      />
    </v-layout>
  </v-form>
</template>

<script>
import AppData              from '../../mixins/appdata'
import ConfirmInputDialog   from '../common/ConfirmInputDialog'
import DataEventService     from '@/lib/services/event/DataEventService'
import DataEventEnum        from '@/lib/services/enum/DataEventEnum'
import { getContrastColor } from '@/lib/color'

export default {
  name      : 'AccountUserProfile',
  components: {
    'confirm-input-dialog': ConfirmInputDialog
  },
  directives: {},
  mixins    : [AppData],
  props     : {
    customerInfo: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      confirmDialog: {
        visible       : false,
        input         : false,
        noButtonText  : this.$t('Account.Manage.Disable.ConfirmationDialog.Cancel'),
        yesButtonText : this.$t('Account.Manage.Disable.ConfirmationDialog.Send'),
        yesButtonClass: 'gfp-adv-common-confirm-actions-button-yes-background gfp-adv-common-confirm-actions-button-yes-text--text',
        noButtonClass : 'btn--outline gfp-adv-common-confirm-actions-button-no-background--text',
        title         : this.$t('Account.Manage.Disable.ConfirmationDialog.Title'),
        content       : '',
        action        : '',
        pin           : '',
        callback      : () => {
        }
      },
      manageAccountVisible: false,
      submitButton        : false,
      tmpCustomer         : null,
      profileFormValid    : false,
      editMode            : false,
      p1                  : true,
      p2                  : true,
      p3                  : true,
      errorMessages       : {
        profileForm: {
          first_name  : [],
          last_name   : [],
          telephone   : [],
          email       : [],
          old_password: [],
          password    : []
        }
      },
      validationRules: {
        profileForm: {
          first_name: [
            v => !!v || this.$t('Form.Firstname.Errors.Mandatory'),
            v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Firstname.Errors.Valid')
          ],
          last_name: [
            v => !!v || this.$t('Form.Lastname.Errors.Mandatory'),
            v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Lastname.Errors.Valid')
          ],
          telephone: [
            v => !!v || this.$t('Form.Telephone.Errors.Mandatory'),
            v => v && v.length >= 8 && v.length <= 16 || this.$t('Form.Telephone.Errors.Valid')
          ],
          telephone2: [
            v => !v || v.length >= 8 && v.length <= 16 || this.$t('Form.Telephone.Errors.Valid')
          ],
          telephone3: [
            v => !v || v.length >= 8 && v.length <= 16 || this.$t('Form.Telephone.Errors.Valid')
          ],
          email: [
            v => !!v || this.$t('Form.Email.Errors.Mandatory'),
            v => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Form.Email.Errors.Valid')
          ],
          old_password: [
            v => !v || (v && v.length >= 6 && v.length <= 32) || this.$t('Form.Password.Errors.Valid')
          ],
          new_password: [
            v => !this.customer.Info.OldPass || (this.customer.Info.OldPass && this.customer.Info.OldPass.length >= 6 && this.customer.Info.OldPass.length <= 32) || this.$t('Form.Password.Errors.Mandatory'),
            v => !this.customer.Info.OldPass || (v && v.length >= 6 && v.length <= 32) || this.$t('Form.Password.Errors.Valid')
          ],
          new_password_confirm: [
            v => !this.customer.Info.OldPass || (this.customer.Info.OldPass && this.customer.Info.OldPass.length >= 6 && this.customer.Info.OldPass.length <= 32) || this.$t('Form.Password.Errors.Mandatory'),
            v => !this.customer.Info.OldPass || (v && v.length >= 6 && v.length <= 32) || this.$t('Form.Password.Errors.Valid'),
            v => !this.customer.Info.OldPass || (v && v === this.customer.Info.NewPass) || this.$t('Form.PasswordConfirm.Errors.Match')
          ]
        }
      }
    }
  },
  computed: {
    customer: {
      get () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (!this.tmpCustomer) this.tmpCustomer = JSON.parse(JSON.stringify(this.customerInfo))
        return this.tmpCustomer
      },
      set (data) {
        this.$emit('update:customerInfo', data)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getContrastColor,
    closeEditMode () {
      this.clearApiErrorMessages()
      this.tmpCustomer = null
      this.tmpCustomer = JSON.parse(JSON.stringify(this.customer))
      this.editMode = false
    },
    accountDisable () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      this.confirmDialog.callback = this.onConfirmGetPin
      this.confirmDialog.action = 'DISABLE'
      this.confirmDialog.yesButtonText = this.$t('Account.Manage.Disable.ConfirmationDialog.Confirm')
      this.confirmDialog.yesButtonClass = 'light-green darken-1 white--text'
      this.confirmDialog.title = this.$t('Account.Manage.Disable.ConfirmationDialog.Title')
      this.confirmDialog.content = this.$t('Account.Manage.Disable.ConfirmationDialog.Body')
      this.confirmDialog.input = false
      this.confirmDialog.visible = true
    },
    onConfirmAccountDisable () {
      window.API.post(window.APICall.accountDisable, {
        CustomerId: this.customer.Info.Id,
        PIN       : this.confirmDialog.pin
      })
        .then(response => {
          if (response.data.status === 200) {
            if (response.data.data.Messages.Success) {
              this.dataStore.userInfo.Info.Status = false
              this.$localStorage.set('userInfo', JSON.stringify(this.dataStore.userInfo))
              this.confirmDialog.visible = false
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Success,
                type : 'success',
                icon : ''
              })
              DataEventService.Emit(DataEventEnum.EVENT, {
                Event  : DataEventEnum.ACCOUNT_DISABLE,
                Payload: JSON.parse(JSON.stringify(this.dataStore.userInfo))
              })
              this.$bus.$emit('logout')
            } else if (response.data.data.Messages.Error) {
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Error,
                type : 'error',
                icon : ''
              })
            }
          }
        })
        .catch(e => {

        })
    },

    accountEnable () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      this.confirmDialog.callback = this.onConfirmGetPin
      this.confirmDialog.action = 'ENABLE'
      this.confirmDialog.yesButtonText = this.$t('Account.Manage.Enable.ConfirmationDialog.Confirm')
      this.confirmDialog.yesButtonClass = 'light-green darken-1 white--text'
      this.confirmDialog.title = this.$t('Account.Manage.Enable.ConfirmationDialog.Title')
      this.confirmDialog.content = this.$t('Account.Manage.Enable.ConfirmationDialog.Body')
      this.confirmDialog.input = false
      this.confirmDialog.visible = true
    },
    onConfirmAccountEnable () {
      window.API.post(window.APICall.accountEnable, {
        CustomerId: this.customer.Info.Id,
        PIN       : this.confirmDialog.pin
      })
        .then(response => {
          if (response.data.status === 200) {
            if (response.data.data.Messages.Success) {
              this.dataStore.userInfo.Info.Status = true
              // this.$auth.storage.setItem('userInfo', JSON.stringify(this.dataStore.userInfo))
              this.$localStorage.set('userInfo', JSON.stringify(this.dataStore.userInfo))
              this.confirmDialog.visible = false
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Success,
                type : 'success',
                icon : ''
              })
              DataEventService.Emit(DataEventEnum.EVENT, {
                Event  : DataEventEnum.ACCOUNT_ENABLE,
                Payload: JSON.parse(JSON.stringify(this.dataStore.userInfo))
              })
            } else if (response.data.data.Messages.Error) {
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Error,
                type : 'error',
                icon : ''
              })
            }
          }
        })
        .catch(e => {

        })
    },

    accountDelete () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      this.confirmDialog.callback = this.onConfirmGetPin
      this.confirmDialog.action = 'DELETE'
      this.confirmDialog.yesButtonText = this.$t('Account.Manage.Delete.ConfirmationDialog.Confirm')
      this.confirmDialog.yesButtonClass = 'light-green darken-1 white--text'
      this.confirmDialog.title = this.$t('Account.Manage.Delete.ConfirmationDialog.Title')
      this.confirmDialog.content = this.$t('Account.Manage.Delete.ConfirmationDialog.Body')
      this.confirmDialog.input = false
      this.confirmDialog.visible = true
    },
    onConfirmAccountDelete () {
      window.API.post(window.APICall.accountDelete, {
        CustomerId: this.customer.Info.Id,
        PIN       : this.confirmDialog.pin
      })
        .then(response => {
          if (response.data.status === 200) {
            if (response.data.data.Messages.Success) {
              this.confirmDialog.visible = false
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Success,
                type : 'success',
                icon : ''
              })
              DataEventService.Emit(DataEventEnum.EVENT, {
                Event  : DataEventEnum.ACCOUNT_DELETE,
                Payload: JSON.parse(JSON.stringify(this.dataStore.userInfo))
              })
              this.$bus.$emit('logout')
            } else if (response.data.data.Messages.Error) {
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Error,
                type : 'error',
                icon : ''
              })
            }
          }
        })
        .catch(e => {

        })
    },

    accountExport () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      this.confirmDialog.callback = this.onConfirmGetPin
      this.confirmDialog.action = 'EXPORT'
      this.confirmDialog.yesButtonText = this.$t('Account.Manage.Export.ConfirmationDialog.Confirm')
      this.confirmDialog.yesButtonClass = 'light-green darken-1 white--text'
      this.confirmDialog.title = this.$t('Account.Manage.Export.ConfirmationDialog.Title')
      this.confirmDialog.content = this.$t('Account.Manage.Export.ConfirmationDialog.Body')
      this.confirmDialog.input = false
      this.confirmDialog.visible = true
    },
    onConfirmAccountExport () {
      window.API.post(window.APICall.accountExport, {
        CustomerId: this.customer.Info.Id,
        PIN       : this.confirmDialog.pin
      })
        .then(response => {
          if (response.data.status === 200) {
            if (response.data.data.Messages.Success) {
              this.confirmDialog.visible = false
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Success,
                type : 'success',
                icon : ''
              })
            } else if (response.data.data.Messages.Error) {
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Error,
                type : 'error',
                icon : ''
              })
            }
          }
        })
        .catch(e => {

        })
    },

    onConfirmGetPin () {
      window.API.post(window.APICall.accountPin, { CustomerId: this.customer.Info.Id })
        .then(response => {
          if (response.data.status === 200) {
            if (response.data.data.Messages.Success) {
              this.confirmDialog.content = response.data.data.Messages.Success + '<br><br>'
              this.confirmDialog.title = this.$t('Account.Manage.Export.ExportDialog.Title')
              if (this.confirmDialog.action === 'EXPORT') {
                this.confirmDialog.callback = this.onConfirmAccountExport
                this.confirmDialog.content += this.$t('Account.Manage.Export.ExportDialog.Body')
                this.confirmDialog.yesButtonText = this.$t('Account.Manage.Export.ExportDialog.Confirm')
                this.confirmDialog.yesButtonClass = 'blue white--text'
              } else if (this.confirmDialog.action === 'ENABLE') {
                this.confirmDialog.callback = this.onConfirmAccountEnable
                this.confirmDialog.content += this.$t('Account.Manage.Enable.EnableDialog.Body')
                this.confirmDialog.yesButtonText = this.$t('Account.Manage.Enable.EnableDialog.Confirm')
                this.confirmDialog.yesButtonClass = 'green white--text'
              } else if (this.confirmDialog.action === 'DISABLE') {
                this.confirmDialog.callback = this.onConfirmAccountDisable
                this.confirmDialog.content += this.$t('Account.Manage.Disable.DisableDialog.Body')
                this.confirmDialog.yesButtonText = this.$t('Account.Manage.Disable.DisableDialog.Confirm')
                this.confirmDialog.yesButtonClass = 'warning white--text'
              } else if (this.confirmDialog.action === 'DELETE') {
                this.confirmDialog.callback = this.onConfirmAccountDelete
                this.confirmDialog.content += this.$t('Account.Manage.Delete.DeleteDialog.Body')
                this.confirmDialog.yesButtonText = this.$t('Account.Manage.Delete.DeleteDialog.Confirm')
                this.confirmDialog.yesButtonClass = 'error white--text'
              }
              this.confirmDialog.input = true
            } else if (response.data.data.Messages.Error) {
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Error,
                type : 'error',
                icon : ''
              })
            }
          }
        })
        .catch(e => {

        })
    },

    saveData () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      this.clearApiErrorMessages()
      this.$refs.profileForm.validate()

      this.$nextTick(() => {
        if (this.profileFormValid) {
          this.submitButton = true
          window.API.post(window.APICall.accountDetails, this.customer)
            .then(response => {
              if (response.data && response.data.status === 200) {
                if (response.data.data.Messages.Success) {
                  this.tmpCustomer = JSON.parse(JSON.stringify(response.data.data.CustomerInfo))
                  this.customer = JSON.parse(JSON.stringify(response.data.data.CustomerInfo))
                  this.editMode = false
                  this.$bus.$emit('show-snackbar', {
                    title: '',
                    body : response.data.data.Messages.Success,
                    type : 'success',
                    icon : ''
                  })
                  DataEventService.Emit(DataEventEnum.EVENT, {
                    Event  : DataEventEnum.ACCOUNT_UPDATE,
                    Payload: JSON.parse(JSON.stringify(response.data.data.CustomerInfo))
                  })
                } else {
                  for (const key in response.data.data.Messages) {
                    if (response.data.data.Messages.hasOwnProperty(key) && this.errorMessages.profileForm.hasOwnProperty(key)) {
                      if (response.data.data.Messages[key].trim()) this.errorMessages.profileForm[key].push(response.data.data.Messages[key])
                    }
                  }
                }
              }
            })
            .catch(e => {
              if (e.response.status === 404) {
                this.$bus.$emit('logout', true)
                location.reload()
              }
            })
            .finally(() => {
              this.submitButton = false
            })
        }
      })
    },

    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      var key
      for (key in this.errorMessages.profileForm) {
        if (this.errorMessages.profileForm.hasOwnProperty(key)) {
          this.errorMessages.profileForm[key] = []
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
