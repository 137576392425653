export default {
  name    : 'vModel',
  props   : ['value'],
  computed: {
    vModel: {
      get () {
        return this.value || ''
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
