<template>
  <v-container
    fluid
    pa-0
    class="gfp-adv-pages-checkout-background gfp-adv-pages-checkout-text--text"
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <!-- HEADER -->
        <page-header
          :address="subTitle"
          :images="appConfig.LOCATION_DATA.Gallery.checkout"
          :title="$t('Checkout.Title')"
          address-icon="arrow_right"
        />
      </v-flex>

      <v-flex
        lg10
        offset-lg1
        xs12
        offset-xs0
      >
        <v-container
          fluid
          pa-0
        >
          <v-layout
            :class="$vuetify.breakpoint.xsOnly ? 'pa-2' : 'pa-4'"
            row
            wrap
          >
            <v-flex
              class="hidden-xs-only"
              md3
              sm4
            >
              <need-help-block />
              <working-hours class="mt-3" />
            </v-flex>

            <v-flex
              md9
              sm8
              xs12
            >
              <router-view />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AppData       from '../../../mixins/appdata'
import i18nRouteMeta from '../../../mixins/i18nRouteMeta'
import PageHeader    from '../../common/PageHeader'
import WorkingHours  from '../../common/WorkingHours'
import NeedHelpBlock from '../../common/NeedHelpBlock'
import i18n          from '../../../lang/lang'
import CartCommon    from '@/mixins/orders/cartCommon'

export default {
  name      : 'Checkout',
  components: {
    PageHeader,
    WorkingHours,
    NeedHelpBlock
  },
  directives: {},
  mixins    : [AppData, i18nRouteMeta, CartCommon],
  beforeRouteEnter (to, from, next) {
    if (from.name || to.params.redirect || to.params.id) {
      next(vm => { vm.setMetaTitle(to.name) })
    } else {
      next({ name: 'Menu', params: { lang: i18n.locale } })
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.setMetaTitle(to.name)
    this.checkPermissions()
    next()
  },
  props: [],
  data () {
    return {
      title   : this.$t('Checkout.Title'),
      subTitle: ''
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
    window.CartPromiseQueue.push(() => window.API.get(window.APICall.cart)
      .then(response => {
        if (response.data.status === 200) {
          this.$set(this.dataStore, 'cart', response.data.data)
        }
      })
      .catch(e => {})
      .finally(() => {
        if (this.route && this.route.name !== 'CheckoutSuccess') this.checkPermissions()
      })
    )
  },
  created () {
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
    setMetaTitle (routeName) {
      let title = this.$t('Checkout.Title')
      if (routeName == 'CheckoutDetails') {
        title += ' - ' + this.$t('Checkout.Details.Title')
        this.subTitle = this.$t('Checkout.Details.Title')
      } else if (routeName == 'CheckoutPayment') {
        title += ' - ' + this.$t('Checkout.Payment.Title')
        this.subTitle = this.$t('Checkout.Payment.Title')
      } else {
        this.subTitle = ''
      }
      this.title = title
    },
    checkPermissions () {
      if (!this.dataStore.cart || (this.dataStore.cart && this.dataStore.cart.CategoryItems.length <= 0)) {
        this.$router.replace({ name: 'Menu', params: { lang: i18n.locale } })
      }
    }
  }
}
</script>

<style scoped>

</style>
