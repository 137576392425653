/**
 *  Converts a string into Capitalize
 *
 * 'abc' => 'Abc'
 */
import greekUtils from '../../components/common/greek-utils'

function sanitizeDiacritics (value) {
  if (!value && value !== 0) return ''
  value = value.toString()

  return greekUtils.sanitizeDiacritics(value)
}

export default sanitizeDiacritics
