export default {
  data () {
    return {}
  },
  components: {},
  mixins    : [],
  directives: {},
  watch     : {},
  methods   : {
    convertMinutesToDisplayString (minutes, padZero = false, useSuffix = true) {
      let retVal = ''
      let day, hour, minute
      minutes = parseInt(minutes) || 0
      hour = Math.floor(minutes / 60)
      minute = minutes % 60
      day = Math.floor(hour / 24)
      hour = hour % 24

      retVal += day > 0 ? `${ padZero ? this.padZero(String(day), 2) : day }${ useSuffix ? this.$tc('Order.OrderTypeTime.Day.Suffix', day) : ' ' + this.$tc('Order.OrderTypeTime.Day.Title', day) }` : ''
      retVal += day > 0 && hour > 0 ? useSuffix ? ' ' : ` ${ this.$t('Common.Misc.And') } ` : ''
      retVal += hour > 0 ? `${ padZero ? this.padZero(String(hour), 2) : hour }${ useSuffix ? this.$tc('Order.OrderTypeTime.Hour.Suffix', hour) : ' ' + this.$tc('Order.OrderTypeTime.Hour.Title', hour) }` : ''
      retVal += hour > 0 && minute > 0 ? useSuffix ? ' ' : ` ${ this.$t('Common.Misc.And') } ` : ''
      retVal += minute > 0 ? `${ padZero ? this.padZero(String(minute), 2) : minute }${ useSuffix ? this.$tc('Order.OrderTypeTime.Minute.Suffix', minute) : ' ' + this.$tc('Order.OrderTypeTime.Minute.Title', minute) }` : ''

      return retVal
    },
    padZero (str, len) {
      len = len || 2
      const zeros = new Array(len).join('0')
      return (zeros + str).slice(-len)
    }
  },
  computed: {
    deliveryTime () {
      return this.convertMinutesToDisplayString(parseInt(isNaN(this.dataStore.cart.DeliveryTime) ? 0 : this.dataStore.cart.DeliveryTime), false, false)
    },
    collectionTime () {
      return this.convertMinutesToDisplayString(parseInt(isNaN(this.dataStore.cart.CollectionTime) ? 0 : this.dataStore.cart.CollectionTime), false, false)
    }
  },
  created () {},
  mounted () { },
  updated () { },
  beforeDestroy () { }

}
