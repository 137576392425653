import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"enter-active-class":("animated " + (_vm.toast.config.animation.enter)),"leave-active-class":("animated " + (_vm.toast.config.animation.exit))},on:{"enter":_vm.enter,"leave":_vm.leave,"before-enter":_vm.beforeEnter,"after-enter":_vm.afterEnter,"before-leave":_vm.beforeLeave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.opacity),expression:"opacity"}],staticClass:"snotifyToast",class:['snotify-' + _vm.toast.config.type],style:({
      opacity: _vm.opacity,
      'max-height': _vm.maxHeight + 'px',
      '-webkit-animation-duration': _vm.toast.config.animation.time + 'ms',
      'animation-duration': _vm.toast.config.animation.time + 'ms',
      '-webkit-transition': _vm.toast.config.animation.time + 'ms',
      transition: _vm.toast.config.animation.time + 'ms'
    }),on:{"click":_vm.onClick,"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[(_vm.toast.config.showProgressBar)?_c('div',{staticClass:"snotifyToast__progressBar"},[_c('span',{staticClass:"snotifyToast__progressBar__percentage",style:({'width': _vm.progress + '%'})})]):_vm._e(),(!_vm.toast.config.html)?_c('div',{staticClass:"snotifyToast__inner"},[(_vm.toast.title)?_c('div',{staticClass:"snotifyToast__title"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.toast.title,_vm.toast.config.titleMaxLength))+" ")]):_vm._e(),_c('div',{staticClass:"snotifyToast__body"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.toast.body,_vm.toast.config.bodyMaxLength))+" ")]),(_vm.toast.config.type === _vm.promptType)?_c('snotify-prompt',{attrs:{"placeholder":_vm.toast.config.placeholder},on:{"valueChanged":_vm.promptValueChanged}}):_vm._e(),(!_vm.toast.config.icon)?_c('snotify-icon',{staticClass:"snotify-icon",attrs:{"type":_vm.toast.config.type}}):_c(VIcon,{staticClass:"snotify-icon",staticStyle:{"opacity":"0.75","font-size":"48px"},attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.toast.config.icon)+" ")])],1):_c('div',{staticClass:"snotifyToast__inner",domProps:{"innerHTML":_vm._s(_vm.toast.config.html)}}),(_vm.toast.config.buttons)?_c('snotify-button',{attrs:{"id":_vm.toast.id,"buttons":_vm.toast.config.buttons,"value":_vm.toast.config.type === _vm.promptType ? _vm.value : null}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }