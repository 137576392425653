<template>
  <v-layout
    :class="blockClass"
    align-center
    row
    wrap
  >
    <v-flex
      v-if="_title"
      class="text-lg-left text-xs-center"
      lg3
      sm12
    >
      <a
        v-if="isCatalogOnly && hasLandingPage && hasMultipleLocations"
        :href="landingPage"
      >
        <h1
          style="white-space: nowrap;"
          class="gfp-adv-pages-home-sections-categories-title--text"
        >
          <v-icon
            v-if="titleIcon"
            class="mr-2 mb-1 gfp-adv-pages-home-sections-categories-title--text"
            size="26"
          >
            {{ titleIcon }}
          </v-icon>
          {{ _title }}
        </h1>
      </a>

      <router-link
        v-else
        :to="titleRouteLink"
      >
        <h1
          style="white-space: nowrap;"
          class="gfp-adv-pages-home-sections-categories-title--text"
        >
          <v-icon
            v-if="titleIcon"
            class="mr-2 mb-1 gfp-adv-pages-home-sections-categories-title--text"
            size="26"
          >
            {{ titleIcon }}
          </v-icon>
          {{ _title }}
        </h1>
      </router-link>
    </v-flex>
    <v-flex
      class="text-lg-center text-xs-center pa-3"
      lg6
      sm12
    >
      <h4 v-show="!allCategoriesVisible">
        <template v-for="(category, index) in actionCategories.slice(0, maxVisibleCategories)">
          <router-link
            :key="category.Id"
            :to="{name: 'Menu', params: {slug: category.Lang[$i18n.locale].Slug}}"
          >
            <span>{{ category.Lang[$i18n.locale].Name.trim() }}{{ index >= (actionCategories.length > maxVisibleCategories ? maxVisibleCategories - 1 : actionCategories.length - 1) ? '' : ', ' }}</span>
          </router-link>
        </template>
        <span
          v-if="!allCategoriesVisible && actionCategories.length > maxVisibleCategories"
          class="pa-1"
          style="cursor: pointer; opacity: 0.7"
          @click="allCategoriesVisible = !allCategoriesVisible"
        >...{{ $t('Buttons.More').toLowerCase() }}</span>
      </h4>

      <v-slide-y-transition>
        <h4 v-show="allCategoriesVisible">
          <template v-for="(category, index) in actionCategories">
            <router-link
              :key="category.Id"
              :to="{name: 'Menu', params: {slug: category.Lang[$i18n.locale].Slug}}"
            >
              {{ category.Lang[$i18n.locale].Name.trim() }}{{ index >= actionCategories.length - 1 ? '' : ', ' }}
            </router-link>
          </template>
        </h4>
      </v-slide-y-transition>
    </v-flex>
    <v-flex
      v-if="_buttonTitle"
      class="text-lg-right text-xs-center"
      lg3
      sm12
    >
      <v-btn
        :color="buttonColor"
        :to="buttonRouteLink"
        large
        outline
      >
        {{ _buttonTitle }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import AppData from '@/mixins/appdata'

export default {
  name      : 'CategoriesBlock',
  components: {},
  directives: {},
  mixins    : [AppData],
  props     : {
    autoFetchData: {
      type   : Boolean,
      default: true
    },
    title         : String,
    titleRouteLink: {
      type   : Object,
      default: () => {
        return { name: 'Menu', params: {}, query: {} }
      }
    },
    titleIcon: {
      type   : String,
      default: ''
    },
    buttonTitle    : String,
    buttonRouteLink: {
      type   : Object,
      default: () => {
        return { name: 'Menu', params: {}, query: {} }
      }
    },
    buttonColor: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-categories-button'
    },
    blockClass: {
      type   : String,
      default: 'pt-3 pb-3 pl-4 pr-4 gfp-adv-pages-home-sections-categories-background gfp-adv-pages-home-sections-categories-text--text'
    }
  },
  data () {
    return {
      toggleCategories: null
    }
  },
  computed: {
    maxVisibleCategories () {
      return this.appConfig.LOCATION_DATA.MaxVisibleCategoriesHome || 8
    },
    allCategoriesVisible: {
      get () {
        return this.toggleCategories === null ? this.categoriesVisible : this.toggleCategories
      },
      set (v) {
        this.toggleCategories = v
      }
    },
    categoriesVisible () {
      return this.appConfig.LOCATION_DATA.hasOwnProperty('ShowAllCategoriesHome') ? this.appConfig.LOCATION_DATA.ShowAllCategoriesHome : true
    },
    showRandomCategories () {
      return this.appConfig.LOCATION_DATA.hasOwnProperty('ShowRandomCategoriesHome') ? this.appConfig.LOCATION_DATA.ShowRandomCategoriesHome : false
    },
    actionCategories () {
      let categories = (this.dataStoreActionCategories || []).filter(category => category.Lang[this.$i18n.locale])
      categories = this.showRandomCategories
        ? categories.map(a => [Math.random(), a]).sort((a, b) => a[0] - b[0]).map(a => a[1])
        : categories
      return categories
    },
    _title () {
      return this.title ? this.title : this.$t('CategoriesBlock.Title')
    },
    _buttonTitle () {
      return this.buttonTitle ? this.buttonTitle : this.$t('CategoriesBlock.ButtonTitle')
    }
  },
  watch: {},
  created () {
    if (this.autoFetchData) {
      window.API.get(window.APICall.actionCategories)
        .then(response => {
          if (response.data.status === 200) {
            this.$set(this.dataStore, 'actionCategories', response.data.data)
          }
        })
        .catch(e => {

        })
    }
  },
  mounted () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
a {
  text-decoration : none;
  color           : var(--v-gfp-primary-text);
}
</style>
