export default {
  Account: {
    Addresses: 'Addresses',
    Gdpr     : 'Personal Data (GDPR)',
    Login    : 'Login',
    Logout   : 'Logout',
    Manage   : {
      Delete: {
        ConfirmationDialog: {
          Body   : 'You will receive a PIN in your email to confirm the permanent deletion of your account.',
          Cancel : 'Cancel',
          Confirm: 'Confirm',
          Title  : 'Send PIN'
        },
        DeleteDialog: {
          Body   : 'Enter the PIN you received in your email to confirm the permanent deletion of your account.',
          Cancel : 'Cancel',
          Confirm: 'Permanently Delete Account',
          Title  : 'Insert PIN'
        },
        Title: 'Delete'
      },
      Disable: {
        ConfirmationDialog: {
          Body   : 'You will receive a PIN in your email to confirm your account deactivation.',
          Cancel : 'Cancel',
          Confirm: 'Confirm',
          Send   : 'Send',
          Title  : 'Send PIN'
        },
        DisableDialog: {
          Body   : 'Enter the PIN you received in your email to confirm your account deactivation.',
          Cancel : 'Cancel',
          Confirm: 'Disable Account',
          Title  : 'Insert PIN'
        },
        Title  : 'Disable',
        Warning: 'Your account is deactivated.'
      },
      Enable: {
        ConfirmationDialog: {
          Body   : 'You will receive a PIN in your email to confirm your account activation.',
          Cancel : 'Cancel',
          Confirm: 'Confirm',
          Title  : 'Send PIN'
        },
        EnableDialog: {
          Body   : 'Enter the PIN you received in your email to confirm your account activation.',
          Cancel : 'Cancel',
          Confirm: 'Activate Account',
          Title  : 'Insert PIN'
        },
        Title    : 'Activate',
        TitleLong: 'Activate Account'
      },
      Export: {
        ConfirmationDialog: {
          Body   : 'You will receive a PIN in your email to confirm the export of your account data.',
          Cancel : 'Cancel',
          Confirm: 'Confirm',
          Title  : 'Send PIN'
        },
        ExportDialog: {
          Body   : 'Enter the PIN you received in your email to confirm the export of your account data.',
          Cancel : 'Cancel',
          Confirm: 'Export Data',
          Title  : 'Insert PIN'
        },
        Title: 'Export'
      },
      FieldPin: {
        Hint : 'PIN must be 6 numbers',
        Label: 'Insert PIN'
      },
      Title: 'Manage Account'
    },
    Orders       : 'Orders',
    Profile      : 'Profile',
    Register     : 'Register',
    Title        : 'My account',
    UserAddresses: {
      AddEditDialog: {
        Add: {
          Subtitle: 'Create New Address',
          Title   : 'Add Address'
        },
        Default: 'Default Address',
        Edit   : {
          Subtitle: 'Modify Address Information',
          Title   : 'Edit Address'
        },
        UseCustom: 'Can\'t find your address on the map ?'
      },
      Comment      : 'Comments',
      ConfirmDelete: {
        Body : 'Are you sure you want to delete the address permanently?',
        Title: 'Delete Address?'
      },
      Default      : 'Default',
      DoorBell     : 'Door Bell',
      Floor        : 'Floor',
      NoDataSubText: 'Create a address',
      NoDataText   : 'No Addresses Available',
      SelectDialog : {
        Subtitle: 'Choose an Address',
        Title   : 'Select Address'
      }
    },
    UserOrders: {
      Unit: {
        Label: 'Measurement Unit',
        Hint : 'Select product measurement unit',
        Icon : 'mdi-counter',
        Error: {
          Required: 'You must select measurement unit.'
        },
        Units: [
          {
            Id      : 1,
            Name    : 'Item (Itm)',
            BaseUnit: 'Itm',
            RateUnit: 'Itm',
            Rate    : 1,
            Divisor : 1.000000
          },
          {
            Id      : 2,
            Name    : 'Kilogram (kg)',
            BaseUnit: 'kg',
            RateUnit: 'g',
            Rate    : 1000,
            Divisor : 0.001000
          },
          {
            Id      : 3,
            Name    : 'Litre (L)',
            BaseUnit: 'L',
            RateUnit: 'ml',
            Rate    : 1000,
            Divisor : 0.001000
          }
        ]
      },
      Details: {
        Date    : 'Date',
        Subtitle: 'Order Details',
        Time    : 'Time',
        Title   : 'Order'
      },
      Pagination: {
        NoDataSubText  : 'Create a new order',
        NoDataText     : 'No Orders Available',
        NoResultsText  : 'No Orders Found',
        RowsPerPageText: 'Orders Per Page'
      },
      Status: {
        0: {
          Desc : 'Payment for your order is being processed.',
          Info : 'You will be notified of your order progress.',
          Title: 'Payment'
        },
        11: {
          Desc : 'The order has been sent to the store for acceptance.',
          Info : 'You will be notified of your order progress.',
          Title: 'Received'
        },
        12: {
          Desc : 'The order is pending',
          Info : '',
          Title: 'Pending'
        },
        13: {
          Desc : 'The order is being prepared.',
          Info : '',
          Title: 'Preparing'
        },
        14: {
          Desc : 'The order is delivered.',
          Info : '',
          Title: 'Delivered'
        },
        15: {
          Desc : 'The order is complete.',
          Info : '',
          Title: 'Complete'
        },
        19: {
          Desc  : 'The order was canceled by the store.',
          Info  : 'For any information or clarification please contact the store.',
          Refund: 'The order amount ({orderTotal}) of your order has been refunded',
          Title : 'Canceled'
        },
        20: {
          Desc : '',
          Info : '',
          Title: 'Printed'
        },
        21: {
          Desc : 'The order was accepted by the store.',
          Info : 'You will soon receive an email with your order details.',
          Title: 'Accepted'
        },
        Title: 'Order Status'
      },
      Type: {
        1: 'Estimated delivery time {time}',
        2: 'Estimated collection time {time}'
      }
    },
    UserProfile: {
      ChangePassword: 'Change password',
      Info          : 'Info',
      Notifications : 'Notifications'
    }
  },
  Buttons: {
    Add          : 'Add',
    AddAddress   : 'Add Address',
    Cancel       : 'Cancel',
    Change       : 'Change',
    ChangeAddress: 'Change Address',
    ChangeInfo   : 'Change Info',
    Close        : 'Close',
    Complete     : 'Complete',
    Confirm      : 'Confirm',
    Delete       : 'Delete',
    Edit         : 'Edit',
    More         : 'More',
    Next         : 'Next',
    No           : 'No',
    Payment      : 'Payment',
    Quantity     : 'Quantity',
    Save         : 'Save',
    Update       : 'Update',
    Yes          : 'Yes'
  },
  Cart: {
    Buy   : 'Buy',
    Coupon: {
      Button: 'Apply Coupon',
      Label : 'Enter coupon code',
      Name  : 'Coupon',
      Title : 'Do you have a coupon?'
    },
    Empty: {
      Subtitle: 'Add products from the catalog',
      Title   : 'Empty Cart'
    },
    Error: {
      MenuItemOption: {
        Between: {
          Message: 'Please select from {MinChoices} to {MaxChoices} options | Please select from {MinChoices} to {MaxChoices} option | Please select from {MinChoices} to {MaxChoices} options'
        },
        MaxChoices: {
          Message: 'Please select up to {MaxChoices} options | Please select up to {MaxChoices} option | Please select up to {MaxChoices} options'
        },
        MinChoices: {
          Message: 'Please select {MinChoices} options | Please select {MinChoices} option | Please select {MinChoices} options'
        },
        MinChoicesRequired: {
          Message: 'Please select at least {MinChoices} options | Please select at least {MinChoices} option | Please select at least {MinChoices} options'
        },
        MinimumQuantity: {
          Body : 'The stock for the product "{menuItem}" is less than the minimum order quantity ({minimumQuantity}).',
          Title: ''
        }
      },
      Stock: {
        Invalid: {
          Body : 'There is no stock available for the product "{menuItem}".',
          Title: ''
        },
        MinimumQuantity: {
          Body : 'The stock for product «{menuItem}» is less than minimum order quantity ({minimumQuantity}).',
          Title: ''
        }
      }
    },
    OrderType: {
      Collection: 'Collection',
      Delivery  : 'Delivery',
      Minutes   : 'in {min}',
      Warning   : 'Collect your order from the store'
    },
    PaymentType: {
      Card: 'Card',
      Cash: 'Cash'
    },
    Tip: {
      Amounts: [
        0.5,
        1.0,
        1.5,
        2.0,
        2.5,
        3.0,
        3.5,
        4.0,
        4.5,
        5.0
      ],
      Icon : 'redeem',
      Title: 'Tip'
    },
    Title: 'My Shopping Cart'
  },
  CategoriesBlock: {
    ButtonTitle: 'View The Catalog',
    Title      : 'ORDER NOW!'
  },
  Checkout: {
    Details: {
      Address      : 'Shipping Address',
      Info         : 'Info',
      Notifications: {
        Confirm             : 'Please confirm that your address is filled correctly.',
        ConfirmLatLngAddress: 'Please confirm that the pin is in the correct location on the map and that your address has been filled correctly.',
        Login               : 'Do you already have an account?',
        Logout              : 'Welcome back {user}. Not you?'
      },
      Title: 'Order Info'
    },
    Error: {
      Address: {
        OutOfBounds: {
          Body : 'The address is not within the delivery area of the store.',
          Title: ''
        },
        Select: {
          Body : 'You must select or add an address.',
          Title: ''
        }
      },
      Generic: {
        Body : 'Please check that you have filled in all fields correctly.',
        Title: ''
      },
      InvalidCard: {
        Body : 'Please check the details and try again or select another payment method.',
        Title: 'Payment Failed'
      },
      PaymentMethod: {
        Body : 'Please select a payment method.',
        Title: ''
      },
      PaymentMethodMinimumOrder: {
        Body   : 'You need {minimumOrderTotalDiff} more for the minimum order. Please add more products to your cart.',
        BodyAlt: 'You need {minimumOrderTotalDiff} more for the minimum order',
        Title  : 'Minimum Order'
      },
      AutoApplyCouponMinimumOrder: {
        Body   : 'You need {minimumOrderTotalDiff} more for a {discount} discount coupon. Please add more products to your cart.',
        BodyAlt: 'With {minimumOrderTotalDiff} more you enable {discount} discount',
        Title  : 'Minimum Order'
      }
    },
    Payment: {
      Form: {
        CardCVV   : 'CVV',
        CardHolder: 'Card Holder Name',
        CardMonth : 'Expiration Month',
        CardNumber: 'Card Number',
        CardYear  : 'Expiration Year'
      },
      PaymentMethod            : 'Payment Method',
      PaymentMethodMinimumOrder: 'Minimum Order',
      SecurityMessage          : 'Transaction Security with 128-bit Encryption.',
      AdBlockerWarning         : 'Make sure that you have turned off any Ad Blocker that you may use.',
      Subtitle                 : 'Card Info',
      Title                    : 'Payment'
    },
    Success: {
      Warning: 'The store received your order and you will soon be informed of its progress.',
      Error  : 'Order not found.'
    },
    Title: 'Checkout'
  },
  Common: {
    Error: {
      StoreClosed: 'This e-shop is unavailable to take orders at the moment.',
      Generic    : {
        Body : 'Please check that you have filled in all fields correctly.',
        Title: ''
      }
    },
    Login: {
      Fail   : 'Unsuccessful Login! Please try again.',
      Success: 'You have been successfully logged in'
    },
    Logout: {
      Fail   : '',
      Success: 'You have successfully logged out'
    },
    Misc: {
      And: '&'
    },
    Map: {
      Title    : 'Map',
      Satellite: 'Satellite'
    }
  },
  Contact: {
    Button: 'Send',
    Meta  : {
      Desc : 'Contact the {locationName} store in {locationArea} area.',
      Title: 'Contact'
    },
    MoreTitle: 'More Info',
    Subtitle : 'Contact Info',
    Title    : 'Contact'
  },
  CookieLaw: {
    AcceptButton: 'Accept',
    Message     : 'The site uses cookies to optimize the user experience. By using this site, you accept the use of cookies. For more info see'
  },
  Demo: {
    Alert  : 'WARNING: This is a demo and no order will be executed !',
    Warning: {
      Body : 'This is a demo and some features are limited!',
      Title: 'WARNING'
    }
  },
  FeaturedCategoriesBlock: {
    CardButtonTitle: 'View category',
    Title          : 'Popular Categories'
  },
  FeaturedMenuBlock: {
    CardButtonTitle: 'Get It!',
    Title          : 'Suggested Products!'
  },
  Footer: {
    Gemi: {
      Label: 'G.E.MI.',
      Desc : 'G.E.MI. Reg. Num.'
    },
    CreditCards: 'Secure Payments',
    MobileApp  : {
      Android: {
        Title: 'Download Android Mobile App'
      },
      IOS: {
        Title: 'Download iOS Mobile App'
      },
      SubTitle: 'Order now from your mobile (iOS or Android) easily and quickly.',
      Title   : 'Download App'
    },
    SocialTitle: 'Connect with us on social media!',
    Store      : {
      About        : 'About Us',
      Location     : 'Our Location',
      OpenHours    : 'Working Hours',
      DeliveryAreas: 'Delivery Areas'
    },
    Stores: 'Stores'
  },
  ForgotPasswordDialog: {
    Button       : 'Reset Password',
    RememberLogin: 'You remember the password?',
    Subtitle     : 'We will send you an email with a new password.',
    Title        : 'Password Reminder'
  },
  Form: {
    AddressComments: {
      Hint : 'Additional directions for address.',
      Label: 'Address Directions'
    },
    AutoComplete: {
      Errors: {
        Mandatory: 'Please enter your address',
        Valid    : 'Address must be within delivery area of the store'
      },
      Hint : 'Enter your address.',
      Label: 'Enter your address'
    },
    City: {
      Errors: {
        Mandatory: 'Please fill in city',
        Valid    : 'The city must be from 2 to 120 characters'
      },
      Hint : 'Enter the address city.',
      Label: 'City'
    },
    Comments: {
      Errors: {
        Mandatory: 'Please fill in your message.'
      },
      Hint : 'Please enter your message.',
      Label: 'Comments'
    },
    DoorBell: {
      Hint : 'Name entered on the bell',
      Label: 'Door Bell'
    },
    Email: {
      Errors: {
        Mandatory: 'Please fill in E-mail',
        Valid    : 'E-mail must be valid'
      },
      Hint : 'Enter your email.',
      Label: 'Email Address'
    },
    Firstname: {
      Errors: {
        Mandatory: 'Please fill in your first name',
        Valid    : 'First name must be from 2 to 32 characters'
      },
      Hint : 'Enter your first name.',
      Label: 'Name'
    },
    Floor: {
      Hint : 'eg. 1st, Ground floor, Maisonette, etc.',
      Label: 'Floor'
    },
    FriendlyName: {
      Hint : 'ex. Home, Office, etc.',
      Label: 'Friendly Name'
    },
    Lastname: {
      Errors: {
        Mandatory: 'Please fill in your last name',
        Valid    : 'Last name must be from 2 to 32 characters'
      },
      Hint : 'Enter your last name.',
      Label: 'Last Name'
    },
    MenuComments: {
      Hint : 'e.g., without onion',
      Label: 'Comments'
    },
    Notifications: {
      Email: 'By Email',
      Phone: 'By Telephone',
      Push : 'By Push Notifications',
      Sms  : 'By SMS',
      Title: 'Notifications for offers'
    },
    Number: {
      Errors: {
        Mandatory: 'Please fill in number',
        Valid    : 'The number must be from 1 to 10 characters'
      },
      Hint : 'Enter the address number.',
      Label: 'Number'
    },
    Password: {
      Errors: {
        Mandatory: 'Please enter a password',
        Valid    : 'The password must be from 6 to 32 characters'
      },
      Hint : 'Enter your password.',
      Label: 'Password'
    },
    PasswordConfirm: {
      Errors: {
        Mandatory: 'Please enter a password',
        Match    : 'Both passwords must be the same',
        Valid    : 'The password must be from 6 to 32 characters'
      },
      Hint : 'Enter your password to confirm.',
      Label: 'Confirm password'
    },
    PasswordCurrent: {
      Errors: {
        Mandatory: 'Please enter your current password',
        Valid    : 'The password must be from 6 to 32 characters'
      },
      Hint : 'Enter your current password.',
      Label: 'Current password'
    },
    PasswordNew: {
      Errors: {
        Mandatory: 'Please enter a password',
        Valid    : 'The new password must be from 6 to 32 characters'
      },
      Hint : 'Enter your new password.',
      Label: 'New Password'
    },
    PasswordNewConfirm: {
      Errors: {
        Mandatory: 'Please enter a password',
        Match    : 'Both new passwords must be the same',
        Valid    : 'The new password must be from 6 to 32 characters'
      },
      Hint : 'Enter your new password to confirm.',
      Label: 'Confirm new password'
    },
    Postcode: {
      Errors: {
        Mandatory: 'Please fill in post code',
        Valid    : 'The post code must be valid'
      },
      Hint : 'eg. 153 54 ',
      Label: 'Post Code'
    },
    Street: {
      Errors: {
        Mandatory: 'Please fill in address street',
        Valid    : 'The address street must be from 2 to 120 characters'
      },
      Hint : 'Enter the address street.',
      Label: 'Street'
    },
    Telephone: {
      Errors: {
        Mandatory: 'Please fill in the telephone',
        Valid    : 'The telephone must be 8 to 16 numbers'
      },
      Hint : 'Enter your telephone.',
      Label: 'Telephone'
    },
    Terms: {
      Agree : 'I agree with the terms and conditions set out on this site.',
      Errors: {
        Mandatory: 'You must agree to the terms'
      },
      Note : '<span class="font-weight-bold">Note:</span> By contacting us through the above form, you agree to contact you by e-mail and / or telephone.',
      Title: 'Terms and conditions'
    }
  },
  GdprDialog: {
    Button  : 'Check',
    Subtitle: 'Enter your email to check your account.',
    Title   : 'Personal Data Management'
  },
  Home: {
    Meta: {
      Desc : 'See the catalog and order online from {locationName} at {locationArea} area, easily, quickly and with exclusive offers!',
      Img  : '',
      Title: 'Home'
    },
    Title: 'Home'
  },
  LoginDialog: {
    Facebook      : 'Login with Facebook',
    ForgotPassword: 'Forgot your password?',
    Google        : 'Login with Google',
    LoginButton   : 'Login',
    NoSubtitle    : 'Create your account quickly and easily and get access to exclusive offers!',
    NoTitle       : 'No, I do not have an account',
    RegisterButton: 'Create Account',
    Subtitle      : 'If you have an account login now, otherwise register',
    Title         : 'Do you have an account?',
    YesTitle      : 'Yes, I already have an account!'
  },
  Menu: {
    All   : 'All',
    Search: 'Product Search',
    Title : 'Catalog'
  },
  NeedHelpBlock: {
    Subtitle: 'Call us now!',
    Title   : 'Do you need help?'
  },
  OffersBlock: {
    BlockButtonTitle: 'See all the offers',
    Subtitle        : 'Choose the offer that suits you!',
    Title           : 'Offers'
  },
  Order: {
    InternalDelivery: 'Your order will be delivered approximately',
    OrderTypeTime   : {
      Day: {
        Suffix: 'd',
        Title : 'day | days'
      },
      Hour: {
        Suffix: 'h',
        Title : 'hour | hours'
      },
      Minute: {
        Suffix: 'm',
        Title : 'min | mins'
      }
    },
    QrSourceType: {
      BTH: {
        Id   : '7',
        Title: 'Booth',
        Type : 'BTH'
      },
      HRM: {
        Id   : '8',
        Title: 'Room',
        Type : 'HRM'
      },
      SNB: {
        Id   : '9',
        Title: 'Sunbed',
        Type : 'SNB'
      },
      TBL: {
        Id   : '6',
        Title: 'Table',
        Type : 'TBL'
      }
    }
  },
  Pagination: {
    NoDataText     : 'No Data Available',
    NoResultsText  : 'No Results Found',
    RowsPerPageText: 'Items Per Page'
  },
  PartnersBlock: {
    Title: 'Our partners'
  },
  Policies: {
    Title: 'Terms and Conditions'
  },
  PoliciesAllergens: {
    Meta: {
      Desc : 'Allergens catalog of the {storeName} store in {locationArea}.',
      Title: 'Allergens'
    }
  },
  PoliciesCookies: {
    Meta: {
      Desc : 'Website cookies policy of the {storeName} store in {locationArea}.',
      Title: 'Cookies Policy'
    }
  },
  PoliciesCopyright: {
    Meta: {
      Desc : 'Website protection policy of the {storeName} store in {locationArea}.',
      Title: 'Protection Policy'
    }
  },
  PoliciesPayments: {
    Meta: {
      Desc : 'Payment methods accepted on website of the {storeName} store in {locationArea}.',
      Title: 'Payment Methods'
    }
  },
  PoliciesPrivacy: {
    Meta: {
      Desc : 'Website privacy policy of the {storeName} store in {locationArea}.',
      Title: 'Privacy Policy'
    }
  },
  PoliciesReturns: {
    Meta: {
      Desc : 'Money back policy on website of the {storeName} store in {locationArea}.',
      Title: 'Money Back Policy'
    }
  },
  PoliciesTerms: {
    Meta: {
      Desc : 'Website terms of use of the {storeName} store in {locationArea}.',
      Title: 'Terms of Use'
    }
  },
  PushNotifications: {
    notifyButton: {
      dialog: {
        blocked: {
          message: 'Follow these instructions to allow notifications:',
          title  : 'Unblock Notifications'
        },
        main: {
          button: {
            subscribe  : 'SUBSCRIBE',
            unsubscribe: 'UNSUBSCRIBE'
          },
          title: 'Manage Site Notifications'
        }
      },
      message: {
        action: {
          resubscribed: 'You\'re subscribed to notifications',
          subscribed  : 'Thanks for subscribing!',
          unsubscribed: 'You won\'t receive notifications again'
        },
        prenotify: 'Click to subscribe to notifications'
      },
      tip: {
        state: {
          blocked     : 'You\'ve blocked notifications',
          subscribed  : 'You\'re subscribed to notifications',
          unsubscribed: 'Subscribe to notifications'
        }
      }
    },
    promptOptions: {
      actionMessage       : 'We would like to show you notifications with news and offers',
      acceptButton        : 'Yes',
      cancelButton        : 'No',
      emailLabel          : 'Email Address',
      smsLabel            : 'Mobile Phone Number',
      updateMessage       : 'Update your push notification subscription preferences.',
      negativeUpdateButton: 'Cancel',
      positiveUpdateButton: 'Save Preferences',
      confirmMessage      : 'Thank You!'
    },
    welcomeNotification: {
      message: 'Thanks for subscribing!'
    }
  },
  RegisterDialog: {
    Button       : 'Register',
    RememberLogin: 'Do you already have an account?',
    Subtitle     : 'By registering you get access to exclusive offers',
    Terms        : {
      Agree : 'By signing up, you agree to the terms and conditions set by this site, including the use of cookies.',
      Errors: {
        Mandatory: 'You must agree to the terms'
      },
      Title: 'Terms and Conditions'
    },
    Title: 'Register now!'
  },
  WorkingHoursBlock: {
    Closed    : 'Closed',
    Collection: 'Collection Hours',
    Delivery  : 'Delivery Hours',
    Open      : 'Open',
    Title     : 'Working Hours'
  },
  DeliveryAreasBlock: {
    Title   : 'Delivery Areas',
    Subtitle: 'Delivery Areas',
    Area    : 'Area',
    Above   : 'More than',
    Below   : 'Less than',
    Charge  : 'Fee',
    Free    : 'Free'
  },
  MenuOptionsDialog: {
    SelectedOptions: 'Your Selections'
  }
}
