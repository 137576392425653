import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import Resize from 'vuetify/lib/directives/resize';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataStoreMenu)?_c(VLayout,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"},{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],ref:"categoriesTabs",attrs:{"row":"","wrap":""}},[_c(VFlex,{ref:"categoriesListContainer",attrs:{"id":"categoriesListAffixContainer","xs12":""}},[_c(VTabs,{attrs:{"color":"gfp-adv-pages-catalog-sections-categories-tabs-background","show-arrows":"","dark":_vm.getContrastColor(_vm.$vuetify.theme['gfp-adv-pages-catalog-sections-categories-tabs-background'].base) === 'white',"slider-color":"gfp-adv-pages-catalog-sections-categories-tabs-active"}},[(_vm.dataStoreMenu.categories && !_vm.allCategories)?_c(VTab,{staticClass:"gfp-adv-pages-catalog-sections-categories-tabs-text--text",attrs:{"to":{name: 'Menu'},"exact":""}},[_vm._v(" "+_vm._s(_vm.$t('Menu.All'))+" ")]):_vm._e(),_vm._l((_vm.dataStoreMenu.categories),function(item){return [(item.Status && item.Lang[_vm.$i18n.locale])?_c(VTab,{key:item.Lang[_vm.$i18n.locale].Slug,staticClass:"gfp-adv-pages-catalog-sections-categories-tabs-text--text",attrs:{"to":{path: '/' + _vm.$route.params.lang + '/catalog/' + item.Lang[_vm.$i18n.locale].Slug}}},[_vm._v(" "+_vm._s(_vm._f("sanitizeDiacritics")(item.Lang[_vm.$i18n.locale].Name))+" ")]):_vm._e()]})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }