<template>
  <v-layout
    v-if="dataStoreMenu"
    v-resize="onResize"
    v-scroll="onScroll"
    row
    wrap
  >
    <v-flex
      id="categoriesListAffixContainer"
      ref="categoriesListContainer"
      xs12
    >
      <affix
        :enabled="affix.Enabled"
        :offset="{top: affix.Offset.Top, bottom: affix.Offset.Bottom}"
        :scroll-affix="affix.Scroll"
        relative-element-selector="#categoryDetailsList"
        class="categoriesListAffix pa-1"
        style="width: 275px;"
      >
        <div ref="categoriesList">
          <v-list
            class="mb-0 pb-0 pt-0 transparent"
            style="overflow-x: hidden; overflow-y: auto; height: calc(100vh - 72px);"
            dense
            two-line
          >
            <template v-if="dataStoreMenu.categories && !allCategories">
              <v-list-tile
                id="cat-all"
                :class="[activeCategoryId == null ? 'gfp-adv-pages-catalog-sections-categories-active' : 'gfp-adv-pages-catalog-sections-categories-background']"
                :to="{name: 'Menu'}"
                exact
                ripple
              >
                <v-list-tile-content>
                  <v-list-tile-title class="text-xs-center gfp-adv-pages-catalog-sections-categories-text--text">
                    {{ $t('Menu.All') }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider />
            </template>

            <template v-for="item in dataStoreMenu.categories">
              <template v-if="item.Status && item.Lang[$i18n.locale]">
                <v-list-tile
                  :id="`cat-${item.Lang[$i18n.locale].Slug}`"
                  :key="`category-${item.Id}`"
                  :class="[parseInt(activeCategoryId) === parseInt(item.Id) ? 'gfp-adv-pages-catalog-sections-categories-active' : 'gfp-adv-pages-catalog-sections-categories-background']"
                  exact
                  ripple
                  @click="onCategoryClick(item)"
                >
                  <v-list-tile-content>
                    <v-list-tile-title class="text-xs-center gfp-adv-pages-catalog-sections-categories-text--text">
                      {{ item.Lang[$i18n.locale].Name }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider :key="`category-divider-${item.Id}`" />
              </template>
            </template>
          </v-list>
        </div>
      </affix>
    </v-flex>
  </v-layout>
</template>

<script>
import AppData from '../../mixins/appdata'

export default {
  name      : 'CategoriesBlock',
  components: {},
  directives: {},
  mixins    : [AppData],
  props     : {
    slug         : String,
    allCategories: {
      type   : Boolean,
      default: true
    }
  },
  data () {
    return {
      affix: {
        Enabled: true,
        Scroll : false,
        Offset : {
          Top   : 72,
          Bottom: 8
        }
      },
      activeCategoryId: -1
    }
  },
  computed: {},
  watch   : {
    dataStoreMenu: function () {
      this.getCategoryDetails(this.getCategoryBySlug(this.slug))
    },
    slug (newVal) {
      this.getCategoryDetails(this.getCategoryBySlug(newVal))
    }
  },
  beforeCreate () {},
  created () {
    this.onResize()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onCategoryClick (category) {
      if (this.$route.params.slug === category.Lang[this.$i18n.locale].Slug) {
        this.$router.push({ name: 'Menu', params: {} }).finally(() => {
          this.$router.replace({ name: 'Menu', params: { slug: category.Lang[this.$i18n.locale].Slug } })
        })
      } else {
        this.$router.push({ name: 'Menu', params: { slug: category.Lang[this.$i18n.locale].Slug } })
      }
    },

    getCategoryBySlug (slug) {
      let category = null
      if (slug) category = this.dataStoreMenu?.categories.find(category => category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Slug === slug)
      return category
    },

    getCategoryDetails (item) {
      const id = item ? item.Id : item
      if (parseInt(this.activeCategoryId) === parseInt(id)) return
      this.activeCategoryId = id
      this.$bus.$emit('menu-category-selected', item)
    },

    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.onScroll()
    },

    onScroll () {
      if (this.$refs.categoriesList) {
        const categoriesListAffixContainer = document.getElementById('categoriesListAffixContainer')
        const padding = window.getComputedStyle(categoriesListAffixContainer, null).getPropertyValue('padding-left')
        this.$refs.categoriesList.style = 'width: ' + (this.$refs.categoriesListContainer.clientWidth - parseInt(padding) * 2) + 'px;'
      }
    }
  }
}
</script>

<style scoped>

</style>
