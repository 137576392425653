<template>
  <v-layout
    v-if="appConfig"
    row
    wrap
  >
    <v-flex xs12>
      <v-card color="gfp-adv-common-sidebar-delivery-areas-body-background">
        <v-toolbar
          card
          class="pl-1"
          height="46"
          prominent
          color="gfp-adv-common-sidebar-delivery-areas-toolbar-background"
        >
          <v-toolbar-title class="">
            <div class="subheading gfp-adv-common-sidebar-delivery-areas-toolbar-title--text">
              <strong>{{ $t('DeliveryAreasBlock.Title') }}</strong>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-icon color="gfp-adv-common-sidebar-delivery-areas-toolbar-icon">
            directions_bike
          </v-icon>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-2">
          <v-layout
            v-if="appConfig.LOCATION_DATA.HasDeliveryAreas"
            row
            wrap
          >
            <template v-for="(area, index) in appConfig.LOCATION_DATA.DeliveryAreas">
              <v-flex
                :key="`area-list-tile-${area.areaId}`"
                xs12
                class="area-list-row"
                :class="{'area-list-row-selected': isSelectedArea(area)}"
                style="cursor: pointer;"
                @click="onAreaClick(area)"
              >
                <div
                  :class="['py-3']"
                  style="min-height: 50px;"
                >
                  <v-avatar
                    :color="area.color"
                    :tile="area.type === 'shape'"
                    size="18"
                  />

                  <span class="ml-2 caption font-weight-bold gfp-adv-common-sidebar-delivery-areas-body-list-area-title--text">
                    {{ $t('DeliveryAreasBlock.Area') }} #{{ index + 1 }}
                  </span>

                  <div
                    v-if="showDeliveryAreasCharges"
                    class="caption right text-xs-right gfp-adv-common-sidebar-delivery-areas-body-list-area-text--text"
                  >
                    <div v-if="isDeliveryAreaFree(area)">
                      {{ $t('DeliveryAreasBlock.Free') }}
                    </div>

                    <div v-else>
                      <template v-for="(charge, index) in area.charge">
                        <div
                          :key="`area-${area.areaId}-charge-${index}`"
                          :class="[`charge-${index}`, {'mt-1': index > 0}]"
                        >
                          <template v-if="charge.condition === 'all' && showChargeConditionAll(area)">
                            {{ getAreaChargeAmount(charge, area) }}
                          </template>

                          <template v-if="charge.condition === 'below' && areaHasChargeConditionBelow(area)">
                            {{ getAreaChargeAmount(charge, area) }} {{ $t('DeliveryAreasBlock.Below').toLocaleLowerCase($i18n.locale) }} {{ charge.total | currency }}
                          </template>

                          <template v-if="charge.condition === 'above' && areaHasChargeConditionAbove(area)">
                            {{ getAreaChargeAmount(charge, area) }} {{ $t('DeliveryAreasBlock.Above').toLocaleLowerCase($i18n.locale) }} {{ charge.total | currency }}
                          </template>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </v-flex>

              <v-flex
                :key="`area-list-tile-divider-${area.areaId}`"
                xs12
              >
                <v-divider />
              </v-flex>
            </template>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import AppData from '@/mixins/appdata'

export default {
  name      : 'DeliveryAreasBlock',
  components: {},
  directives: {},
  mixins    : [AppData],
  props     : [],
  data () {
    return {
      selectedArea: null
    }
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  destroyed () {},
  methods : {
    isDeliveryAreaFree (area) {
      if (!area) return false

      if (area?.charge?.length === 1 && parseFloat(area?.charge[0]?.total) === 0 && parseFloat(area?.charge[0]?.amount) === 0) return true

      return false
    },

    isSelectedArea (area) {
      return area.areaId === this.selectedArea?.areaId
    },

    onAreaClick (area) {
      if (area?.areaId === this.selectedArea?.areaId) {
        this.selectedArea = null
      } else {
        this.selectedArea = area
      }
      this.$emit('click:area', this.selectedArea)
    },

    allOrdersDeliveryCharge (area) {
      if (!area) return 0
      const all = area?.charge?.find(charge => charge.condition === 'all')

      if (all) return parseFloat(all.amount) || 0

      return 0
    },

    getAreaChargeAmount (charge, area) {
      let amount = 0

      if (!area) amount = parseFloat(charge?.amount)
      amount = parseFloat(charge?.amount) + (charge.condition === 'all' ? 0 : this.allOrdersDeliveryCharge(area))

      if (amount > 0) return `${ this.$t('DeliveryAreasBlock.Charge') } ${ this.$options.filters.currency(amount) }`

      return this.$t('DeliveryAreasBlock.Free')
    },

    showChargeConditionAll (area) {
      if (this.areaHasChargeConditionAll(area) && this.areaHasChargeConditionAny(area)) return true
      if (this.areaHasChargeConditionAll(area) && !this.areaHasChargeConditionBoth(area)) return true

      return false
    },

    areaHasChargeConditionAll (area) {
      const charge = area?.charge?.find(charge => charge.condition === 'all')
      return charge && parseFloat(charge?.amount) > 0
    },

    areaHasChargeConditionAbove (area) {
      const charge = area?.charge?.find(charge => charge.condition === 'above')
      return !!charge
    },

    areaHasChargeConditionBelow (area) {
      const charge = area?.charge?.find(charge => charge.condition === 'below')
      return !!charge
    },

    areaHasChargeConditionBoth (area) {
      return this.areaHasChargeConditionAbove(area) && this.areaHasChargeConditionBelow(area)
    },

    areaHasChargeConditionAny (area) {
      return this.areaHasChargeConditionAbove(area) || this.areaHasChargeConditionBelow(area)
    }

  }
}
</script>

<style scoped>
.area-list-row {
  padding          : 6px;
  border-radius    : 6px;
  background-color : initial;
}

.area-list-row-selected {
  background-color : var(--v-gfp-adv-common-sidebar-delivery-areas-body-list-area-selected-base);
}

.area-list-row:hover {
  background-color : var(--v-gfp-adv-common-sidebar-delivery-areas-body-list-area-selected-base);
}

/deep/ .v-list__tile {
  padding : 0;
}
</style>
