/**
 *
 * 12345 => 12.345,00€
 *
 * @param {String || Number} amount value
 */

function currency (value = 0) {
  // window.AppConfig.APP_SETTINGS.Currency.name = 'GBP'
  const Locale = window.AppConfig.APP_SETTINGS.Currency.locale || 'el-GR'
  const Currency = window.AppConfig.APP_SETTINGS.Currency.name || 'EUR'
  value = String(value).replace(',', '')
  return new Intl.NumberFormat(Locale, { style: 'currency', currency: Currency }).format(parseFloat(value))
}

export default currency
