<template>
  <v-container
    v-if="appConfig && appConfig.PAGES"
    fluid
    pa-0
    class="gfp-adv-pages-informational-background- gfp-adv-pages-informational-text--text"
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <!-- HEADER -->
        <page-header
          :images="appConfig.LOCATION_DATA.Gallery.policies"
          :title="title"
        />
      </v-flex>

      <v-flex
        v-if="isMobile"
        xs12
      >
        <v-tabs
          color="gfp-adv-pages-informational-content-tabs-background"
          :dark="getContrastColor($vuetify.theme['gfp-adv-pages-informational-content-tabs-background'].base) === 'white'"
          show-arrows
          slider-color="gfp-adv-pages-informational-content-tabs-active"
        >
          <template v-for="(page, index) in policiesPages">
            <v-tab
              v-if="page.Status"
              :key="index"
              :to="{name: page.Name}"
              class="gfp-adv-pages-informational-content-tabs-text--text"
              exact
            >
              {{ page.Heading }}
            </v-tab>
          </template>
        </v-tabs>
      </v-flex>

      <v-flex
        :class="$vuetify.breakpoint.xsOnly ? 'pa-2' : 'pa-4'"
        lg8
        md10
        offset-lg2
        offset-md1
        offset-sm0
        sm12
      >
        <v-card
          flat
          color="gfp-adv-pages-informational-content-background"
        >
          <v-card-text>
            <v-container
              fluid
              grid-list-lg
              pa-0
            >
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-if="!isMobile"
                  xs4
                >
                  <v-list class="gfp-adv-pages-informational-content-list-background">
                    <template v-for="(page, index) in policiesPages">
                      <v-list-tile
                        v-if="page.Status"
                        :key="index"
                        :to="{name: page.Name}"
                        class="gfp-adv-pages-informational-content-list-text--text"
                        active-class="gfp-adv-pages-informational-content-list-active--text"
                        exact
                      >
                        <v-list-tile-content>
                          <v-list-tile-title>{{ page.Heading }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-list>
                </v-flex>
                <v-flex
                  sm8
                  xs12
                >
                  <router-view />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AppData              from '../../mixins/appdata'
import i18nRouteMeta        from '../../mixins/i18nRouteMeta'
import PageHeader           from '../common/PageHeader'
import i18n                 from '../../lang/lang'
import { getContrastColor } from '@/lib/color'

export default {
  name      : 'Policies',
  components: {
    PageHeader
  },
  directives: {},
  mixins    : [AppData, i18nRouteMeta],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setMetaTitle(to.name)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.setMetaTitle(to.name)
    next()
  },
  props: [],
  data () {
    return {
      title: ''
    }
  },
  computed: {
    policiesPages () {
      return this.appConfig.PAGES.Policies.Pages.hasOwnProperty(this.currentLocale) ? this.appConfig.PAGES.Policies.Pages[this.currentLocale].pages : []
    },
    currentLocale () {
      return i18n.locale || 'el'
    },
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getContrastColor,
    setMetaTitle (routeName) {
      this.title = this.$t(routeName + '.Meta.Title')
    }
  }
}
</script>

<style scoped>

</style>
