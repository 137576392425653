<template>
  <v-card
    class="transparent"
    flat
  >
    <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
      <v-icon
        class="pr-2"
        color="gfp-adv-pages-checkout-details-header-icon"
      >
        shopping_cart
      </v-icon>
      {{ $t('Account.UserOrders.Details.Title') }}
    </v-subheader>

    <v-card-text class="pa-2">
      <!-- Render Order Items -->
      <template v-if="withCategories">
        <template v-for="(category, index) in cartItems">
          <div
            v-if="category.Category && category.Category.Lang && category.Category.Lang[$i18n.locale] && withCategories"
            :key="`cart-category-${index}-${category.Id}`"
            :style="`border-left: #${category.Category.Color ? category.Category.Color : 'e1e1e1'} 3px solid; background-color: ${$vuetify.theme['gfp-adv-common-cart-items-category-background'].base};`"
          >
            <v-subheader class="subheading pl-3 pt-2 pb-2 mt-2 mb-1 gfp-adv-common-cart-items-category-text--text">
              {{ category.Category.Lang[$i18n.locale].Name }}
            </v-subheader>
          </div>

          <template v-for="(item, i) in category.Items">
            <template v-if="item.Lang && item.Lang[$i18n.locale]">
              <div :key="`cart-item-${index}-${i}-${item.RowId}`">
                <div class="gfp-adv-common-cart-items-product-background pa-2">
                  <div
                    class="body-1 py-1"
                    style="display: table;"
                  >
                    <div style="display: table-row-group; vertical-align: middle;">
                      <span
                        class="left mr-1"
                        style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                        :style="`min-width:${item.UnitId > 1 ? 48 : 28}px`"
                      >
                        <strong class="gfp-adv-common-cart-items-product-text-title-quantity--text">
                          <template v-if="item.UnitId > 1">
                            {{ formatUnitQuantity($i18n.locale, item.UnitQuantity, getMeasurementUnitById(item.UnitId)) }}
                          </template>
                          <template v-else>
                            {{ item.Quantity }} x
                          </template>
                        </strong>
                      </span>

                      <span
                        style="display: table-cell; vertical-align: middle; width: 100%;"
                        class="gfp-adv-common-cart-items-product-text-title--text"
                      >
                        {{ item.Lang[$i18n.locale].Name }}
                      </span>

                      <span
                        class="right ml-2 gfp-adv-common-cart-items-product-text-price--text"
                        style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                      >
                        {{ item.SubTotal | currency }}
                      </span>
                    </div>
                  </div>

                  <div
                    v-if="item.OptionsWithGroups && Array.isArray(item.OptionsWithGroups) && item.OptionsWithGroups.length"
                    class="gfp-adv-common-cart-items-product-text-description--text ml-1 caption"
                  >
                    <template v-for="(optionGroup, idx) in item.OptionsWithGroups">
                      <div
                        v-if="optionGroup.group[$i18n.locale]"
                        :key="idx"
                        style="display: table-row-group; vertical-align: middle;"
                      >
                        <div
                          style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                          :style="`min-width:${item.UnitId > 1 ? 48 : 28}px`"
                        />
                        <div style="display: table-cell; vertical-align: middle; white-space: nowrap; width: 100%;">
                          <div
                            v-if="optionGroup.display"
                            class="caption font-weight-bold pt-2 pb-1 grey--text text--darken-2 ml-1"
                          >
                            {{ optionGroup.group[$i18n.locale] }}
                          </div>
                          <template v-for="(option, odx) in optionGroup.options[$i18n.locale]">
                            <div
                              :key="odx"
                              style="margin-left: 6px !important;"
                            >
                              • <span v-html="option" />
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>

                  <div
                    v-if="item.Comment"
                    class="gfp-adv-common-cart-items-product-text-title--text ml-1 caption"
                  >
                    {{ $t('Form.MenuComments.Label') }}:
                    <span class="gfp-adv-common-cart-items-product-text-description--text">
                      {{ item.Comment }}
                    </span>
                  </div>
                </div>

                <v-divider
                  :key="`cart-item-divider-${i}-${item.RowId}`"
                  class="mb-1"
                />
              </div>
            </template>
          </template>
        </template>
      </template>

      <!-- Render Totals -->
      <div class="mt-1">
        <template v-if="cart.CartTotals.cart_total">
          <div class="mt-1 gfp-adv-common-cart-items-total-background">
            <div class="px-2 py-2 body-2 font-weight-medium gfp-adv-common-cart-items-total-text--text">
              <template v-if="cart.CartTotals.cart_total.lang && cart.CartTotals.cart_total.lang[$i18n.locale]">
                {{ cart.CartTotals.cart_total.lang[$i18n.locale].name }}
              </template>
              <template v-else>
                {{ cart.CartTotals.cart_total.title }}
              </template>

              <span class="right">
                {{ cart.CartTotals.cart_total.amount | currency }}
              </span>
            </div>
          </div>
        </template>

        <template v-if="cart.CartTotals.extra_charge">
          <div class="mt-1 gfp-adv-common-cart-items-total-background">
            <template v-for="(charge, idx) in cart.CartTotals.extra_charge">
              <div
                :key="`charge-${idx}`"
                class="px-2 py-2 body-2 font-weight-medium gfp-adv-common-cart-items-total-text--text"
              >
                <template v-if="cart.CartTotals.cart_total.lang && cart.CartTotals.cart_total.lang[$i18n.locale]">
                  {{ charge.lang[$i18n.locale].Name }}
                </template>
                <template v-else>
                  {{ charge.title }}
                </template>

                <span class="right">
                  {{ charge.amount | currency }}
                </span>
              </div>
            </template>
          </div>
        </template>

        <template v-if="cart.CartTotals.delivery">
          <div class="mt-1 gfp-adv-common-cart-items-total-background">
            <div class="px-2 py-2 body-2 font-weight-medium gfp-adv-common-cart-items-total-text--text">
              <template v-if="cart.CartTotals.delivery.lang && cart.CartTotals.delivery.lang[$i18n.locale]">
                {{ cart.CartTotals.delivery.lang[$i18n.locale].name }}
              </template>
              <template v-else>
                {{ cart.CartTotals.delivery.title }}
              </template>

              <span class="right">
                {{ cart.CartTotals.delivery.amount | currency }}
              </span>
            </div>
          </div>
        </template>

        <template v-if="cart.CartTotals.tip_amount">
          <div class="mt-1 gfp-adv-common-cart-items-total-background">
            <div class="px-2 py-2 body-2 font-weight-medium gfp-adv-common-cart-items-total-text--text">
              <template v-if="cart.CartTotals.tip_amount.lang && cart.CartTotals.tip_amount.lang[$i18n.locale]">
                {{ cart.CartTotals.tip_amount.lang[$i18n.locale].name }}
              </template>
              <template v-else>
                {{ cart.CartTotals.tip_amount.title }}
              </template>

              <span class="right">
                {{ cart.CartTotals.tip_amount.amount | currency }}
              </span>
            </div>
          </div>
        </template>

        <template v-if="cart.CartTotals.coupon">
          <div class="mt-1 gfp-adv-common-cart-items-total-background">
            <div class="px-2 py-2 body-2 font-weight-medium gfp-adv-common-cart-items-total-text--text">
              <template v-if="cart.CartTotals.coupon.lang && cart.CartTotals.coupon.lang[$i18n.locale]">
                {{ cart.CartTotals.coupon.lang[$i18n.locale].name }}
              </template>
              <template v-else>
                {{ cart.CartTotals.coupon.title }}
              </template>

              <span class="right">
                -{{ cart.CartTotals.coupon.amount | currency }}
              </span>
            </div>
          </div>
        </template>

        <template v-if="cart.CartTotals.order_total">
          <div class="mt-1 gfp-adv-common-cart-items-total-background">
            <div class="px-2 py-3 subheading font-weight-bold gfp-adv-common-cart-items-total-text--text">
              <template v-if="cart.CartTotals.order_total.lang && cart.CartTotals.order_total.lang[$i18n.locale]">
                {{ cart.CartTotals.order_total.lang[$i18n.locale].name }}
              </template>
              <template v-else>
                {{ cart.CartTotals.order_total.title }}
              </template>

              <span class="right">
                {{ cart.CartTotals.order_total.amount | currency }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AppData                from '../../mixins/appdata'
import CartCommon             from '../../mixins/orders/cartCommon'
import { formatUnitQuantity } from '../../lib/currency/currency'

export default {
  name      : 'CartOrderTable',
  components: {},
  directives: {},
  mixins    : [AppData, CartCommon],
  props     : {
    withCategories: {
      type   : Boolean,
      default: true
    },
    cart: {
      type    : [Object, undefined],
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : { formatUnitQuantity }
}
</script>

<style scoped>

</style>
