<template>
  <v-layout
    justify-center
    row
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          card
          height="80"
          prominent
          color="gfp-adv-common-login-toolbar-background"
          style="min-height: 80px"
        >
          <v-icon
            color="gfp-adv-common-login-toolbar-icon"
            x-large
          >
            fas fa-sign-in-alt
          </v-icon>

          <v-toolbar-title class="pl-1">
            <div class="body-3 gfp-adv-common-login-toolbar-title--text">
              {{ $t('LoginDialog.Title') }}
            </div>
            <div class="caption gfp-adv-common-login-toolbar-description--text">
              {{ $t('LoginDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            :color="getContrastColor($vuetify.theme['gfp-adv-common-login-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="gfp-adv-common-login-body-background fill-height"
          style="overflow-y: auto; min-height: 400px;"
        >
          <v-form
            ref="loginForm"
            v-model="loginFormValid"
          >
            <v-layout
              row
              wrap
            >
              <v-flex
                :class="$vuetify.breakpoint.smAndUp ? 'pr-3' : ''"
                :style="$vuetify.breakpoint.smAndUp ? 'border-right: 1px solid rgba(0, 0, 0, 0.12);' : ''"
                sm6
                xs12
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    class="text-xs-center"
                    xs12
                  >
                    <h2 class="gfp-adv-common-login-body-title--text pb-2">
                      {{ $t('LoginDialog.YesTitle') }}
                    </h2>
                  </v-flex>

                  <v-flex
                    v-if="appConfig.LOCATION_DATA.SocialFacebook && !dataStore.isMobileApp"
                    xs12
                  >
                    <v-btn
                      block
                      class="elevation-0"
                      color="blue darken-3"
                      dark
                      large
                      @click="authenticate('facebook')"
                    >
                      <v-icon left>
                        fab fa-facebook-f
                      </v-icon>
                      {{ $t('LoginDialog.Facebook') | sanitizeDiacritics }}
                    </v-btn>
                  </v-flex>
                  <v-flex
                    v-if="appConfig.LOCATION_DATA.SocialGoogle && !dataStore.isMobileApp"
                    class="mb-2"
                    xs12
                  >
                    <v-btn
                      block
                      class="elevation-0"
                      color="red darken-1"
                      dark
                      large
                      @click="authenticate('google')"
                    >
                      <v-icon left>
                        fab fa-google
                      </v-icon>
                      {{ $t('LoginDialog.Google') | sanitizeDiacritics }}
                    </v-btn>
                  </v-flex>
                  <v-flex
                    v-if="errorMessages.error || errorMessages.success"
                    xs12
                  >
                    <v-alert
                      :type="errorMessages.error ? 'error' : 'success'"
                      :value="true"
                      outline
                    >
                      {{ errorMessages.error ? errorMessages.error : errorMessages.success }}
                    </v-alert>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="email"
                      :error-messages="errorMessages.loginForm.email"
                      :hint="$t('Form.Email.Hint')"
                      :label="$t('Form.Email.Label')"
                      :rules="validationRules.loginForm.email"
                      color="gfp-adv-common-login-body-control"
                      prepend-icon="email"
                      required
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="password"
                      :error-messages="errorMessages.loginForm.password"
                      :hint="$t('Form.Password.Hint')"
                      :label="$t('Form.Password.Label')"
                      :rules="validationRules.loginForm.password"
                      color="gfp-adv-common-login-body-control"
                      prepend-icon="lock"
                      required
                      type="password"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <vue-recaptcha
                      ref="recaptcha"
                      :sitekey="appConfig.LOCATION_DATA.RecaptchaKey"
                      size="invisible"
                      @expired="onRecaptchaExpired"
                      @verify="onRecaptchaVerify"
                    />
                  </v-flex>
                  <v-flex
                    class="text-xs-center gfp-adv-common-login-body-link--text"
                    xs12
                  >
                    <a
                      class="gfp-adv-common-login-body-link--text"
                      @click="onCancelButtonClick(); $bus.$emit('show-forgot-password-dialog')"
                    >
                      {{ $t('LoginDialog.ForgotPassword') }}
                    </a>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn
                      :disabled="loginButton"
                      :loading="loginButton"
                      block
                      class="elevation-0"
                      color="gfp-adv-common-login-body-button-login-background gfp-adv-common-login-body-button-login-text--text"
                      large
                      @click="login()"
                    >
                      {{ $t('LoginDialog.LoginButton') | sanitizeDiacritics }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex
                :class="$vuetify.breakpoint.smAndUp ? 'pl-3' : 'pt-3'"
                sm6
                xs12
              >
                <v-layout
                  column
                  fill-height
                  style="height: 100%;"
                >
                  <v-flex
                    :style="$vuetify.breakpoint.smAndUp ? 'min-height: 316px;' : ''"
                    class="text-xs-center"
                    xs12
                  >
                    <h2 class="gfp-adv-common-login-body-title--text pb-2">
                      {{ $t('LoginDialog.NoTitle') }}
                    </h2>
                    <v-icon
                      class="mt-2 mb-3"
                      color="gfp-adv-common-login-body-icon"
                      size="128px"
                    >
                      fas fa-user-plus
                    </v-icon>
                    <p class="gfp-adv-common-login-body-text--text">
                      {{ $t('LoginDialog.NoSubtitle') }}
                    </p>
                  </v-flex>
                  <v-flex>
                    <v-btn
                      block
                      class="elevation-0"
                      color="gfp-adv-common-login-body-button-register-background gfp-adv-common-login-body-button-register-text--text"
                      large
                      @click="onCancelButtonClick(); $bus.$emit('show-register-dialog')"
                    >
                      {{ $t('LoginDialog.RegisterButton') | sanitizeDiacritics }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import VueRecaptcha         from 'vue-recaptcha'
import AppData              from '../../mixins/appdata'
import Recaptcha            from '../../mixins/recaptcha'
import DataEventEnum        from '@/lib/services/enum/DataEventEnum'
import DataEventService     from '@/lib/services/event/DataEventService'
import AppSettings          from '@/settings/AppSettings'
import { getContrastColor } from '@/lib/color'

export default {
  name      : 'LoginDialog',
  components: {
    'vue-recaptcha': VueRecaptcha
  },
  directives: {},
  mixins    : [AppData, Recaptcha],
  props     : ['visible'],
  data () {
    return {
      loginButton   : false,
      email         : '',
      password      : '',
      loginFormValid: false,
      errorMessages : {
        loginForm: {
          email   : [],
          password: []
        },
        error  : '',
        success: ''
      },
      validationRules: {
        loginForm: {
          email: [
            v => !!v || this.$t('Form.Email.Errors.Mandatory'),
            v => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Form.Email.Errors.Valid')
          ],
          password: [
            v => !!v || this.$t('Form.Password.Errors.Mandatory'),
            v => (v && v.length >= 6 && v.length <= 32) || this.$t('Form.Password.Errors.Valid')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        if (this.appSettings.IsDemo) {
          this.email = 'demouser@getfoodpro.gr'
          this.password = 'demouser'
        }
        this.$refs.loginForm.$el.style.display = 'block'
        this.resetRecaptcha()
      }
    }
  },
  created () {
    this.$bus.$on('social-login', this.authenticate)
  },
  mounted () {
  },
  destroyed () {
    this.$bus.$off('social-login', this.authenticate)
  },
  methods: {
    getContrastColor,
    onCancelButtonClick () {
      this.clearApiErrorMessages()
      this.$refs.loginForm.reset()
      this.isVisible = false
      this.loginButton = false
    },

    login () {
      this.loginButton = true

      if (this.$auth.isAuthenticated()) {
        this.logout()
      }

      this.$refs.loginForm.validate()
      if (!this.loginFormValid) {
        this.loginButton = false
        return
      }

      this.$bus.$emit('show-full-screen-loader')
      this.onRecaptchaSubmit()
    },

    submitForm () {
      this.clearApiErrorMessages()
      const credentials = {
        email                 : this.email,
        password              : this.password,
        'g-recaptcha-response': this.recaptchaValue
      }
      const options = {
        timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000,
        responseType   : 'json',
        crossDomain    : true,
        withCredentials: true,
        method         : 'POST',
        headers        : {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Session'       : this.$localStorage.get('x-session', AppSettings.XSession || ''),
          'X-Referer'       : process.env.VUE_APP_API_X_REFERER || '',
          'Accept-Language' : this.$i18n.locale
        }
      }

      this.$auth.login(credentials, options)
        .then((authResponse) => {
          // Execute application logic after successful login

          for (const key in authResponse.data.data.Messages) {
            if (authResponse.data.data.Messages.hasOwnProperty(key) && this.errorMessages.loginForm.hasOwnProperty(key)) {
              this.errorMessages.loginForm[key].push(authResponse.data.data.Messages[key])
            }
          }

          if (authResponse.data.data.Messages.Error) {
            this.errorMessages.error = authResponse.data.data.Messages.Error
          } else if (authResponse.data.data.Messages.Success) {
            this.$refs.loginForm.$el.style.display = 'none'
            authResponse.data.access_token = authResponse.data.data.SessionId
            this.$auth.setToken(authResponse)
            this.errorMessages.success = authResponse.data.data.Messages.Success
            // this.$auth.storage.setItem('userInfo', authResponse.data.data.CustomerInfo ? JSON.stringify(authResponse.data.data.CustomerInfo) : null)
            this.$localStorage.set('userInfo', authResponse.data.data.CustomerInfo ? JSON.stringify(authResponse.data.data.CustomerInfo) : null)
            this.$set(this.dataStore, 'userInfo', authResponse.data.data.CustomerInfo ? authResponse.data.data.CustomerInfo : null)
            this.$set(this.dataStore, 'isAuthenticated', this.$auth.isAuthenticated())
            this.$bus.$emit('user-logged-in')
            this.onCancelButtonClick()
            this.$bus.$emit('show-snackbar', {
              title: '',
              body : authResponse.data.data.Messages.Success,
              type : 'success',
              icon : ''
            })

            DataEventService.Emit(DataEventEnum.EVENT, {
              Event  : DataEventEnum.LOGIN,
              Payload: {
                Method: authResponse.data.data.CustomerInfo.Info.SocialProvider || 'Email',
                User  : authResponse.data.data.CustomerInfo
              }
            })

            if (this.$auth.isAuthenticated()) {
              if (!this.dataStore.userInfo.Info.Status) {
                this.$router.push({ name: 'AccountUserProfile' })
              }
            }
          }
        })
        .catch(e => {

        })
        .finally(() => {
          this.loginButton = false
          this.resetRecaptcha()
          this.$bus.$emit('hide-full-screen-loader')
        })
    },

    logout () {
      this.$bus.$emit('logout')
    },

    authenticate (provider) {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      this.$bus.$emit('show-full-screen-loader')
      this.onCancelButtonClick()

      if (this.$auth.isAuthenticated()) {
        this.logout()
      }

      this.$auth.authenticate(provider).then((authResponse) => {
        if (authResponse.data.IsLogged) {
          this.$localStorage.set('userInfo', authResponse.data.CustomerInfo ? JSON.stringify(authResponse.data.CustomerInfo) : null)
          this.$set(this.dataStore, 'userInfo', authResponse.data.CustomerInfo)
          this.$set(this.dataStore, 'isAuthenticated', this.$auth.isAuthenticated())
          this.$bus.$emit('user-logged-in')
          this.$bus.$emit('show-snackbar', {
            title: '',
            body : this.$t('Common.Login.Success'),
            type : 'success',
            icon : ''
          })
          DataEventService.Emit(DataEventEnum.EVENT, {
            Event  : DataEventEnum.LOGIN,
            Payload: {
              Method: provider,
              User  : authResponse.data.CustomerInfo
            }
          })
          this.onCancelButtonClick()
        } else {
          this.$auth.storage.setItem('vueauth_access_token_tmp', this.$auth.storage.getItem('vueauth_access_token'))
          this.$auth.storage.removeItem('vueauth_access_token')
          this.$localStorage.set('userInfo', authResponse.data.CustomerInfo ? { Info: JSON.stringify(authResponse.data.CustomerInfo) } : null)
          this.$set(this.dataStore, 'userInfo', { Info: authResponse.data.CustomerInfo })
          this.$set(this.dataStore, 'isAuthenticated', this.$auth.isAuthenticated())
          this.$bus.$emit('show-register-dialog')
          this.onCancelButtonClick()
        }
        this.$bus.$emit('hide-full-screen-loader')
      }).catch(() => {
        this.$bus.$emit('hide-full-screen-loader')
      })
    },

    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      this.errorMessages.error = ''
      this.errorMessages.success = ''
      var key
      for (key in this.errorMessages.loginForm) {
        if (this.errorMessages.loginForm.hasOwnProperty(key)) {
          this.errorMessages.loginForm[key] = []
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
