<template>
  <div
    v-if="isCartEnabled"
    class="flex ma-0 pa-0"
  >
    <div
      v-if="isVisible"
      class="justify-center"
    >
      <v-btn-toggle
        v-if="$vuetify.breakpoint.smAndUp && !minimized"
        v-model="dataStore.tmpOrderType"
        class="lighten-2 elevation-0"
        mandatory
        style="width: 100%;"
      >
        <v-btn
          :disabled="!hasDelivery"
          :loading="IsLoading && dataStore.tmpOrderType !== '1'"
          class="gfp-adv-common-cart-button-ordertype-delivery-background gfp-adv-common-cart-button-ordertype-collection-text--text elevation-0 mr-0"
          large
          style="width: 50%; font-size: 13px !important;"
          value="1"
        >
          <span>{{ $t('Cart.OrderType.Delivery') | sanitizeDiacritics }}
            <template v-if="hasDelivery">
              &nbsp;{{ $t('Cart.OrderType.Minutes', {min: deliveryTime}) | sanitizeDiacritics }}
            </template>
          </span>
          <v-icon class="white--text">
            directions_bike
          </v-icon>
        </v-btn>

        <v-btn
          :disabled="!hasCollection"
          :loading="IsLoading && dataStore.tmpOrderType !== '2'"
          class="gfp-adv-common-cart-button-ordertype-collection-background gfp-adv-common-cart-button-ordertype-collection-text--text elevation-0 ml-0"
          large
          style="width: 50%; font-size: 13px !important;"
          value="2"
        >
          <span>{{ $t('Cart.OrderType.Collection') | sanitizeDiacritics }}
            <template v-if="hasCollection">
              &nbsp;{{ $t('Cart.OrderType.Minutes', {min: collectionTime}) | sanitizeDiacritics }}
            </template>
          </span>
          <v-icon class="white--text">
            directions_run
          </v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle
        v-else
        v-model="dataStore.tmpOrderType"
        class="lighten-2 elevation-0"
        mandatory
        style="width:100%;"
      >
        <v-btn
          :disabled="!hasDelivery"
          :loading="IsLoading && dataStore.tmpOrderType !== '1'"
          :style="isDelivery ? 'width: calc(100% - 44px);' : 'width:44px;'"
          class="gfp-adv-common-cart-button-ordertype-delivery-background gfp-adv-common-cart-button-ordertype-collection-text--text elevation-0 mr-0"
          large
          style="font-size: 13px !important;"
          value="1"
        >
          <span v-if="isDelivery">
            {{ $t('Cart.OrderType.Delivery') | sanitizeDiacritics }}
            <template v-if="isDelivery && hasDelivery">
              &nbsp;{{ $t('Cart.OrderType.Minutes', {min: deliveryTime}) | sanitizeDiacritics }}
            </template>
          </span>
          <v-icon class="white--text">
            directions_bike
          </v-icon>
        </v-btn>

        <v-btn
          :disabled="!hasCollection"
          :loading="IsLoading && dataStore.tmpOrderType !== '2'"
          :style="isCollection ? 'width: calc(100% - 44px)' : 'width:44px;'"
          class="gfp-adv-common-cart-button-ordertype-collection-background gfp-adv-common-cart-button-ordertype-collection-text--text elevation-0 ml-0"
          large
          style="font-size: 13px !important;"
          value="2"
        >
          <span v-if="isCollection">
            {{ $t('Cart.OrderType.Collection') | sanitizeDiacritics }}
            <template v-if="isCollection && hasCollection">
              &nbsp;{{ $t('Cart.OrderType.Minutes', {min: collectionTime}) | sanitizeDiacritics }}
            </template>
          </span>
          <v-icon class="white--text">
            directions_run
          </v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>

    <div
      v-if="isCollection || (isQrOrder && (isCollection || isDelivery))"
      class="pt-2"
    >
      <v-subheader class="white--text orange darken-2 caption pa-1">
        <v-icon
          class="pr-2"
          dark
        >
          info
        </v-icon>
        <div
          class="font-weight-regular"
          style="width: 100%;"
        >
          <template v-if="isQrOrder">
            <div class="font-weight-bold">
              {{ $t(`Order.QrSourceType.${ QrData.type }.Title`) }} #{{ QrData.value }}
            </div>
          </template>
          <template v-if="isCollection">
            {{ $t('Cart.OrderType.Warning') }}&nbsp;{{ $t('Cart.OrderType.Minutes', {min: collectionTime}) }}
          </template>
          <template v-if="isDelivery && isQrOrder">
            {{ $t('Order.InternalDelivery') }}&nbsp;{{ $t('Cart.OrderType.Minutes', {min: isCollection ? collectionTime : deliveryTime}) }}
          </template>
        </div>

        <template v-if="isQrOrder && $route.name !== 'CheckoutPayment'">
          <v-btn
            v-if="isQrOnlyOrder && appSettings.HasLandingPage && appSettings.LandingPageUrl && QrData.type === $t('Order.QrSourceType.BTH.Type')"
            :href="appSettings.LandingPageUrl"
            dark
            flat
            small
            target="_self"
          >
            {{ $t('Buttons.Change') }}
          </v-btn>
        </template>
      </v-subheader>
    </div>
  </div>
</template>

<script>
import AppData       from '../../mixins/appdata'
import CartCommon    from '../../mixins/orders/cartCommon'
import OrderTypeTime from '../../mixins/orders/orderTypeTime'

export default {
  name      : 'CartOrderType',
  components: {},
  directives: {},
  mixins    : [AppData, CartCommon, OrderTypeTime],
  props     : {
    minimized: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    isVisible () {
      if (this.isQrOrder && this.QrData.type === this.$t('Order.QrSourceType.TBL.Type')) return false
      return this.showOrderTypes
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.$bus.$on('cart-order-type-loading', this.onOrderTypeLoading)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('cart-order-type-loading', this.onOrderTypeLoading)
  },
  destroyed () {},
  methods: {
    onOrderTypeLoading (status) {
      this.IsLoading = status
    }
  }
}
</script>

<style scoped>
/deep/ .v-alert__icon {
  margin-right : 8px;
}
</style>
