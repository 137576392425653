/**
 * A collection of utilities for the Greek language such as replacement of accented and other diacritics characters,
 * conversion from Greek to phonetic, transliterated or greeklish Latin and more.
 *
 */
var greekUtils = {
  /**
   * Convert a Latin/greeklish characters text to its modern Greek equivalent.
   *
   * @method toGreek
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  toGreek: function (text, ignore) {
    return replaceText(text, greeklishToGreekMap, true, ignore)
  },

  /**
   * Convert a modern Greek characters text to its greeklish equivalent.
   *
   * @method toGreeklish
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  toGreeklish: function (text, ignore) {
    return replaceText(text, greekToGreeklishMap, true, ignore)
  },

  /**
   * Convert a modern Greek characters text to its phonetically equivalent Latin (sound mapping).
   *
   * @method toPhoneticLatin
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  toPhoneticLatin: function (text, ignore) {
    return replaceText(text, greekToPhoneticLatinMap, true, ignore)
  },

  /**
   * Convert a modern Greek characters text to its transliterated equivalent Latin (letter mapping).
   *
   * @method toTransliteratedLatin
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  toTransliteratedLatin: function (text, ignore) {
    return replaceText(text, greekToTransliteratedLatinMap, true, ignore)
  },

  /**
   * Convert a modern/ancient Greek characters text containing diacritics to its simple equivalent without diacritics.
   *
   * @method sanitizeDiacritics
   * @static
   * @param {String} text
   * @param {String} ignore
   * @returns {String}
   */
  sanitizeDiacritics: function (text, ignore) {
    return replaceText(text, diacriticsMap, false, ignore)
  },

  /**
   * Slugifies a string
   *
   * @param str
   * @returns {string}
   */

  slugify: function (str) {
    str = String(str).toString()
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = greekUtils.toGreeklish(str, '')
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    const swaps = {
      0    : ['°', '₀', '۰', '０'],
      1    : ['¹', '₁', '۱', '１'],
      2    : ['²', '₂', '۲', '２'],
      3    : ['³', '₃', '۳', '３'],
      4    : ['⁴', '₄', '۴', '٤', '４'],
      5    : ['⁵', '₅', '۵', '٥', '５'],
      6    : ['⁶', '₆', '۶', '٦', '６'],
      7    : ['⁷', '₇', '۷', '７'],
      8    : ['⁸', '₈', '۸', '８'],
      9    : ['⁹', '₉', '۹', '９'],
      a    : ['à', 'á', 'ả', 'ã', 'ạ', 'ă', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ā', 'ą', 'å', 'α', 'ά', 'ἀ', 'ἁ', 'ἂ', 'ἃ', 'ἄ', 'ἅ', 'ἆ', 'ἇ', 'ᾀ', 'ᾁ', 'ᾂ', 'ᾃ', 'ᾄ', 'ᾅ', 'ᾆ', 'ᾇ', 'ὰ', 'ά', 'ᾰ', 'ᾱ', 'ᾲ', 'ᾳ', 'ᾴ', 'ᾶ', 'ᾷ', 'а', 'أ', 'အ', 'ာ', 'ါ', 'ǻ', 'ǎ', 'ª', 'ა', 'अ', 'ا', 'ａ', 'ä'],
      b    : ['б', 'β', 'ب', 'ဗ', 'ბ', 'ｂ'],
      c    : ['ç', 'ć', 'č', 'ĉ', 'ċ', 'ｃ'],
      d    : ['ď', 'ð', 'đ', 'ƌ', 'ȡ', 'ɖ', 'ɗ', 'ᵭ', 'ᶁ', 'ᶑ', 'д', 'δ', 'د', 'ض', 'ဍ', 'ဒ', 'დ', 'ｄ'],
      e    : ['é', 'è', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'ë', 'ē', 'ę', 'ě', 'ĕ', 'ė', 'ε', 'έ', 'ἐ', 'ἑ', 'ἒ', 'ἓ', 'ἔ', 'ἕ', 'ὲ', 'έ', 'е', 'ё', 'э', 'є', 'ə', 'ဧ', 'ေ', 'ဲ', 'ე', 'ए', 'إ', 'ئ', 'ｅ'],
      f    : ['ф', 'φ', 'ف', 'ƒ', 'ფ', 'ｆ'],
      g    : ['ĝ', 'ğ', 'ġ', 'ģ', 'г', 'ґ', 'γ', 'ဂ', 'გ', 'گ', 'ｇ'],
      h    : ['ĥ', 'ħ', 'η', 'ή', 'ح', 'ه', 'ဟ', 'ှ', 'ჰ', 'ｈ'],
      i    : ['í', 'ì', 'ỉ', 'ĩ', 'ị', 'î', 'ï', 'ī', 'ĭ', 'į', 'ı', 'ι', 'ί', 'ϊ', 'ΐ', 'ἰ', 'ἱ', 'ἲ', 'ἳ', 'ἴ', 'ἵ', 'ἶ', 'ἷ', 'ὶ', 'ί', 'ῐ', 'ῑ', 'ῒ', 'ΐ', 'ῖ', 'ῗ', 'і', 'ї', 'и', 'ဣ', 'ိ', 'ီ', 'ည်', 'ǐ', 'ი', 'इ', 'ی', 'ｉ'],
      j    : ['ĵ', 'ј', 'Ј', 'ჯ', 'ج', 'ｊ'],
      k    : ['ķ', 'ĸ', 'к', 'κ', 'Ķ', 'ق', 'ك', 'က', 'კ', 'ქ', 'ک', 'ｋ'],
      l    : ['ł', 'ľ', 'ĺ', 'ļ', 'ŀ', 'л', 'λ', 'ل', 'လ', 'ლ', 'ｌ'],
      m    : ['м', 'μ', 'م', 'မ', 'მ', 'ｍ'],
      n    : ['ñ', 'ń', 'ň', 'ņ', 'ŉ', 'ŋ', 'ν', 'н', 'ن', 'န', 'ნ', 'ｎ'],
      o    : ['ó', 'ò', 'ỏ', 'õ', 'ọ', 'ô', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ơ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ø', 'ō', 'ő', 'ŏ', 'ο', 'ὀ', 'ὁ', 'ὂ', 'ὃ', 'ὄ', 'ὅ', 'ὸ', 'ό', 'о', 'و', 'θ', 'ို', 'ǒ', 'ǿ', 'º', 'ო', 'ओ', 'ｏ', 'ö'],
      p    : ['п', 'π', 'ပ', 'პ', 'پ', 'ｐ'],
      q    : ['ყ', 'ｑ'],
      r    : ['ŕ', 'ř', 'ŗ', 'р', 'ρ', 'ر', 'რ', 'ｒ'],
      s    : ['ś', 'š', 'ş', 'с', 'σ', 'ș', 'ς', 'س', 'ص', 'စ', 'ſ', 'ს', 'ｓ'],
      t    : ['ť', 'ţ', 'т', 'τ', 'ț', 'ت', 'ط', 'ဋ', 'တ', 'ŧ', 'თ', 'ტ', 'ｔ'],
      u    : ['ú', 'ù', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'û', 'ū', 'ů', 'ű', 'ŭ', 'ų', 'µ', 'у', 'ဉ', 'ု', 'ူ', 'ǔ', 'ǖ', 'ǘ', 'ǚ', 'ǜ', 'უ', 'उ', 'ｕ', 'ў', 'ü'],
      v    : ['в', 'ვ', 'ϐ', 'ｖ'],
      w    : ['ŵ', 'ω', 'ώ', 'ဝ', 'ွ', 'ｗ'],
      x    : ['χ', 'ξ', 'ｘ'],
      y    : ['ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'ÿ', 'ŷ', 'й', 'ы', 'υ', 'ϋ', 'ύ', 'ΰ', 'ي', 'ယ', 'ｙ'],
      z    : ['ź', 'ž', 'ż', 'з', 'ζ', 'ز', 'ဇ', 'ზ', 'ｚ'],
      aa   : ['ع', 'आ', 'آ'],
      ae   : ['æ', 'ǽ'],
      ai   : ['ऐ'],
      ch   : ['ч', 'ჩ', 'ჭ', 'چ'],
      dj   : ['ђ', 'đ'],
      dz   : ['џ', 'ძ'],
      ei   : ['ऍ'],
      gh   : ['غ', 'ღ'],
      ii   : ['ई'],
      ij   : ['ĳ'],
      kh   : ['х', 'خ', 'ხ'],
      lj   : ['љ'],
      nj   : ['њ'],
      oe   : ['ö', 'œ', 'ؤ'],
      oi   : ['ऑ'],
      oii  : ['ऒ'],
      ps   : ['ψ'],
      sh   : ['ш', 'შ', 'ش'],
      shch : ['щ'],
      ss   : ['ß'],
      sx   : ['ŝ'],
      th   : ['þ', 'ϑ', 'ث', 'ذ', 'ظ'],
      ts   : ['ц', 'ც', 'წ'],
      ue   : ['ü'],
      uu   : ['ऊ'],
      ya   : ['я'],
      yu   : ['ю'],
      zh   : ['ж', 'ჟ', 'ژ'],
      '(c)': ['©'],
      A    : ['Á', 'À', 'Ả', 'Ã', 'Ạ', 'Ă', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Å', 'Ā', 'Ą', 'Α', 'Ά', 'Ἀ', 'Ἁ', 'Ἂ', 'Ἃ', 'Ἄ', 'Ἅ', 'Ἆ', 'Ἇ', 'ᾈ', 'ᾉ', 'ᾊ', 'ᾋ', 'ᾌ', 'ᾍ', 'ᾎ', 'ᾏ', 'Ᾰ', 'Ᾱ', 'Ὰ', 'Ά', 'ᾼ', 'А', 'Ǻ', 'Ǎ', 'Ａ', 'Ä'],
      B    : ['Б', 'Β', 'ब', 'Ｂ'],
      C    : ['Ç', 'Ć', 'Č', 'Ĉ', 'Ċ', 'Ｃ'],
      D    : ['Ď', 'Ð', 'Đ', 'Ɖ', 'Ɗ', 'Ƌ', 'ᴅ', 'ᴆ', 'Д', 'Δ', 'Ｄ'],
      E    : ['É', 'È', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Ë', 'Ē', 'Ę', 'Ě', 'Ĕ', 'Ė', 'Ε', 'Έ', 'Ἐ', 'Ἑ', 'Ἒ', 'Ἓ', 'Ἔ', 'Ἕ', 'Έ', 'Ὲ', 'Е', 'Ё', 'Э', 'Є', 'Ə', 'Ｅ'],
      F    : ['Ф', 'Φ', 'Ｆ'],
      G    : ['Ğ', 'Ġ', 'Ģ', 'Г', 'Ґ', 'Γ', 'Ｇ'],
      H    : ['Η', 'Ή', 'Ħ', 'Ｈ'],
      I    : ['Í', 'Ì', 'Ỉ', 'Ĩ', 'Ị', 'Î', 'Ï', 'Ī', 'Ĭ', 'Į', 'İ', 'Ι', 'Ί', 'Ϊ', 'Ἰ', 'Ἱ', 'Ἳ', 'Ἴ', 'Ἵ', 'Ἶ', 'Ἷ', 'Ῐ', 'Ῑ', 'Ὶ', 'Ί', 'И', 'І', 'Ї', 'Ǐ', 'ϒ', 'Ｉ'],
      J    : ['Ｊ'],
      K    : ['К', 'Κ', 'Ｋ'],
      L    : ['Ĺ', 'Ł', 'Л', 'Λ', 'Ļ', 'Ľ', 'Ŀ', 'ल', 'Ｌ'],
      M    : ['М', 'Μ', 'Ｍ'],
      N    : ['Ń', 'Ñ', 'Ň', 'Ņ', 'Ŋ', 'Н', 'Ν', 'Ｎ'],
      O    : ['Ó', 'Ò', 'Ỏ', 'Õ', 'Ọ', 'Ô', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ơ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ø', 'Ō', 'Ő', 'Ŏ', 'Ο', 'Ό', 'Ὀ', 'Ὁ', 'Ὂ', 'Ὃ', 'Ὄ', 'Ὅ', 'Ὸ', 'Ό', 'О', 'Θ', 'Ө', 'Ǒ', 'Ǿ', 'Ｏ', 'Ö'],
      P    : ['П', 'Π', 'Ｐ'],
      Q    : ['Ｑ'],
      R    : ['Ř', 'Ŕ', 'Р', 'Ρ', 'Ŗ', 'Ｒ'],
      S    : ['Ş', 'Ŝ', 'Ș', 'Š', 'Ś', 'С', 'Σ', 'Ｓ'],
      T    : ['Ť', 'Ţ', 'Ŧ', 'Ț', 'Т', 'Τ', 'Ｔ'],
      U    : ['Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Û', 'Ū', 'Ů', 'Ű', 'Ŭ', 'Ų', 'У', 'Ǔ', 'Ǖ', 'Ǘ', 'Ǚ', 'Ǜ', 'Ｕ', 'Ў', 'Ü'],
      V    : ['В', 'Ｖ'],
      W    : ['Ω', 'Ώ', 'Ŵ', 'Ｗ'],
      X    : ['Χ', 'Ξ', 'Ｘ'],
      Y    : ['Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ', 'Ÿ', 'Ῠ', 'Ῡ', 'Ὺ', 'Ύ', 'Ы', 'Й', 'Υ', 'Ϋ', 'Ŷ', 'Ｙ'],
      Z    : ['Ź', 'Ž', 'Ż', 'З', 'Ζ', 'Ｚ'],
      AE   : ['Æ', 'Ǽ'],
      Ch   : ['Ч'],
      Dj   : ['Ђ'],
      Dz   : ['Џ'],
      Gx   : ['Ĝ'],
      Hx   : ['Ĥ'],
      Ij   : ['Ĳ'],
      Jx   : ['Ĵ'],
      Kh   : ['Х'],
      Lj   : ['Љ'],
      Nj   : ['Њ'],
      Oe   : ['Œ'],
      Ps   : ['Ψ'],
      Sh   : ['Ш'],
      Shch : ['Щ'],
      Ss   : ['ẞ'],
      Th   : ['Þ'],
      Ts   : ['Ц'],
      Ya   : ['Я'],
      Yu   : ['Ю'],
      Zh   : ['Ж']
    }

    Object.keys(swaps).forEach((swap) => {
      swaps[swap].forEach(s => {
        str = str.replace(new RegExp(s, 'g'), swap)
      })
    })
    return str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes
      .replace(/^-+/, '') // trim - from start of text
      .replace(/-+$/, '')
  }
}

// Private Functions
/**
 *
 * @param {String} text
 * @param {Array} characterMap
 * @param {Boolean} exactMatch
 * @param {String} ignore
 * @returns {String}
 */
function replaceText (text, characterMap, exactMatch, ignore) {
  var regexString,
    regex

  exactMatch = exactMatch || false

  if (typeof text === 'string' && text.length > 0) {
    characterMap.forEach(function (characters) {
      regexString = exactMatch ? characters.find : '[' + characters.find + ']'
      if (ignore) {
        regexString = '(?![' + ignore + '])' + regexString
      }
      regex = new RegExp(regexString, 'g')
      text = text.replace(regex, characters.replace)
    })
  }

  return text
}

export default greekUtils

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(greekUtils)
}

var diacriticsMap = [
  { find: 'άἀἁἂἃἄἅἆἇὰάᾀᾁᾂᾃᾄᾅᾆᾇᾰᾱᾲᾳᾴᾶᾷ', replace: 'α' },
  { find: 'ΆἈἉἊἋἌἍἎἏᾈᾉᾊᾋᾌᾍᾎᾏᾸᾹᾺΆᾼ', replace: 'Α' },
  { find: 'έἐἑἒἓἔἕὲέ', replace: 'ε' },
  { find: 'ΈἘἙἚἛἜἝ', replace: 'Ε' },
  { find: 'ήἠἡἢἣἤἥἦἧῆὴῇ', replace: 'η' },
  { find: 'ΉἨἩἪἫἬἭἮἯ', replace: 'Η' },
  { find: 'ίἰἱἲἳἴἵὶῖ', replace: 'ι' },
  { find: 'ΊἶἷἸἹἺἻἼἽἾἿ', replace: 'Ι' },
  { find: 'όὀὁὂὃὄὅὸ', replace: 'ο' },
  { find: 'ΌὈὉὊὋὌὍ', replace: 'Ο' },
  { find: 'ύὐὑὒὓὔὕὖὗ', replace: 'υ' },
  { find: 'ΎὙὛὝὟ', replace: 'Υ' },
  { find: 'ώὠὡὢὣὤὥὦὧῶ', replace: 'ω' },
  { find: 'ΏὨὩὪὫὬὭὮὯ', replace: 'Ω' }
]

var greekToGreeklishMap = [
  { find: 'ΓΧ', replace: 'GX' },
  { find: 'γχ', replace: 'gx' },
  { find: 'ΤΘ', replace: 'T8' },
  { find: 'τθ', replace: 't8' },
  { find: '(θη|Θη)', replace: '8h' },
  { find: 'ΘΗ', replace: '8H' },
  { find: 'αυ', replace: 'au' },
  { find: 'Αυ', replace: 'Au' },
  { find: 'ΑΥ', replace: 'AY' },
  { find: 'ευ', replace: 'eu' },
  { find: 'εύ', replace: 'eu' },
  { find: 'εϋ', replace: 'ey' },
  { find: 'εΰ', replace: 'ey' },
  { find: 'Ευ', replace: 'Eu' },
  { find: 'Εύ', replace: 'Eu' },
  { find: 'Εϋ', replace: 'Ey' },
  { find: 'Εΰ', replace: 'Ey' },
  { find: 'ΕΥ', replace: 'EY' },
  { find: 'ου', replace: 'ou' },
  { find: 'ού', replace: 'ou' },
  { find: 'οϋ', replace: 'oy' },
  { find: 'οΰ', replace: 'oy' },
  { find: 'Ου', replace: 'Ou' },
  { find: 'Ού', replace: 'Ou' },
  { find: 'Οϋ', replace: 'Oy' },
  { find: 'Οΰ', replace: 'Oy' },
  { find: 'ΟΥ', replace: 'OY' },
  { find: 'Α', replace: 'A' },
  { find: 'α', replace: 'a' },
  { find: 'ά', replace: 'a' },
  { find: 'Ά', replace: 'A' },
  { find: 'Β', replace: 'V' },
  { find: 'β', replace: 'v' },
  { find: 'Γ', replace: 'G' },
  { find: 'γ', replace: 'g' },
  { find: 'Δ', replace: 'D' },
  { find: 'δ', replace: 'd' },
  { find: 'Ε', replace: 'E' },
  { find: 'ε', replace: 'e' },
  { find: 'έ', replace: 'e' },
  { find: 'Έ', replace: 'E' },
  { find: 'Ζ', replace: 'Z' },
  { find: 'ζ', replace: 'z' },
  { find: 'Η', replace: 'H' },
  { find: 'η', replace: 'h' },
  { find: 'ή', replace: 'h' },
  { find: 'Ή', replace: 'H' },
  { find: 'Θ', replace: 'TH' },
  { find: 'θ', replace: 'th' },
  { find: 'Ι', replace: 'I' },
  { find: 'Ϊ', replace: 'I' },
  { find: 'ι', replace: 'i' },
  { find: 'ί', replace: 'i' },
  { find: 'ΐ', replace: 'i' },
  { find: 'ϊ', replace: 'i' },
  { find: 'Ί', replace: 'I' },
  { find: 'Κ', replace: 'K' },
  { find: 'κ', replace: 'k' },
  { find: 'Λ', replace: 'L' },
  { find: 'λ', replace: 'l' },
  { find: 'Μ', replace: 'M' },
  { find: 'μ', replace: 'm' },
  { find: 'Ν', replace: 'N' },
  { find: 'ν', replace: 'n' },
  { find: 'Ξ', replace: 'KS' },
  { find: 'ξ', replace: 'ks' },
  { find: 'Ο', replace: 'O' },
  { find: 'ο', replace: 'o' },
  { find: 'Ό', replace: 'O' },
  { find: 'ό', replace: 'o' },
  { find: 'Π', replace: 'p' },
  { find: 'π', replace: 'p' },
  { find: 'Ρ', replace: 'R' },
  { find: 'ρ', replace: 'r' },
  { find: 'Σ', replace: 'S' },
  { find: 'σ', replace: 's' },
  { find: 'Τ', replace: 'T' },
  { find: 'τ', replace: 't' },
  { find: 'Υ', replace: 'Y' },
  { find: 'Ύ', replace: 'Y' },
  { find: 'Ϋ', replace: 'Y' },
  { find: 'ΰ', replace: 'y' },
  { find: 'ύ', replace: 'y' },
  { find: 'ϋ', replace: 'y' },
  { find: 'υ', replace: 'y' },
  { find: 'Φ', replace: 'F' },
  { find: 'φ', replace: 'f' },
  { find: 'Χ', replace: 'X' },
  { find: 'χ', replace: 'x' },
  { find: 'Ψ', replace: 'Ps' },
  { find: 'ψ', replace: 'ps' },
  { find: 'Ω', replace: 'w' },
  { find: 'ω', replace: 'w' },
  { find: 'Ώ', replace: 'w' },
  { find: 'ώ', replace: 'w' },
  { find: 'ς', replace: 's' },
  { find: ';', replace: '?' }
]

var greeklishToGreekMap = [
  { find: 'tha', replace: 'θα' },
  { find: 'the', replace: 'θε' },
  { find: 'thi', replace: 'θι' },
  { find: 'thh', replace: 'θη' },
  { find: 'tho', replace: 'θο' },
  { find: '(thy|thu)', replace: 'θυ' },
  { find: '(thw|thv)', replace: 'θω' },
  { find: 'tH', replace: 'τΗ' },
  { find: 'TH', replace: 'ΤΗ' },
  { find: 'Th', replace: 'Τη' },
  { find: 'th', replace: 'τη' },
  { find: '(cH|ch)', replace: 'χ' },
  { find: '(CH|Ch)', replace: 'Χ' },
  { find: '(PS|Ps)', replace: 'Ψ' },
  { find: '(ps|pS)', replace: 'ψ' },
  { find: '(Ks|KS)', replace: 'Ξ' },
  { find: '(ks|kS)', replace: 'ξ' },
  { find: '(VR)', replace: 'ΒΡ' },
  { find: '(vr|vR)', replace: 'βρ' },
  { find: '(Vr)', replace: 'Βρ' },
  { find: '8a', replace: 'θα' },
  { find: '8e', replace: 'θε' },
  { find: '8h', replace: 'θη' },
  { find: '8i', replace: 'θι' },
  { find: '8o', replace: 'θο' },
  { find: '8y', replace: 'θυ' },
  { find: '8u', replace: 'θυ' },
  { find: '(8v|8w)', replace: 'θω' },
  { find: '8A', replace: 'ΘΑ' },
  { find: '8E', replace: 'ΘΕ' },
  { find: '8H', replace: 'ΘΗ' },
  { find: '8I', replace: 'ΘΙ' },
  { find: '8O', replace: 'ΘΟ' },
  { find: '(8Y|8U)', replace: 'ΘΥ' },
  { find: '8V', replace: 'ΘΩ' },
  { find: '8W', replace: 'ΘΩ' },
  { find: '9a', replace: 'θα' },
  { find: '9e', replace: 'θε' },
  { find: '9h', replace: 'θη' },
  { find: '9i', replace: 'θι' },
  { find: '9o', replace: 'θο' },
  { find: '9y', replace: 'θυ' },
  { find: '9u', replace: 'θυ' },
  { find: '(9v|9w)', replace: 'θω' },
  { find: '9A', replace: 'ΘΑ' },
  { find: '9E', replace: 'ΘΕ' },
  { find: '9H', replace: 'ΘΗ' },
  { find: '9I', replace: 'ΘΙ' },
  { find: '9O', replace: 'ΘΟ' },
  { find: '(9Y|9U)', replace: 'ΘΥ' },
  { find: '9V', replace: 'ΘΩ' },
  { find: '9W', replace: 'ΘΩ' },
  { find: 's[\\n]', replace: 'ς\n' },
  { find: 's[\\!]', replace: 'ς!' },
  { find: 's[\\.]', replace: 'ς.' },
  { find: 's[\\ ]', replace: 'ς ' },
  { find: 's[\\,]', replace: 'ς,' },
  { find: 's[\\+]', replace: 'ς+' },
  { find: 's[\\-]', replace: 'ς-' },
  { find: 's[\\(]', replace: 'ς(' },
  { find: 's[\\)]', replace: 'ς)' },
  { find: 's[\\[]', replace: 'ς[' },
  { find: 's[\\]]', replace: 'ς]' },
  { find: 's[\\{]', replace: 'ς{' },
  { find: 's[\\}]', replace: 'ς}' },
  { find: 's[\\<]', replace: 'ς<' },
  { find: 's[\\>]', replace: 'ς>' },
  { find: 's[\\?]', replace: 'ς;' },
  { find: 's[\\/]', replace: 'ς/' },
  { find: 's[\\:]', replace: 'ς:' },
  { find: 's[\\;]', replace: 'ς;' },
  { find: 's[\\"]', replace: 'ς"' },
  { find: 's[\\\']', replace: 'ς\'' },
  { find: 's[\\f]', replace: 'ς\f' },
  { find: 's[\\r]', replace: 'ς\r' },
  { find: 's[\\t]', replace: 'ς\t' },
  { find: 's[\\v]', replace: 'ς\v' },
  { find: 'rx', replace: 'ρχ' },
  { find: 'sx', replace: 'σχ' },
  { find: 'Sx', replace: 'Σχ' },
  { find: 'SX', replace: 'ΣΧ' },
  { find: 'ux', replace: 'υχ' },
  { find: 'Ux', replace: 'Υχ' },
  { find: 'UX', replace: 'ΥΧ' },
  { find: 'yx', replace: 'υχ' },
  { find: 'Yx', replace: 'Υχ' },
  { find: 'YX', replace: 'ΥΧ' },
  { find: '3a', replace: 'ξα' },
  { find: '3e', replace: 'ξε' },
  { find: '3h', replace: 'ξη' },
  { find: '3i', replace: 'ξι' },
  { find: '3ο', replace: 'ξο' },
  { find: '3u', replace: 'ξυ' },
  { find: '3y', replace: 'ξυ' },
  { find: '3v', replace: 'ξω' },
  { find: '3w', replace: 'ξω' },
  { find: 'a3', replace: 'αξ' },
  { find: 'e3', replace: 'εξ' },
  { find: 'h3', replace: 'ηξ' },
  { find: 'i3', replace: 'ιξ' },
  { find: 'ο3', replace: 'οξ' },
  { find: 'u3', replace: 'υξ' },
  { find: 'y3', replace: 'υξ' },
  { find: 'v3', replace: 'ωξ' },
  { find: 'w3', replace: 'ωξ' },
  { find: '3A', replace: 'ξΑ' },
  { find: '3E', replace: 'ξΕ' },
  { find: '3H', replace: 'ξΗ' },
  { find: '3I', replace: 'ξΙ' },
  { find: '3O', replace: 'ξΟ' },
  { find: '3U', replace: 'ξΥ' },
  { find: '3Y', replace: 'ξΥ' },
  { find: '3V', replace: 'ξΩ' },
  { find: '3W', replace: 'ξΩ' },
  { find: 'A3', replace: 'Αξ' },
  { find: 'E3', replace: 'Εξ' },
  { find: 'H3', replace: 'Ηξ' },
  { find: 'I3', replace: 'Ιξ' },
  { find: 'O3', replace: 'Οξ' },
  { find: 'U3', replace: 'Υξ' },
  { find: 'Y3', replace: 'Υξ' },
  { find: 'V3', replace: 'Ωξ' },
  { find: 'W3', replace: 'Ωξ' },
  { find: 'A', replace: 'Α' },
  { find: 'a', replace: 'α' },
  { find: 'B', replace: 'Β' },
  { find: 'b', replace: 'β' },
  { find: 'V', replace: 'Β' },
  { find: 'v', replace: 'β' },
  { find: 'c', replace: 'ψ' },
  { find: 'C', replace: 'Ψ' },
  { find: 'G', replace: 'Γ' },
  { find: 'g', replace: 'γ' },
  { find: 'D', replace: 'Δ' },
  { find: 'd', replace: 'δ' },
  { find: 'E', replace: 'Ε' },
  { find: 'e', replace: 'ε' },
  { find: 'Z', replace: 'Ζ' },
  { find: 'z', replace: 'ζ' },
  { find: 'H', replace: 'Η' },
  { find: 'h', replace: 'η' },
  { find: 'U', replace: 'Θ' },
  { find: 'u', replace: 'υ' },
  { find: 'I', replace: 'Ι' },
  { find: 'i', replace: 'ι' },
  { find: 'j', replace: 'ξ' },
  { find: 'J', replace: 'Ξ' },
  { find: 'K', replace: 'Κ' },
  { find: 'k', replace: 'κ' },
  { find: 'L', replace: 'Λ' },
  { find: 'l', replace: 'λ' },
  { find: 'M', replace: 'Μ' },
  { find: 'm', replace: 'μ' },
  { find: 'N', replace: 'Ν' },
  { find: 'n', replace: 'ν' },
  { find: 'X', replace: 'Χ' },
  { find: 'x', replace: 'χ' },
  { find: 'O', replace: 'Ο' },
  { find: 'o', replace: 'ο' },
  { find: 'P', replace: 'Π' },
  { find: 'p', replace: 'π' },
  { find: 'R', replace: 'Ρ' },
  { find: 'r', replace: 'ρ' },
  { find: 'S', replace: 'Σ' },
  { find: 's', replace: 'σ' },
  { find: 'T', replace: 'Τ' },
  { find: 't', replace: 'τ' },
  { find: 'Y', replace: 'Υ' },
  { find: 'y', replace: 'υ' },
  { find: 'F', replace: 'Φ' },
  { find: 'f', replace: 'φ' },
  { find: 'W', replace: 'Ω' },
  { find: 'w', replace: 'ω' },
  { find: '\\?', replace: ';' }
]

var greekVowels = 'αεηιουω'
var greekConsonants = 'βγδζθκλμνξπρςστφχψ'
var greekToPhoneticLatinMap = [
  { find: 'ηυ', replace: 'if' },
  { find: '(αυ)(?=[' + greekConsonants + '])', replace: 'af' },
  { find: '(αυ)(?=[' + greekVowels + '])', replace: 'av' },
  { find: '(ευ)(?=[' + greekConsonants + '])', replace: 'ef' },
  { find: '(ευ)(?=[' + greekVowels + '])', replace: 'ev' },
  { find: 'ου', replace: 'ou' },

  { find: '(Αυ)(?=[' + greekConsonants + '])', replace: 'Af' },
  { find: '(Αυ)(?=[' + greekVowels + '])', replace: 'Av' },
  { find: '(Ευ)(?=[' + greekConsonants + '])', replace: 'Ef' },
  { find: '(Ευ)(?=[' + greekVowels + '])', replace: 'Ev' },
  { find: 'Ηυ', replace: 'If' },
  { find: 'Ου', replace: 'Ou' },

  { find: 'ηύ', replace: 'íf' },
  { find: '(αύ)(?=[' + greekConsonants + '])', replace: 'áf' },
  { find: '(αύ)(?=[' + greekVowels + '])', replace: 'áv' },
  { find: '(εύ)(?=[' + greekConsonants + '])', replace: 'éf' },
  { find: '(εύ)(?=[' + greekVowels + '])', replace: 'éf' },
  { find: 'ού', replace: 'oú' },

  { find: '(Αύ)(?=[' + greekConsonants + '])', replace: 'Áf' },
  { find: '(Αύ)(?=[' + greekVowels + '])', replace: 'Áv' },
  { find: '(Εύ)(?=[' + greekConsonants + '])', replace: 'Éf' },
  { find: '(Εύ)(?=[' + greekVowels + '])', replace: 'Év' },
  { find: 'Ηύ', replace: 'Íf' },
  { find: 'Ού', replace: 'Oú' },

  { find: 'αι', replace: 'e' },
  { find: 'ει', replace: 'i' },
  { find: 'οι', replace: 'i' },

  { find: 'αί', replace: 'é' },
  { find: 'εί', replace: 'í' },
  { find: 'οί', replace: 'í' },

  { find: 'Αι|ΑΙ', replace: 'E' },
  { find: 'Ει|ΕΙ', replace: 'I' },
  { find: 'Οι|ΟΙ', replace: 'I' },

  { find: 'Αί', replace: 'É' },
  { find: 'Εί', replace: 'Í' },
  { find: 'Οί', replace: 'Í' },

  { find: 'γε', replace: 'ye' },
  { find: 'γι', replace: 'yi' },
  { find: 'γυ', replace: 'yi' },

  { find: 'Γε', replace: 'Ye' },
  { find: 'Γι', replace: 'Yi' },
  { find: 'Γυ', replace: 'Yi' },

  { find: 'μπ', replace: 'b' },
  { find: 'ντ', replace: 'd' },
  { find: 'γκ', replace: 'g' },

  { find: 'Μπ|ΜΠ', replace: 'B' },
  { find: 'Ντ|ΝΤ', replace: 'D' },
  { find: 'Γκ|ΓΚ', replace: 'G' },

  { find: 'α', replace: 'a' },
  { find: 'β', replace: 'v' },
  { find: 'γ', replace: 'g' },
  { find: 'δ', replace: 'd' },
  { find: 'ε', replace: 'e' },
  { find: 'ζ', replace: 'z' },
  { find: 'η', replace: 'i' },
  { find: 'θ', replace: 'th' },
  { find: 'ι', replace: 'i' },
  { find: 'κ', replace: 'k' },
  { find: 'λ', replace: 'l' },
  { find: 'μ', replace: 'm' },
  { find: 'ν', replace: 'n' },
  { find: 'ξ', replace: 'x' },
  { find: 'ο', replace: 'o' },
  { find: 'π', replace: 'p' },
  { find: 'ρ', replace: 'r' },
  { find: 'ς', replace: 's' },
  { find: 'σ', replace: 's' },
  { find: 'τ', replace: 't' },
  { find: 'υ', replace: 'i' },
  { find: 'φ', replace: 'ph' },
  { find: 'χ', replace: 'kh' },
  { find: 'ψ', replace: 'ps' },
  { find: 'ω', replace: 'o' },

  { find: 'ά', replace: 'á' },
  { find: 'έ', replace: 'é' },
  { find: 'ή', replace: 'í' },
  { find: 'ί', replace: 'í' },
  { find: 'ό', replace: 'ó' },
  { find: 'ύ', replace: 'í' },
  { find: 'ώ', replace: 'ó' },
  { find: 'ΰ', replace: 'ï' },
  { find: 'ΐ', replace: 'ï' },
  { find: 'ϊ', replace: 'ï' },
  { find: 'ϋ', replace: 'ï' },

  { find: 'Α', replace: 'A' },
  { find: 'Β', replace: 'V' },
  { find: 'Γ', replace: 'G' },
  { find: 'Δ', replace: 'D' },
  { find: 'Ε', replace: 'E' },
  { find: 'Ζ', replace: 'Z' },
  { find: 'Η', replace: 'I' },
  { find: 'Θ', replace: 'Th' },
  { find: 'Ι', replace: 'I' },
  { find: 'Κ', replace: 'K' },
  { find: 'Λ', replace: 'L' },
  { find: 'Μ', replace: 'M' },
  { find: 'Ν', replace: 'N' },
  { find: 'Ξ', replace: 'X' },
  { find: 'Ο', replace: 'O' },
  { find: 'Π', replace: 'P' },
  { find: 'Ρ', replace: 'R' },
  { find: 'Σ', replace: 'S' },
  { find: 'Τ', replace: 'T' },
  { find: 'Υ', replace: 'I' },
  { find: 'Φ', replace: 'Ph' },
  { find: 'Χ', replace: 'Kh' },
  { find: 'Ψ', replace: 'Ps' },
  { find: 'Ω', replace: 'O' },

  { find: 'Ά', replace: 'Á' },
  { find: 'Έ', replace: 'É' },
  { find: 'Ή', replace: 'Í' },
  { find: 'Ί', replace: 'Í' },
  { find: 'Ό', replace: 'Ó' },
  { find: 'Ύ', replace: 'Í' },
  { find: 'Ώ', replace: 'Ó' },
  { find: 'ΰ', replace: 'Ï' },
  { find: 'ΐ', replace: 'Ï' },
  { find: 'Ϊ', replace: 'Ï' },
  { find: 'Ϋ', replace: 'Ï' }
]

var greekToTransliteratedLatinMap = [
  { find: 'α', replace: 'a' },
  { find: 'β', replace: 'v' },
  { find: 'γ', replace: 'g' },
  { find: 'δ', replace: 'd' },
  { find: 'ε', replace: 'e' },
  { find: 'ζ', replace: 'z' },
  { find: 'η', replace: 'ē' },
  { find: 'θ', replace: 'th' },
  { find: 'ι', replace: 'i' },
  { find: 'κ', replace: 'k' },
  { find: 'λ', replace: 'l' },
  { find: 'μ', replace: 'm' },
  { find: 'ν', replace: 'n' },
  { find: 'ξ', replace: 'x' },
  { find: 'ο', replace: 'o' },
  { find: 'π', replace: 'p' },
  { find: 'ρ', replace: 'r' },
  { find: 'σ', replace: 's' },
  { find: 'ς', replace: 's' },
  { find: 'τ', replace: 't' },
  { find: 'υ', replace: 'u' },
  { find: 'φ', replace: 'ph' },
  { find: 'χ', replace: 'kh' },
  { find: 'ψ', replace: 'ps' },
  { find: 'ω', replace: 'ō' },

  { find: 'ά', replace: 'á' },
  { find: 'έ', replace: 'é' },
  { find: 'ί', replace: 'í' },
  { find: 'ό', replace: 'ó' },
  { find: 'ύ', replace: 'ú' },
  { find: 'ή', replace: 'ḗ' },
  { find: 'ώ', replace: 'ṓ' },
  { find: 'ϊ', replace: 'ï' },
  { find: 'ϋ', replace: 'ü' },
  { find: 'ΰ', replace: 'ǘ' },
  { find: 'ΐ', replace: 'ḯ' },

  { find: 'Α', replace: 'A' },
  { find: 'Β', replace: 'V' },
  { find: 'Γ', replace: 'G' },
  { find: 'Δ', replace: 'D' },
  { find: 'Ε', replace: 'E' },
  { find: 'Ζ', replace: 'Z' },
  { find: 'Η', replace: 'Ē' },
  { find: 'Θ', replace: 'Th' },
  { find: 'Ι', replace: 'I' },
  { find: 'Κ', replace: 'K' },
  { find: 'Λ', replace: 'L' },
  { find: 'Μ', replace: 'M' },
  { find: 'Ν', replace: 'N' },
  { find: 'Ξ', replace: 'X' },
  { find: 'Ο', replace: 'O' },
  { find: 'Π', replace: 'P' },
  { find: 'Ρ', replace: 'R' },
  { find: 'Σ', replace: 'S' },
  { find: 'Τ', replace: 'T' },
  { find: 'Υ', replace: 'U' },
  { find: 'Φ', replace: 'Ph' },
  { find: 'Χ', replace: 'Kh' },
  { find: 'Ψ', replace: 'Ps' },
  { find: 'Ω', replace: 'Ō' },

  { find: 'Ά', replace: 'Á' },
  { find: 'Έ', replace: 'É' },
  { find: 'Ί', replace: 'Í' },
  { find: 'Ό', replace: 'Ó' },
  { find: 'Ύ', replace: 'Ú' },
  { find: 'Ή', replace: 'Ḗ' },
  { find: 'Ώ', replace: 'Ṓ' },
  { find: 'Ϊ', replace: 'Ï' },
  { find: 'Ϋ', replace: 'Ü' },

  { find: ';', replace: '?' }
]
