export default {
  // Type of users. Can be one of: Guest, Email, FB, Google
  USER_TYPE: 'user_type',

  // Most recent date the user added an item to their shopping cart or expressed interest in a purchase (usually through clicking a button). Recommended tag value as a Unix Timestamp in seconds.
  CART_UPDATE: 'cart_update',

  // The product count that the user added to their shopping cart
  CART_ITEMS: 'cart_items',

  // Most recent date the user finished a purchase or order. Set value as a Unix Timestamp in seconds.
  LAST_ORDER: 'last_order',

  // Track how much money the user spent. Recommended to use integers 100, 35. Don't use $ or currency.
  AMOUNT_SPENT: 'amount_spent',

  // User’s first name
  FIRST_NAME: 'first_name',

  // User’s last name
  // LAST_NAME: 'last_name',

  // User's locale / website selected language
  USER_LOCALE: 'user_locale',

  // User's date of birth (strongly recommended to be a Unix timestamp)
  BIRTHDAY: 'birthdate',

  // User's year of birth (example: 1998)
  // BIRTH_YEAR: 'birth_year',

  // The amount of loyalty points a user has
  LOYALTY_POINTS: 'points'
}
