<template>
  <v-layout
    justify-center
    row
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <v-card color="gfp-adv-pages-account-address-dialog-body-background">
        <v-toolbar
          card
          class="pl-1"
          height="80"
          prominent
          color="gfp-adv-pages-account-address-dialog-toolbar-background"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              my_location
            </v-icon>
          </v-avatar>

          <v-toolbar-title
            v-if="!editAddress"
            class="pl-1"
          >
            <div class="body-3 gfp-adv-pages-account-address-dialog-toolbar-title--text">
              {{ $t('Account.UserAddresses.AddEditDialog.Add.Title') }}
            </div>
            <div class="caption gfp-adv-pages-account-address-dialog-toolbar-description--text">
              {{ $t('Account.UserAddresses.AddEditDialog.Add.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-toolbar-title
            v-else
            class="pl-1"
          >
            <div class="body-3 gfp-adv-pages-account-address-dialog-toolbar-title--text">
              {{ $t('Account.UserAddresses.AddEditDialog.Edit.Title') }}
            </div>
            <div class="caption">
              {{ $t('Account.UserAddresses.AddEditDialog.Edit.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            :color="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="gfp-adv-pages-account-address-dialog-body-background gfp-adv-pages-account-address-dialog-body-text--text"
          style="overflow-y: auto;"
        >
          <v-form
            ref="addressForm"
            v-model="addressFormValid"
            @submit.prevent=""
          >
            <v-container
              fluid
              grid-list-md
              mt-0
              pt-0
            >
              <v-layout
                row
                wrap
              >
                <v-flex
                  sm6
                  xs12
                >
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex xs12>
                      <v-map-autocomplete-service
                        v-model="selectedLocation"
                        :maps-provider="interactiveMapsProvider.Name"
                        :maps-access-token="interactiveMapsProvider.Key"
                        :geocode-provider="geocodeProvider.Name"
                        :geocode-access-token="geocodeProvider.Key"
                        :map-center="mapCenter"
                        :store-marker="markerStore"
                        :address-marker="markerAddress"
                        :country="locationDeliveryCountriesStr"
                        :disabled="useCustomAddress || !isVisible"
                        :error-messages="errorMessages.addressForm.formatted_address"
                        :hint="$t('Form.AutoComplete.Hint')"
                        :placeholder="$t('Form.AutoComplete.Label')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        :types="['geocode']"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                        display-field="Name"
                        :map="isVisible && (!isCustomAddress(address) && !useCustomAddress)"
                        @place-changed="(placeObj) => setAddressFromPlace(placeObj)"
                      />
                    </v-flex>

                    <v-flex xs12>
                      <v-img
                        v-if="isCustomAddress(address) || useCustomAddress"
                        :height="acceptCustomAddresses ? 320 : 350"
                        position="center center"
                        src="/img/map/map-placeholder.png"
                        style="border: 1px solid #e2e2e2;"
                        width="100%"
                      />
                    </v-flex>

                    <v-flex
                      v-if="acceptCustomAddresses"
                      xs12
                    >
                      <v-switch
                        v-model="useCustomAddress"
                        :label="$t('Account.UserAddresses.AddEditDialog.UseCustom')"
                        class="pt-0 mt-0"
                        color="success"
                        hide-details
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex
                  sm6
                  xs12
                >
                  <v-layout
                    v-if="!useCustomAddress && !acceptLatLngAddresses"
                    row
                    wrap
                  >
                    <v-flex xs12>
                      <v-text-field
                        v-model="address.Nickname"
                        :counter="35"
                        :disabled="!address.Formatted"
                        :error-messages="errorMessages.addressForm.nickname"
                        :hint="$t('Form.FriendlyName.Hint')"
                        :label="$t('Form.FriendlyName.Label')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                        prepend-icon="label"
                      />
                    </v-flex>
                    <v-flex xs9>
                      <v-map-autocomplete-service
                        v-model="selectedCity"
                        :geocode-provider="geocodeProvider.Name"
                        :geocode-access-token="geocodeProvider.Key"
                        :country="locationDeliveryCountriesStr"
                        :disabled="!address.Formatted || !isVisible"
                        :error-messages="errorMessages.addressForm.city"
                        :hint="$t('Form.City.Hint')"
                        :placeholder="$t('Form.City.Label')"
                        :rules="validationRules.addressForm.City"
                        :types="['city']"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        display-field="Properties.City"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                        @place-changed="(placeObj) => setAddressFromPlace(placeObj)"
                      />
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="address.Postcode"
                        :disabled="!address.Formatted"
                        :error-messages="errorMessages.addressForm.postcode"
                        :hint="$t('Form.Postcode.Hint')"
                        :label="$t('Form.Postcode.Label')"
                        :rules="validationRules.addressForm.Postcode"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>

                    <v-flex xs9>
                      <v-map-autocomplete-service
                        v-model="selectedStreet"
                        :geocode-provider="geocodeProvider.Name"
                        :geocode-access-token="geocodeProvider.Key"
                        :country="locationDeliveryCountriesStr"
                        :disabled="!address.Formatted || !isVisible"
                        :error-messages="errorMessages.addressForm.address_1"
                        :hint="$t('Form.Street.Hint')"
                        :placeholder="$t('Form.Street.Label')"
                        :rules="validationRules.addressForm.Street"
                        :types="['address']"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        display-field="Properties.Street"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                        @place-changed="(placeObj) => setAddressFromPlace(placeObj)"
                      />
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="address.Number"
                        :counter="10"
                        :disabled="!address.Formatted"
                        :error-messages="errorMessages.addressForm.number"
                        :hint="$t('Form.Number.Hint')"
                        :label="$t('Form.Number.Label')"
                        :rules="validationRules.addressForm.Number"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                        @blur="getAddress"
                      />
                    </v-flex>

                    <v-flex xs5>
                      <v-text-field
                        v-model="address.Floor"
                        :disabled="!address.Formatted"
                        :error-messages="errorMessages.addressForm.floor"
                        :hint="$t('Form.Floor.Hint')"
                        :label="$t('Form.Floor.Label')"
                        class="pr-4"
                        prepend-icon="domain"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>
                    <v-flex xs7>
                      <v-text-field
                        v-model="address.DoorBell"
                        :disabled="!address.Formatted"
                        :error-messages="errorMessages.addressForm.door_bell"
                        :hint="$t('Form.DoorBell.Hint')"
                        :label="$t('Form.DoorBell.Label')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        prepend-icon="notifications"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        v-model="address.Comment"
                        :disabled="!address.Formatted"
                        :error-messages="errorMessages.addressForm.comment"
                        :hint="$t('Form.AddressComments.Hint')"
                        :label="$t('Form.AddressComments.Label')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        prepend-icon="help"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>

                    <v-flex xs12>
                      <v-checkbox
                        v-model="address.IsDefault"
                        :disabled="!address.Formatted"
                        :label="$t('Account.UserAddresses.AddEditDialog.Default')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                        hide-details
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-else
                    row
                    wrap
                  >
                    <v-flex xs12>
                      <v-text-field
                        v-model="address.Nickname"
                        :counter="35"
                        :error-messages="errorMessages.addressForm.nickname"
                        :hint="$t('Form.FriendlyName.Hint')"
                        :label="$t('Form.FriendlyName.Label')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        prepend-icon="label"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="address.City"
                        :error-messages="errorMessages.addressForm.city"
                        :hint="$t('Form.City.Hint')"
                        :label="$t('Form.City.Label')"
                        :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.City"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        prepend-icon="location_on"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        v-model="address.Street"
                        :error-messages="errorMessages.addressForm.address_1"
                        :hint="$t('Form.Street.Hint')"
                        :label="$t('Form.Street.Label')"
                        :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Street"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        prepend-icon="location_on"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="address.Number"
                        :counter="10"
                        :error-messages="errorMessages.addressForm.number"
                        :hint="$t('Form.Number.Hint')"
                        :label="$t('Form.Number.Label')"
                        :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Number"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="address.Postcode"
                        :error-messages="errorMessages.addressForm.postcode"
                        :hint="$t('Form.Postcode.Hint')"
                        :label="$t('Form.Postcode.Label')"
                        :rules="acceptLatLngAddresses ? [] : validationRules.addressForm.Postcode"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>

                    <v-flex xs5>
                      <v-text-field
                        v-model="address.Floor"
                        :error-messages="errorMessages.addressForm.floor"
                        :hint="$t('Form.Floor.Hint')"
                        :label="$t('Form.Floor.Label')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        class="pr-4"
                        prepend-icon="domain"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>
                    <v-flex xs7>
                      <v-text-field
                        v-model="address.DoorBell"
                        :error-messages="errorMessages.addressForm.door_bell"
                        :hint="$t('Form.DoorBell.Hint')"
                        :label="$t('Form.DoorBell.Label')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        prepend-icon="notifications"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                      />
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        v-model="address.Comment"
                        :error-messages="errorMessages.addressForm.comment"
                        :hint="$t('Form.AddressComments.Hint')"
                        :label="$t('Form.AddressComments.Label')"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                        prepend-icon="help"
                      />
                    </v-flex>

                    <v-flex xs12>
                      <v-checkbox
                        v-model="address.IsDefault"
                        :label="$t('Account.UserAddresses.AddEditDialog.Default')"
                        color="gfp-adv-pages-account-address-dialog-body-controls"
                        :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                        hide-details
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions
          class="pa-3 gfp-adv-pages-account-address-dialog-actions-background gfp-adv-pages-account-address-dialog-actions-text--text"
        >
          <v-spacer />

          <v-btn
            color="grey"
            outline
            @click="onCancelButtonClick"
          >
            {{ $t('Buttons.Cancel') | sanitizeDiacritics }}
          </v-btn>

          <v-btn
            :disabled="submitButton"
            :loading="submitButton"
            class="elevation-0 white--text"
            color="success"
            @click="onSaveButtonClick"
          >
            {{ $t('Buttons.Save') | sanitizeDiacritics }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

import AppData                 from '@/mixins/appdata'
import MapsCommon              from '@/mixins/maps/mapsCommon'
import VMapAutocompleteService from '@/components/common/maps/VMapAutocompleteService'
import { getContrastColor }    from '@/lib/color'
import AddressCommon           from '@/mixins/maps/addressCommon'

export default {
  name      : 'AccountAddEditAddressDialog',
  components: {
    VMapAutocompleteService
  },
  mixins: [AppData, MapsCommon, AddressCommon],
  props : ['editAddress', 'dialogVisible'],
  data () {
    return {
      submitButton     : false,
      addressFormValid : false,
      useCustomAddress : false,
      selectedAddressId: null,
      address          : this.defaultEmptyAddress(),
      errorMessages    : {
        addressForm: {
          nickname         : [],
          address_1        : [],
          number           : [],
          city             : [],
          lat              : [],
          lng              : [],
          postcode         : [],
          formatted_address: [],
          state            : [],
          floor            : [],
          door_bell        : [],
          comment          : []
        }
      },
      validationRules: {
        addressForm: {
          AutoComplete: this.useCustomAddress ? [] : [
            v => !!v || this.$t('Form.AutoComplete.Errors.Mandatory'),
            v => !!this.address.Formatted || this.$t('Form.AutoComplete.Errors.Valid')
          ],
          City: [
            v => v && v.length >= 2 && v.length <= 120 || this.$t('Form.City.Errors.Valid')
          ],
          Postcode: [
            v => v && v.length >= 2 && v.length <= 10 || this.$t('Form.Postcode.Errors.Valid')
          ],
          Street: [
            v => v && v.length >= 2 && v.length <= 120 || this.$t('Form.Street.Errors.Valid')
          ],
          Number: [
            v => v && v.length >= 1 && v.length <= 10 || this.$t('Form.Number.Errors.Valid')
          ]
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.dialogVisible
      },
      set (val) {
        this.$emit('update:dialogVisible', val)
      }
    }
  },
  watch: {
    isVisible (newVal, oldVal) {
      if (newVal) {
        this.setAddress()
      }
    },
    useCustomAddress (newVal, oldVal) {
      if (newVal) {
        this.address.Lng = this.address.Lat = this.address.PlaceId = this.address.Formatted = ''
      }
    }
  },
  mounted () {},
  destroyed () {},
  methods: {
    getContrastColor,
    onSaveButtonClick () {
      this.clearApiErrorMessages()

      this.$refs.addressForm.validate()

      if (this.addressFormValid) {
        this.submitButton = true
        const formData = JSON.parse(JSON.stringify(this.address))

        formData.CustomerId = this.dataStore.userInfo.Info.Id
        formData.Formatted = this.getAddressFormatted(this.address)
        if (this.useCustomAddress) formData.Lat = formData.Lng = 0

        window.API.post(window.APICall.accountAddress, formData)
          .then(response => {
            if (response.data.status === 200) {
              for (const key in response.data.data.Messages) {
                if (response.data.data.Messages.hasOwnProperty(key) && this.errorMessages.addressForm.hasOwnProperty(key)) {
                  if (response.data.data.Messages[key].trim()) this.errorMessages.addressForm[key].push(response.data.data.Messages[key])
                }
              }

              if (response.data.data.Messages.Success) {
                this.$localStorage.set('userInfo', response.data.data.CustomerInfo ? JSON.stringify(response.data.data.CustomerInfo) : null)
                this.$set(this.dataStore, 'userInfo', response.data.data.CustomerInfo)
                this.$emit('addressAdded')
                this.$bus.$emit('show-snackbar', {
                  title: '',
                  body : response.data.data.Messages.Success,
                  type : 'success',
                  icon : ''
                })
                this.onCancelButtonClick()
              }
            }
          })
          .catch(e => {
            if (e.response.status === 404) {
              this.onCancelButtonClick()
              this.$bus.$emit('logout', true)
              location.reload()
            }
          })
          .finally(() => {
            this.submitButton = false
          })
      } else {
        this.$bus.$emit('show-snackbar', {
          title: this.$t('Common.Error.Generic.Title'),
          body : this.$t('Common.Error.Generic.Body'),
          type : 'error',
          icon : ''
        })
      }
    },
    onCancelButtonClick () {
      this.clearApiErrorMessages()
      this.clearSelectedAddress()
      this.isVisible = false
    },
    setAddress () {
      if (this.editAddress) {
        this.clearSelectedAddress()
        this.$set(this, 'address', JSON.parse(JSON.stringify(this.editAddress)))
        this.selectedLocation = this.selectedStreet = this.selectedCity = this.addressToPlaceObject(this.editAddress)
        this.markerAddress = this.mapCenter = this.getAddressLatLng(this.editAddress)
      } else {
        this.clearSelectedAddress()
        this.initMapCenter()
      }
      this.useCustomAddress = this.isCustomAddress(this.address)
    },
    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      let key
      for (key in this.errorMessages.addressForm) {
        if (this.errorMessages.addressForm.hasOwnProperty(key)) {
          this.errorMessages.addressForm[key] = []
        }
      }
    }
  }
}
</script>
