<template>
  <v-app
    footer
    light
    class="gfp-adv-global-background gfp-adv-global-text--text"
  >
    <vue-snotify />

    <full-screen-loader
      :event-bus="$bus"
      :overlay="true"
      :show="showFullScreenLoader"
      event-hide="hide-full-screen-loader"
      event-show="show-full-screen-loader"
    />

    <v-navigation-drawer
      v-model="cart"
      app
      right
      style="padding: 0"
      temporary
      width="500"
    >
      <cart-drawer :drawer.sync="cart" />
    </v-navigation-drawer>

    <v-toolbar
      app
      color="gfp-adv-common-toolbar-background"
      class="gfp-adv-common-toolbar-text--text"
      dark
      fixed
      flat
      prominent
    >
      <div
        class="pr-1"
        style="height: 45px;"
      >
        <router-link :to="{path: `/${$i18n.locale}`}">
          <img
            v-if="getContrastLogo(LOGO_TYPE.MAIN)"
            :alt="appConfig.LOCATION_DATA.Name"
            :src="getContrastLogo(LOGO_TYPE.MAIN, $vuetify.theme['gfp-adv-common-toolbar-background'].base)"
            style="height: 100%; width: auto;"
          >
        </router-link>
      </div>
      <v-toolbar-items class="hidden-xs-only">
        <v-btn
          class="gfp-adv-common-toolbar-item-text--text"
          :to="{name: 'Menu', params: {}, query: {}}"
          flat
        >
          <span>{{ $t('Menu.Title') | sanitizeDiacritics }}</span>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-if="appConfig.LOCATION_DATA.Telephone"
          class="gfp-adv-common-toolbar-item-text--text"
          :href="'tel:' + appConfig.LOCATION_DATA.Telephone"
          flat
        >
          <div class="mr-2">
            <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
              <v-chip
                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'label'"
                label
              >
                {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value }}
              </v-chip>

              <v-img
                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'img'"
                :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value"
                height="24"
                width="24"
              />

              <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Type === 'icon'">
                <v-icon color="gfp-adv-common-toolbar-item-icon">
                  {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone.Value : 'phone' }}
                </v-icon>
              </template>
            </template>

            <template v-else>
              <v-icon color="gfp-adv-common-toolbar-item-icon">
                phone
              </v-icon>
            </template>
          </div>
          {{ appConfig.LOCATION_DATA.Telephone }}
        </v-btn>

        <v-btn
          v-if="appConfig.LOCATION_DATA.Telephone2"
          class="gfp-adv-common-toolbar-item-text--text"
          :href="'tel:' + appConfig.LOCATION_DATA.Telephone2"
          flat
        >
          <div class="mr-2">
            <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
              <v-chip
                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'label'"
                label
              >
                {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value }}
              </v-chip>

              <v-img
                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'img'"
                :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value"
                height="24"
                width="24"
              />

              <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Type === 'icon'">
                <v-icon color="gfp-adv-common-toolbar-item-icon">
                  {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone2.Value : 'phone' }}
                </v-icon>
              </template>
            </template>

            <template v-else>
              <v-icon color="gfp-adv-common-toolbar-item-icon">
                phone
              </v-icon>
            </template>
          </div>
          {{ appConfig.LOCATION_DATA.Telephone2 }}
        </v-btn>

        <v-btn
          v-if="appConfig.LOCATION_DATA.Telephone3"
          class="gfp-adv-common-toolbar-item-text--text"
          :href="'tel:' + appConfig.LOCATION_DATA.Telephone3"
          flat
        >
          <div class="mr-2">
            <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
              <v-chip
                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'label'"
                label
              >
                {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value }}
              </v-chip>

              <v-img
                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'img'"
                :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value"
                height="24"
                width="24"
              />

              <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Type === 'icon'">
                <v-icon color="gfp-adv-common-toolbar-item-icon">
                  {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone3.Value : 'phone' }}
                </v-icon>
              </template>
            </template>

            <template v-else>
              <v-icon color="gfp-adv-common-toolbar-item-icon">
                phone
              </v-icon>
            </template>
          </div>
          {{ appConfig.LOCATION_DATA.Telephone3 }}
        </v-btn>

        <v-btn
          v-if="appConfig.LOCATION_DATA.Telephone4"
          class="gfp-adv-common-toolbar-item-text--text"
          :href="'tel:' + appConfig.LOCATION_DATA.Telephone4"
          flat
        >
          <div class="mr-2">
            <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
              <v-chip
                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'label'"
                label
              >
                {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value }}
              </v-chip>

              <v-img
                v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'img'"
                :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value"
                height="24"
                width="24"
              />

              <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Type === 'icon'">
                <v-icon color="gfp-adv-common-toolbar-item-icon">
                  {{ appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Toolbar.Telephone4.Value : 'phone' }}
                </v-icon>
              </template>
            </template>

            <template v-else>
              <v-icon color="gfp-adv-common-toolbar-item-icon">
                phone
              </v-icon>
            </template>
          </div>
          {{ appConfig.LOCATION_DATA.Telephone4 }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer />

      <v-toolbar-items class="hidden-sm-and-down">
        <template v-if="!isCatalogOnly">
          <template v-if="isAuthenticated">
            <v-menu
              bottom
              left
            >
              <template #activator="{on}">
                <v-btn
                  class="gfp-adv-common-toolbar-item-text--text"
                  flat
                  v-on="on"
                >
                  {{ $t('Account.Title').toUpperCase() | sanitizeDiacritics }}
                </v-btn>
              </template>

              <v-list
                class="gfp-adv-common-toolbar-background"
                :dark="getContrastColor($vuetify.theme['gfp-adv-common-toolbar-background'].base) === 'white'"
                dense
                subheader
              >
                <v-subheader
                  class="justify-center gfp-adv-common-toolbar-text--text"
                  style="opacity: 0.85"
                >
                  {{ $t('Account.Title').toUpperCase() | sanitizeDiacritics }}
                </v-subheader>

                <v-divider :light="getContrastColor($vuetify.theme['gfp-adv-common-toolbar-background'].base) === 'black'" />

                <v-list-tile
                  :to="{name: 'AccountUserProfile'}"
                  active-class="default-class gfp-adv-common-toolbar-item-active"
                  exact
                >
                  <v-list-tile-action>
                    <v-icon
                      small
                      color="gfp-adv-common-toolbar-item-icon"
                    >
                      account_circle
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                    {{ $t('Account.Profile').toUpperCase() | sanitizeDiacritics }}
                  </v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  v-if="!isQrOnlyOrder"
                  :to="{name: 'AccountAddresses'}"
                  active-class="default-class gfp-adv-common-toolbar-item-active"
                  exact
                >
                  <v-list-tile-action>
                    <v-icon
                      small
                      color="gfp-adv-common-toolbar-item-icon"
                    >
                      location_on
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                    {{ $t('Account.Addresses').toUpperCase() | sanitizeDiacritics }}
                  </v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  :to="{name: 'AccountOrders'}"
                  active-class="default-class gfp-adv-common-toolbar-item-active"
                  exact
                >
                  <v-list-tile-action>
                    <v-icon
                      small
                      color="gfp-adv-common-toolbar-item-icon"
                    >
                      shopping_cart
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                    {{ $t('Account.Orders').toUpperCase() | sanitizeDiacritics }}
                  </v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  active-class="default-class gfp-adv-common-toolbar-item-active"
                  exact
                  @click="$bus.$emit('logout')"
                >
                  <v-list-tile-action>
                    <v-icon
                      small
                      color="gfp-adv-common-toolbar-item-icon"
                    >
                      fas fa-sign-in-alt
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                    {{ $t('Account.Logout').toUpperCase() | sanitizeDiacritics }}
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </template>

          <template v-else>
            <v-btn
              :disabled="$route.name === 'CheckoutPayment'"
              flat
              color="gfp-adv-common-toolbar-item-text"
              @click="$bus.$emit('show-login-dialog')"
            >
              {{ $t('Account.Login').toUpperCase() | sanitizeDiacritics }}
            </v-btn>

            <v-btn
              :disabled="$route.name === 'CheckoutPayment'"
              flat
              color="gfp-adv-common-toolbar-item-text"
              @click="$bus.$emit('show-register-dialog')"
            >
              {{ $t('Account.Register').toUpperCase() | sanitizeDiacritics }}
            </v-btn>
          </template>
        </template>

        <v-btn
          class="gfp-adv-common-toolbar-item-text--text"
          :to="{name: 'Contact', params: {}, query: {}}"
          flat
        >
          {{ $t('Contact.Title').toUpperCase() | sanitizeDiacritics }}
        </v-btn>
      </v-toolbar-items>

      <v-menu
        v-if="$vuetify.breakpoint.smAndDown"
        absolute
        bottom
        left
      >
        <template #activator="{on}">
          <v-toolbar-side-icon
            class="gfp-adv-common-toolbar-item-text--text"
            v-on="on"
          />
        </template>

        <v-list
          class="gfp-adv-common-toolbar-background"
          :dark="getContrastColor($vuetify.theme['gfp-adv-common-toolbar-background'].base) === 'white'"
          dense
          subheader
        >
          <v-list-tile
            :to="{name: 'Home', params: {}, query: {}}"
            active-class="default-class gfp-adv-common-toolbar-item-active"
            exact
          >
            <v-list-tile-action>
              <v-icon
                small
                color="gfp-adv-common-toolbar-item-icon"
              >
                home
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
              {{ $t('Home.Title').toUpperCase() | sanitizeDiacritics }}
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :to="{name: 'Menu', params: {}, query: {}}"
            active-class="default-class gfp-adv-common-toolbar-item-active"
            exact
          >
            <v-list-tile-action>
              <v-icon
                small
                color="gfp-adv-common-toolbar-item-icon"
              >
                fas fa-th-list
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
              {{ $t('Menu.Title').toUpperCase() | sanitizeDiacritics }}
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :to="{name: 'Contact', params: {}, query: {}}"
            active-class="default-class gfp-adv-common-toolbar-item-active"
            exact
          >
            <v-list-tile-action>
              <v-icon
                small
                color="gfp-adv-common-toolbar-item-icon"
              >
                fas fa-envelope
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
              {{ $t('Contact.Title').toUpperCase() | sanitizeDiacritics }}
            </v-list-tile-title>
          </v-list-tile>

          <template v-if="!isCatalogOnly">
            <v-divider :light="getContrastColor($vuetify.theme['gfp-adv-common-toolbar-background'].base) === 'black'" />

            <v-subheader class="justify-center gfp-adv-common-toolbar-item-text--text">
              {{ $t('Account.Title').toUpperCase() | sanitizeDiacritics }}
            </v-subheader>

            <v-divider :light="getContrastColor($vuetify.theme['gfp-adv-common-toolbar-background'].base) === 'black'" />

            <template v-if="!isAuthenticated">
              <v-list-tile
                :disabled="$route.name === 'CheckoutPayment'"
                active-class="default-class gfp-adv-common-toolbar-item-active"
                exact
                @click="$bus.$emit('show-login-dialog')"
              >
                <v-list-tile-action>
                  <v-icon
                    small
                    color="gfp-adv-common-toolbar-item-icon"
                  >
                    fas fa-sign-in-alt
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                  {{ $t('Account.Login').toUpperCase() | sanitizeDiacritics }}
                </v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :disabled="$route.name === 'CheckoutPayment'"
                active-class="default-class gfp-adv-common-toolbar-item-active"
                exact
                @click="$bus.$emit('show-register-dialog')"
              >
                <v-list-tile-action>
                  <v-icon
                    small
                    color="gfp-adv-common-toolbar-item-icon"
                  >
                    fas fa-user-plus
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                  {{ $t('Account.Register').toUpperCase() | sanitizeDiacritics }}
                </v-list-tile-title>
              </v-list-tile>
            </template>
            <template v-else>
              <v-list-tile
                :to="{name: 'AccountUserProfile'}"
                active-class="default-class gfp-adv-common-toolbar-item-active"
                exact
              >
                <v-list-tile-action>
                  <v-icon
                    small
                    color="gfp-adv-common-toolbar-item-icon"
                  >
                    account_circle
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                  {{ $t('Account.Profile').toUpperCase() | sanitizeDiacritics }}
                </v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                :to="{name: 'AccountAddresses'}"
                active-class="default-class gfp-adv-common-toolbar-item-active"
                exact
              >
                <v-list-tile-action>
                  <v-icon
                    small
                    color="gfp-adv-common-toolbar-item-icon"
                  >
                    location_on
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                  {{ $t('Account.Addresses').toUpperCase() | sanitizeDiacritics }}
                </v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                :to="{name: 'AccountOrders'}"
                active-class="default-class gfp-adv-common-toolbar-item-active"
                exact
              >
                <v-list-tile-action>
                  <v-icon
                    small
                    color="gfp-adv-common-toolbar-item-icon"
                  >
                    shopping_cart
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                  {{ $t('Account.Orders').toUpperCase() | sanitizeDiacritics }}
                </v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                active-class="default-class gfp-adv-common-toolbar-item-active"
                exact
                @click="$bus.$emit('logout')"
              >
                <v-list-tile-action>
                  <v-icon
                    small
                    color="gfp-adv-common-toolbar-item-icon"
                  >
                    fas fa-sign-in-alt
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="gfp-adv-common-toolbar-item-text--text">
                  {{ $t('Account.Logout').toUpperCase() | sanitizeDiacritics }}
                </v-list-tile-title>
              </v-list-tile>
            </template>
          </template>
        </v-list>
      </v-menu>

      <v-btn
        v-if="!isCatalogOnly"
        icon
        @click.stop="cart = !cart"
      >
        <v-badge
          :value="totalCartItemsCount > 0"
          color="gfp-adv-common-cart-toolbar-icon-badge-background"
          overlap
          right
        >
          <span
            v-if="totalCartItemsCount > 0"
            slot="badge"
            class="gfp-adv-common-cart-toolbar-icon-badge-text--text"
          >
            {{ totalCartItemsCount }}
          </span>
          <v-icon color="gfp-adv-common-toolbar-item-icon">
            shopping_cart
          </v-icon>
        </v-badge>
      </v-btn>

      <lang-switch />
    </v-toolbar>

    <v-content>
      <template v-if="dataStore.cart">
        <v-alert
          :dismissible="false"
          :value="dataStore.cart.IsClosed && !isCatalogOnly"
          class="caption ma-0 pa-2 white text-xs-center"
          outline
          style="width: 100%;"
          type="error"
        >
          <span
            style="width:100%;"
            v-html="$t('Common.Error.StoreClosed')"
          />
        </v-alert>
      </template>
      <auth />
      <router-view />
    </v-content>

    <v-footer
      id="appFooter"
      height="auto"
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          v-if="appSettings.Mobile && appSettings.Mobile.Active && !dataStore.isMobileApp"
          class="pa-4 gfp-adv-common-footer-mobile-background"
          xs12
        >
          <v-card class="elevation-1 gfp-adv-common-footer-mobile-text-background">
            <v-card-text class="pa-4">
              <v-layout
                row
                wrap
              >
                <v-flex
                  md6
                  xs12
                >
                  <div class="d-flex align-center">
                    <div>
                      <h1 class="gfp-adv-common-footer-mobile-text-title--text">
                        {{ $t('Footer.MobileApp.Title') }}
                      </h1>
                      <h3 class="gfp-adv-common-footer-mobile-text-description--text mt-2 font-weight-regular">
                        {{ $t('Footer.MobileApp.SubTitle') }}
                      </h3>
                    </div>
                  </div>
                </v-flex>

                <v-flex
                  v-if="(appSettings.Mobile.Android && appSettings.Mobile.Android.Active && appSettings.Mobile.Android.StoreUrl) || (appSettings.Mobile.Ios && appSettings.Mobile.Ios.Active && appSettings.Mobile.Ios.StoreUrl)"
                  :class="[$vuetify.breakpoint.smAndDown ? 'justify-start' : 'justify-end']"
                  class="d-flex fill-height align-center"
                  md6
                  xs12
                >
                  <div
                    :class="[$vuetify.breakpoint.smAndDown ? 'text-xs-left mt-4' : 'text-xs-right mt-2', $vuetify.breakpoint.xsOnly ? 'd-flex' : '']"
                    style="display: inline-block;"
                  >
                    <a
                      v-if="appSettings.Mobile.Android && appSettings.Mobile.Android.Active && appSettings.Mobile.Android.StoreUrl"
                      :href="appSettings.Mobile.Android.StoreUrl"
                      :title="$t('Footer.MobileApp.Android.Title')"
                      target="_blank"
                    >
                      <img
                        :alt="$t('Footer.MobileApp.Android.Title')"
                        :class="[$vuetify.breakpoint.xsOnly ? 'mr-2' : 'mr-3 mt-3']"
                        :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appSettings.Mobile.Android.LogoUrl"
                        height="auto"
                        style="max-width: 130px"
                        width="100%"
                      >
                    </a>

                    <a
                      v-if="appSettings.Mobile.Ios && appSettings.Mobile.Ios.Active && appSettings.Mobile.Ios.StoreUrl"
                      :href="appSettings.Mobile.Ios.StoreUrl"
                      :title="$t('Footer.MobileApp.IOS.Title')"
                      target="_blank"
                    >
                      <img
                        :alt="$t('Footer.MobileApp.IOS.Title')"
                        :class="[$vuetify.breakpoint.xsOnly ? 'mx-1' : 'mx-3 mt-3']"
                        :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appSettings.Mobile.Ios.LogoUrl"
                        height="auto"
                        style="max-width: 130px"
                        width="100%"
                      >
                    </a>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12>
          <v-card flat>
            <v-card-title
              v-if="appConfig.LOCATION_DATA.Social.length > 0"
              class="gfp-adv-common-footer-social-background gfp-adv-common-footer-social-text--text justify-center pt-0 pb-0"
            >
              <strong class="subheading hidden-xs-only">
                {{ $t('Footer.SocialTitle') }}
              </strong>

              <v-spacer class="hidden-xs-only" />

              <v-btn
                v-for="social in appConfig.LOCATION_DATA.Social"
                :key="social.name.toString()"
                :href="social.url"
                class="mx-3"
                dark
                icon
                rel="noopener"
                target="_blank"
              >
                <v-icon
                  size="24px"
                  color="gfp-adv-common-footer-social-button"
                >
                  {{ social.icon }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="gfp-footer gfp-footer-text--text red">
              <v-layout
                class="text-xs-center text-sm-left"
                justify-space-around
                row
                wrap
              >
                <div
                  class="pa-2"
                  style="min-width: 220px;"
                >
                  <span
                    class="body-2"
                    style="white-space: nowrap; text-transform: uppercase;"
                  >
                    {{ appConfig.LOCATION_DATA.Name | sanitizeDiacritics }}
                  </span>

                  <router-link
                    v-if="aboutStatus"
                    :to="{name: 'About'}"
                  >
                    <div
                      v-t="'Footer.Store.About'"
                      style="white-space: nowrap;"
                    />
                  </router-link>
                  <router-link :to="{name: 'Contact'}">
                    <div
                      v-t="'Footer.Store.Location'"
                      style="white-space: nowrap;"
                    />
                  </router-link>
                  <router-link :to="{name: 'Contact'}">
                    <div
                      v-t="'Footer.Store.OpenHours'"
                      style="white-space: nowrap;"
                    />
                  </router-link>
                  <router-link
                    v-if="showDeliveryAreas"
                    :to="{name: 'Contact'}"
                  >
                    <div
                      v-t="'Footer.Store.DeliveryAreas'"
                      style="white-space: nowrap;"
                    />
                  </router-link>
                  <router-link :to="{name: 'Contact'}">
                    <div
                      v-t="'Contact.Title'"
                      style="white-space: nowrap;"
                    />
                  </router-link>

                  <div
                    v-if="storeGemi"
                    class="caption gfp-adv-common-footer-links-text--text mt-3"
                    style="white-space: nowrap;"
                  >
                    <strong>{{ $t('Footer.Gemi.Desc') }}</strong><br> {{ storeGemi }}
                  </div>
                </div>

                <div
                  v-if="hasMultipleLocations"
                  class="pa-2"
                  style="min-width: 220px;"
                >
                  <span
                    class="body-2"
                    style="white-space: nowrap; text-transform: uppercase;"
                  >
                    {{ $t('Footer.Stores') | sanitizeDiacritics }}
                  </span>

                  <template v-for="(location, index) in multipleLocations">
                    <a
                      :key="`location-${index}`"
                      :href="`//${location.SiteUrl}/`"
                      @click.prevent="goToStore(location.SiteUrl)"
                    >
                      <div style="white-space: nowrap;">
                        {{ location.Name }} - {{ location.City }}
                      </div>
                    </a>
                  </template>
                </div>

                <div
                  v-if="!isCatalogOnly"
                  class="pa-2"
                  style="min-width: 220px;"
                >
                  <span
                    class="body-2"
                    style="white-space: nowrap; text-transform: uppercase;"
                  >
                    {{ $t('Account.Title') | sanitizeDiacritics }}
                  </span>
                  <a @click.stop="cart = !cart">
                    <div
                      v-t="'Cart.Title'"
                      style="white-space: nowrap;"
                    />
                  </a>
                  <template v-if="!isAuthenticated">
                    <a
                      v-if="$route.name !== 'CheckoutPayment'"
                      @click="$bus.$emit('show-login-dialog')"
                    >
                      <div
                        v-t="'Account.Login'"
                        style="white-space: nowrap;"
                      />
                    </a>
                    <a
                      v-if="$route.name !== 'CheckoutPayment'"
                      @click="$bus.$emit('show-register-dialog')"
                    >
                      <div
                        v-t="'Account.Register'"
                        style="white-space: nowrap;"
                      />
                    </a>
                    <a @click="$bus.$emit('show-gdpr-dialog')">
                      <div
                        v-t="'Account.Gdpr'"
                        style="white-space: nowrap;"
                      />
                    </a>
                  </template>
                  <template v-else>
                    <router-link :to="{name: 'AccountUserProfile'}">
                      <div
                        v-t="'Account.Profile'"
                        style="white-space: nowrap;"
                      />
                    </router-link>
                    <router-link
                      v-if="!isQrOnlyOrder"
                      :to="{name: 'AccountAddresses'}"
                    >
                      <div
                        v-t="'Account.Addresses'"
                        style="white-space: nowrap;"
                      />
                    </router-link>
                    <router-link :to="{name: 'AccountOrders'}">
                      <div
                        v-t="'Account.Orders'"
                        style="white-space: nowrap;"
                      />
                    </router-link>
                    <a @click="$bus.$emit('logout')">
                      <div
                        v-t="'Account.Logout'"
                        style="white-space: nowrap;"
                      />
                    </a>
                  </template>
                </div>

                <div
                  v-if="policiesStatus"
                  class="pa-2"
                  style="min-width: 220px;"
                >
                  <span
                    class="body-2"
                    style="text-transform: uppercase;"
                  >
                    {{ $t('Policies.Title') | sanitizeDiacritics }}
                  </span>
                  <template v-for="(page, index) in policiesPages">
                    <router-link
                      v-if="isPageActive(page)"
                      :key="index"
                      :to="{name: page.Name}"
                    >
                      <div
                        style="white-space: nowrap;"
                        v-text="page.Heading"
                      />
                    </router-link>
                  </template>
                </div>
              </v-layout>
            </v-card-text>

            <v-card-actions class="gfp-footer-copyright gfp-footer-copyright-text--text justify-center">
              <div>
                © {{ new Date().getFullYear() }} <strong><a
                  href="https://www.otodev.gr/"
                  target="_blank"
                >OTO Dev</a></strong> - Powered By <strong><a
                  href="https://getfoodpro.gr/"
                  target="_blank"
                >GETFOOD Pro</a></strong>
              </div>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-footer>

    <cookie-law
      v-if="!dataStore.isMobileApp"
      :button-text="$t('CookieLaw.AcceptButton')"
      theme="gfp-theme"
    >
      <div
        slot="message"
        class="copyrightLinks"
      >
        {{ $t('CookieLaw.Message') }}
        <router-link :to="{name: 'PoliciesCookies'}">
          {{ $t('PoliciesCookies.Meta.Title') }}
        </router-link>
        .
      </div>
    </cookie-law>
  </v-app>
</template>

<script>
import AppData              from '../mixins/appdata'
import CartCommon           from '../mixins/orders/cartCommon'
import Auth                 from './auth/Auth'
import CartDrawer           from './cart/CartDrawer'
import FullScreenLoader     from './common/FullScreenLoader'
import LangSwitch           from '../lang/LangSwitch'
import CookieLaw            from 'vue-cookie-law'
import 'swiper/dist/css/swiper.css'
import i18n                 from '../lang/lang'
import { getContrastColor } from '@/lib/color'

export default {
  name      : 'App',
  components: {
    auth                : Auth,
    'cookie-law'        : CookieLaw,
    'cart-drawer'       : CartDrawer,
    'full-screen-loader': FullScreenLoader,
    'lang-switch'       : LangSwitch
  },
  directives: {},
  mixins    : [AppData, CartCommon],
  props     : [],
  data () {
    return {
      cart                : false,
      showFullScreenLoader: false
    }
  },
  computed: {
    storeGemi () {
      return this.appSettings?.Store?.Gemi || ''
    },
    version () {
      return 'v' + process.env.VUE_APP_VERSION
    },
    totalCartItemsCount () {
      return this.dataStore.cart && this.dataStore.cart.CartTotalQuantity || 0
    },
    isAuthenticated () {
      return this.dataStore.isAuthenticated
    },
    aboutStatus () {
      return this.appConfig.PAGES.About.Pages.hasOwnProperty(this.currentLocale) ? this.appConfig.PAGES.About.Pages[this.currentLocale].status : false
    },
    policiesStatus () {
      return this.appConfig.PAGES.Policies.Pages.hasOwnProperty(this.currentLocale) ? this.appConfig.PAGES.Policies.Pages[this.currentLocale].status : false
    },
    policiesPages () {
      return this.appConfig.PAGES.Policies.Pages.hasOwnProperty(this.currentLocale) ? this.appConfig.PAGES.Policies.Pages[this.currentLocale].pages : []
    },
    currentLocale () {
      return i18n.locale || 'el'
    }
  },
  watch: {},
  created () {
    this.$bus.$on('show-snackbar', this.onShowSnackbar)
    this.$bus.$on('show-demo-warning', this.onDemoWarning)
  },
  mounted () {},
  destroyed () {
    this.$bus.$off('show-snackbar', this.onShowSnackbar)
    this.$bus.$off('show-demo-warning', this.onDemoWarning)
  },
  methods: {
    getContrastColor,
    isPageActive (page) {
      const excludeCatalogOnlyPages = ['PoliciesPayments', 'PoliciesReturns']
      return this.isCatalogOnly ? !excludeCatalogOnlyPages.includes(page.Name) && page.Status : page.Status
    },

    onDemoWarning () {
      if (!this.appSettings.IsDemo) return
      this.$bus.$emit('show-snackbar', {
        title  : this.$t('Demo.Warning.Title'),
        body   : this.$t('Demo.Warning.Body'),
        type   : 'warning',
        icon   : '',
        timeout: 5000
      })
    },
    onShowSnackbar (data) {
      this.$snotify[data.type](data.body, data.title, {
        timeout        : data.hasOwnProperty('timeout') ? data.timeout : 3000,
        showProgressBar: data.hasOwnProperty('showProgressBar') ? data.showProgressBar : true,
        closeOnClick   : data.hasOwnProperty('closeOnClick') ? data.closeOnClick : true,
        pauseOnHover   : data.hasOwnProperty('pauseOnHover') ? data.pauseOnHover : true,
        position       : data.position ? data.position : 'centerTop',
        buttons        : data.buttons ? data.buttons : [],
        icon           : data.icon ? data.icon : null
      })
    }
  }
}
</script>

<style>
html {
  overflow-y : auto !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border                  : initial;
  -webkit-text-fill-color : initial;
  -webkit-box-shadow      : 0 0 0px 100px rgb(238, 238, 238) inset;
}

span.old-price {
  opacity      : 0.6;
  position     : relative;
  display      : inline-block;
  margin-right : 0px;
}

span.old-price:before {
  content    : '';
  position   : absolute;
  left       : 0;
  top        : 50%;
  right      : 0;
  border-top : 2px solid red;
  transform  : rotateZ(-15deg);
}

::-webkit-scrollbar {
  width  : 8px;
  height : 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow    : inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-border-radius : 1px;
  border-radius         : 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius : 1px;
  border-radius         : 1px;
  background            : rgb(220, 220, 220);
  -webkit-box-shadow    : inset 0 0 1px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:window-inactive {
  background : rgb(220, 220, 220);
}

::-webkit-scrollbar-track {
  /*margin : 20px 20px;*/
}

.Cookie--gfp-theme {
  background : var(--v-gfp-adv-common-cookies-background-base);
  color      : var(--v-gfp-adv-common-cookies-text-base);
  padding    : 1.250em
}

.Cookie--gfp-theme a {
  text-decoration : none;
  color           : var(--v-gfp-adv-common-cookies-link-base);
}

.Cookie--gfp-theme a:hover {
  text-decoration : underline;
}

.Cookie--gfp-theme > div.Cookie__buttons > button.Cookie__button {
  background    : var(--v-gfp-adv-common-cookies-button-background-base);
  padding       : 0.625em 3.125em;
  color         : var(--v-gfp-adv-common-cookies-button-text-base);
  border-radius : 0;
  border        : 0;
  font-size     : 1em;
  font-weight   : bold;
}

.Cookie--gfp-theme > div.Cookie__buttons > button.Cookie__button:hover {
  background : var(--v-gfp-adv-common-cookies-button-hover-base);
}
</style>

<style scoped>
/deep/ .gfp-footer-copyright {
  background-color : var(--v-gfp-adv-common-footer-copyright-background-base) !important;
  color            : var(--v-gfp-adv-common-footer-copyright-text-base) !important;
}

/deep/ .gfp-footer-copyright a {
  text-decoration : none;
  color           : var(--v-gfp-adv-common-footer-copyright-text-base) !important;
}

/deep/ .gfp-footer-copyright a:hover {
  text-decoration : underline;
}

/deep/ .gfp-footer {
  background-color : var(--v-gfp-adv-common-footer-links-background-base) !important;
  border-color     : var(--v-gfp-adv-common-footer-links-background-base) !important;
  color            : var(--v-gfp-adv-common-footer-links-text-base) !important;
}

/deep/ .gfp-footer a {
  text-decoration : none;
  color           : var(--v-gfp-adv-common-footer-links-text-base) !important;
}

/deep/ .gfp-footer a:hover {
  text-decoration : underline;
}
</style>
