<template>
  <v-container
    v-if="!isQrOnlyOrder"
    fluid
    grid-list-lg
    pa-0
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        :class="$vuetify.breakpoint.smAndUp ? 'pr-0' : ''"
        class="text-xs-right"
        pb-1
        pt-2
        xs12
      >
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          color="gfp-adv-pages-account-address-button-add"
          class="gfp-adv-pages-account-address-button-add-text--text"
          large
          @click="addAddress"
        >
          <v-icon
            color="gfp-adv-pages-account-address-button-add-text"
            left
          >
            add
          </v-icon>
          {{ $t('Buttons.AddAddress') | sanitizeDiacritics }}
        </v-btn>
      </v-flex>

      <v-flex
        v-if="customer.Addresses.length <= 0"
        xs12
      >
        <div class="text-xs-center grey--text">
          <v-icon
            class="mb-3 grey--text text--lighten-2"
            size="96"
          >
            location_off
          </v-icon>
          <div class="title mb-2">
            {{ $t('Account.UserAddresses.NoDataText') }}
          </div>
          <div class="subheading">
            {{ $t('Account.UserAddresses.NoDataSubText') }}
          </div>
        </div>
      </v-flex>

      <template v-for="address in customer.Addresses">
        <v-flex
          :key="address.Id"
          :class="[{'sm6': customer.Addresses.length > 1}]"
          xs12
        >
          <v-card
            height="100%"
            color="gfp-adv-pages-account-address-card-background"
          >
            <v-card-text>
              <v-img
                v-if="isCustomAddress(address)"
                height="200"
                position="center center"
                src="/img/map/map-placeholder.png"
                style="border: 1px solid #e2e2e2;"
                width="570"
              />

              <v-map
                v-else
                :map-center="{lat: parseFloat(address.Lat), lng: parseFloat(address.Lng)}"
                :address-marker="{lat: parseFloat(address.Lat), lng: parseFloat(address.Lng)}"
                show-address-marker
                show-store-marker
                map-static
                :width="customer.Addresses.length === 1 ? '1195' : '570'"
                :height="customer.Addresses.length === 1 ? '200' : '200'"
              />
            </v-card-text>

            <v-card-title
              class="pt-0 align-start gfp-adv-pages-account-address-card-text--text"
              style="min-height: 100px"
            >
              <v-layout
                row
                wrap
              >
                <v-flex
                  class="pl-2 pr-2 pa-0"
                  style="min-height: 32px"
                  xs12
                >
                  <v-chip
                    v-if="address.Nickname"
                    class="ml-0"
                    color="pink"
                    label
                    small
                    text-color="white"
                  >
                    {{ address.Nickname }}
                  </v-chip>

                  <v-chip
                    v-if="address.IsDefault"
                    class="ml-0"
                    color="success"
                    label
                    small
                    text-color="white"
                  >
                    {{ $t('Account.UserAddresses.Default') }}
                  </v-chip>
                </v-flex>

                <v-flex
                  class="pa-2"
                  xs12
                >
                  <h5>{{ getAddressFormatted(address) }}</h5>
                </v-flex>

                <v-flex
                  class="pl-2 pr-2 pa-0"
                  xs12
                >
                  <span
                    v-if="address.Floor"
                    class="pr-2"
                    style="white-space: nowrap;"
                  >
                    <h5 class="d-inline-block">
                      {{ $t('Account.UserAddresses.Floor') }}:
                    </h5> {{ address.Floor }}
                  </span>

                  <span
                    v-if="address.DoorBell"
                    style="white-space: nowrap;"
                  >
                    <h5 class="d-inline-block">
                      {{ $t('Account.UserAddresses.DoorBell') }}:
                    </h5> {{ address.DoorBell }}
                  </span>

                  <span
                    v-if="address.Comment"
                    class="d-block"
                    style="white-space: nowrap;"
                  >
                    <h5 class="d-inline-block">
                      {{ $t('Account.UserAddresses.Comment') }}:
                    </h5> {{ address.Comment }}
                  </span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-actions>
              <v-btn
                color="gfp-adv-pages-account-address-button-delete"
                flat
                @click="deleteAddress(address)"
              >
                {{ $t('Buttons.Delete') | sanitizeDiacritics }}
              </v-btn>

              <v-btn
                v-if="!isCustomAddress(address) || (isCustomAddress(address) && acceptCustomAddresses)"
                color="gfp-adv-pages-account-address-button-edit"
                flat
                @click="editAddress(address)"
              >
                {{ $t('Buttons.Edit') | sanitizeDiacritics }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </template>

      <account-add-edit-address-dialog
        :dialog-visible.sync="addEditAddressDialogVisible"
        :edit-address="selectedAddress"
      />

      <confirm-dialog
        :html-content="$t('Account.UserAddresses.ConfirmDelete.Body') + '<br><br><span class=\'caption\'>' + (selectedAddress ? selectedAddress.Formatted : '') + '</span>'"
        :html-title="$t('Account.UserAddresses.ConfirmDelete.Title')"
        :visible.sync="confirmDeleteDialogVisible"
        yes-button-event="delete-address-yes"
        @delete-address-yes="onDeleteAddressYes"
      />
    </v-layout>
  </v-container>
</template>

<script>
import AppData                     from '@/mixins/appdata'
import CartCommon                  from '@/mixins/orders/cartCommon'
import ConfirmDialog               from '@/components/common/ConfirmDialog'
import VMap                        from '@/components/common/maps/VMap.vue'
import AccountAddEditAddressDialog from './AccountAddEditAddressDialog'
import MapsCommon                  from '@/mixins/maps/mapsCommon'
import AddressCommon               from '@/mixins/maps/addressCommon'

export default {
  name      : 'AccountAddresses',
  components: {
    VMap,
    'confirm-dialog'                 : ConfirmDialog,
    'account-add-edit-address-dialog': AccountAddEditAddressDialog
  },
  directives: {},
  mixins    : [AppData, CartCommon, MapsCommon, AddressCommon],
  props     : ['customerInfo'],
  data () {
    return {
      addEditAddressDialogVisible: false,
      confirmDeleteDialogVisible : false,
      selectedAddress            : null
    }
  },
  computed: {
    customer: {
      get () {
        return this.customerInfo
      },
      set (data) {
        this.$emit('update:customerInfo', data)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    addAddress () {
      this.selectedAddress = null
      this.addEditAddressDialogVisible = true
    },
    async editAddress (address) {
      const result = await this.isPlaceInsideDeliveryAreas(this.addressToPlaceObject(address))

      if (result) {
        this.selectedAddress = address
        this.addEditAddressDialogVisible = true
      } else {
        this.notifyUserOutOfBoundsAddress()
      }
    },
    deleteAddress (address) {
      this.selectedAddress = address
      this.confirmDeleteDialogVisible = true
    },
    onDeleteAddressYes () {
      window.API.post(window.APICall.accountAddressDelete, {
        Id        : this.selectedAddress.Id,
        CustomerId: this.customerInfo.Info.Id
      })
        .then(response => {
          if (response.data.status === 200) {
            if (response.data.data.Messages.Success) {
              this.customer = response.data.data.CustomerInfo
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Success,
                type : 'success',
                icon : ''
              })
            } else {
              this.$bus.$emit('show-snackbar', {
                title: '',
                body : response.data.data.Messages.Error,
                type : 'error',
                icon : ''
              })
            }
          }

          this.selectedAddress = null
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$bus.$emit('logout', true)
            location.reload()
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
