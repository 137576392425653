/* eslint-disable camelcase */
import Vue                  from 'vue'
import DataEventEnum        from '@/lib/services/enum/DataEventEnum'
import OSTagEnum            from '@/lib/services/enum/OSTagEnum'
import DataEventService     from '@/lib/services/event/DataEventService'
import i18n                 from '@/lang/lang'
import { CapacitorService } from '@/lib/services/Capacitor'

const Tier = {
  Free: {
    TagsTotal: 2,
    Tags     : [
      OSTagEnum.CART_UPDATE,
      OSTagEnum.LAST_ORDER
    ]
  },
  Growth: {
    TagsTotal: 10,
    Tags     : [
      OSTagEnum.USER_TYPE,
      OSTagEnum.CART_UPDATE,
      OSTagEnum.CART_ITEMS,
      OSTagEnum.LAST_ORDER,
      OSTagEnum.AMOUNT_SPENT,
      OSTagEnum.FIRST_NAME,
      OSTagEnum.USER_LOCALE,
      OSTagEnum.BIRTHDAY,
      OSTagEnum.LOYALTY_POINTS
    ]
  },
  Professional: {
    TagsTotal: 20,
    Tags     : [
      OSTagEnum.USER_TYPE,
      OSTagEnum.CART_UPDATE,
      OSTagEnum.CART_ITEMS,
      OSTagEnum.LAST_ORDER,
      OSTagEnum.AMOUNT_SPENT,
      OSTagEnum.FIRST_NAME,
      OSTagEnum.USER_LOCALE,
      OSTagEnum.BIRTHDAY,
      OSTagEnum.LOYALTY_POINTS
    ]
  }
}

export const setupOneSignal = (Settings) => {
  try {
    const IS_DEV = process.env.NODE_ENV === 'development'
    const APP_ID = IS_DEV && process.env.VUE_APP_ONESIGNAL_APP_ID ? process.env.VUE_APP_ONESIGNAL_APP_ID : Settings?.Marketing?.OneSignal?.AppId || ''
    const ACTIVE = Settings?.Marketing?.OneSignal?.Active || false
    const EMAIL_ACTIVE = ACTIVE && Settings?.Marketing?.OneSignal?.Email?.Active || false
    const SMS_ACTIVE = ACTIVE && Settings?.Marketing?.OneSignal?.Sms?.Active || false
    const PUSH_ACTIVE = ACTIVE && Settings?.Marketing?.OneSignal?.Push?.Active || false
    const TIER = ACTIVE && Settings?.Marketing?.OneSignal?.Tier || 'Free'
    const MOBILE_ACTIVE = ACTIVE && PUSH_ACTIVE && CapacitorService.isNative && Settings?.Marketing?.OneSignal?.Push?.Mobile?.Active || false
    const WEB_ACTIVE = ACTIVE && PUSH_ACTIVE && !CapacitorService.isNative && Settings?.Marketing?.OneSignal?.Push?.Web?.Active || false
    const SEND_DATA = ACTIVE && APP_ID && (MOBILE_ACTIVE || WEB_ACTIVE || EMAIL_ACTIVE || SMS_ACTIVE)

    window.$OneSignal = {
      Service : () => SEND_DATA ? (window?.plugins?.OneSignal || window?.OneSignal || window?.OneSignalDeferred) || [] : [],
      Version : null,
      Settings: {
        AppId : '',
        Tier  : TIER,
        Active: false,
        Email : {
          Active: false
        },
        Sms: {
          Active: false
        },
        Push: {
          Active: false,
          Mobile: {
            Active: false
          },
          Web: {
            Active                      : false,
            appId                       : '',
            allowLocalhostAsSecureOrigin: IS_DEV,
            autoResubscribe             : true,
            persistNotification         : true,
            requiresUserPrivacyConsent  : false,
            serviceWorkerParam          : { scope: '/push/onesignal/' },
            serviceWorkerPath           : 'push/onesignal/OneSignalSDKWorker.js',
            serviceWorkerUpdaterPath    : 'push/onesignal/OneSignalSDKUpdaterWorker.js',
            notifyButton                : {
              enable    : true,
              showCredit: false,
              size      : 'medium',
              position  : 'bottom-right',
              offset    : {
                left  : '5px',
                right : '5px',
                bottom: '5px'
              },
              text: {
                tip: {
                  state: {
                    unsubscribed: i18n.t('PushNotifications.notifyButton.tip.state.unsubscribed').replace(/'/g, '\\\''),
                    subscribed  : i18n.t('PushNotifications.notifyButton.tip.state.subscribed').replace(/'/g, '\\\''),
                    blocked     : i18n.t('PushNotifications.notifyButton.tip.state.blocked').replace(/'/g, '\\\'')
                  }
                },
                message: {
                  prenotify: i18n.t('PushNotifications.notifyButton.message.prenotify').replace(/'/g, '\\\''),
                  action   : {
                    subscribed  : i18n.t('PushNotifications.notifyButton.message.action.subscribed').replace(/'/g, '\\\''),
                    resubscribed: i18n.t('PushNotifications.notifyButton.message.action.resubscribed').replace(/'/g, '\\\''),
                    unsubscribed: i18n.t('PushNotifications.notifyButton.message.action.unsubscribed').replace(/'/g, '\\\'')
                  }
                },
                dialog: {
                  main: {
                    title : i18n.t('PushNotifications.notifyButton.dialog.main.title').replace(/'/g, '\\\''),
                    button: {
                      subscribe  : i18n.t('PushNotifications.notifyButton.dialog.main.button.subscribe').replace(/'/g, '\\\''),
                      unsubscribe: i18n.t('PushNotifications.notifyButton.dialog.main.button.unsubscribe').replace(/'/g, '\\\'')
                    }
                  },
                  blocked: {
                    title  : i18n.t('PushNotifications.notifyButton.dialog.blocked.title').replace(/'/g, '\\\''),
                    message: i18n.t('PushNotifications.notifyButton.dialog.blocked.message').replace(/'/g, '\\\'')
                  }
                }
              }
            },
            promptOptions: {
              slidedown: {
                prompts: [
                  {
                    type      : 'push',
                    autoPrompt: true,
                    text      : {
                      actionMessage       : i18n.t('PushNotifications.promptOptions.actionMessage').replace(/'/g, '\\\''),
                      acceptButton        : i18n.t('PushNotifications.promptOptions.acceptButton').replace(/'/g, '\\\''),
                      cancelButton        : i18n.t('PushNotifications.promptOptions.cancelButton').replace(/'/g, '\\\''),
                      emailLabel          : i18n.t('PushNotifications.promptOptions.emailLabel').replace(/'/g, '\\\''),
                      smsLabel            : i18n.t('PushNotifications.promptOptions.smsLabel').replace(/'/g, '\\\''),
                      updateMessage       : i18n.t('PushNotifications.promptOptions.updateMessage').replace(/'/g, '\\\''),
                      negativeUpdateButton: i18n.t('PushNotifications.promptOptions.negativeUpdateButton').replace(/'/g, '\\\''),
                      positiveUpdateButton: i18n.t('PushNotifications.promptOptions.positiveUpdateButton').replace(/'/g, '\\\''),
                      confirmMessage      : i18n.t('PushNotifications.promptOptions.confirmMessage').replace(/'/g, '\\\'')
                    },
                    delay: {
                      pageViews: 1,
                      timeDelay: 10
                    }
                  },
                  {
                    type      : 'sms',
                    autoPrompt: false,
                    text      : {
                      actionMessage       : i18n.t('PushNotifications.promptOptions.actionMessage').replace(/'/g, '\\\''),
                      acceptButton        : i18n.t('PushNotifications.promptOptions.acceptButton').replace(/'/g, '\\\''),
                      cancelButton        : i18n.t('PushNotifications.promptOptions.cancelButton').replace(/'/g, '\\\''),
                      emailLabel          : i18n.t('PushNotifications.promptOptions.emailLabel').replace(/'/g, '\\\''),
                      smsLabel            : i18n.t('PushNotifications.promptOptions.smsLabel').replace(/'/g, '\\\''),
                      updateMessage       : i18n.t('PushNotifications.promptOptions.updateMessage').replace(/'/g, '\\\''),
                      negativeUpdateButton: i18n.t('PushNotifications.promptOptions.negativeUpdateButton').replace(/'/g, '\\\''),
                      positiveUpdateButton: i18n.t('PushNotifications.promptOptions.positiveUpdateButton').replace(/'/g, '\\\''),
                      confirmMessage      : i18n.t('PushNotifications.promptOptions.confirmMessage').replace(/'/g, '\\\'')
                    },
                    delay: {
                      pageViews: 1,
                      timeDelay: 10
                    }
                  },
                  {
                    type      : 'email',
                    autoPrompt: false,
                    text      : {
                      actionMessage       : i18n.t('PushNotifications.promptOptions.actionMessage').replace(/'/g, '\\\''),
                      acceptButton        : i18n.t('PushNotifications.promptOptions.acceptButton').replace(/'/g, '\\\''),
                      cancelButton        : i18n.t('PushNotifications.promptOptions.cancelButton').replace(/'/g, '\\\''),
                      emailLabel          : i18n.t('PushNotifications.promptOptions.emailLabel').replace(/'/g, '\\\''),
                      smsLabel            : i18n.t('PushNotifications.promptOptions.smsLabel').replace(/'/g, '\\\''),
                      updateMessage       : i18n.t('PushNotifications.promptOptions.updateMessage').replace(/'/g, '\\\''),
                      negativeUpdateButton: i18n.t('PushNotifications.promptOptions.negativeUpdateButton').replace(/'/g, '\\\''),
                      positiveUpdateButton: i18n.t('PushNotifications.promptOptions.positiveUpdateButton').replace(/'/g, '\\\''),
                      confirmMessage      : i18n.t('PushNotifications.promptOptions.confirmMessage').replace(/'/g, '\\\'')
                    },
                    delay: {
                      pageViews: 1,
                      timeDelay: 10
                    }
                  },
                  {
                    type      : 'smsAndEmail',
                    autoPrompt: false,
                    text      : {
                      actionMessage       : i18n.t('PushNotifications.promptOptions.actionMessage').replace(/'/g, '\\\''),
                      acceptButton        : i18n.t('PushNotifications.promptOptions.acceptButton').replace(/'/g, '\\\''),
                      cancelButton        : i18n.t('PushNotifications.promptOptions.cancelButton').replace(/'/g, '\\\''),
                      emailLabel          : i18n.t('PushNotifications.promptOptions.emailLabel').replace(/'/g, '\\\''),
                      smsLabel            : i18n.t('PushNotifications.promptOptions.smsLabel').replace(/'/g, '\\\''),
                      updateMessage       : i18n.t('PushNotifications.promptOptions.updateMessage').replace(/'/g, '\\\''),
                      negativeUpdateButton: i18n.t('PushNotifications.promptOptions.negativeUpdateButton').replace(/'/g, '\\\''),
                      positiveUpdateButton: i18n.t('PushNotifications.promptOptions.positiveUpdateButton').replace(/'/g, '\\\''),
                      confirmMessage      : i18n.t('PushNotifications.promptOptions.confirmMessage').replace(/'/g, '\\\'')
                    },
                    delay: {
                      pageViews: 1,
                      timeDelay: 10
                    }
                  }
                ]
              }
            },
            welcomeNotification: {
              disable: true,
              title  : `${ window.AppConfig?.LOCATION_DATA?.Name }, ${ window.AppConfig?.LOCATION_DATA?.City }`,
              message: i18n.t('PushNotifications.welcomeNotification.message').replace(/'/g, '\\\'')
            }
          }
        }
      }
    }

    window.$OneSignal.Settings.AppId = APP_ID
    window.$OneSignal.Settings.Tier = TIER
    window.$OneSignal.Settings.SendData = SEND_DATA
    window.$OneSignal.Settings.Active = ACTIVE
    window.$OneSignal.Settings.Email.Active = EMAIL_ACTIVE
    window.$OneSignal.Settings.Sms.Active = SMS_ACTIVE
    window.$OneSignal.Settings.Push.Active = PUSH_ACTIVE
    window.$OneSignal.Settings.Push.Mobile.Active = MOBILE_ACTIVE
    window.$OneSignal.Settings.Push.Web.Active = WEB_ACTIVE
    window.$OneSignal.Settings.Push.Web.appId = APP_ID

    // console.log('window.$OneSignal::', window.$OneSignal)

    // console.log('>>> ACTIVE::', ACTIVE)
    // console.log('>>> APP_ID::', APP_ID)

    if (!ACTIVE || !APP_ID) return

    // console.log('>>> MOBILE_ACTIVE::', MOBILE_ACTIVE)
    // console.log('>>> WEB_ACTIVE::', WEB_ACTIVE)

    Object.defineProperties(Vue.prototype, {
      $OneSignal: {
        get: function () {
          return window.$OneSignal
        }
      }
    })

    if (MOBILE_ACTIVE) {
      const OneSignal = window.$OneSignal.Service()
      if (!OneSignal) return

      const notificationOpenedCallback = function (jsonData) {
        // eslint-disable-next-line no-console
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData))
      }

      // Set iOS Settings
      const iosSettings = {}
      iosSettings.kOSSettingsKeyAutoPrompt = false
      iosSettings.kOSSettingsKeyInAppLaunchURL = false

      const isOneSignalv3 = typeof OneSignal.setAppId === 'function'
      const isOneSignalv5 = typeof OneSignal.initialize === 'function'

      if (isOneSignalv5) {
        window.$OneSignal.Version = 5
        // 0 = None, 1 = Fatal, 2 = Errors, 3 = Warnings, 4 = Info, 5 = Debug, 6 = Verbose
        OneSignal.Debug.setLogLevel(6)
        OneSignal.initialize(window.$OneSignal.Settings.AppId)
      } else if (isOneSignalv3) {
        window.$OneSignal.Version = 3
        // 0 = None, 1 = Fatal, 2 = Errors, 3 = Warnings, 4 = Info, 5 = Debug, 6 = Verbose
        // OneSignal.setLogLevel({ logLevel   : 6, visualLevel: 0 })
        OneSignal.setAppId(window.$OneSignal.Settings.AppId)
        OneSignal.setNotificationOpenedHandler(notificationOpenedCallback)
      } else {
        window.$OneSignal.Version = 1
        OneSignal
          .startInit(window.$OneSignal.Settings.AppId)
          .handleNotificationOpened(notificationOpenedCallback)
          .iOSSettings(iosSettings)
          .inFocusDisplaying(OneSignal.OSInFocusDisplayOption.Notification)
          .endInit()
      }

      if (isOneSignalv5) {
        OneSignal.Notifications.requestPermission(false).then((accepted) => {
          // eslint-disable-next-line no-console
          console.log('User accepted notifications: ' + JSON.stringify(accepted))
        })

        window.afterOneSignalInit()
      } else {
        // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt.
        // We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
        OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
          // eslint-disable-next-line no-console
          console.log('User accepted notifications: ' + accepted)
        })

        OneSignal.addSubscriptionObserver(state => {
          DataEventService.Emit(DataEventEnum.EVENT, {
            Event  : DataEventEnum.PUSH_SUBSCRIPTION_CHANGE,
            Payload: (!state.from.subscribed && state.to.subscribed)
          })
        })
      }
    }

    if (WEB_ACTIVE) {
      const OneSignalSDK = document.createElement('script')
      OneSignalSDK.setAttribute('src', 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js')
      OneSignalSDK.setAttribute('defer', '')
      document.head.appendChild(OneSignalSDK)

      const OneSignalScriptText = `
        var OneSignalDeferred = window.$OneSignal.Service()
        var initConfig = window.$OneSignal.Settings.Push.Web

        OneSignalDeferred.push(async function (OneSignal) {
          OneSignal.Notifications.setDefaultTitle('${ window.AppConfig?.LOCATION_DATA?.Name }, ${ window.AppConfig?.LOCATION_DATA?.City }')
          OneSignal.Notifications.setDefaultUrl('${ window.AppConfig?.LOCATION_DATA?.SiteUrl }')

          OneSignal.Notifications.addEventListener("click", function (notification, result) { })
          OneSignal.Notifications.addEventListener("dismiss", function (notification) { })
          OneSignal.Notifications.addEventListener("permissionChange", function (permission) {
            window.EventBus.$emit('data-event', { Event: 'data-event:push_subscription_change', Payload: permission })
          })
          OneSignal.Notifications.addEventListener("permissionPromptDisplay", function () { })
          OneSignal.Notifications.addEventListener("foregroundWillDisplay", function () { })

          await OneSignal.init(initConfig)

          window.afterOneSignalInit()
        })
      `

      const OneSignalScript = document.createElement('script')
      OneSignalScript.textContent = OneSignalScriptText || ''
      document.head.appendChild(OneSignalScript)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

window.afterOneSignalInit = () => {
  DataEventService.On(DataEventEnum.EVENT, handleOSDataEvent)
}

// eslint-disable-next-line no-unused-vars
const handleOSDataEvent = ($data) => {
  // console.log('handleAnalyticsEvent:', $data)
  const OneSignal = window.$OneSignal.Service()
  const Data = JSON.parse(JSON.stringify($data))
  const Settings = window.$OneSignal.Settings
  const Timestamp = Math.floor(Date.now() / 1000)
  if (!Settings.SendData) return

  switch (Data.Event) {
  case DataEventEnum.INIT:
    CapacitorService.isNative ? onInit(Data, Timestamp) : OneSignal.push(() => onInit(Data, Timestamp))
    break

  case DataEventEnum.SIGN_UP:
  case DataEventEnum.LOGIN:
    CapacitorService.isNative ? onLogin(Data, Timestamp) : OneSignal.push(() => onLogin(Data, Timestamp))
    break

  case DataEventEnum.ADD_TO_CART:
    CapacitorService.isNative ? onAddToCart(Data, Timestamp) : OneSignal.push(() => onAddToCart(Data, Timestamp))
    break

  case DataEventEnum.REMOVE_FROM_CART:
    CapacitorService.isNative ? onRemoveFromCart(Data, Timestamp) : OneSignal.push(() => onRemoveFromCart(Data, Timestamp))
    break

  case DataEventEnum.BEGIN_CHECKOUT:
    CapacitorService.isNative ? onBeginCheckout(Data, Timestamp) : OneSignal.push(() => onBeginCheckout(Data, Timestamp))
    break

  case DataEventEnum.PURCHASE:
    CapacitorService.isNative ? onPurchase(Data, Timestamp) : OneSignal.push(() => onPurchase(Data, Timestamp))
    break

  case DataEventEnum.ACCOUNT_UPDATE:
    CapacitorService.isNative ? onAccountUpdate(Data, Timestamp) : OneSignal.push(() => onAccountUpdate(Data, Timestamp))
    break

  case DataEventEnum.ACCOUNT_ENABLE:
    CapacitorService.isNative ? onAccountEnable(Data, Timestamp) : OneSignal.push(() => onAccountEnable(Data, Timestamp))
    break

  case DataEventEnum.ACCOUNT_DISABLE:
    CapacitorService.isNative ? onAccountDisable(Data, Timestamp) : OneSignal.push(() => onAccountDisable(Data, Timestamp))
    break

  case DataEventEnum.ACCOUNT_DELETE:
    CapacitorService.isNative ? onAccountDelete(Data, Timestamp) : OneSignal.push(() => onAccountDelete(Data, Timestamp))
    break

  case DataEventEnum.PUSH_SUBSCRIPTION_CHANGE:
    CapacitorService.isNative ? onSubscriptionChange(Data, Timestamp) : OneSignal.push(() => onSubscriptionChange(Data, Timestamp))
    break
  }
}

const onInit = (Data) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  const Settings = window.$OneSignal.Settings
  const UserEmail = Data.Payload?.User?.Info?.Email || null
  const UserMobile = Data.Payload?.User?.Info?.Telephone || null
  const UserFirstname = Data.Payload?.User?.Info?.Firstname || ''

  if (Data.Payload.User) {
    OneSignal.User.addTags({
      ...(tierTagAllowed(OSTagEnum.USER_TYPE) && { [OSTagEnum.USER_TYPE]: String(Data.Payload.Method) }),
      ...(tierTagAllowed(OSTagEnum.USER_LOCALE) && { [OSTagEnum.USER_LOCALE]: String(Data.Payload.Locale) }),
      ...(tierTagAllowed(OSTagEnum.FIRST_NAME) && { [OSTagEnum.FIRST_NAME]: String(UserFirstname) })
    })

    OneSignal.login(UserEmail)

    if (Settings.Push.Active) Data.Payload.User && (!Data.Payload.User.Info.Push || !Data.Payload.User.Info.Status) ? OneSignal.User.PushSubscription.optOut() : OneSignal.setSubscription(Data.Payload.User.Info.Push || false)
    if (Settings.Email.Active) !Data.Payload.User.Info.Newsletter || !Data.Payload.User.Info.Status ? OneSignal.User.removeEmail(UserEmail) : OneSignal.User.addEmail(UserEmail)
    if (Settings.Sms.Active) !Data.Payload.User.Info.SMS || !Data.Payload.User.Info.Status ? OneSignal.User.removeSms(UserMobile) : OneSignal.User.addSms(UserMobile)
  } else {
    OneSignal.User.addTags({
      ...(tierTagAllowed(OSTagEnum.USER_TYPE) && { [OSTagEnum.USER_TYPE]: String(Data.Payload.Method) }),
      ...(tierTagAllowed(OSTagEnum.USER_LOCALE) && { [OSTagEnum.USER_LOCALE]: String(Data.Payload.Locale) })
    })
  }
}

const tierTagAllowed = (tag) => {
  return !!Tier[window.$OneSignal.Settings.Tier]?.Tags?.includes(tag)
}

const onLogin = (Data) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  const Settings = window.$OneSignal.Settings
  const User = Data.Payload?.User?.Info || null
  if (!User) return

  OneSignal.User.addTags({
    ...(tierTagAllowed(OSTagEnum.USER_TYPE) && { [OSTagEnum.USER_TYPE]: String(Data.Payload.Method) }),
    ...(tierTagAllowed(OSTagEnum.LOYALTY_POINTS) && { [OSTagEnum.LOYALTY_POINTS]: String(User?.Points || 0) }),
    ...(tierTagAllowed(OSTagEnum.FIRST_NAME) && { [OSTagEnum.FIRST_NAME]: String(User?.Firstname || '') })
  })

  OneSignal.login(User.Email)

  if (Settings.Push.Active) User && (!User.Push || !User.Status) ? OneSignal.User.PushSubscription.optOut() : OneSignal.setSubscription(User.Push || false)
  if (Settings.Email.Active) !User.Newsletter || !User.Status ? OneSignal.User.removeEmail(User.Email) : OneSignal.User.addEmail(User.Email)
  if (Settings.Sms.Active) !User.SMS || !User.Status ? OneSignal.User.removeSms(User.Telephone) : OneSignal.User.addSms(User.Telephone)
}

const onAddToCart = (Data, Timestamp) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  OneSignal.User.addTags({
    ...(tierTagAllowed(OSTagEnum.CART_UPDATE) && { [OSTagEnum.CART_UPDATE]: String(Timestamp) }),
    ...(tierTagAllowed(OSTagEnum.CART_ITEMS) && { [OSTagEnum.CART_ITEMS]: String(Data?.Payload?.cartTotalQuantity) })
  })
}

const onRemoveFromCart = (Data, Timestamp) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  OneSignal.User.addTags({
    ...(tierTagAllowed(OSTagEnum.CART_UPDATE) && { [OSTagEnum.CART_UPDATE]: String(Timestamp) }),
    ...(tierTagAllowed(OSTagEnum.CART_ITEMS) && { [OSTagEnum.CART_ITEMS]: String(Data?.Payload?.cartTotalQuantity) })
  })
}

const onBeginCheckout = (Data, Timestamp) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  OneSignal.User.addTags({
    ...(tierTagAllowed(OSTagEnum.CART_UPDATE) && { [OSTagEnum.CART_UPDATE]: String(Timestamp) }),
    ...(tierTagAllowed(OSTagEnum.CART_ITEMS) && { [OSTagEnum.CART_ITEMS]: String(Data?.Payload?.cartTotalQuantity) })
  })
}

const onPurchase = (Data, Timestamp) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  OneSignal.getTags(tags => {
    let purchase_amount = 0
    if (tags[OSTagEnum.AMOUNT_SPENT]) purchase_amount = parseInt(tags[OSTagEnum.AMOUNT_SPENT]) || 0
    purchase_amount += parseInt(Data.Payload.value) || 0
    const UserFirstName = Data.Payload?.user?.firstname || ''

    OneSignal.User.addTags({
      ...(tierTagAllowed(OSTagEnum.LAST_ORDER) && { [OSTagEnum.LAST_ORDER]: String(Timestamp) }),
      ...(tierTagAllowed(OSTagEnum.AMOUNT_SPENT) && { [OSTagEnum.AMOUNT_SPENT]: String(purchase_amount) }),
      ...(tierTagAllowed(OSTagEnum.CART_UPDATE) && { [OSTagEnum.CART_UPDATE]: String('') }),
      ...(tierTagAllowed(OSTagEnum.CART_ITEMS) && { [OSTagEnum.CART_ITEMS]: String(0) }),
      ...(tierTagAllowed(OSTagEnum.FIRST_NAME) && { [OSTagEnum.FIRST_NAME]: String(UserFirstName) })
    })

    OneSignal.login(Data.Payload.user.email)
  })
}

const onAccountUpdate = (Data, Timestamp) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return
  const Settings = window.$OneSignal.Settings
  const UserEmail = Data.Payload?.User?.Info?.Email || null
  const UserMobile = Data.Payload?.User?.Info?.Telephone || null

  if (Settings.Push.Active) (!Data.Payload.Info.Push || !Data.Payload.Info.Status) ? OneSignal.User.PushSubscription.optOut() : Data.Payload.Info.Push && OneSignal.User.PushSubscription.optIn()
  if (Settings.Email.Active) !Data.Payload.Info.Newsletter || !Data.Payload.Info.Status ? OneSignal.User.removeEmail(UserEmail) : OneSignal.User.addEmail(UserEmail)
  if (Settings.Sms.Active) !Data.Payload.Info.SMS || !Data.Payload.Info.Status ? OneSignal.User.removeSms(UserMobile) : OneSignal.User.addSms(UserMobile)
}

const onAccountEnable = (Data, Timestamp) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  const Settings = window.$OneSignal.Settings
  const UserEmail = Data.Payload?.User?.Info?.Email || null
  const UserMobile = Data.Payload?.User?.Info?.Telephone || null

  if (Settings.Push.Active) (!Data.Payload.Info.Push || !Data.Payload.Info.Status) ? OneSignal.User.PushSubscription.optOut() : Data.Payload.Info.Push && OneSignal.User.PushSubscription.optIn()
  if (Settings.Email.Active) !Data.Payload.Info.Newsletter || !Data.Payload.Info.Status ? OneSignal.User.removeEmail(UserEmail) : OneSignal.User.addEmail(UserEmail)
  if (Settings.Sms.Active) !Data.Payload.Info.SMS || !Data.Payload.Info.Status ? OneSignal.User.removeSms(UserMobile) : OneSignal.User.addSms(UserMobile)

  if (UserEmail) OneSignal.login(UserEmail)
}

const onAccountDisable = (Data, Timestamp) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  const UserEmail = Data.Payload?.User?.Info?.Email || null
  const UserMobile = Data.Payload?.User?.Info?.Telephone || null

  if (UserEmail) OneSignal.User.removeEmail(UserEmail)
  if (UserMobile) OneSignal.User.removeSms(UserMobile)
  OneSignal.User.PushSubscription.optOut()
  OneSignal.User.logout()
}

const onAccountDelete = (Data, Timestamp) => {
  const OneSignal = window.$OneSignal.Service()
  if (!OneSignal) return

  const UserEmail = Data.Payload?.User?.Info?.Email || null
  const UserMobile = Data.Payload?.User?.Info?.Telephone || null

  if (UserEmail) OneSignal.User.removeEmail(UserEmail)
  if (UserMobile) OneSignal.User.removeSms(UserMobile)
  OneSignal.User.PushSubscription.optOut()
  OneSignal.User.logout()
}

const onSubscriptionChange = (Data, Timestamp) => {
  if (window?.Vue?.store?.userInfo) {
    if (Data.Payload === window.Vue.store.userInfo.Info.Push) return
    window.Vue.store.userInfo.Info.Push = Data.Payload || false
    window.Vue.$localStorage.set('userInfo', JSON.stringify(window.Vue.store.userInfo) || null)
    window.API.post(window.APICall.accountDetails, window.Vue.store.userInfo).catch(e => { })
  }
}
