<template>
  <v-container
    fluid
    pa-0
    class="gfp-adv-pages-account-background gfp-adv-pages-account-text--text"
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <!-- HEADER -->
        <page-header
          :images="appConfig.LOCATION_DATA.Gallery.account"
          :title="$t('Account.Title')"
        />
      </v-flex>

      <v-flex
        lg8
        md10
        offset-lg2
        offset-md1
        offset-sm0
        sm12
      >
        <v-container
          fluid
          pa-0
        >
          <v-layout
            :class="$vuetify.breakpoint.xsOnly ? 'pa-2' : 'pa-4'"
            row
            wrap
          >
            <v-flex xs12>
              <v-tabs
                color="gfp-adv-pages-account-tabs-background"
                :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-tabs-background'].base) === 'white'"
                grow
                show-arrows
                slider-color="gfp-adv-pages-account-tabs-active"
              >
                <v-tab
                  :to="{name: 'AccountUserProfile'}"
                  class="gfp-adv-pages-account-tabs-text--text"
                >
                  {{ $t('Account.Profile') | sanitizeDiacritics }}
                </v-tab>

                <v-tab
                  v-if="!isQrOnlyOrder"
                  :to="{name: 'AccountAddresses'}"
                  class="gfp-adv-pages-account-tabs-text--text"
                >
                  {{ $t('Account.Addresses') | sanitizeDiacritics }}
                </v-tab>

                <v-tab
                  :to="{name: 'AccountOrders'}"
                  class="gfp-adv-pages-account-tabs-text--text"
                >
                  {{ $t('Account.Orders') | sanitizeDiacritics }}
                </v-tab>
              </v-tabs>
            </v-flex>
            <v-flex xs12>
              <router-view
                :customer-info.sync="customerInfo"
                class="pt-2"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AppData              from '../../mixins/appdata'
import CartCommon           from '../../mixins/orders/cartCommon'
import i18nRouteMeta        from '../../mixins/i18nRouteMeta'
import PageHeader           from '../common/PageHeader'
import { getContrastColor } from '@/lib/color'

export default {
  name      : 'Account',
  components: {
    PageHeader
  },
  directives: {},
  mixins    : [AppData, CartCommon, i18nRouteMeta],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setMetaTitle(to.name)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.setMetaTitle(to.name)
    next()
  },
  props: [],
  data () {
    return {
      title: this.$t('Account.Title')
    }
  },
  computed: {
    customerInfo: {
      get () {
        return this.dataStore.userInfo
      },
      set (data) {
        // this.$auth.storage.setItem('userInfo', JSON.stringify(data))
        this.$localStorage.set('userInfo', JSON.stringify(data))
        this.$set(this.dataStore, 'userInfo', data)
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.getData()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getContrastColor,
    getData () {
      window.API.post(window.APICall.accountDetails, {})
        .then(response => {
          if (response.data.status === 200) {
            this.customerInfo = response.data.data.CustomerInfo ? JSON.parse(JSON.stringify(response.data.data.CustomerInfo)) : JSON.parse(this.$localStorage.get('userInfo'))
          }
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$bus.$emit('logout', true)
            location.reload()
          }
        })
    },
    setMetaTitle (routeName) {
      let title = this.$t('Account.Title')
      if (routeName === 'AccountUserProfile') {
        title += ' - ' + this.$t('Account.Profile')
      } else if (routeName === 'AccountOrders') {
        title += ' - ' + this.$t('Account.Orders')
      } else if (routeName === 'AccountAddresses') {
        title += ' - ' + this.$t('Account.Addresses')
      }
      this.title = title
    }
  }
}
</script>

<style scoped>

</style>
