import Vue     from 'vue'
import VueI18n from 'vue-i18n'
// Import Lang Files
import el      from './locale/el'
import en      from './locale/en'
import bg      from './locale/bg'

Vue.use(VueI18n)

export const languages = [
  {
    active    : true,
    visible   : true,
    name      : 'Ελληνικά',
    short_name: 'GR',
    locale    : 'el',
    code      : 'el-GR',
    code2     : 'el_GR',
    icon      : '/img/flags/1x1/gr.svg'
  },
  {
    active    : true,
    visible   : true,
    name      : 'English',
    short_name: 'EN',
    locale    : 'en',
    code      : 'en-US',
    code2     : 'en_US',
    icon      : '/img/flags/1x1/gb.svg'
  },
  {
    active    : true,
    visible   : true,
    name      : 'Bulgarian',
    short_name: 'BG',
    locale    : 'bg',
    code      : 'bg-BG',
    code2     : 'bg_BG',
    icon      : '/img/flags/1x1/bg.svg'
  }
]

const messages = {
  el: el,
  en: en,
  bg: bg
}

export const i18n = new VueI18n({
  locale               : process.env.VUE_APP_I18N_LOCALE || 'el',
  fallbackLocale       : process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages
})
i18n.languages = languages

export const setupI18n = (locale, fallbackLocale) => {
  i18n.locale = locale || getDefaultLocale()
  i18n.fallbackLocale = fallbackLocale || getFallbackLocale()

  if (i18n.locale === i18n.fallbackLocale && window.AppConfig.LOCATION_DATA.LanguageList.length === 1) {
    Object.keys(messages).forEach(key => {
      if (!window.AppConfig.LOCATION_DATA.LanguageList.find(lang => lang.Code === key)) delete messages[key]
    })
  }
  setI18nLanguage(i18n.locale)
}

export const setI18nLanguage = (locale) => {
  const lang = languages.find(l => l.locale === locale) || languages[0]
  i18n.locale = lang.locale
  i18n.code = lang.code
  i18n.code2 = lang.code2
  i18n.name = lang.name
  i18n.short_name = lang.short_name
  i18n.active = lang.active
  if (window.Vue) window.Vue.$vuetify.lang.current = lang.locale
  document.querySelector('html').setAttribute('lang', lang.locale)
  return lang
}

export const setI18nLanguageFromBrowser = () => {
  setI18nLanguage(getBrowserLocale())
}

export const getBrowserLocale = (countryCodeOnly = true) => {
  const navigatorLocale = navigator && navigator.languages && Array.isArray(navigator.languages) && navigator.languages.length ? navigator.languages[0] : navigator.language
  if (!navigatorLocale) return process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'el'
  return countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim()
}

export const getDefaultLocale = () => {
  const defaultLocale = window.AppConfig.LOCATION_DATA.LanguageList.find(lang => lang.Selected) && window.AppConfig.LOCATION_DATA.LanguageList.find(lang => lang.Selected).Code || 'el'
  return defaultLocale
}
export const getFallbackLocale = () => {
  const fallbackLocale = window.AppConfig.LOCATION_DATA.LanguageList.find(lang => !lang.Selected) && window.AppConfig.LOCATION_DATA.LanguageList.find(lang => !lang.Selected).Code || i18n.locale || 'en'
  return fallbackLocale
}

// Hot Reloading Language Updates
if (module.hot) {
  module.hot.accept(['./locale/el', './locale/en'], function () {
    i18n.setLocaleMessage('el', require('./locale/el').default)
    i18n.setLocaleMessage('en', require('./locale/en').default)
    i18n.setLocaleMessage('bg', require('./locale/bg').default)
  })
}

export default i18n
