import Vue              from 'vue'
import VueGtag          from 'vue-gtag'
import DataEventEnum    from '@/lib/services/enum/DataEventEnum'
import GTagEventEnum    from '@/lib/services/enum/GTagEventEnum'
import DataEventService from '@/lib/services/event/DataEventService'

export const setupGoogleAnalytics = (Settings, Router) => {
  const gfpId = Settings?.Analytics?.google?.gfpId || ''
  const clientId = Settings?.Analytics?.google?.clientId || ''
  const config = gfpId ? { id: gfpId, params: { send_page_view: true } } : clientId ? { id: clientId, params: { send_page_view: true } } : null
  const includes = clientId ? gfpId ? [{ id: clientId, params: { send_page_view: true } }] : [] : []

  if (config && process.env.NODE_ENV === 'production') {
    Vue.use(VueGtag, {
      config  : config,
      includes: includes,
      enabled : !!gfpId
    }, Router)

    DataEventService.On(DataEventEnum.EVENT, handleGADataEvent)
  }
}

const handleGADataEvent = ($data) => {
  const data = JSON.parse(JSON.stringify($data))
  const GTag = window?.Vue?.$gtag
  if (!GTag) return

  switch (data.Event) {
  case DataEventEnum.LOGIN:
    GTag.event(GTagEventEnum.LOGIN, { method: data.Payload.Method })
    break

  case DataEventEnum.SIGN_UP:
    GTag.event(GTagEventEnum.SIGN_UP, { method: data.Payload.Method })
    break

  case DataEventEnum.ADD_TO_CART:
    delete data.Payload.cartTotalQuantity
    GTag.event(GTagEventEnum.ADD_TO_CART, data.Payload)
    break

  case DataEventEnum.REMOVE_FROM_CART:
    delete data.Payload.cartTotalQuantity
    GTag.event(GTagEventEnum.REMOVE_FROM_CART, data.Payload)
    break

  case DataEventEnum.VIEW_ITEM:
    GTag.event(GTagEventEnum.VIEW_ITEM, data.Payload)
    break

  case DataEventEnum.BEGIN_CHECKOUT:
    delete data.Payload.cartTotalQuantity
    GTag.event(GTagEventEnum.BEGIN_CHECKOUT, data.Payload)
    break

  case DataEventEnum.ADD_SHIPPING_INFO:
    GTag.event(GTagEventEnum.ADD_SHIPPING_INFO, data.Payload)
    break

  case DataEventEnum.ADD_PAYMENT_INFO:
    GTag.event(GTagEventEnum.ADD_PAYMENT_INFO, data.Payload)
    break

  case DataEventEnum.PURCHASE:
    delete data.Payload.user
    GTag.event(GTagEventEnum.PURCHASE, data.Payload)
    break

  case DataEventEnum.SEARCH:
    GTag.event(GTagEventEnum.SEARCH, { search_term: data.Payload })
    break
  }
}
