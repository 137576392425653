<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      class="pb-0 mb-0"
      xs12
    >
      <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
        <v-icon
          class="pr-2"
          color="gfp-adv-pages-checkout-details-header-icon"
        >
          account_circle
        </v-icon>
        {{ $t('Checkout.Payment.PaymentMethod') }}
      </v-subheader>
    </v-flex>
    <v-flex
      class="pt-0 mt-0"
      xs12
    >
      <v-radio-group
        v-model="vModel.code"
        row
      >
        <v-list

          class="pa-0 ma-0 mb-2"
          two-line
        >
          <template v-for="payment in payments">
            <v-list-tile
              v-if="payment.status === '1'"
              :key="`payment-${payment.code}`"
              avatar
              @click="setPaymentType(payment)"
            >
              <v-list-tile-action>
                <v-radio
                  :color="!orderTotalAmountValidPayment(payment) ? 'error' : 'success'"
                  :value="payment.code"
                  @click="setPaymentType(payment)"
                />
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-if="payment.data && payment.lang && payment.lang[$i18n.locale]">
                  {{ payment.lang[$i18n.locale].name }}
                </v-list-tile-title>
                <v-list-tile-title v-else>
                  {{ payment.name }}
                </v-list-tile-title>

                <v-list-tile-sub-title
                  :class="[!orderTotalAmountValidPayment(payment) ? 'error--text' : 'success--text']"
                  class="caption"
                >
                  <template v-if="payment.data && payment.data.minimum_order_total > minOrderTotalPayment(payment)">
                    {{ $t('Checkout.Payment.PaymentMethodMinimumOrder') }} {{ payment.data && payment.data.minimum_order_total | currency }}
                  </template>
                  <template v-else>
                    {{ $t('Checkout.Payment.PaymentMethodMinimumOrder') }} {{ minOrderTotalPayment(payment) | currency }}
                  </template>
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action class="hidden-xs-only">
                <v-icon>{{ payment.code === 'cod' ? 'euro_symbol' : 'credit_card' }}</v-icon>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="`payment-divider-${payment.code}`" />
          </template>
        </v-list>
      </v-radio-group>
    </v-flex>
  </v-layout>
</template>

<script>
import VModel     from '@/mixins/vModel'
import AppData    from '@/mixins/appdata'
import CartCommon from '@/mixins/orders/cartCommon'

export default {
  name      : 'SelectPaymentMethod',
  components: {},
  directives: {},
  mixins    : [AppData, CartCommon, VModel],
  props     : {
    payments: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    setPaymentType (payment) {
      if (this.vModel?.code === payment.code) return
      this.vModel = payment
    }
  }
}
</script>

<style scoped>

</style>
