<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div
      v-if="showing"
      :class="{[overlayClass]: overlay}"
      :style="overlayStyle"
    >
      <slot name="loading-container">
        <div :style="loaderStyle">
          <slot name="loading-spinner">
            <div :style="wrapperStyle">
              <v-progress-circular
                :color="color"
                :size="size"
                :width="width"
                indeterminate
              />
            </div>
          </slot>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
export default {
  name : 'FullScreenLoader',
  props: {
    show: {
      default: false
    },
    overlay: {
      default: true
    },
    overlayClass: {
      default: 'class-overlay'
    },
    width: {
      default: 3,
      type   : Number
    },
    size: {
      default: 50,
      type   : Number
    },
    color: {
      default: 'red',
      type   : String
    },
    bgColor: {
      default: 'rgba(0, 0, 0, 0.75)',
      type   : String
    },
    // central event bus
    eventBus: {
      default: null
    },
    eventShow: {
      default: 'show-full-loading'
    },
    eventHide: {
      default: 'hide-full-loading'
    }
  },
  data () {
    return {
      showing: false
    }
  },
  computed: {
    loaderStyle () {
      return `position: absolute; display: inline-block; left: calc(50% - ${ this.size }px); top: calc(50% - ${ this.size }px);`
    },
    wrapperStyle () {
      return `position: relative; display: inline-block; width: ${ this.size }px; height: ${ this.size }px; font-size : 0;`
    },
    overlayStyle () {
      return this.overlay ? `background-color : ${ this.bgColor };` : ''
    }
  },
  watch: {
    show (val) {
      this.showing = val
    }
  },
  mounted () {
    if (this.eventBus) this.registerBusMethods()
  },
  beforeDestroy () {
    if (this.eventBus) this.unregisterBusMethods()
  },
  methods: {
    showMe () {
      this.showing = true
    },
    hideMe () {
      this.showing = false
    },
    registerBusMethods () {
      this.eventBus.$on(this.eventShow, this.showMe)
      this.eventBus.$on(this.eventHide, this.hideMe)
    },
    unregisterBusMethods () {
      this.eventBus.$off(this.eventShow, this.showMe)
      this.eventBus.$off(this.eventHide, this.hideMe)
    }
  }
}
</script>
<style scoped>
.class-overlay {
  z-index  : 999999;
  top      : 0;
  left     : 0;
  height   : 100vh;
  width    : 100vw;
  position : fixed;
  margin   : 0;
}
</style>
