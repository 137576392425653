const DataStore = {
  install: function (Vue) {
    Vue.mixin({
      computed: {
        dataStore: {
          get () {
            return window.Vue.store
          }
        },

        dataStoreSelectedCatalog () {
          if (!this.dataStore?.cart) return null

          const activeCatalogs = this.appConfig?.LOCATION_DATA?.Catalogs || []
          const selectedSourceType = parseInt(this.$sessionStorage.hasKey('QRData') ? parseInt(this.$sessionStorage.get('QRData')?.source || 1) : this.dataStore?.isMobileApp ? 10 : 1 || 1)
          const selectedOrderType = parseInt(this.dataStore?.cart?.OrderType || window?.AppConfig?.APP_SETTINGS?.DefaultOrderType || 1)
          const selectedCatalog =
                  activeCatalogs.find(catalog => (catalog.SourceTypes.includes(selectedSourceType) || catalog.SourceTypes.includes(0)) && parseInt(catalog.OrderType) === selectedOrderType && !catalog.Default) ||
                  activeCatalogs.find(catalog => (catalog.SourceTypes.includes(selectedSourceType) || catalog.SourceTypes.includes(0)) && parseInt(catalog.OrderType) === 0 && !catalog.Default) ||
                  activeCatalogs.find(catalog => catalog.Default)

          if (!selectedCatalog) return null

          // eslint-disable-next-line no-console
          // console.warn('>>> dataStoreSelectedCatalog <<<', 'selectedCatalog:', selectedCatalog.Name, selectedCatalog.Id, 'SourceTypeId:', selectedSourceType, 'OrderType:', selectedOrderType)

          return selectedCatalog
        },

        dataStoreMenu () {
          if (!this.dataStore.menu || !window.menu || !window?.menu?.items || !window?.menu?.categories) return null

          // eslint-disable-next-line no-console
          // console.time('dataStoreMenu')

          const menuItems = JSON.parse(JSON.stringify(window.menu))
          const selectedCatalog = this.dataStoreSelectedCatalog

          if (!selectedCatalog) return null

          menuItems.items = menuItems.items
            .map(item => {
              item.Items = item.Items.filter(menu => menu.Active && menu?.Catalogs?.includes(selectedCatalog.Id))
              return item
            })
            .filter(item => item.Category.Status && item.Category?.Catalogs?.includes(selectedCatalog.Id) && item.Items.length)

          menuItems.categories = menuItems.categories
            .filter(category => category.Status && category?.Catalogs?.includes(selectedCatalog.Id) && menuItems.items.find(menu => menu.Category.Id === category.Id))

          // eslint-disable-next-line no-console
          // console.timeEnd('dataStoreMenu')

          // eslint-disable-next-line no-console
          // console.log('>>>>>> dataStoreMenu <<<', 'selectedCatalog:', selectedCatalog.Name, selectedCatalog.Id, 'menuItems:', menuItems)

          return menuItems
        },

        dataStoreDashboard () {
          if (!this.dataStoreSelectedCatalog) return null

          // eslint-disable-next-line no-console
          // console.time('dataStoreDashboard')

          const retVal = {
            actionCategories  : this.dataStore?.dashboard?.actionCategories?.filter(item => item.Catalogs.includes(this.dataStoreSelectedCatalog.Id)) || [],
            featuredMenus     : this.dataStore?.dashboard?.featuredMenu?.filter(item => item.Catalogs.includes(this.dataStoreSelectedCatalog.Id)) || [],
            featuredCategories: this.dataStore?.dashboard?.featuredCategories?.filter(item => item.Catalogs.includes(this.dataStoreSelectedCatalog.Id)) || [],
            specialOffers     : this.dataStore?.dashboard?.specialOffers?.filter(item => item.Catalogs.includes(this.dataStoreSelectedCatalog.Id)) || []
          }

          // eslint-disable-next-line no-console
          // console.timeEnd('dataStoreDashboard')

          return retVal
        },

        dataStoreFeaturedMenus () {
          return this.dataStoreDashboard?.featuredMenus || []
        },

        dataStoreSpecialOffers () {
          return this.dataStoreDashboard?.specialOffers || []
        },

        dataStoreFeaturedCategories () {
          return this.dataStoreDashboard?.featuredCategories || []
        },

        dataStoreActionCategories () {
          return this.dataStoreDashboard?.actionCategories || []
        }
      },

      methods: {}
    })
  }
}

export default DataStore

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(DataStore)
}
