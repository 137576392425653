<template>
  <v-layout
    v-if="specialOffers && offersCount > 0"
    :class="blockClass"
    align-center
    row
    wrap
  >
    <v-flex
      class="text-xs-center justify-center pa-2"
      xs12
    >
      <h1
        v-if="_title"
        class="pb-2 gfp-adv-pages-home-sections-offers-title--text"
      >
        <v-icon
          v-if="titleIcon"
          class="pr-2"
          color=" gfp-adv-pages-home-sections-offers-title"
          size="30"
        >
          {{ titleIcon }}
        </v-icon>
        {{ _title }}
      </h1>
      <h4
        v-if="_subTitle"
        class="pb-2 gfp-adv-pages-home-sections-offers-description--text"
      >
        {{ _subTitle }}
      </h4>
    </v-flex>

    <template v-if="specialOffers">
      <v-flex
        class="text-xs-center"
        xs12
      >
        <swiper
          v-if="offersCount > 0"
          key="offers-block-swiper"
          :options="swiperOptionsObj"
          style="width: 100%;"
        >
          <swiper-slide
            v-for="(offer) in specialOffers"
            :key="'offers-block-swiper-' + offer.Id"
          >
            <v-card
              :class="cardClass"
              class="ml-2"
              height="100%"
              style="cursor: pointer;"
              @click="onOfferClick(offer)"
            >
              <div
                v-if="offer.Image"
                v-lazy:background-image="{src: offer.Image, loading: getContrastLogo(LOGO_TYPE.LAZY, LOGO_COLOR.DARK)}"
                :style="{height: cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px'}"
                class="v-image__image--cover gfp-adv-pages-home-sections-offers-offer-image-background"
                style="background-position: center center;"
              />
              <div
                v-else
                class="gfp-adv-pages-home-sections-offers-offer-image-background"
                :style="'height:' + (cardMediaHeight.toString().includes('px') ? cardMediaHeight : cardMediaHeight.toString() + 'px') + ';background-size: 200px; background:url('+getContrastLogo(LOGO_TYPE.ICONS, LOGO_COLOR.DARK).find(i => i.sizes==='180x180').href+') center center no-repeat;'"
              />

              <h1 class="ribbon gfp-adv-pages-home-sections-offers-offer-ribbon-background gfp-adv-pages-home-sections-offers-offer-ribbon-text--text py-1">
                <span>{{ offer.Lang[$i18n.locale].Name }}</span>
                <div class="pt-1">
                  <span class="title old-price">{{ offer.Price | currency }}</span><span class="title pl-2">{{ offer.SpecialPrice | currency }}</span>
                </div>
              </h1>

              <v-divider />

              <v-card-title
                class="justify-center align-center gfp-adv-pages-home-sections-offers-offer-text-title--text"
                style="min-height: 85px;"
                v-html="offer.Lang[$i18n.locale].Description"
              />
            </v-card>
          </swiper-slide>
        </swiper>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import AppData                 from '../../mixins/appdata'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name      : 'OffersBlock',
  components: {
    swiper,
    swiperSlide
  },
  directives: {},
  mixins    : [AppData],
  props     : {
    autoFetchData: {
      type   : Boolean,
      default: true
    },
    title    : String,
    titleIcon: {
      type   : String,
      default: 'fas fa-tag'
    },
    subTitle  : String,
    blockClass: {
      type   : String,
      default: 'pt-3 pb-3 pl-4 pr-4 gfp-adv-pages-home-sections-offers-background'
    },
    cardClass: {
      type   : String,
      default: 'gfp-adv-pages-home-sections-offers-offer-text-background'
    },
    cardMediaHeight: {
      type   : [String, Number],
      default: 250
    },
    cardButtonColor: {
      type   : String,
      default: 'success'
    },
    blockButtonColor: {
      type   : String,
      default: 'gfp-primary-text'
    },
    blockButtonTitle: String,
    swiperOptions   : {
      type   : Object,
      default: () => ({})
    }
  },
  data () {
    return {}
  },
  computed: {
    specialOffers () {
      const randomizeData = true
      const data = this.dataStoreSpecialOffers || []
      const retVal = data.filter(offer => offer.Lang && offer.Lang[this.$i18n.locale] && offer.Category.Lang && offer.Category.Lang[this.$i18n.locale])

      if (randomizeData) {
        return retVal
          .map(a => [Math.random(), a])
          .sort((a, b) => a[0] - b[0])
          .map(a => a[1])
      }
      return retVal
    },
    _title () {
      return this.title ? this.title : this.$t('OffersBlock.Title')
    },
    _subTitle () {
      return this.subTitle ? this.subTitle : this.$t('OffersBlock.Subtitle')
    },
    _blockButtonTitle () {
      return this.blockButtonTitle ? this.blockButtonTitle : this.$t('OffersBlock.BlockButtonTitle')
    },
    swiperOptionsObj () {
      const swiperDefaultOptions = {
        slidesPerView  : this.offersCount < 4 ? this.offersCount : 4,
        slidesPerColumn: 1,
        spaceBetween   : 24,
        loop           : false,
        autoplay       : {
          delay               : 2500,
          disableOnInteraction: true
        },
        breakpoints: {
          1264: {
            slidesPerView: this.offersCount < 3 ? this.offersCount : 3,
            spaceBetween : 24
          },
          960: {
            slidesPerView: this.offersCount < 2 ? this.offersCount : 2,
            spaceBetween : 24
          },
          600: {
            slidesPerView: 1,
            spaceBetween : 24
          }
        }
      }
      return { ...swiperDefaultOptions, ...this.swiperOptions }
    },
    offersCount () {
      return this.specialOffers.length
    }
  },
  watch: {},
  created () {
    if (this.autoFetchData) {
      window.API.get(window.APICall.specialOffers)
        .then(response => {
          if (response.data.status === 200) {
            this.$set(this.dataStore, 'specialOffers', response.data.data)
          }
        })
        .catch(e => {

        })
    }
  },
  mounted () {},
  destroyed () {},
  methods: {
    getItemById (menuItemId, categorySlug) {
      let item = null
      if (menuItemId && categorySlug) {
        const category = Object.entries(this.dataStoreMenu.items).find((obj) => obj[1].Category.Lang[this.$i18n.locale] && obj[1].Category.Lang[this.$i18n.locale].Slug.toString() === categorySlug.toString())[1]
        if (category) item = category.Items.find(menu => menu.Id.toString() === menuItemId.toString())
      }
      return item
    },

    onOfferClick (offer) {
      const menuItem = this.getItemById(offer.Id, offer.Category.Lang[this.$i18n.locale].Slug)
      if (menuItem) {
        this.$bus.$emit('show-menu-options-dialog', { menuItem: menuItem, editMode: false })
      } else {
        this.$router.push({
          name  : 'Menu',
          params: { slug: offer.Category.Lang[this.$i18n.locale].Slug, menuId: offer.Id, hashId: offer.Category.Lang[this.$i18n.locale].Slug + offer.Id }
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .fixCardMediaBgTop *:nth-child(1) {
  background-position : top center !important;
}

.full-height .flex {
  display : flex;
}

.full-height .flex > .card {
  flex : 1 1 auto;
}

.ribbon {
  min-width          : 35%;
  position           : absolute;
  top                : 8px;
  padding            : 0 16px;
  font-size          : 1.200em;
  border-radius      : 0 0.156em 0.156em 0;
  opacity            : 0.85;
  -webkit-box-shadow : -1px 2px 3px rgba(0, 0, 0, 0.5);
  box-shadow         : -1px 2px 3px rgba(0, 0, 0, 0.5);
}

.ribbon:before, .ribbon:after {
  position : absolute;
  content  : '';
  display  : block;
}

.ribbon:before {
  width         : 0.469em;
  height        : 100%;
  padding       : 0 0 0.438em;
  top           : 0;
  left          : -0.469em;
  background    : inherit;
  border-radius : 0.313em 0 0 0.313em;
}

.ribbon:after {
  width         : 0.313em;
  height        : 0.313em;
  background    : rgba(0, 0, 0, 0.35);
  bottom        : -0.313em;
  left          : -0.313em;
  border-radius : 0.313em 0 0 0.313em;
  box-shadow    : inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}
</style>
