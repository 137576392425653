<template>
  <v-layout
    justify-center
    row
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <v-card color="gfp-adv-pages-account-select-address-dialog-body-background">
        <v-toolbar
          card
          class="pl-1"
          height="80"
          prominent
          color="gfp-adv-pages-account-select-address-dialog-toolbar-background"
        >
          <v-avatar class="indigo">
            <v-icon dark>
              my_location
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-1">
            <div class="body-3 gfp-adv-pages-account-select-address-dialog-toolbar-title--text">
              {{ $t('Account.UserAddresses.SelectDialog.Title') }}
            </div>
            <div class="caption gfp-adv-pages-account-select-address-dialog-toolbar-description--text">
              {{ $t('Account.UserAddresses.SelectDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            :color="getContrastColor($vuetify.theme['gfp-adv-pages-account-select-address-dialog-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          class="fill-height pa-2 gfp-adv-pages-account-select-address-dialog-body-background gfp-adv-pages-account-select-address-dialog-body-text--text"
          style="overflow-y:auto;"
        >
          <v-container
            fluid
            grid-list-xs
          >
            <v-layout
              row
              wrap
            >
              <v-flex
                v-if="dataStore.userInfo"
                xs12
              >
                <v-list
                  v-for="item in dataStore.userInfo.Addresses"
                  :key="item.Id"
                  class="mb-2 mt-2 pb-0 pt-0"
                  three-line
                >
                  <v-list-tile
                    avatar
                    ripple
                    class="gfp-adv-pages-account-select-address-dialog-body-list-item-background"
                    @click.stop="setAddress(item)"
                  >
                    <v-list-tile-avatar class="hidden-xs-only mt-1">
                      <v-icon
                        :class="[item.IsDefault ? 'success' : 'grey lighten-2']"
                        class="white--text"
                        large
                      >
                        check
                      </v-icon>
                    </v-list-tile-avatar>

                    <v-list-tile-content class="gfp-adv-pages-account-select-address-dialog-body-list-item-title--text">
                      <v-list-tile-title class="text-xs-left">
                        <v-chip
                          v-if="item.Nickname"
                          class="ma-0 pa-0 hidden-xs-only"
                          color="pink"
                          label
                          small
                          style="height: 22px;"
                          text-color="white"
                        >
                          {{ item.Nickname }}
                        </v-chip>

                        {{ getAddressFormatted(item) }}
                      </v-list-tile-title>

                      <v-list-tile-sub-title v-if="item.Floor || item.DoorBell">
                        <span
                          v-if="item.Floor"
                          class="mr-3 gfp-adv-pages-account-select-address-dialog-body-list-item-address-text--text"
                        >
                          <span class="gfp-adv-pages-account-select-address-dialog-body-list-item-address-title--text">
                            Όροφος:
                          </span> {{ item.Floor }}
                        </span>
                        <span v-if="item.DoorBell">
                          <span class="gfp-adv-pages-account-select-address-dialog-body-list-item-address-title--text">
                            Κουδούνι:
                          </span> {{ item.DoorBell }}
                        </span>
                      </v-list-tile-sub-title>

                      <v-list-tile-sub-title
                        v-if="item.Comment"
                        class="gfp-adv-pages-account-select-address-dialog-body-list-item-address-text--text"
                      >
                        <span class="gfp-adv-pages-account-select-address-dialog-body-list-item-address-title--text">
                          Οδηγίες:
                        </span> {{ item.Comment }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>

                    <v-list-tile-action class="hidden-xs-only">
                      <v-icon class="success--text">
                        my_location
                      </v-icon>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider />
                </v-list>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-3 gfp-adv-pages-account-select-address-dialog-actions-background gfp-adv-pages-account-select-address-dialog-actions-text--text">
          <v-spacer />
          <v-btn
            color="gfp-adv-pages-account-select-address-dialog-actions-button-cancel-background"
            outline
            @click="onCancelButtonClick"
          >
            {{ $t('Buttons.Cancel') | sanitizeDiacritics }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import AppData              from '../../mixins/appdata'
import { getContrastColor } from '@/lib/color'
import AddressCommon        from '@/mixins/maps/addressCommon'

export default {
  name      : 'AccountSelectAddressDialog',
  components: {},
  mixins    : [AppData, AddressCommon],
  props     : ['selectedAddress', 'selectAddressDialogVisible'],
  data () {
    return {
      selectedAddressId: null
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.selectAddressDialogVisible
      },
      set (val) {
        this.$emit('update:selectAddressDialogVisible', val)
      }
    },
    currentAddress: {
      get () {
        return this.selectedAddress
      },
      set (val) {
        this.$emit('update:selectedAddress', val)
      }
    }
  },
  methods: {
    getContrastColor,
    async setAddress (address) {
      const result = await this.isPlaceInsideDeliveryAreas(this.addressToPlaceObject(address))

      if (result) {
        this.isVisible = false
        this.currentAddress = address
      } else {
        this.notifyUserOutOfBoundsAddress()
      }
    },
    onEditAddressButtonClick (address) {
      this.isVisible = false
      this.currentAddress = address
    },
    onNewAddressButtonClick () {
      this.isVisible = false
    },
    onCancelButtonClick () {
      this.isVisible = false
    }
  }
}
</script>
