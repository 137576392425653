export default {
  Account: {
    Addresses: 'Διευθύνσεις',
    Gdpr     : 'Προσωπικά Δεδομένα (GDPR)',
    Login    : 'Είσοδος',
    Logout   : 'Έξοδος',
    Manage   : {
      Delete: {
        ConfirmationDialog: {
          Body   : 'Θα λάβετε PIN στο email σας για να επιβεβαιώσετε την οριστική διαγραφή του λογαριασμού σας.',
          Cancel : 'Ακύρωση',
          Confirm: 'Επιβεβαίωση',
          Title  : 'Αποστολή PIN'
        },
        DeleteDialog: {
          Body   : 'Εισάγετε το PIN που λάβετε στο email σας για να επιβεβαιώσετε την οριστική διαγραφή του λογαριασμού σας.',
          Cancel : 'Ακύρωση',
          Confirm: 'Οριστική Διαγραφή Λογαριασμού',
          Title  : 'Εισαγωγή PIN'
        },
        Title: 'Διαγραφή'
      },
      Disable: {
        ConfirmationDialog: {
          Body   : 'Θα λάβετε PIN στο email σας για να επιβεβαιώσετε την απενεργοποίηση του λογαριασμού σας.',
          Cancel : 'Ακύρωση',
          Confirm: 'Επιβεβαίωση',
          Send   : 'Αποστολή',
          Title  : 'Αποστολή PIN'
        },
        DisableDialog: {
          Body   : 'Εισάγετε το PIN που λάβετε στο email σας για να επιβεβαιώσετε την απενεργοποίηση του λογαριασμού σας.',
          Cancel : 'Ακύρωση',
          Confirm: 'Απενεργοποίηση Λογαριασμού',
          Title  : 'Εισαγωγή PIN'
        },
        Title  : 'Απενεργοποίηση',
        Warning: 'Ο λογαριασμός σας είναι απενεργοποιημένος.'
      },
      Enable: {
        ConfirmationDialog: {
          Body   : 'Θα λάβετε PIN στο email σας για να επιβεβαιώσετε την ενεργοποίηση του λογαριασμού σας.',
          Cancel : 'Ακύρωση',
          Confirm: 'Επιβεβαίωση',
          Title  : 'Αποστολή PIN'
        },
        EnableDialog: {
          Body   : 'Εισάγετε το PIN που λάβετε στο email σας για να επιβεβαιώσετε την ενεργοποίηση του λογαριασμού σας.',
          Cancel : 'Ακύρωση',
          Confirm: 'Ενεργοποίηση Λογαριασμού',
          Title  : 'Εισαγωγή PIN'
        },
        Title    : 'Ενεργοποίηση',
        TitleLong: 'Ενεργοποίηση Λογαριασμού'
      },
      Export: {
        ConfirmationDialog: {
          Body   : 'Θα λάβετε PIN στο email σας για να επιβεβαιώσετε την εξαγωγή δεδομένων του λογαριασμού σας.',
          Cancel : 'Ακύρωση',
          Confirm: 'Επιβεβαίωση',
          Title  : 'Αποστολή PIN'
        },
        ExportDialog: {
          Body   : 'Εισάγετε το PIN που λάβετε στο email σας για να επιβεβαιώσετε την εξαγωγή δεδομένων του λογαριασμού σας.',
          Cancel : 'Ακύρωση',
          Confirm: 'Εξαγωγή Δεδομένων',
          Title  : 'Εισαγωγή PIN'
        },
        Title: 'Εξαγωγή'
      },
      FieldPin: {
        Hint : 'Το PIN πρέπει να είναι 6 αριθμοί',
        Label: 'Εισαγωγή PIN'
      },
      Title: 'Διαχείριση Λογαριασμού'
    },
    Orders       : 'Παραγγελίες',
    Profile      : 'Προφίλ',
    Register     : 'Εγγραφή',
    Title        : 'Ο λογαριασμός μου',
    UserAddresses: {
      AddEditDialog: {
        Add: {
          Subtitle: 'Δημιουργία Νέας Διεύθυνσης',
          Title   : 'Προσθήκη Διεύθυνσης'
        },
        Default: 'Προεπιλεγμένη Διεύθυνση',
        Edit   : {
          Subtitle: 'Τροποποίηση Στοιχείων Διεύθυνσης',
          Title   : 'Επεξεργασία Διεύθυνσης'
        },
        UseCustom: 'Δεν βρίσκεις την διεύθυνση σου στο χάρτη;'
      },
      Comment      : 'Οδηγίες',
      ConfirmDelete: {
        Body : 'Θέλετε να διαγράψετε οριστικά τη διεύθυνση;',
        Title: 'Διαγραφή Διεύθυνσης;'
      },
      Default      : 'Προεπιλεγμένη',
      DoorBell     : 'Κουδούνι',
      Floor        : 'Όροφος',
      NoDataSubText: 'Δημιουργήστε μια νέα διεύθυνση',
      NoDataText   : 'Δεν Υπάρχουν Διευθύνσεις',
      SelectDialog : {
        Subtitle: 'Επιλέξτε Διεύθυνση',
        Title   : 'Επιλογή Διεύθυνσης'
      }
    },
    UserOrders: {
      Unit: {
        Label: 'Μονάδα Μέτρησης',
        Hint : 'Επιλέξτε μονάδα μέτρησης προϊόντος',
        Icon : 'mdi-counter',
        Error: {
          Required: 'Πρέπει να επιλέξετε μονάδα μέτρησης.'
        },
        Units: [
          {
            Id      : 1,
            Name    : 'Τεμάχιο (Τμχ)',
            BaseUnit: 'Τμχ',
            RateUnit: 'Τμχ',
            Rate    : 1,
            Divisor : 1.000000
          },
          {
            Id      : 2,
            Name    : 'Κιλό (kg)',
            BaseUnit: 'kg',
            RateUnit: 'g',
            Rate    : 1000,
            Divisor : 0.001000
          },
          {
            Id      : 3,
            Name    : 'Λίτρο (L)',
            BaseUnit: 'L',
            RateUnit: 'ml',
            Rate    : 1000,
            Divisor : 0.001000
          }
        ]
      },
      Details: {
        Date    : 'Ημερομηνία',
        Subtitle: 'Στοιχεία Παραγγελίας',
        Time    : 'Ώρα',
        Title   : 'Παραγγελία'
      },
      Pagination: {
        NoDataSubText  : 'Κάνε μία νέα παραγγελία',
        NoDataText     : 'Δεν Υπάρχουν Παραγγελίες',
        NoResultsText  : 'Δεν Βρέθηκαν Παραγγελίες',
        RowsPerPageText: 'Παραγγελίες Ανά Σελίδα'
      },
      Status: {
        0: {
          Desc : 'Η πληρωμή της παραγγελίας σας βρίσκεται σε διαδικασία επεξεργασίας.',
          Info : 'Θα ενημερωθείτε για την εξέλιξη της παραγγελίας σας.',
          Title: 'Πληρωμή'
        },
        11: {
          Desc : 'Η παραγγελία έχει σταλεί στο κατάστημα προς αποδοχή.',
          Info : 'Θα ενημερωθείτε για την εξέλιξη της παραγγελίας σας.',
          Title: 'Ελήφθη'
        },
        12: {
          Desc : 'Η παραγγελία είναι σε εκκρεμότητα',
          Info : '',
          Title: 'Σε Εκκρεμότητα'
        },
        13: {
          Desc : 'Η παραγγελία προετοιμάζεται.',
          Info : '',
          Title: 'Προετοιμάζεται'
        },
        14: {
          Desc : 'Η παραγγελία παραδόθηκε.',
          Info : '',
          Title: 'Παραδόθηκε'
        },
        15: {
          Desc : 'Η παραγγελία ολοκληρώθηκε.',
          Info : '',
          Title: 'Ολοκληρώθηκε'
        },
        19: {
          Desc  : 'Η παραγγελία ακυρώθηκε από το κατάστημα.',
          Info  : 'Για οποιαδήποτε πληροφορία ή διευκρίνιση παρακαλούμε επικοινωνήστε με το κατάστημα.',
          Refund: 'Έχει γίνει επιστροφή του ποσού ({orderTotal}) της παραγγελίας σας',
          Title : 'Ακυρώθηκε'
        },
        20: {
          Desc : '',
          Info : '',
          Title: 'Τυπώθηκε'
        },
        21: {
          Desc : 'Η παραγγελία έγινε αποδεκτή από το κατάστημα.',
          Info : 'Σύντομα θα λάβετε ενημερωτικό email με την παραγγελία σας.',
          Title: 'Αποδεκτή'
        },
        Title: 'Κατάσταση Παραγγελίας'
      },
      Type: {
        1: 'Εκτιμώμενος χρόνος παράδοσης {time}',
        2: 'Εκτιμώμενος χρόνος παραλαβής {time}'
      }
    },
    UserProfile: {
      ChangePassword: 'Αλλαγή κωδικού πρόσβασης',
      Info          : 'Στοιχεία',
      Notifications : 'Ενημερώσεις'
    }
  },
  Buttons: {
    Add          : 'Προσθήκη',
    AddAddress   : 'Προσθήκη Διεύθυνσης',
    Cancel       : 'Ακύρωση',
    Change       : 'Αλλαγή',
    ChangeAddress: 'Αλλαγή Διεύθυνσης',
    ChangeInfo   : 'Αλλαγή Στοιχείων',
    Close        : 'Κλείσιμο',
    Complete     : 'Ολοκλήρωση',
    Confirm      : 'Επιβεβαίωση',
    Delete       : 'Διαγραφή',
    Edit         : 'Επεξεργασία',
    More         : 'Περισσότερα',
    Next         : 'Επόμενο',
    No           : 'Όχι',
    Payment      : 'Πληρωμή',
    Quantity     : 'Ποσότητα',
    Save         : 'Αποθήκευση',
    Update       : 'Ενημέρωση',
    Yes          : 'Ναι'
  },
  Cart: {
    Buy   : 'Αγορά',
    Coupon: {
      Button: 'Εφαρμογή Κουπονιού',
      Label : 'Εισάγετε τον κωδικό κουπονιού',
      Name  : 'Κουπόνι',
      Title : 'Έχεις κουπόνι;'
    },
    Empty: {
      Subtitle: 'Προσθέστε προϊόντα από τον κατάλογο',
      Title   : 'Άδειο Καλάθι'
    },
    Error: {
      MenuItemOption: {
        Between: {
          Message: 'Παρακαλώ κάντε από {MinChoices} έως {MaxChoices} επιλογές | Παρακαλώ κάντε από {MinChoices} έως {MaxChoices} επιλογή | Παρακαλώ κάντε από {MinChoices} έως {MaxChoices} επιλογές'
        },
        MaxChoices: {
          Message: 'Παρακαλώ κάντε μέχρι {MaxChoices} επιλογές | Παρακαλώ κάντε μέχρι {MaxChoices} επιλογή | Παρακαλώ κάντε μέχρι {MaxChoices} επιλογές'
        },
        MinChoices: {
          Message: 'Παρακαλώ κάντε {MinChoices} επιλογές | Παρακαλώ κάντε {MinChoices} επιλογή | Παρακαλώ κάντε {MinChoices} επιλογές'
        },
        MinChoicesRequired: {
          Message: 'Παρακαλώ κάντε τουλάχιστον {MinChoices} επιλογές | Παρακαλώ κάντε τουλάχιστον {MinChoices} επιλογή | Παρακαλώ κάντε τουλάχιστον {MinChoices} επιλογές'
        },
        MinimumQuantity: {
          Body : 'Το απόθεμα για το προϊόν «{menuItem}» είναι μικρότερο από την ελάχιστη ποσότητα ({minimumQuantity}) παραγγελίας.',
          Title: ''
        }
      },
      Stock: {
        Invalid: {
          Body : 'Δεν υπάρχει διαθέσιμο απόθεμα για το προϊόν «{menuItem}».',
          Title: ''
        },
        MinimumQuantity: {
          Body : 'Το απόθεμα για το προϊόν «{menuItem}» είναι μικρότερο από την ελάχιστη ποσότητα ({minimumQuantity}) παραγγελίας.',
          Title: ''
        }
      }
    },
    OrderType: {
      Collection: 'Παραλαβή',
      Delivery  : 'Διανομή',
      Minutes   : 'σε {min}',
      Warning   : 'Παραλαβή παραγγελίας από το κατάστημα'
    },
    PaymentType: {
      Card: 'Κάρτα',
      Cash: 'Μετρητά'
    },
    Tip: {
      Amounts: [
        0.5,
        1.0,
        1.5,
        2.0,
        2.5,
        3.0,
        3.5,
        4.0,
        4.5,
        5.0
      ],
      Icon : 'redeem',
      Title: 'Tip'
    },
    Title: 'Το Καλάθι μου'
  },
  CategoriesBlock: {
    ButtonTitle: 'ΔΕΣ ΤΟΝ ΚΑΤΑΛΟΓΟ',
    Title      : 'ΠΑΡΑΓΓΕΙΛΕ ΤΩΡΑ!'
  },
  Checkout: {
    Details: {
      Address      : 'Διεύθυνση Παράδοσης',
      Info         : 'Στοιχεία',
      Notifications: {
        Confirm             : 'Παρακαλούμε επιβεβαιώστε ότι η διεύθυνσή σας έχει συμπληρωθεί σωστά.',
        ConfirmLatLngAddress: 'Παρακαλούμε επιβεβαιώστε ότι η πινέζα βρίσκεται στο σωστό σημείο στο χάρτη και ότι η διεύθυνσή σας έχει συμπληρωθεί σωστά.',
        Login               : 'Έχετε ήδη λογαριασμό;',
        Logout              : 'Καλώς ήρθατε πίσω {user}. Δεν είστε εσείς;'
      },
      Title: 'Στοιχεία παραγγελίας'
    },
    Error: {
      Address: {
        OutOfBounds: {
          Body : 'Η διεύθυνση δεν είναι μέσα στα όρια διανομής του καταστήματος.',
          Title: ''
        },
        Select: {
          Body : 'Πρέπει να επιλέξετε ή να προσθέσετε διεύθυνση.',
          Title: ''
        }
      },
      Generic: {
        Body : 'Παρακαλώ ελέγξτε ότι συμπληρώσατε σωστά τα πεδία.',
        Title: ''
      },
      InvalidCard: {
        Body : 'Παρακαλώ ελέγξτε τα στοιχεία και δοκιμάστε ξανά ή επιλέξτε άλλη μέθοδο πληρωμής.',
        Title: 'Ανεπιτυχής Πληρωμή'
      },
      PaymentMethod: {
        Body : 'Παρακαλώ επιλέξτε μέθοδο πληρωμής.',
        Title: ''
      },
      PaymentMethodMinimumOrder: {
        Body   : 'Χρειάζεστε {minimumOrderTotalDiff} ακόμα για την ελάχιστη παραγγελία. Παρακαλώ προσθέστε περισσότερα προϊόντα στο καλάθι σας.',
        BodyAlt: 'Χρειάζεσαι {minimumOrderTotalDiff} ακόμα για την ελάχιστη παραγγελία.',
        Title  : 'Ελάχιστη Παραγγελία'
      },
      AutoApplyCouponMinimumOrder: {
        Body   : 'Χρειάζεστε {minimumOrderTotalDiff} ακόμα για εκπτωτικό κουπόνι {discount}. Παρακαλώ προσθέστε περισσότερα προϊόντα στο καλάθι σας.',
        BodyAlt: 'Με {minimumOrderTotalDiff} ακόμα ενεργοποιείς έκπτωση {discount}.',
        Title  : 'Ελάχιστη Παραγγελία'
      }
    },
    Payment: {
      Form: {
        CardCVV   : 'CVV',
        CardHolder: 'Όνομα κατόχου',
        CardMonth : 'Μήνας λήξης',
        CardNumber: 'Αριθμός κάρτας',
        CardYear  : 'Έτος λήξης'
      },
      PaymentMethod            : 'Μέθοδος Πληρωμής',
      PaymentMethodMinimumOrder: 'Ελάχιστη Παραγγελία',
      SecurityMessage          : 'Ασφάλεια Συναλλαγών με 128-bit Κρυπτογράφηση.',
      AdBlockerWarning         : 'Βεβαιωθείτε ότι έχετε απενεργοποιήσει οποιοδήποτε<br/>Ad Blocker που μπορείτε να χρησιμοποιήσετε.',
      Subtitle                 : 'Στοιχεία Κάρτας',
      Title                    : 'Πληρωμή'
    },
    Success: {
      Warning: 'Το κατάστημα έλαβε την παραγγελία σας και σύντομα Θα ενημερωθείτε για την εξέλιξη της.',
      Error  : 'Η παραγγελία δεν βρέθηκε.'
    },
    Title: 'Ολοκλήρωση παραγγελίας'
  },
  Common: {
    Error: {
      StoreClosed: 'Το κατάστημα δεν εξυπηρετεί online παραγγελίες αυτή τη στιγμή.',
      Generic    : {
        Body : 'Παρακαλώ ελέγξτε ότι συμπληρώσατε σωστά τα πεδία.',
        Title: ''
      }
    },
    Login: {
      Fail   : 'Μη επιτυχής σύνδεση! Παρακαλώ προσπαθήστε ξανά.',
      Success: 'Έχετε συνδεθεί με επιτυχία'
    },
    Logout: {
      Fail   : '',
      Success: 'Έχετε αποσυνδεθεί με επιτυχία'
    },
    Misc: {
      And: '&'
    },
    Map: {
      Title    : 'Χάρτης',
      Satellite: 'Δορυφόρος'
    }
  },
  Contact: {
    Button: 'Αποστολή',
    Meta  : {
      Desc : 'Επικοινωνήστε με το κατάστημα {locationName} στην περιοχή {locationArea}.',
      Title: 'Επικοινωνία'
    },
    MoreTitle: 'Επιπλέον Πληροφορίες',
    Subtitle : 'Στοιχεία Επικοινωνίας',
    Title    : 'Επικοινωνία'
  },
  CookieLaw: {
    AcceptButton: 'Αποδοχή',
    Message     : 'Η ιστοσελίδα χρησιμοποιεί cookies για βελτιστοποίηση της εμπειρίας του χρήστη. Με τη χρήση αυτού του ιστοτόπου, αποδέχεστε τη χρήση των cookies. Για περισσότερες πληροφορίες δείτε την'
  },
  Demo: {
    Alert  : 'ΠΡΟΣΟΧΗ: Αυτό είναι demo και δεν θα εκτελεστεί καμία παραγγελία !',
    Warning: {
      Body : 'Αυτό είναι demo και κάποιες λειτουργίες είναι περιορισμένες !',
      Title: 'ΠΡΟΣΟΧΗ'
    }
  },
  FeaturedCategoriesBlock: {
    CardButtonTitle: 'Δες όλα τα προϊόντα',
    Title          : 'Δημοφιλείς κατηγορίες'
  },
  FeaturedMenuBlock: {
    CardButtonTitle: 'Το θελω!',
    Title          : 'Προτεινόμενα προϊόντα'
  },
  Footer: {
    Gemi: {
      Label: 'Γ.Ε.ΜΗ',
      Desc : 'Αρ. Γ.Ε.ΜΗ.'
    },
    CreditCards: 'Ασφαλείς Πληρωμές',
    MobileApp  : {
      Android: {
        Title: 'Download Android Mobile App'
      },
      IOS: {
        Title: 'Download iOS Mobile App'
      },
      SubTitle: 'Παραγγείλτε τώρα από το κινητό σας (iOS ή Android) εύκολα και γρήγορα.',
      Title   : 'Κατεβάστε το App'
    },
    SocialTitle: 'Συνδεθείτε μαζί μας στα social media!',
    Store      : {
      About        : 'Για Εμάς',
      Location     : 'Που Βρισκόμαστε',
      OpenHours    : 'Ωράριο Λειτουργίας',
      DeliveryAreas: 'Περιοχές Διανομής'
    },
    Stores: 'Καταστήματα'
  },
  ForgotPasswordDialog: {
    Button       : 'Επαναφορά Κωδικού',
    RememberLogin: 'Θυμάστε τον κωδικό πρόσβασης;',
    Subtitle     : 'Θα σας στείλουμε ένα email με έναν νέο κωδικό πρόσβασης.',
    Title        : 'Υπενθύμιση κωδικού'
  },
  Form: {
    AddressComments: {
      Hint : 'Επιπλέον οδηγίες για την διεύθυνση.',
      Label: 'Οδηγίες διεύθυνσης'
    },
    AutoComplete: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε την διεύθυνση σας',
        Valid    : 'Η διεύθυνση πρέπει να είναι μέσα στα όρια διανομής του καταστήματος'
      },
      Hint : 'Εισάγετε την διεύθυνση σας.',
      Label: 'Εισάγετε την διεύθυνση σας'
    },
    City: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε περιοχή',
        Valid    : 'Η περιοχή πρέπει να είναι από 2 έως 120 χαρακτήρες'
      },
      Hint : 'Εισάγετε την περιοχή της διεύθυνσης.',
      Label: 'Περιοχή'
    },
    Comments: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε το μήνυμα σας'
      },
      Hint : 'Εισάγετε το μήνυμα σας.',
      Label: 'Σχόλια'
    },
    DoorBell: {
      Hint : 'Όνομα που αναγράφεται στο κουδούνι',
      Label: 'Κουδούνι'
    },
    Email: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε E-mail',
        Valid    : 'Το E-mail πρέπει να είναι έγκυρο'
      },
      Hint : 'Εισάγετε το email σας.',
      Label: 'Διεύθυνση Email'
    },
    Firstname: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε όνομα',
        Valid    : 'Το όνομα πρέπει να είναι από 2 έως 32 χαρακτήρες'
      },
      Hint : 'Εισάγετε το όνομα σας.',
      Label: 'Όνομα'
    },
    Floor: {
      Hint : 'πχ. 1ος, Ισόγειο, Μεζονέτα, Μονοκατοικια, κλπ.',
      Label: 'Όροφος'
    },
    FriendlyName: {
      Hint : 'πχ. Σπίτι, Γραφείο, κλπ.',
      Label: 'Φιλική Ονομασία'
    },
    Lastname: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε επίθετο',
        Valid    : 'Το επίθετο πρέπει να είναι από 2 έως 32 χαρακτήρες'
      },
      Hint : 'Εισάγετε το επώνυμο σας.',
      Label: 'Επώνυμο'
    },
    MenuComments: {
      Hint : 'Επιπλέον οδηγίες και σχόλια για το προϊόν',
      Label: 'Σχόλια'
    },
    Notifications: {
      Email: 'Μέσω Email',
      Phone: 'Μέσω Τηλεφώνου',
      Push : 'Μέσω Push Notifications',
      Sms  : 'Μέσω SMS',
      Title: 'Ενημερώσεις για προσφορές'
    },
    Number: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε αριθμό',
        Valid    : 'Ο αριθμός πρέπει να είναι από 1 έως 10 χαρακτήρες'
      },
      Hint : 'Εισάγετε τον αριθμό της διεύθυνσης.',
      Label: 'Αριθμός'
    },
    Password: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε κωδικό',
        Valid    : 'Ο κωδικός πρέπει να είναι από 6 έως 32 χαρακτήρες'
      },
      Hint : 'Εισάγετε το κωδικό σας.',
      Label: 'Κωδικός Πρόσβασης'
    },
    PasswordConfirm: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε κωδικό',
        Match    : 'Οι δύο κωδικοί πρέπει να είναι ίδιοι',
        Valid    : 'Ο κωδικός πρέπει να είναι από 6 έως 32 χαρακτήρες'
      },
      Hint : 'Εισάγετε το κωδικό σας για επιβεβαίωση.',
      Label: 'Επιβεβαίωση κωδικού πρόσβασης'
    },
    PasswordCurrent: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε κωδικό',
        Valid    : 'Ο κωδικός πρέπει να είναι από 6 έως 32 χαρακτήρες'
      },
      Hint : 'Εισάγετε το Προηγούμενος κωδικό σας.',
      Label: 'Προηγούμενος Κωδικός Πρόσβασης'
    },
    PasswordNew: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε κωδικό',
        Valid    : 'Ο κωδικός πρέπει να είναι από 6 έως 32 χαρακτήρες'
      },
      Hint : 'Εισάγετε το νέο κωδικό σας.',
      Label: 'Νέος Κωδικός Πρόσβασης'
    },
    PasswordNewConfirm: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε κωδικό',
        Match    : 'Οι δύο κωδικοί πρέπει να είναι ίδιοι',
        Valid    : 'Ο κωδικός πρέπει να είναι από 6 έως 32 χαρακτήρες'
      },
      Hint : 'Εισάγετε το νέο κωδικό σας για επιβεβαίωση.',
      Label: 'Επιβεβαίωση νέου κωδικού πρόσβασης'
    },
    Postcode: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε τ.κ.',
        Valid    : 'Το Τ.Κ. πρέπει να είναι έγκυρο'
      },
      Hint : 'πχ. 153 54',
      Label: 'Τ.Κ.'
    },
    Street: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε οδό',
        Valid    : 'Η οδος πρέπει να είναι από 2 έως 120 χαρακτήρες'
      },
      Hint : 'Εισάγετε την οδό της διεύθυνσης.',
      Label: 'Οδός'
    },
    Telephone: {
      Errors: {
        Mandatory: 'Παρακαλώ συμπληρώστε τηλέφωνο',
        Valid    : 'Το τηλέφωνο πρέπει να είναι 8 έως 16 αριθμοί'
      },
      Hint : 'Εισάγετε το τηλέφωνο σας.',
      Label: 'Τηλέφωνο'
    },
    Terms: {
      Agree : 'Συμφωνώ με τους όρους και προϋποθέσεις που ορίζονται από αυτό το site.',
      Errors: {
        Mandatory: 'Πρέπει να συμφωνήσετε με τους όρους'
      },
      Note : '<span class="font-weight-bold">Σημείωση:</span> Επικοινωνώντας μαζί μας μέσω της παραπάνω φόρμας αποδέχεστε να επικοινωνήσουμε μαζί σας μέσω ηλεκτρονικού ταχυδρομείου ή / και τηλεφώνου.',
      Title: 'Όροι και προϋποθέσεις'
    }
  },
  GdprDialog: {
    Button  : 'Έλεγχος',
    Subtitle: 'Εισάγετε το email σας για να γίνει έλεγχος του λογαριασμού σας.',
    Title   : 'Διαχείριση Προσωπικών Δεδομένων'
  },
  Home: {
    Meta: {
      Desc : 'Δες τον κατάλογο και παράγγειλε Online από το κατάστημα {locationName} στην περιοχή {locationArea}, εύκολα, γρήγορα και με αποκλειστικές προσφορές.',
      Img  : '',
      Title: 'Αρχική'
    },
    Title: 'Αρχική'
  },
  LoginDialog: {
    Facebook      : 'Είσοδος με Facebook',
    ForgotPassword: 'Ξεχάσατε τον κωδικό σας;',
    Google        : 'Είσοδος με Google',
    LoginButton   : 'Είσοδος',
    NoSubtitle    : 'Δημιουργήστε το λογαριασμό σας γρήγορα και εύκολα και αποκτήστε πρόσβαση σε αποκλειστικές προσφορές!',
    NoTitle       : 'Όχι, δεν έχω λογαριασμό',
    RegisterButton: 'Δημιουργία Λογαριασμού',
    Subtitle      : 'Αν έχετε λογαριασμό συνδεθείτε τώρα, διαφορετικά εγγραφείτε',
    Title         : 'Έχετε λογαριασμό;',
    YesTitle      : 'Ναι, έχω ήδη λογαριασμό!'
  },
  Menu: {
    All   : 'Όλα',
    Search: 'Αναζήτηση Προϊόντος',
    Title : 'Κατάλογος'
  },
  NeedHelpBlock: {
    Subtitle: 'Καλέστε μας τώρα!',
    Title   : 'Χρειάζεστε Βοήθεια;'
  },
  OffersBlock: {
    BlockButtonTitle: 'Δες όλες τις προσφορές',
    Subtitle        : 'Επιλέξτε την προσφορά που σας ταιριάζει!',
    Title           : 'Προσφορές'
  },
  Order: {
    InternalDelivery: 'Η παραγγελία θα παραδοθεί περίπου',
    OrderTypeTime   : {
      Day: {
        Suffix: 'ημ',
        Title : 'ημέρα | ημέρες'
      },
      Hour: {
        Suffix: 'ώ',
        Title : 'ώρα | ώρες'
      },
      Minute: {
        Suffix: 'λ',
        Title : 'λεπτό | λεπτά'
      }
    },
    QrSourceType: {
      BTH: {
        Id   : '7',
        Title: 'Περίπτερο',
        Type : 'BTH'
      },
      HRM: {
        Id   : '8',
        Title: 'Δωμάτιο',
        Type : 'HRM'
      },
      SNB: {
        Id   : '9',
        Title: 'Ξαπλώστρα',
        Type : 'SNB'
      },
      TBL: {
        Id   : '6',
        Title: 'Τραπέζι',
        Type : 'TBL'
      }
    }
  },
  Pagination: {
    NoDataText     : 'Δεν Υπάρχουν Διαθέσιμα Δεδομένα',
    NoResultsText  : 'Δεν Βρέθηκαν Αποτελέσματα',
    RowsPerPageText: 'Εγγραφές Ανά Σελίδα'
  },
  PartnersBlock: {
    Title: 'Συνεργάτες μας'
  },
  Policies: {
    Title: 'Όροι και Προϋποθέσεις'
  },
  PoliciesAllergens: {
    Meta: {
      Desc : 'Αλλεργιογόνα καταλόγου καταστήματος {locationName} στην περιοχή {locationArea}.',
      Title: 'Αλλεργιογόνα'
    }
  },
  PoliciesCookies: {
    Meta: {
      Desc : 'Πολιτική cookies διαδικτυακού τόπου καταστήματος {locationName} στην περιοχή {locationArea}.',
      Title: 'Πολιτική Cookies'
    }
  },
  PoliciesCopyright: {
    Meta: {
      Desc : 'Πολιτική προστασίας διαδικτυακού τόπου καταστήματος {locationName} στην περιοχή {locationArea}.',
      Title: 'Πολιτική Προστασίας'
    }
  },
  PoliciesPayments: {
    Meta: {
      Desc : 'Τρόποι πληρωμής διαδικτυακού τόπου καταστήματος {locationName} στην περιοχή {locationArea}.',
      Title: 'Τρόποι Πληρωμής'
    }
  },
  PoliciesPrivacy: {
    Meta: {
      Desc : 'Πολιτική απορρήτου διαδικτυακού τόπου καταστήματος {locationName} στην περιοχή {locationArea}.',
      Title: 'Πολιτική Απορρήτου'
    }
  },
  PoliciesReturns: {
    Meta: {
      Desc : 'Πολιτική επιστροφής χρημάτων διαδικτυακού τόπου καταστήματος {locationName} στην περιοχή {locationArea}.',
      Title: 'Πολιτική Eπιστροφής Xρημάτων'
    }
  },
  PoliciesTerms: {
    Meta: {
      Desc : 'Όροι χρήσης διαδικτυακού τόπου καταστήματος {locationName} στην περιοχή {locationArea}.',
      Title: 'Όροι Χρήσης'
    }
  },
  PushNotifications: {
    notifyButton: {
      dialog: {
        blocked: {
          message: 'Ακολουθήστε αυτές τις οδηγίες για να επιτρέψετε τη λήψη ειδοποιήσεων:',
          title  : 'Κατάργηση αποκλεισμού λήψης ειδοποιήσεων'
        },
        main: {
          button: {
            subscribe  : 'ΕΓΓΡΑΦΗ',
            unsubscribe: 'ΚΑΤΑΡΓΗΣΗ'
          },
          title: 'Διαχείριση ειδοποιήσεων'
        }
      },
      message: {
        action: {
          resubscribed: 'Έχετε εγγραφεί να λαμβάνετε ειδοποιήσεις',
          subscribed  : 'Ευχαριστούμε που εγγραφήκατε!',
          unsubscribed: 'Δεν θα λάβετε ξανά ειδοποιήσεις'
        },
        prenotify: 'Κάντε κλικ για εγγραφή να λαμβάνετε ειδοποιήσεις'
      },
      tip: {
        state: {
          blocked     : 'Έχετε αποκλείσει να λαμβάνετε ειδοποιήσεις',
          subscribed  : 'Έχετε εγγραφεί να λαμβάνετε ειδοποιήσεις',
          unsubscribed: 'Εγγραφείτε να λαμβάνετε ειδοποιήσεις'
        }
      }
    },
    promptOptions: {
      actionMessage       : 'Θα θέλαμε να σας δείχνουμε ειδοποιήσεις με νέα και προσφορές',
      acceptButton        : 'Ναι',
      cancelButton        : 'Όχι',
      emailLabel          : 'Email',
      smsLabel            : 'Κινητό',
      updateMessage       : 'Ενημέρωση προτιμήσεων ειδοποιήσεων',
      negativeUpdateButton: 'Ακύρωση',
      positiveUpdateButton: 'Αποθήκευση',
      confirmMessage      : 'Ευχαριστούμε!'
    },
    welcomeNotification: {
      message: 'Ευχαριστούμε που εγγραφήκατε!'
    }
  },
  RegisterDialog: {
    Button       : 'Εγγραφείτε',
    RememberLogin: 'Έχετε ήδη λογαριασμό;',
    Subtitle     : 'Με την εγγραφή σου αποκτάς πρόσβαση σε αποκλειστικές προσφορές',
    Terms        : {
      Agree : 'Κάνοντας εγγραφή, συμφωνείτε με τους όρους και προϋποθέσεις που ορίζονται από αυτό το site, συμπεριλαμβανομένων την χρήση cookies.',
      Errors: {
        Mandatory: 'Πρέπει να συμφωνήσετε με τους όρους'
      },
      Title: 'Όροι και προϋποθέσεις'
    },
    Title: 'Εγγράψου τώρα!'
  },
  WorkingHoursBlock: {
    Closed    : 'Κλειστά',
    Collection: 'Ωράριο Παραλαβής',
    Delivery  : 'Ωράριο Διανομής',
    Open      : 'Ανοιχτά',
    Title     : 'Ωράριο Λειτουργίας'
  },
  DeliveryAreasBlock: {
    Title   : 'Περιοχές Διανομής',
    Subtitle: 'Περιοχές Διανομής',
    Area    : 'Περιοχή',
    Above   : 'Πάνω από',
    Below   : 'Κάτω από',
    Charge  : 'Χρέωση',
    Free    : 'Δωρεάν'
  },
  MenuOptionsDialog: {
    SelectedOptions: 'Έχεις επιλέξει'
  }
}
