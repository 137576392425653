<template>
  <v-container
    fluid
    pa-0
    class="gfp-adv-pages-catalog-background gfp-adv-pages-catalog-text--text"
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <!-- HEADER -->
        <page-header
          id="pageHeader"
          :address="AddressTitle"
          :images="appConfig.LOCATION_DATA.Gallery.menu"
          :phone1="appConfig.LOCATION_DATA.Telephone"
          :phone2="appConfig.LOCATION_DATA.Telephone2"
          :phone3="appConfig.LOCATION_DATA.Telephone3"
          :phone4="appConfig.LOCATION_DATA.Telephone4"
          :show-texts="appConfig.LOCATION_DATA.ShowRestaurantInfoCatalog"
          :title="HeaderTitle"
        />
      </v-flex>

      <!-- MOBILE CATEGORIES TABS BLOCK -->
      <v-flex
        v-if="isMobile"
        xs12
      >
        <categories-tabs :slug="slug" />
      </v-flex>

      <v-flex
        lg10
        offset-lg1
        offset-xs0
        xs12
      >
        <v-container
          fluid
          pa-0
        >
          <v-layout
            :class="isMobile ? 'pa-0' : 'pa-3'"
            row
            wrap
          >
            <v-flex
              v-if="!isMobile"
              :class="[hasFeaturedMenus ? '' : 'offset-md0']"
              xl2
              md3
              sm4
            >
              <!-- CATEGORIES BLOCK -->
              <categories-block
                :all-categories="showAllCategories"
                :slug="slug"
              />
            </v-flex>

            <v-flex
              id="categoryDetailsList"
              :class="[hasFeaturedMenus ? 'md6 xl7' : 'md9']"
              sm8
              xs12
            >
              <!-- MENU ITEM BLOCK -->
              <template v-if="menuItemSlug">
                <menu-item-block
                  :menu-item-slug="menuItemSlug"
                  :slug="slug"
                />
              </template>

              <!-- MENU BLOCK -->
              <template v-if="!menuItemSlug">
                <menu-block-large-list-items
                  :all-categories="isMobile ? false : showAllCategories"
                  :has-featured-menus="hasFeaturedMenus"
                  :slug="slug"
                  :left-image="menuBlockListItemLeft"
                />
              </template>
            </v-flex>

            <v-flex
              v-if="$vuetify.breakpoint.mdAndUp && hasFeaturedMenus"
              class="hidden-sm-and-down"
              md3
            >
              <!-- FEATURED MENU BLOCK -->
              <featured-menu-block
                :auto-fetch-data="false"
                :swiper="false"
                card-media-height="210"
                card-text-height="50px"
                title-style="font-size: 20px;"
                block-class="caption"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AppData                 from '@/mixins/appdata'
import PageHeader              from '@/components/common/PageHeader'
import FeaturedMenuBlock       from '@/components/common/FeaturedMenuBlock'
import CategoriesBlock         from '@/components/menu/CategoriesBlock'
import CategoriesTabs          from '@/components/menu/CategoriesTabs'
import MenuItemBlock           from '@/components/menu/MenuItemBlock'
import MenuBlockLargeListItems from '@/components/menu/MenuBlockLargeListItems.vue'

export default {
  name      : 'Menu',
  components: {
    PageHeader,
    MenuBlockLargeListItems,
    FeaturedMenuBlock,
    MenuItemBlock,
    CategoriesBlock,
    CategoriesTabs
  },
  directives: {},

  mixins: [AppData],
  props : {
    menuItemSlug: {
      type    : String,
      required: false
    },
    slug: {
      type    : String,
      required: false
    }
  },
  data () {
    return {
      offsetTop : 0,
      windowSize: {
        x: 0,
        y: 0
      },
      swiperOptions: {
        type   : Object,
        default: () => {
          return {
            slidesPerView: 1,
            spaceBetween : 24,
            loop         : true,
            autoplay     : {
              delay               : 2500,
              disableOnInteraction: true
            }
          }
        }
      }
    }
  },
  computed: {
    HeaderTitle () {
      if (this.multipleLocations?.length) return (this.appConfig?.LOCATION_DATA?.Name || '') + (this.appConfig?.LOCATION_DATA?.City ? ' - ' + this.appConfig?.LOCATION_DATA?.City : '')

      return this.appConfig?.LOCATION_DATA?.Name || ''
    },

    AddressTitle () {
      return ''
    },

    hasFeaturedMenus () {
      return this.dataStoreFeaturedMenus?.length > 0 && !this.menuItemSlug
    },

    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    },

    menuBlockListItemLeft () {
      return this?.appConfig?.LOCATION_DATA?.MenuListItemImageLeft || false
    },

    showAllCategories () {
      const menuCategoriesLimit = parseInt(this.appConfig?.LOCATION_DATA?.ShowMenuCategories)
      if (!menuCategoriesLimit) return false

      return this.dataStoreSelectedCatalog?.ProductsCount <= menuCategoriesLimit
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.$bus.$on('menu-result-success', this.onMenuResultSuccess)

    this.$bus.$emit('init-menu')
  },
  beforeMount () {},
  mounted () {
    if (!this.dataStoreFeaturedMenus.length) {
      window.API.get(window.APICall.featuredMenu)
        .then(response => {
          if (response.data.status === 200) {
            this.$set(this.dataStore.dashboard, 'featuredMenu', response.data.data)
          }
        })
        .catch(e => {

        })
    }

    if (typeof window === 'undefined') return
    window.dispatchEvent(new Event('resize'))
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('menu-result-success', this.onMenuResultSuccess)
  },
  destroyed () {
    const data = JSON.parse(JSON.stringify(this.dataStoreMenu))

    // eslint-disable-next-line no-return-assign
    data.items.map(row => row.Category.ObserveVisibility = false)
    if (data.items.length >= 1) data.categories[0].ObserveVisibility = data.items[0].Category.ObserveVisibility = true
    if (data.items.length >= 2) data.categories[1].ObserveVisibility = data.items[1].Category.ObserveVisibility = true

    window.menu = data
    this.$set(this.dataStore, 'menu', Date.now())
  },

  methods: {
    onMenuResultSuccess () {
      const category = this.dataStoreMenu?.categories?.find(category => (category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Slug === this.slug))
      this.$bus.$emit('menu-category-selected', category || null)
    }
  }
}
</script>

<style scoped>

</style>
