<template>
  <v-flex
    v-if="!orderTotalAmountValid"
    xs12
    class="text-xs-center pb-0 font-weight-bold"
  >
    <div>{{ $t('Checkout.Error.PaymentMethodMinimumOrder.BodyAlt', {minimumOrderTotalDiff: $options.filters.currency(orderMinTotalAmountDiff)}) }}</div>
  </v-flex>
</template>

<script>

import AppData    from '@/mixins/appdata'
import CartCommon from '@/mixins/orders/cartCommon'

export default {
  name      : 'PaymentMinimumOrderWarning',
  components: {},
  directives: {},
  mixins    : [AppData, CartCommon],
  props     : {},
  enums     : {},
  dataModel : null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
