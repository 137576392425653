<template>
  <v-layout
    v-if="appConfig"
    row
    wrap
  >
    <v-flex xs12>
      <v-card color="gfp-adv-common-sidebar-needhelp-body-background">
        <v-toolbar
          card
          class="pl-1"
          height="46"
          prominent
          color="gfp-adv-common-sidebar-needhelp-toolbar-background"
        >
          <v-toolbar-title class="">
            <div class="subheading gfp-adv-common-sidebar-needhelp-toolbar-title--text">
              <strong>{{ $t('NeedHelpBlock.Title') }}</strong>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-icon color="gfp-adv-common-sidebar-needhelp-toolbar-icon">
            help_outline
          </v-icon>
        </v-toolbar>

        <v-divider />

        <v-card-text>
          <v-layout
            align-center
            class="phoneLinks"
            column
          >
            <h3 class="gfp-adv-common-sidebar-needhelp-body-title--text pb-2">
              {{ $t('NeedHelpBlock.Subtitle') }}
            </h3>

            <div
              v-if="appConfig.LOCATION_DATA.Telephone"
              class="gfp-adv-common-sidebar-needhelp-body-text--text title pt-2"
              style="white-space: nowrap"
            >
              <span class="mr-1">
                <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                  <v-chip
                    v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone.Type === 'label'"
                    color="gfp-adv-common-sidebar-needhelp-body-label"
                    class="gfp-adv-common-sidebar-needhelp-body-icon--text"
                    label
                    small
                  >
                    {{ appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone.Value }}
                  </v-chip>

                  <v-img
                    v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone.Type === 'img'"
                    :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone.Value"
                    class="d-inline-block"
                    height="24"
                    width="24"
                  />

                  <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone.Type === 'icon'">
                    <v-icon color="gfp-adv-common-sidebar-needhelp-body-icon"> {{ appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone.Value : 'phone' }}</v-icon>
                  </template>
                </template>

                <template v-else>
                  <v-icon color="gfp-adv-common-sidebar-needhelp-body-icon">phone</v-icon>
                </template>
              </span>
              <a :href="'tel:' + appConfig.LOCATION_DATA.Telephone">
                {{ appConfig.LOCATION_DATA.Telephone }}
              </a>
            </div>

            <div
              v-if="appConfig.LOCATION_DATA.Telephone2"
              class="gfp-adv-common-sidebar-needhelp-body-text--text title pt-2"
              style="white-space: nowrap"
            >
              <span class="mr-1">
                <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                  <v-chip
                    v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone2.Type === 'label'"
                    color="gfp-adv-common-sidebar-needhelp-body-label"
                    class="gfp-adv-common-sidebar-needhelp-body-icon--text"
                    label
                    small
                  >
                    {{ appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone2.Value }}
                  </v-chip>

                  <v-img
                    v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone2.Type === 'img'"
                    :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone2.Value"
                    class="d-inline-block"
                    height="24"
                    width="24"
                  />

                  <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone2.Type === 'icon'">
                    <v-icon color="gfp-adv-common-sidebar-needhelp-body-icon"> {{ appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone2.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone2.Value : 'phone' }}</v-icon>
                  </template>
                </template>

                <template v-else>
                  <v-icon color="gfp-adv-common-sidebar-needhelp-body-icon">phone</v-icon>
                </template>
              </span>
              <a :href="'tel:' + appConfig.LOCATION_DATA.Telephone2">
                {{ appConfig.LOCATION_DATA.Telephone2 }}
              </a>
            </div>

            <div
              v-if="appConfig.LOCATION_DATA.Telephone3"
              class="gfp-adv-common-sidebar-needhelp-body-text--text title pt-2"
              style="white-space: nowrap"
            >
              <span class="mr-1">
                <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                  <v-chip
                    v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone3.Type === 'label'"
                    color="gfp-adv-common-sidebar-needhelp-body-label"
                    class="gfp-adv-common-sidebar-needhelp-body-icon--text"
                    label
                    small
                  >
                    {{ appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone3.Value }}
                  </v-chip>

                  <v-img
                    v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone3.Type === 'img'"
                    :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone3.Value"
                    class="d-inline-block"
                    height="24"
                    width="24"
                  />

                  <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone3.Type === 'icon'">
                    <v-icon color="gfp-adv-common-sidebar-needhelp-body-icon"> {{ appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone3.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone3.Value : 'phone' }}</v-icon>
                  </template>
                </template>

                <template v-else>
                  <v-icon color="gfp-adv-common-sidebar-needhelp-body-icon">phone</v-icon>
                </template>
              </span>
              <a :href="'tel:' + appConfig.LOCATION_DATA.Telephone3">
                {{ appConfig.LOCATION_DATA.Telephone3 }}
              </a>
            </div>

            <div
              v-if="appConfig.LOCATION_DATA.Telephone4"
              class="gfp-adv-common-sidebar-needhelp-body-text--text title pt-2"
              style="white-space: nowrap"
            >
              <span class="mr-1">
                <template v-if="appConfig.LOCATION_DATA.TelephoneIcons">
                  <v-chip
                    v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone4.Type === 'label'"
                    color="gfp-adv-common-sidebar-needhelp-body-label"
                    class="gfp-adv-common-sidebar-needhelp-body-icon--text"
                    label
                    small
                  >
                    {{ appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone4.Value }}
                  </v-chip>

                  <v-img
                    v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone4.Type === 'img'"
                    :src="appConfig.LOCATION_DATA.CdnImagesDefaultUrl + appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone4.Value"
                    class="d-inline-block"
                    height="24"
                    width="24"
                  />

                  <template v-if="appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone4.Type === 'icon'">
                    <v-icon color="gfp-adv-common-sidebar-needhelp-body-icon"> {{ appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone4.Value ? appConfig.LOCATION_DATA.TelephoneIcons.Website.Telephone4.Value : 'phone' }}</v-icon>
                  </template>
                </template>

                <template v-else>
                  <v-icon color="gfp-adv-common-sidebar-needhelp-body-icon">phone</v-icon>
                </template>
              </span>
              <a :href="'tel:' + appConfig.LOCATION_DATA.Telephone4">
                {{ appConfig.LOCATION_DATA.Telephone4 }}
              </a>
            </div>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import AppData from '../../mixins/appdata'

export default {
  name      : 'NeedHelpBlock',
  components: {},
  directives: {},
  mixins    : [AppData],
  props     : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>
/deep/ .phoneLinks a {
  text-decoration : none;
  color           : #9e9e9e !important;
}
</style>
