<template>
  <v-layout
    class="text-xs-justify"
    row
    wrap
  >
    <v-flex
      xs12
      v-html="content"
    />
  </v-layout>
</template>

<script>

export default {
  name      : 'PoliciesReturns',
  components: {},
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {}
  },
  computed: {
    content () {
      if (this.dataStore.pages) {
        if (this.dataStore.pages.PoliciesReturns) {
          return this.dataStore.pages.PoliciesReturns.Content
        }
      }
      return ''
    }
  },
  watch: {},
  created () {
    window.API.get(window.APICall.pageById + 'PoliciesReturns')
      .then(response => {
        if (response.data.status === 200) {
          this.$set(this.dataStore.pages, 'PoliciesReturns', response.data.data)
        }
      })
      .catch(e => {

      })
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
