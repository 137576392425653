<template>
  <v-layout
    v-if="dataStore.workingHours"
    row
    wrap
  >
    <v-flex
      v-if="dataStore.workingHours.hours.opening"
      xs12
    >
      <v-card color="gfp-adv-common-sidebar-working-hours-body-background">
        <v-toolbar
          card
          class="pl-1"
          height="46"
          prominent
          color="gfp-adv-common-sidebar-working-hours-toolbar-background"
        >
          <v-toolbar-title class="">
            <div class="subheading gfp-adv-common-sidebar-working-hours-toolbar-title--text">
              <strong>
                {{ $t('WorkingHoursBlock.Title') }}
              </strong>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-icon color="gfp-adv-common-sidebar-working-hours-toolbar-icon">
            access_time
          </v-icon>
        </v-toolbar>

        <v-divider />

        <div
          v-if="dataStore.workingHours.types.opening==='24_7'"
          class="text-xs-center pa-4"
        >
          <svg
            style="width: 196px;"
            viewBox="1065.998 2559.945 90.199 79.33"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Group_279"
              data-name="Group 279"
              transform="translate(713 92)"
            >
              <g
                id="Group_277"
                data-name="Group 277"
                transform="translate(309.946 -33)"
              >
                <path
                  id="Path_189"
                  class="cls-1"
                  d="M782.8,54.2c.1-.6.1-1.2.2-1.9a34.5,34.5,0,1,0-55.6,25.4"
                  data-name="Path 189"
                  transform="translate(-670 2486)"
                />
                <path
                  id="Path_190"
                  class="cls-2"
                  d="M790.4,48.7l-1.2-1.3-6.5,6.1-5.6-6.8-1.4,1.1,6.8,8.4Z"
                  data-name="Path 190"
                  transform="translate(-670 2486)"
                />
                <path
                  id="Path_191"
                  class="cls-2"
                  d="M725.5,80.1a3.011,3.011,0,1,0-.7-4.2A3.041,3.041,0,0,0,725.5,80.1Z"
                  data-name="Path 191"
                  transform="translate(-670 2486)"
                />
                <text
                  id="_24_7"
                  class="cls-3"
                  data-name="24/7"
                  transform="translate(65.251 2572.275)"
                >
                  <tspan
                    x="0"
                    y="0"
                  >
                    24/7
                  </tspan>
                </text>
                <path
                  id="Path_192"
                  class="cls-4"
                  d="M728.5,31.5l21,20L764,39"
                  data-name="Path 192"
                  transform="translate(-670 2486)"
                />
                <circle
                  id="Ellipse_29"
                  class="cls-5"
                  cx="2.4"
                  cy="2.4"
                  data-name="Ellipse 29"
                  r="2.4"
                  transform="translate(77.2 2534.1)"
                />
              </g>
              <g
                id="Group_278"
                data-name="Group 278"
                transform="translate(235 5)"
              >
                <path
                  id="Path_208"
                  class="cls-2"
                  d="M35.3,9.5H35a.367.367,0,0,1-.3-.1c-.1,0-.2-.1-.3-.1-.1-.1-.2-.1-.2-.2A1.5,1.5,0,0,1,33.8,8V7.7a.367.367,0,0,1,.1-.3c0-.1.1-.2.1-.3.1-.1.1-.2.2-.2l.2-.2a.367.367,0,0,1,.3-.1c.1,0,.2-.1.3-.1h.6a.367.367,0,0,1,.3.1c.1,0,.2.1.3.1.1.1.2.1.2.2l.2.2a.367.367,0,0,1,.1.3c0,.1.1.2.1.3V8a2.1,2.1,0,0,1-.4,1.1l-.2.2c-.1,0-.2.1-.3.1s-.2.1-.3.1Z"
                  data-name="Path 208"
                  transform="translate(118 2462)"
                />
                <path
                  id="Path_211"
                  class="cls-2"
                  d="M8,37.2a2.1,2.1,0,0,1-1.1-.4,1.5,1.5,0,0,1-.4-1.1v-.3a.367.367,0,0,1,.1-.3c0-.1.1-.2.1-.3s.1-.1.2-.2a1.449,1.449,0,0,1,2.1,0l.2.2a.367.367,0,0,1,.1.3c0,.1.1.2.1.3V36a.367.367,0,0,1-.1.3c0,.1-.1.2-.1.3a.215.215,0,0,1-.2.2A1.159,1.159,0,0,1,8,37.2Z"
                  data-name="Path 211"
                  transform="translate(118 2462)"
                />
                <path
                  id="Path_212"
                  class="cls-2"
                  d="M21.5,12.8a.908.908,0,0,1-.7-.3c-.1-.1-.2-.2-.2-.3a.6.6,0,0,1-.1-.4.908.908,0,0,1,.3-.7.967.967,0,0,1,1.4,0,.908.908,0,0,1,.3.7.6.6,0,0,1-.1.4c0,.1-.1.2-.2.3l-.1.1c-.1,0-.1.1-.2.1h-.2C21.7,12.8,21.6,12.8,21.5,12.8Z"
                  data-name="Path 212"
                  transform="translate(118 2462)"
                />
                <path
                  id="Path_214"
                  class="cls-2"
                  d="M59.2,22.5a.99.99,0,0,1-.7-1.7c.1-.1.2-.2.3-.2a1.09,1.09,0,0,1,1.1.2l.1.1c0,.1.1.1.1.2v.4a.908.908,0,0,1-.3.7A.713.713,0,0,1,59.2,22.5Z"
                  data-name="Path 214"
                  transform="translate(118 2462)"
                />
                <path
                  id="Path_215"
                  class="cls-2"
                  d="M11.8,50.4a.6.6,0,0,1-.4-.1c-.1-.1-.2-.1-.3-.2s-.2-.2-.2-.3a.6.6,0,0,1-.1-.4.908.908,0,0,1,.3-.7c.1-.1.2-.2.3-.2a.638.638,0,0,1,.6,0h.2c.1,0,.1.1.2.1s.1.1.2.1l.1.1a.349.349,0,0,1,.1.2c0,.1.1.1.1.2v.2a.6.6,0,0,1-.1.4c0,.1-.1.2-.2.3,0,.1-.1.1-.2.1s-.1.1-.2.1-.1,0-.2.1Z"
                  data-name="Path 215"
                  transform="translate(118 2462)"
                />
                <path
                  id="Path_216"
                  class="cls-2"
                  d="M11.6,23a1.025,1.025,0,0,1-1-1,.6.6,0,0,1,.1-.4c0-.1.1-.2.2-.3a.967.967,0,0,1,1.4,0,.967.967,0,0,1,0,1.4c-.1.1-.2.2-.3.2C11.8,22.9,11.7,23,11.6,23Z"
                  data-name="Path 216"
                  transform="translate(118 2462)"
                />
                <path
                  id="Path_218"
                  class="cls-2"
                  d="M49,12.6a1.025,1.025,0,0,1-1-1,.6.6,0,0,1,.1-.4c0-.1.1-.2.2-.3a1.4,1.4,0,0,1,.9-.3c.1,0,.1,0,.2.1.1,0,.1.1.2.1l.1.1c.1.1.2.2.2.3s.1.3.1.4a.908.908,0,0,1-.3.7.1.1,0,0,0-.1.1c-.1,0-.1.1-.2.1s-.1,0-.2.1Z"
                  data-name="Path 218"
                  transform="translate(118 2462)"
                />
              </g>
            </g>
          </svg>
        </div>

        <v-list
          v-else
          dense
          class="gfp-adv-common-sidebar-working-hours-body-background"
        >
          <v-list-tile
            v-for="(day, index) in dataStore.workingHours.hours.opening"
            :key="'opening-' + index"
          >
            <v-list-tile-content>
              <v-list-tile-title class="caption gfp-adv-common-sidebar-working-hours-body-text--text">
                <template v-if="!day.isToday">
                  {{ day.day }}
                </template>
                <v-chip
                  v-else
                  class="ma-0 caption"
                  color="gfp-adv-common-sidebar-working-hours-body-today-open-background gfp-adv-common-sidebar-working-hours-body-today-open-text--text"
                  label
                  small
                  style="height: 18px;"
                >
                  {{ day.day }}
                </v-chip>
              </v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action
              v-if="day.status"
              class="gfp-adv-common-sidebar-working-hours-body-text--text"
              v-html="getDayTimes(day)"
            />
            <v-list-tile-action v-else>
              <v-chip
                class="ma-0 caption"
                color="gfp-adv-common-sidebar-working-hours-body-today-closed-background gfp-adv-common-sidebar-working-hours-body-today-closed-text--text"
                label
                small
                style="height: 18px;"
              >
                {{ $t('WorkingHoursBlock.Closed') }}
              </v-chip>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>

    <v-flex
      v-if="dataStore.workingHours.hours.delivery"
      xs12
    >
      <v-card color="mt-3 gfp-adv-common-sidebar-working-hours-body-background">
        <v-toolbar
          card
          class="pl-1"
          height="46"
          prominent
          color="gfp-adv-common-sidebar-working-hours-toolbar-background"
        >
          <v-toolbar-title class="">
            <div class="subheading gfp-adv-common-sidebar-working-hours-toolbar-title--text">
              <strong>
                {{ $t('WorkingHoursBlock.Delivery') }}
              </strong>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-icon color="gfp-adv-common-sidebar-working-hours-toolbar-icon">
            access_time
          </v-icon>
        </v-toolbar>

        <v-divider />

        <v-list
          dense
          class="gfp-adv-common-sidebar-working-hours-body-background"
        >
          <v-list-tile
            v-for="(day, index) in dataStore.workingHours.hours.delivery"
            :key="'opening-' + index"
          >
            <v-list-tile-content>
              <v-list-tile-title class="caption gfp-adv-common-sidebar-working-hours-body-text--text">
                <template v-if="!day.isToday">
                  {{ day.day }}
                </template>
                <v-chip
                  v-else
                  class="ma-0 caption"
                  color="gfp-adv-common-sidebar-working-hours-body-today-open-background gfp-adv-common-sidebar-working-hours-body-today-open-text--text"
                  label
                  small
                  style="height: 18px;"
                >
                  {{ day.day }}
                </v-chip>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action
              v-if="day.status"
              v-html="getDayTimes(day)"
            />
            <v-list-tile-action v-else>
              <v-chip
                class="ma-0 caption"
                color="gfp-adv-common-sidebar-working-hours-body-today-closed-background gfp-adv-common-sidebar-working-hours-body-today-closed-text--text"
                label
                small
                style="height: 18px;"
              >
                {{ $t('WorkingHoursBlock.Closed') }}
              </v-chip>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>

    <v-flex
      v-if="dataStore.workingHours.hours.collection"
      xs12
    >
      <v-card color="mt-3 gfp-adv-common-sidebar-working-hours-body-background">
        <v-toolbar
          card
          class="pl-1"
          height="46"
          prominent
          color="gfp-adv-common-sidebar-working-hours-toolbar-background"
        >
          <v-toolbar-title class="">
            <div class="subheading gfp-adv-common-sidebar-working-hours-toolbar-title--text">
              <strong>
                {{ $t('WorkingHoursBlock.Collection') }}
              </strong>
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-icon color="gfp-adv-common-sidebar-working-hours-toolbar-icon">
            access_time
          </v-icon>
        </v-toolbar>

        <v-divider />

        <v-list
          dense
          class="gfp-adv-common-sidebar-working-hours-body-background"
        >
          <v-list-tile
            v-for="(day, index) in dataStore.workingHours.hours.collection"
            :key="'opening-' + index"
          >
            <v-list-tile-content>
              <v-list-tile-title class="caption gfp-adv-common-sidebar-working-hours-body-text--text">
                <template v-if="!day.isToday">
                  {{ day.day }}
                </template>
                <v-chip
                  v-else
                  class="ma-0 caption"
                  color="gfp-adv-common-sidebar-working-hours-body-today-open-background gfp-adv-common-sidebar-working-hours-body-today-open-text--text"
                  label
                  small
                  style="height: 18px;"
                >
                  {{ day.day }}
                </v-chip>
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action
              v-if="day.status"
              v-html="getDayTimes(day)"
            />
            <v-list-tile-action v-else>
              <v-chip
                class="ma-0 caption"
                color="gfp-adv-common-sidebar-working-hours-body-today-closed-background gfp-adv-common-sidebar-working-hours-body-today-closed-text--text"
                label
                small
                style="height: 18px;"
              >
                {{ $t('WorkingHoursBlock.Closed') }}
              </v-chip>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name      : 'WorkingHours',
  components: {},
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {
    this.getData()
    this.$bus.$on('on-language-change', this.getData)
  },
  mounted () {},
  destroyed () {
    this.$bus.$off('on-language-change', this.getData)
  },
  methods: {
    getData () {
      window.API.get(window.APICall.workingHours)
        .then(response => {
          if (response.data.status === 200) {
            this.$set(this.dataStore, 'workingHours', response.data.data)
          }
        })
        .catch(e => {

        })
    },
    getDayTimes (day) {
      let tpl = `<span>${ day.hourStart } - ${ day.hourEnd }</span>`
      if (day.after) {
        tpl += `<span class="pl-2">${ day.hourStartAfter } - ${ day.hourEndAfter }</span>`
      }
      return tpl
    }
  }
}
</script>

<style scoped>
/deep/ .v-chip__content {
  padding : 4px;
}

/deep/ .v-list__tile {
  height : 25px !important;
}

.cls-1, .cls-4 {
  fill : none;
}

.cls-1, .cls-4, .cls-5 {
  stroke          : #4caf50;
  stroke-linecap  : round;
  stroke-linejoin : round;
  stroke-width    : 2px;
}

.cls-2, .cls-3 {
  fill : #4caf50;
}

.cls-3 {
  font-size   : 34.89px;
  font-family : Helvetica;
}

.cls-4, .cls-5 {
  stroke-miterlimit : 10;
}

.cls-5 {
  fill : #fff;
}
</style>
