<template>
  <v-container
    fluid
    grid-list-lg
    pt-0
  >
    <v-layout
      row
      wrap
    >
      <template v-if="order && order.OrderId">
        <v-flex xs12>
          <v-subheader
            :class="[getOrderStatusData(orderStatus).color]"
            class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading"
          >
            <v-icon
              :class="{'custom-loader': isLoading}"
              color="gfp-adv-pages-checkout-details-header-icon"
            >
              {{ isLoading ? 'cached' : getOrderStatusData(orderStatus).icon }}
            </v-icon>
            <span class="pl-2">
              {{ $t('Account.UserOrders.Status.Title') }} #{{ order.OrderId }}
            </span>
          </v-subheader>
        </v-flex>
        <v-flex
          class="text-xs-center"
          xs12
        >
          <v-avatar
            :color="getOrderStatusData(orderStatus).color"
            size="64px"
          >
            <v-icon
              dark
              x-large
            >
              {{ getOrderStatusData(orderStatus).icon }}
            </v-icon>
          </v-avatar>
        </v-flex>
        <v-flex
          class="text-xs-center grey--text text--darken-2 title subheading"
          xs12
        >
          <div>{{ $t('Account.UserOrders.Status.' + orderStatus + '.Desc') }}</div>
          <template v-if="order.StatusId != '0'">
            <div
              v-if="order.StatusId == '19' && ['viva_card_3ds', 'viva_card_smart_checkout', 'everypay_card', 'stripe'].includes(order.Payment)"
              class="body-2 pt-2 success--text"
            >
              {{ $t('Account.UserOrders.Status.' + orderStatus + '.Refund', {orderTotal: $options.filters.currency(orderTotal)}) }}
            </div>
            <div
              v-if="order.StatusId != '15' && order.StatusId != '19'"
              class="pt-2"
            >
              {{ $t('Account.UserOrders.Type.' + order.OrderType + '', {time: convertMinutesToDisplayString(parseInt(order.OrderTypeTime), false, false)}) }}
            </div>
            <div class="pt-2 caption grey--text text--darken-1">
              {{ $t('Account.UserOrders.Status.' + orderStatus + '.Info') }}
            </div>
          </template>
          <template v-else>
            <div
              v-if="order.StatusId != '15' && order.StatusId != '19'"
              class="pt-2"
            >
              {{ $t('Account.UserOrders.Status.' + orderStatus + '.Info') }}
            </div>
          </template>
        </v-flex>
        <template v-if="isQrOrderWithCustomerData">
          <v-flex xs12>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
                  <v-icon
                    class="pr-2"
                    color="gfp-adv-pages-checkout-details-header-icon"
                  >
                    account_circle
                  </v-icon>
                  {{ $t('Checkout.Details.Info') }}
                </v-subheader>
              </v-flex>

              <v-flex
                sm6
                xs12
              >
                <v-text-field
                  v-model="order.FirstName"
                  :label="$t('Form.Firstname.Label')"
                  disabled
                  prepend-icon="account_circle"
                />
              </v-flex>
              <v-flex
                sm6
                xs12
              >
                <v-text-field
                  v-model="order.LastName"
                  :label="$t('Form.Lastname.Label')"
                  disabled
                  prepend-icon="account_circle"
                />
              </v-flex>
              <v-flex
                sm6
                xs12
              >
                <v-text-field
                  v-model="order.Email"
                  :label="$t('Form.Email.Label')"
                  disabled
                  prepend-icon="email"
                />
              </v-flex>
              <v-flex
                sm6
                xs12
              >
                <v-text-field
                  v-model="order.Telephone"
                  :label="$t('Form.Telephone.Label')"
                  disabled
                  prepend-icon="phone"
                />
              </v-flex>

              <v-flex
                v-if="!isQrOrder"
                xs12
              >
                <v-textarea
                  v-model="order.Comment"
                  :label="$t('Form.Comments.Label')"
                  auto-grow
                  disabled
                  prepend-icon="chat"
                  rows="1"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </template>

        <v-flex
          v-if="order.OrderType != '1'"
          xs12
        >
          <v-layout
            row
            wrap
          >
            <v-flex
              v-if="isCollection || (isQrOrder && (isCollection || isDelivery))"
              xs12
            >
              <v-subheader class="white--text orange darken-2 caption pa-1">
                <v-icon
                  class="pr-2"
                  dark
                >
                  info
                </v-icon>
                <div
                  class="font-weight-regular"
                  style="width: 100%;"
                >
                  <template v-if="isQrOrder">
                    <div class="font-weight-bold">
                      {{ $t(`Order.QrSourceType.${ QrData.type }.Title`) }} #{{ QrData.value }}
                    </div>
                  </template>
                  <template v-if="isCollection">
                    {{ $t('Cart.OrderType.Warning') }}&nbsp;{{ $t('Cart.OrderType.Minutes', {min: convertMinutesToDisplayString(parseInt(order.OrderTypeTime), false, false)}) }}
                  </template>
                  <template v-if="isDelivery && isQrOrder">
                    {{ $t('Order.InternalDelivery') }}&nbsp;{{ $t('Cart.OrderType.Minutes', {min: convertMinutesToDisplayString(parseInt(order.OrderTypeTime), false, false)}) }}
                  </template>
                </div>
              </v-subheader>
            </v-flex>

            <v-flex
              v-if="!isQrOrder"
              xs12
            >
              <v-card>
                <v-card-text>
                  <v-map
                    v-if="order"
                    :map-center="mapCenter"
                    :address-marker="markerStore"
                    show-address-marker
                    show-store-marker
                    map-static
                    width="1080"
                    height="250"
                  />
                </v-card-text>
                <v-card-title class="pt-0 align-start">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      class="pa-2"
                      xs12
                    >
                      <h5>{{ appConfig.LOCATION_DATA.Street }}, {{ appConfig.LOCATION_DATA.City }}</h5>
                    </v-flex>
                  </v-layout>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex
          v-if="parseInt(order.OrderType) === 1 && order.Details.CustomerAddress && !isQrOrder"
          xs12
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
                <v-icon
                  class="pr-2"
                  color="gfp-adv-pages-checkout-details-header-icon"
                >
                  location_on
                </v-icon>
                {{ $t('Checkout.Details.Address') }}
              </v-subheader>
            </v-flex>
            <v-flex xs12>
              <v-card height="100%">
                <v-card-text>
                  <v-img
                    v-if="isCustomAddress(order.Details.CustomerAddress)"
                    height="300"
                    position="center center"
                    src="/img/map/map-placeholder.png"
                    style="border: 1px solid #e2e2e2;"
                    width="100%"
                  />
                  <v-map
                    v-else-if="order"
                    :map-center="{lat: parseFloat(order.Details.CustomerAddress.Lat), lng: parseFloat(order.Details.CustomerAddress.Lng)}"
                    :address-marker="{lat: parseFloat(order.Details.CustomerAddress.Lat), lng: parseFloat(order.Details.CustomerAddress.Lng)}"
                    show-address-marker
                    show-store-marker
                    map-static
                    width="1080"
                    height="250"
                  />
                </v-card-text>
                <v-card-title
                  class="pt-0 align-start"
                  style="min-height: 100px"
                >
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      class="pl-2 pr-2 pa-0"
                      style="min-height: 34px"
                      xs12
                    >
                      <v-chip
                        v-if="order.Details.CustomerAddress.Nickname"
                        class="ml-0"
                        color="pink"
                        label
                        small
                        text-color="white"
                      >
                        {{ order.Details.CustomerAddress.Nickname }}
                      </v-chip>
                    </v-flex>
                    <v-flex
                      class="pa-2"
                      xs12
                    >
                      <h5>{{ order.Details.CustomerAddress.FormattedAddress }}</h5>
                    </v-flex>

                    <v-flex
                      class="pl-2 pr-2 pa-0"
                      xs12
                    >
                      <span
                        v-if="order.Details.CustomerAddress.Floor"
                        class="pr-2"
                        style="white-space: nowrap;"
                      >
                        <h5 class="d-inline-block">
                          {{ $t('Account.UserAddresses.Floor') }}:
                        </h5> {{ order.Details.CustomerAddress.Floor }}
                      </span>
                      <span
                        v-if="order.Details.CustomerAddress.DoorBell"
                        style="white-space: nowrap;"
                      >
                        <h5 class="d-inline-block">
                          {{ $t('Account.UserAddresses.DoorBell') }}:
                        </h5> {{ order.Details.CustomerAddress.DoorBell }}
                      </span>
                      <span
                        v-if="order.Details.CustomerAddress.Comment"
                        class="d-block"
                        style="white-space: nowrap;"
                      >
                        <h5 class="d-inline-block">
                          {{ $t('Account.UserAddresses.Comment') }}:
                        </h5> {{ order.Details.CustomerAddress.Comment }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12>
          <v-card
            class="transparent"
            flat
            height="100%"
          >
            <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
              <v-icon
                class="pr-2"
                color="gfp-adv-pages-checkout-details-header-icon"
              >
                shopping_cart
              </v-icon>
              {{ $t('Account.UserOrders.Details.Title') }} #{{ order.OrderId }}
            </v-subheader>

            <v-card-text class="pa-0">
              <template v-for="(category, index) in order.Details.CartItemsWithCategories">
                <div
                  v-if="category.Category && category.Category.Lang && category.Category.Lang[$i18n.locale]"
                  :key="`category-${index}`"
                  :style="`border-left: #${category.Category.Color ? category.Category.Color : 'e1e1e1'} 3px solid; background-color: ${$vuetify.theme['gfp-adv-common-cart-items-category-background'].base};`"
                >
                  <v-subheader class="subheading pl-3 pt-2 pb-2 mt-2 mb-1 gfp-adv-common-cart-items-category-text--text">
                    {{ category.Category.Lang[$i18n.locale].Name }}
                  </v-subheader>
                </div>

                <template v-for="(item, i) in category.Items">
                  <template v-if="item.Lang && item.Lang[$i18n.locale]">
                    <div :key="`item-${index}-${i}`">
                      <div class="gfp-adv-common-cart-items-product-background pa-2">
                        <div
                          class="body-1 pa-1"
                          style="display: table;"
                        >
                          <div style="display: table-row-group; vertical-align: middle;">
                            <span
                              class="left mr-2"
                              style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                            >
                              <strong class="gfp-adv-common-cart-items-product-text-title-quantity--text">
                                {{ item.Quantity }} x
                              </strong>
                            </span>

                            <span
                              style="display: table-cell; vertical-align: middle; width: 100%;"
                              class="gfp-adv-common-cart-items-product-text-title--text"
                            >
                              {{ item.Lang[$i18n.locale].Name }}
                            </span>

                            <span
                              class="right ml-2 gfp-adv-common-cart-items-product-text-price--text"
                              style="display: table-cell; vertical-align: middle; white-space: nowrap;"
                            >
                              {{ item.SubTotal | currency }}
                            </span>
                          </div>
                        </div>

                        <div
                          v-if="item.OptionsWithGroups && Array.isArray(item.OptionsWithGroups) && item.OptionsWithGroups.length"
                          class="gfp-adv-common-cart-items-product-text-description--text ml-1 caption"
                        >
                          <template v-for="(optionGroup, idx) in item.OptionsWithGroups">
                            <div
                              v-if="optionGroup.group[$i18n.locale]"
                              :key="idx"
                            >
                              <div
                                v-if="optionGroup.display"
                                class="subheading pt-2 pb-1 grey--text text--darken-2"
                              >
                                {{ optionGroup.group[$i18n.locale] }}
                              </div>
                              <template v-for="(option, odx) in optionGroup.options[$i18n.locale]">
                                <div
                                  :key="odx"
                                  style="margin-left: 36px !important;"
                                >
                                  • <span v-html="option" />
                                </div>
                              </template>
                            </div>
                          </template>
                        </div>

                        <div
                          v-if="item.Comment"
                          class="gfp-adv-common-cart-items-product-text-title--text ml-1 caption"
                        >
                          {{ $t('Form.MenuComments.Label') }}:
                          <span class="gfp-adv-common-cart-items-product-text-description--text">
                            {{ item.Comment }}
                          </span>
                        </div>
                      </div>

                      <v-divider
                        :key="`divider-${index}-${i}`"
                        class="mb-1"
                      />
                    </div>
                  </template>
                </template>
              </template>

              <div
                v-if="orderPaymentType"
                class="mt-2 pa-2 secondary white--text subheading"
              >
                {{ $t('Checkout.Payment.PaymentMethod') }}
                <div class="right subheading grey--text text--lighten-2">
                  <v-icon
                    class="mr-2"
                    color="grey lighten-2"
                    dark
                    size="21"
                  >
                    {{ orderPaymentType.code === 'cod' ? 'euro_symbol' : 'credit_card' }}
                  </v-icon>
                  <span>{{ orderPaymentType.lang && orderPaymentType.lang[$i18n.locale] && orderPaymentType.lang[$i18n.locale].Name }}</span>
                </div>
              </div>

              <template v-for="(total, key, index) in order.Details.CartTotals">
                <div
                  :key="`${key}-${index}`"
                  class="mt-2 gfp-adv-common-cart-items-total-background"
                >
                  <div
                    :class="[key === 'order_total' ? 'py-3 subheading font-weight-bold' : 'py-2 body-2 font-weight-medium']"
                    class="px-2 gfp-adv-common-cart-items-total-text--text"
                  >
                    <template v-if="total.Lang && total.Lang[$i18n.locale]">
                      <template v-if="key === 'coupon'">
                        {{ total.Lang[$i18n.locale].Name }} {{ total.Code || uppercase }}
                      </template>
                      <template v-else>
                        {{ total.Lang[$i18n.locale].Name }}
                      </template>
                    </template>
                    <template v-else>
                      {{ total.Title }}
                    </template>

                    <span class="right">
                      {{ total.Value | currency }}
                    </span>
                  </div>
                </div>
              </template>
            </v-card-text>
          </v-card>
        </v-flex>
      </template>

      <template v-else-if="errorOrderNotFound && customOrderId">
        <v-flex xs12>
          <v-subheader class="info white--text subheading">
            <span class="pl-2">
              {{ $t('Account.UserOrders.Status.Title') }}
            </span>
          </v-subheader>
        </v-flex>

        <v-flex
          class="text-xs-center"
          xs12
        >
          <v-avatar
            color="red"
            size="64px"
          >
            <v-icon
              dark
              x-large
            >
              close
            </v-icon>
          </v-avatar>
        </v-flex>

        <v-flex
          class="text-xs-center grey--text text--darken-2 title"
          xs12
        >
          {{ $t('Checkout.Success.Error') }}
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import AppData          from '@/mixins/appdata'
import CartCommon       from '@/mixins/orders/cartCommon'
import OrderTypeTime    from '@/mixins/orders/orderTypeTime'
import AnalyticsCommon  from '@/mixins/analytics/analyticsCommon'
import DataEventEnum    from '@/lib/services/enum/DataEventEnum'
import DataEventService from '@/lib/services/event/DataEventService'
import VMap             from '@/components/common/maps/VMap.vue'
import MapsCommon       from '@/mixins/maps/mapsCommon'
import AddressCommon    from '@/mixins/maps/addressCommon'

export default {
  name      : 'CheckoutSuccess',
  components: { VMap },
  directives: {},
  mixins    : [AppData, MapsCommon, AddressCommon, CartCommon, OrderTypeTime, AnalyticsCommon],
  props     : [],
  data () {
    return {
      checkStatusInterval: null,
      order              : null,
      payments           : [],
      isLoading          : false,
      errorOrderNotFound : false
    }
  },
  computed: {
    orderTotal () {
      const total = this.order?.Details?.CartTotals?.find(total => total.Code === 'order_total')?.Value
      return total ? parseFloat(total) : 0.0
    },
    orderPaymentType () {
      return this.payments.find(payment => payment.code === this.order?.Payment)
    },
    orderStatus () {
      return this.order?.StatusId
    },
    customOrderId () {
      return this.$route?.params?.id || null
    }
  },
  watch: {},
  created () {
    this.getOrderData()
    this.orderComment = ''
  },
  mounted () {},
  beforeDestroy () {
    this.stopCheckOrderStatus()
  },
  destroyed () {
  },
  methods: {
    purchaseGA () {
      if (!this.order) return

      const items = []
      for (let i = 0; i < this.order?.Details?.CartItemsWithCategories?.length; i++) {
        const category = this.order.Details.CartItemsWithCategories[i].Category
        const categoryItems = this.order.Details.CartItemsWithCategories[i].Items
        for (let ii = 0; ii < categoryItems.length; ii++) {
          categoryItems[ii].CategoryName = category.Lang && category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Name || category.Name
          const item = this.menuItemToGAItem(categoryItems[ii])
          items.push(item)
        }
      }

      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.PURCHASE,
        Payload: {
          items         : items,
          value         : this.orderTotal,
          currency      : window?.AppConfig?.APP_SETTINGS?.Currency?.name || 'EUR',
          coupon        : this.order?.Details?.CartTotals?.find(t => t.Code === 'coupon')?.Title || '',
          transaction_id: this.order.OrderId || '',
          user          : {
            firstname: this.order.FirstName,
            lastname : this.order.LastName,
            email    : this.order.Email
          }
        }
      })
    },
    getOrderData () {
      const payload = this.customOrderId ? { OrderId: this.customOrderId } : {}

      window.API.post(window.APICall.checkoutSuccess, payload)
        .then(response => {
          if (response.data && response.data.status === 200) {
            if (Array.isArray(response.data?.data?.Order) && response.data?.data?.Order?.length <= 0) {
              this.errorOrderNotFound = true
              return
            }

            this.order = response.data.data.Order
            this.payments = response.data.data.Payments
            if (parseInt(this.order.StatusId) !== 15 && parseInt(this.order.StatusId) !== 19) {
              this.startCheckOrderStatus()
              this.purchaseGA()
            }
            this.$bus.$emit('update-cart')
          } else if (this.customOrderId && response.data.status === 'fail') {
            this.errorOrderNotFound = true
          } else {
            this.$router.replace({ name: 'Menu' })
            // location.reload()
          }
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$router.replace({ name: 'Menu' })
            // location.reload()
          }
        })
    },
    checkOrderStatus () {
      if (!this.order?.OrderId) return

      const payload = this.customOrderId ? { OrderId: this.order?.OrderId } : {}

      window.API.post(window.APICall.checkoutOrderStatus, payload)
        .then(response => {
          if (response.data && response.data.status === 200) {
            this.order.StatusId = response.data.data.Status
            this.order.OrderTypeTime = response.data.data.OrderTypeTime
            if (parseInt(response.data.data.Status) === 15 || parseInt(response.data.data.Status) === 19) {
              this.stopCheckOrderStatus()
              this.isLoading = false
            }
          }
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.stopCheckOrderStatus()
            this.$router.replace({ name: 'Menu' })
            // location.reload()
          }
        })
    },
    startCheckOrderStatus () {
      this.isLoading = true
      this.checkStatusInterval = setInterval(this.checkOrderStatus, 3500)
    },
    stopCheckOrderStatus () {
      if (this.checkStatusInterval) {
        clearInterval(this.checkStatusInterval)
        this.checkStatusInterval = null
        this.isLoading = false
      }
    },
    getOrderStatusData (statusId) {
      const orderStatusId = parseInt(statusId)
      if (orderStatusId === 0) {
        return {
          color: 'orange',
          icon : 'payment'
        }
      }
      if (orderStatusId === 11) {
        return {
          color: 'grey',
          icon : 'call_received'
        }
      }
      if (orderStatusId === 12) {
        return {
          color: 'grey',
          icon : 'swap_vertical_circle'
        }
      }
      if (orderStatusId === 13) {
        return {
          color: 'grey',
          icon : 'access_time'
        }
      }
      if (orderStatusId === 14) {
        return {
          color: 'grey',
          icon : 'directions_bike'
        }
      }
      if (orderStatusId === 15) {
        return {
          color: 'light-green',
          icon : 'done_all'
        }
      }
      if (orderStatusId === 19) {
        return {
          color: 'red',
          icon : 'close'
        }
      }
      if (orderStatusId === 20) {
        return {
          color: 'grey',
          icon : 'print'
        }
      }
      if (orderStatusId === 21) {
        return {
          color: 'green',
          icon : 'done'
        }
      }
      return {
        color: 'grey',
        icon : 'help'
      }
    }
  }
}
</script>

<style scoped>
.custom-loader {
  animation : loader 1s infinite;
  display   : flex;
}

@-moz-keyframes loader {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform : rotate(0);
  }
  to {
    transform : rotate(360deg);
  }
}
</style>
