<template>
  <v-layout
    justify-center
    row
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      lazy
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          card
          height="80"
          prominent
          color="gfp-adv-common-register-toolbar-background"
          style="min-height: 80px"
        >
          <v-icon
            color="gfp-adv-common-register-toolbar-icon"
            x-large
          >
            fas fa-user-plus
          </v-icon>

          <v-toolbar-title class="pl-1">
            <div class="body-3 gfp-adv-common-register-toolbar-title--text">
              {{ $t('RegisterDialog.Title') }}
            </div>
            <div class="caption gfp-adv-common-register-toolbar-description--text">
              {{ $t('RegisterDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            :color="getContrastColor($vuetify.theme['gfp-adv-common-register-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
            @click="onCancelButtonClick"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="gfp-adv-common-register-body-background fill-height">
          <v-form
            ref="registerForm"
            v-model="registerFormValid"
          >
            <v-container
              fluid
              grid-list-md
            >
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-if="errorMessages.error || errorMessages.success"
                  xs12
                >
                  <v-alert
                    :type="errorMessages.error ? 'error' : 'success'"
                    :value="true"
                    outline
                  >
                    {{ errorMessages.error ? errorMessages.error : errorMessages.success }}
                  </v-alert>
                </v-flex>
                <v-flex
                  sm6
                  xs12
                >
                  <v-text-field
                    v-model="credentials.first_name"
                    :error-messages="errorMessages.registerForm.first_name"
                    :hint="$t('Form.Firstname.Hint')"
                    :label="$t('Form.Firstname.Label')"
                    :rules="validationRules.registerForm.first_name"
                    color="gfp-adv-common-register-body-control"
                    prepend-icon="account_circle"
                    required
                  />
                </v-flex>
                <v-flex
                  sm6
                  xs12
                >
                  <v-text-field
                    v-model="credentials.last_name"
                    :error-messages="errorMessages.registerForm.last_name"
                    :hint="$t('Form.Lastname.Hint')"
                    :label="$t('Form.Lastname.Label')"
                    :rules="validationRules.registerForm.last_name"
                    color="gfp-adv-common-register-body-control"
                    prepend-icon="account_circle"
                    required
                  />
                </v-flex>
                <v-flex
                  sm6
                  xs12
                >
                  <v-text-field
                    v-model="credentials.email"
                    :disabled="fromUserInfo"
                    :error-messages="errorMessages.registerForm.email"
                    :hint="$t('Form.Email.Hint')"
                    :label="$t('Form.Email.Label')"
                    :rules="validationRules.registerForm.email"
                    color="gfp-adv-common-register-body-control"
                    prepend-icon="email"
                    required
                  />
                </v-flex>
                <v-flex
                  sm6
                  xs12
                >
                  <v-text-field
                    v-model="credentials.telephone"
                    :error-messages="errorMessages.registerForm.telephone"
                    :hint="$t('Form.Telephone.Hint')"
                    :label="$t('Form.Telephone.Label')"
                    :rules="validationRules.registerForm.telephone"
                    color="gfp-adv-common-register-body-control"
                    mask="### ## ## ### ######"
                    prepend-icon="phone"
                    required
                  />
                </v-flex>
                <v-flex
                  v-if="!fromUserInfo"
                  sm6
                  xs12
                >
                  <v-text-field
                    v-model="credentials.password"
                    :append-icon="p1 ? 'visibility' : 'visibility_off'"
                    :error-messages="errorMessages.registerForm.password"
                    :hint="$t('Form.Password.Hint')"
                    :label="$t('Form.Password.Label')"
                    :rules="validationRules.registerForm.password"
                    :type="p1 ? 'password' : 'text'"
                    color="gfp-adv-common-register-body-control"
                    prepend-icon="lock"
                    required
                    @click:append="p1 = !p1"
                  />
                </v-flex>
                <v-flex
                  v-if="!fromUserInfo"
                  sm6
                  xs12
                >
                  <v-text-field
                    v-model="credentials.password_confirm"
                    :append-icon="p2 ? 'visibility' : 'visibility_off'"
                    :error-messages="errorMessages.registerForm.password_confirm"
                    :hint="$t('Form.PasswordConfirm.Hint')"
                    :label="$t('Form.PasswordConfirm.Label')"
                    :rules="validationRules.registerForm.password_confirm"
                    :type="p2 ? 'password' : 'text'"
                    color="gfp-adv-common-register-body-control"
                    prepend-icon="lock"
                    required
                    @click:append="p2 = !p2"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-subheader class="pl-1 gfp-adv-common-register-body-title--text">
                    {{ $t('Form.Notifications.Title') }}
                  </v-subheader>

                  <v-layout
                    align-center
                    ma-0
                    row
                    wrap
                  >
                    <v-checkbox
                      v-model="credentials.newsletter"
                      :error-messages="errorMessages.registerForm.newsletter"
                      :label="$t('Form.Notifications.Email')"
                      class="ma-0 pa-0"
                      color="success"
                      false-value="0"
                      hide-details
                      true-value="1"
                    />
                    <v-checkbox
                      v-model="credentials.sms"
                      :error-messages="errorMessages.registerForm.sms"
                      :label="$t('Form.Notifications.Sms')"
                      class="ma-0 pa-0"
                      color="success"
                      false-value="0"
                      hide-details
                      true-value="1"
                    />
                    <v-checkbox
                      v-model="credentials.push"
                      :error-messages="errorMessages.registerForm.push"
                      :label="$t('Form.Notifications.Push')"
                      class="ma-0 pa-0"
                      color="success"
                      false-value="0"
                      hide-details
                      true-value="1"
                    />
                  </v-layout>
                </v-flex>

                <v-flex xs12>
                  <v-subheader class="pl-1 gfp-adv-common-register-body-title--text">
                    {{ $t('RegisterDialog.Terms.Title') }}
                  </v-subheader>

                  <v-layout
                    align-start
                    ma-0
                  >
                    <v-checkbox
                      v-model="credentials.terms_condition"
                      :error-messages="errorMessages.registerForm.terms_condition"
                      :label="$t('RegisterDialog.Terms.Agree')"
                      :rules="validationRules.registerForm.terms_condition"
                      class="ma-0 pa-0"
                      color="success"
                      false-value="0"
                      required
                      true-value="1"
                    />
                  </v-layout>
                </v-flex>

                <v-flex xs12>
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="appConfig.LOCATION_DATA.RecaptchaKey"
                    size="invisible"
                    @expired="onRecaptchaExpired"
                    @verify="onRecaptchaVerify"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="gfp-adv-common-register-toolbar-background">
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-btn
                :disabled="registerButton"
                :loading="registerButton"
                block
                class="elevation-0 mb-2"
                color="gfp-adv-common-register-body-button-register-background gfp-adv-common-register-body-button-register-text--text"
                large
                @click="register()"
              >
                {{ $t('RegisterDialog.Button') | sanitizeDiacritics }}
              </v-btn>
            </v-flex>

            <v-flex
              class="text-xs-center gfp-adv-common-register-body-text--text"
              xs12
            >
              {{ $t('RegisterDialog.RememberLogin') }}
              <a
                class="gfp-adv-common-register-body-link--text"
                @click="onCancelButtonClick(); $bus.$emit('show-login-dialog')"
              >
                {{ $t('Account.Login') }}
              </a>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import VueRecaptcha         from 'vue-recaptcha'
import AppData              from '../../mixins/appdata'
import Recaptcha            from '../../mixins/recaptcha'
import DataEventEnum        from '@/lib/services/enum/DataEventEnum'
import DataEventService     from '@/lib/services/event/DataEventService'
import AppSettings          from '@/settings/AppSettings'
import { getContrastColor } from '@/lib/color'

export default {
  name      : 'RegisterDialog',
  components: {
    'vue-recaptcha': VueRecaptcha
  },
  directives: {},
  mixins    : [AppData, Recaptcha],
  props     : ['visible'],
  data () {
    return {
      p1               : true,
      p2               : true,
      registerButton   : false,
      registerFormValid: false,
      fromUserInfo     : false,
      errorMessages    : {
        registerForm: {
          first_name      : [],
          last_name       : [],
          telephone       : [],
          email           : [],
          password        : [],
          password_confirm: [],
          newsletter      : [],
          sms             : [],
          push            : [],
          terms_condition : []
        },
        error  : '',
        success: ''
      },
      validationRules: {
        registerForm: {
          first_name: [
            v => !!v || this.$t('Form.Firstname.Errors.Mandatory'),
            v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Firstname.Errors.Valid')
          ],
          last_name: [
            v => !!v || this.$t('Form.Lastname.Errors.Mandatory'),
            v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Lastname.Errors.Valid')
          ],
          telephone: [
            v => !!v || this.$t('Form.Telephone.Errors.Mandatory'),
            v => v && v.length >= 8 && v.length <= 16 || this.$t('Form.Telephone.Errors.Valid')
          ],
          email: [
            v => !!v || this.$t('Form.Email.Errors.Mandatory'),
            v => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Form.Email.Errors.Valid')
          ],
          password: [
            v => !!v || this.$t('Form.Password.Errors.Mandatory'),
            v => (v && v.length >= 6 && v.length <= 32) || this.$t('Form.Password.Errors.Valid')
          ],
          password_confirm: [
            v => !!v || this.$t('Form.PasswordConfirm.Errors.Mandatory'),
            v => (v && v.length >= 6 && v.length <= 32) || this.$t('Form.PasswordConfirm.Errors.Valid'),
            v => (v && v == this.credentials.password) || this.$t('Form.PasswordConfirm.Errors.Match')
          ],
          terms_condition: [
            v => v != '0' || this.$t('RegisterDialog.Terms.Errors.Mandatory')
          ]
        }
      },
      credentials: {
        first_name      : '',
        last_name       : '',
        email           : '',
        telephone       : '',
        password        : '',
        password_confirm: '',
        newsletter      : '0',
        sms             : '0',
        push            : '0',
        terms_condition : '0'
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    isVisible (newVal, oldVal) {
      if (newVal) {
        if (this.dataStore.userInfo && this.dataStore.userInfo.Info && !this.dataStore.userInfo.Info.Id) {
          this.fromUserInfo = true
          this.credentials.first_name = this.dataStore.userInfo.Info.Firstname
          this.credentials.last_name = this.dataStore.userInfo.Info.Lastname
          this.credentials.email = this.dataStore.userInfo.Info.Email
          this.credentials.telephone = this.dataStore.userInfo.Info.Telephone
          this.credentials.password = ''
          this.credentials.password_confirm = ''
          this.validationRules.registerForm.password = []
          this.validationRules.registerForm.password_confirm = []
          this.$forceUpdate()
        } else {
          this.fromUserInfo = false
          this.$set(this.dataStore, 'userInfo', null)
          this.$auth.storage.removeItem('userInfo')

          this.validationRules.registerForm.password = [
            v => !!v || this.$t('Form.Password.Errors.Mandatory'),
            v => (v && v.length >= 6 && v.length <= 32) || this.$t('Form.Password.Errors.Valid')
          ]
          this.validationRules.registerForm.password_confirm = [
            v => !!v || this.$t('Form.PasswordConfirm.Errors.Mandatory'),
            v => (v && v.length >= 6 && v.length <= 32) || this.$t('Form.PasswordConfirm.Errors.Valid'),
            v => (v && v == this.credentials.password) || this.$t('Form.PasswordConfirm.Errors.Match')
          ]
        }
      } else {
        this.fromUserInfo = false
        this.$set(this, 'credentials', {
          first_name      : '',
          last_name       : '',
          email           : '',
          telephone       : '',
          password        : '',
          password_confirm: '',
          newsletter      : '0',
          sms             : '0',
          terms_condition : '0'
        })
        if (this.dataStore.userInfo && this.dataStore.userInfo.Info && !this.dataStore.userInfo.Info.Id) {
          this.dataStore.userInfo = null
        }
      }
    }
  },
  created () {},
  mounted () {},
  destroyed () {},
  methods: {
    getContrastColor,
    onCancelButtonClick () {
      this.$refs.registerForm.reset()
      this.clearApiErrorMessages()
      this.isVisible = false
      this.registerButton = false
    },

    register: function () {
      if (this.appSettings.IsDemo) {
        this.$bus.$emit('show-demo-warning')
        return
      }

      this.registerButton = true

      if (this.$auth.isAuthenticated()) {
        this.logout()
      }
      this.clearApiErrorMessages()
      this.$refs.registerForm.validate()
      if (!this.registerFormValid) {
        this.registerButton = false
        return
      }

      this.onRecaptchaSubmit()
    },

    submitForm () {
      const sessionId = this.$localStorage.get('x-session', AppSettings.XSession || '')
      const sourceTypeId = this.$sessionStorage.get('QRData') ? this.$sessionStorage.get('QRData')?.source || '1' : this.dataStore?.isMobileApp ? '10' : '1'
      const referer = process.env.VUE_APP_API_X_REFERER || ''
      const locale = this.$i18n.locale

      const options = {
        timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000,
        responseType   : 'json',
        crossDomain    : true,
        withCredentials: true,
        method         : 'POST',
        headers        : {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Session'       : sessionId,
          'X-Source'        : sourceTypeId,
          'X-Referer'       : referer,
          'Accept-Language' : locale
        }
      }

      this.credentials['g-recaptcha-response'] = this.recaptchaValue
      this.$bus.$emit('show-full-screen-loader')

      this.$auth.register(this.credentials, options)
        .then((authResponse) => {
          // Execute application logic after successful registration
          for (const key in authResponse.data.data.Messages) {
            if (authResponse.data.data.Messages.hasOwnProperty(key) && this.errorMessages.registerForm.hasOwnProperty(key)) {
              this.errorMessages.registerForm[key].push(authResponse.data.data.Messages[key])
            }
          }

          if (authResponse.data.data.Messages.Error) {
            this.errorMessages.error = authResponse.data.data.Messages.Error
          } else if (authResponse.data.data.Messages.Success) {
            this.errorMessages.success = authResponse.data.data.Messages.Success
            this.$bus.$emit('show-snackbar', {
              title: '',
              body : authResponse.data.data.Messages.Success,
              type : 'success',
              icon : ''
            })

            if (authResponse.data.data.CustomerInfo && authResponse.data.data.CustomerInfo.Info && authResponse.data.data.CustomerInfo.Info.SocialProvider) {
              DataEventService.Emit(DataEventEnum.EVENT, {
                Event  : DataEventEnum.SIGN_UP,
                Payload: {
                  Method: authResponse.data.data.CustomerInfo.Info.SocialProvider,
                  User  : authResponse.data.data.CustomerInfo
                }
              })
              this.autoLogin(authResponse)
            } else if (authResponse.data.data.CustomerInfo && authResponse.data.data.CustomerInfo.Info) {
              DataEventService.Emit(DataEventEnum.EVENT, {
                Event  : DataEventEnum.SIGN_UP,
                Payload: {
                  Method: authResponse.data.data.CustomerInfo.Info.SocialProvider || 'Email',
                  User  : authResponse.data.data.CustomerInfo
                }
              })
              this.autoLoginEmail(authResponse.data.data.CustomerInfo)
            }
            this.onCancelButtonClick()
          }
          this.registerButton = false
        })
        .catch(e => {

        })
        .finally(() => {
          this.registerButton = false
          this.resetRecaptcha()
          this.$bus.$emit('hide-full-screen-loader')
        })
    },

    autoLoginEmail (authResponse) {
      this.$localStorage.set('userInfo', authResponse ? JSON.stringify(authResponse) : null)
      this.$set(this.dataStore, 'userInfo', authResponse || null)
      this.$set(this.dataStore, 'isAuthenticated', true)
    },

    autoLogin (authResponse) {
      authResponse.data.access_token = this.$auth.storage.getItem('vueauth_access_token_tmp')
      this.$auth.storage.removeItem('vueauth_access_token_tmp')
      authResponse.data.token_type = 'Bearer'
      this.$auth.setToken(authResponse)

      // this.$auth.storage.setItem('userInfo', authResponse.data.data.CustomerInfo ? JSON.stringify(authResponse.data.data.CustomerInfo) : null)
      this.$localStorage.set('userInfo', authResponse.data.data.CustomerInfo ? JSON.stringify(authResponse.data.data.CustomerInfo) : null)
      this.$set(this.dataStore, 'userInfo', authResponse.data.data.CustomerInfo ? authResponse.data.data.CustomerInfo : null)
      this.$set(this.dataStore, 'isAuthenticated', this.$auth.isAuthenticated())

      this.$bus.$emit('user-logged-in')
      if (this.$auth.isAuthenticated()) {
        if (authResponse.data.data.CustomerInfo && authResponse.data.data.CustomerInfo.Info) {
          if (!authResponse.data.data.CustomerInfo.Info.Status) this.$router.push({ name: 'AccountUserProfile' })
        }

        this.$bus.$emit('show-snackbar', {
          title: '',
          body : this.$t('Common.Login.Success'),
          type : 'success',
          icon : ''
        })
      }
    },

    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      this.errorMessages.error = ''
      this.errorMessages.success = ''
      var key
      for (key in this.errorMessages.registerForm) {
        if (this.errorMessages.registerForm.hasOwnProperty(key)) {
          this.errorMessages.registerForm[key] = []
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
