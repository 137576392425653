<template>
  <v-container
    fluid
    grid-list-lg
    pt-0
  >
    <v-form
      ref="checkoutForm"
      v-model="checkoutFormValid"
      @submit.prevent=""
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          pt-0
        >
          <v-stepper
            value="1"
            alt-labels
            class="elevation-0 mt-2 gfp-adv-pages-checkout-steps-background"
            :dark="getContrastColor($vuetify.theme['gfp-adv-pages-checkout-steps-background'].base) === 'white'"
          >
            <v-stepper-header>
              <v-stepper-step
                step="1"
                color="blue"
              >
                {{ $t('Checkout.Details.Title') }}
              </v-stepper-step>

              <v-divider />

              <v-stepper-step step="2">
                {{ $t('Checkout.Payment.Title') }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-flex>

        <v-flex
          v-if="dataStore.isAuthenticated"
          xs12
        >
          <v-alert
            :value="true"
            color="gfp-adv-pages-checkout-details-user-alert gfp-adv-pages-checkout-details-user-alert-text--text"
            icon="live_help"
            outline
          >
            {{ $t('Checkout.Details.Notifications.Logout', {user: dataStore.userInfo.Info.Firstname}) }}
            <a
              class="gfp-adv-pages-checkout-details-user-alert-link--text"
              @click="$bus.$emit('logout')"
            >
              {{ $t('Account.Logout') }}
            </a>
          </v-alert>
        </v-flex>

        <v-flex
          v-else-if="!isQrOrderWithCustomerData"
          xs12
        >
          <v-alert
            :value="true"
            color="gfp-adv-pages-checkout-details-user-alert gfp-adv-pages-checkout-details-user-alert-text--text"
            icon="live_help"
            outline
          >
            <a
              class="pr-3 gfp-adv-pages-checkout-details-user-alert-text--text"
              @click="$bus.$emit('show-login-dialog')"
            >
              {{ $t('Checkout.Details.Notifications.Login') }} <span class="gfp-adv-pages-checkout-details-user-alert-link--text">{{ $t('Account.Login') }}</span>
            </a>

            <a
              class="gfp-adv-pages-checkout-details-user-alert-text--text"
              @click="$bus.$emit('show-register-dialog')"
            >
              {{ $t('LoginDialog.NoTitle') }}. <span class="gfp-adv-pages-checkout-details-user-alert-link--text">{{ $t('LoginDialog.RegisterButton') }}</span>
            </a>
          </v-alert>
        </v-flex>

        <template v-if="isQrOrderWithCustomerData">
          <v-flex
            v-if="!dataStore.isAuthenticated"
            xs12
          >
            <v-alert
              :value="true"
              color="gfp-adv-pages-checkout-details-user-alert gfp-adv-pages-checkout-details-user-alert-text--text"
              icon="live_help"
              outline
            >
              <a
                class="pr-3 gfp-adv-pages-checkout-details-user-alert-text--text"
                @click="$bus.$emit('show-login-dialog')"
              >
                {{ $t('Checkout.Details.Notifications.Login') }} <span class="gfp-adv-pages-checkout-details-user-alert-link--text">{{ $t('Account.Login') }}</span>
              </a>

              <a
                class="gfp-adv-pages-checkout-details-user-alert-text--text"
                @click="$bus.$emit('show-register-dialog')"
              >
                {{ $t('LoginDialog.NoTitle') }}. <span class="gfp-adv-pages-checkout-details-user-alert-link--text">{{ $t('LoginDialog.RegisterButton') }}</span>
              </a>
            </v-alert>
          </v-flex>

          <v-flex xs12>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
                  <v-icon
                    class="pr-2"
                    color="gfp-adv-pages-checkout-details-header-icon"
                  >
                    account_circle
                  </v-icon>
                  {{ $t('Checkout.Details.Info') }}
                </v-subheader>
              </v-flex>

              <v-flex
                lg6
                xs12
              >
                <v-text-field
                  v-model="user.Info.Firstname"
                  :disabled="allowGuestOrders"
                  :error-messages="errorMessages.checkoutForm.Firstname"
                  :hint="$t('Form.Firstname.Hint')"
                  :label="$t('Form.Firstname.Label')"
                  :rules="validationRules.Firstname"
                  prepend-icon="account_circle"
                  required
                />
              </v-flex>
              <v-flex
                lg6
                xs12
              >
                <v-text-field
                  v-model="user.Info.Lastname"
                  :disabled="allowGuestOrders"
                  :error-messages="errorMessages.checkoutForm.Lastname"
                  :hint="$t('Form.Lastname.Hint')"
                  :label="$t('Form.Lastname.Label')"
                  :rules="validationRules.Lastname"
                  prepend-icon="account_circle"
                  required
                />
              </v-flex>
              <v-flex
                lg6
                xs12
              >
                <v-text-field
                  v-model="user.Info.Email"
                  :disabled="allowGuestOrders || dataStore.isAuthenticated"
                  :error-messages="errorMessages.checkoutForm.Email"
                  :hint="$t('Form.Email.Hint')"
                  :label="$t('Form.Email.Label')"
                  :rules="validationRules.Email"
                  prepend-icon="email"
                  required
                  type="email"
                />
              </v-flex>
              <v-flex
                lg6
                xs12
              >
                <v-text-field
                  v-model="user.Info.Telephone"
                  :disabled="allowGuestOrders"
                  :error-messages="errorMessages.checkoutForm.Telephone"
                  :hint="$t('Form.Telephone.Hint')"
                  :label="$t('Form.Telephone.Label')"
                  :rules="validationRules.Telephone"
                  mask="### ## ## ### ######"
                  prepend-icon="phone"
                  required
                  type="tel"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </template>

        <v-flex
          v-show="dataStore.cart && parseInt(dataStore.cart.OrderType) === 1 && !isQrOrder"
          xs12
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
                <v-icon
                  class="pr-2"
                  color="gfp-adv-pages-checkout-details-header-icon"
                >
                  location_on
                </v-icon>
                {{ $t('Checkout.Details.Address') }}
              </v-subheader>
            </v-flex>

            <template v-if="dataStore.isAuthenticated">
              <v-flex
                v-if="user.Addresses.length > 0 && address.Lat"
                xs12
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-card
                      height="100%"
                      color="gfp-adv-pages-checkout-details-address-card-background elevation-0"
                    >
                      <v-card-text>
                        <v-img
                          v-if="isCustomAddress(address)"
                          height="300"
                          position="center center"
                          src="/img/map/map-placeholder.png"
                          style="border: 1px solid #e2e2e2;"
                          width="100%"
                        />
                        <v-map
                          v-else-if="user.Addresses.length > 0"
                          :map-center="{lat: parseFloat(address.Lat), lng: parseFloat(address.Lng)}"
                          :address-marker="{lat: parseFloat(address.Lat), lng: parseFloat(address.Lng)}"
                          show-address-marker
                          show-store-marker
                          map-static
                          width="1080"
                          height="250"
                        />
                      </v-card-text>

                      <v-card-title
                        class="pt-0 align-start gfp-adv-pages-checkout-details-address-card-text--text"
                        style="min-height: 100px"
                      >
                        <v-layout
                          row
                          wrap
                        >
                          <v-flex
                            class="pl-2 pr-2 pa-0"
                            style="min-height: 34px"
                            xs12
                          >
                            <v-chip
                              v-if="address.Nickname"
                              class="ml-0"
                              color="pink"
                              label
                              small
                              text-color="white"
                            >
                              {{ address.Nickname }}
                            </v-chip>
                          </v-flex>

                          <v-flex
                            class="pa-2"
                            xs12
                          >
                            <h5>{{ getAddressFormatted(address) }}</h5>
                          </v-flex>

                          <v-flex
                            class="pl-2 pr-2 pa-0"
                            xs12
                          >
                            <span
                              v-if="address.Floor"
                              class="pr-2"
                              style="white-space: nowrap;"
                            >
                              <h5 class="d-inline-block">
                                {{ $t('Account.UserAddresses.Floor') }}:
                              </h5> {{ address.Floor }}
                            </span>
                            <span
                              v-if="address.DoorBell"
                              style="white-space: nowrap;"
                            >
                              <h5 class="d-inline-block">
                                {{ $t('Account.UserAddresses.DoorBell') }}:
                              </h5> {{ address.DoorBell }}
                            </span>
                            <span
                              v-if="address.Comment"
                              class="d-block"
                              style="white-space: nowrap;"
                            >
                              <h5 class="d-inline-block">
                                {{ $t('Account.UserAddresses.Comment') }}:
                              </h5> {{ address.Comment }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card-title>

                      <v-card-actions>
                        <v-layout
                          class="pl-0 pr-2"
                          row
                          wrap
                        >
                          <v-flex
                            class="text-xs-right"
                            xs12
                          >
                            <v-btn
                              :block="$vuetify.breakpoint.smAndDown"
                              class="elevation-0 mb-2 ml-1 mr-1 gfp-adv-pages-checkout-details-address-card-button-add-text--text"
                              color="gfp-adv-pages-checkout-details-address-card-button-add"
                              @click="addAddress"
                            >
                              {{ $t('Buttons.AddAddress') | sanitizeDiacritics }}
                            </v-btn>

                            <v-btn
                              v-if="user.Addresses.length > 1"
                              :block="$vuetify.breakpoint.smAndDown"
                              class="elevation-0 mb-2 ml-1 mr-1 gfp-adv-pages-checkout-details-address-card-button-change-text--text"
                              color="gfp-adv-pages-checkout-details-address-card-button-change"
                              @click="selectAddress"
                            >
                              {{ $t('Buttons.ChangeAddress') | sanitizeDiacritics }}
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </template>

            <template v-else>
              <v-flex
                v-if="!allowGuestOrders && (!isCustomAddress(address) && !useCustomAddress)"
                xs12
              >
                <v-map-autocomplete-service
                  v-model="selectedLocation"
                  :maps-provider="interactiveMapsProvider.Name"
                  :maps-access-token="interactiveMapsProvider.Key"
                  :geocode-provider="geocodeProvider.Name"
                  :geocode-access-token="geocodeProvider.Key"
                  :map-center="mapCenter"
                  :store-marker="markerStore"
                  :address-marker="markerAddress"
                  :country="locationDeliveryCountriesStr"
                  :disabled="allowGuestOrders || useCustomAddress"
                  :hint="$t('Form.AutoComplete.Hint')"
                  :placeholder="$t('Form.AutoComplete.Label')"
                  :rules="validationRules.AutoComplete"
                  :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                  :types="['geocode']"
                  map-width="100%"
                  color="gfp-adv-pages-account-address-dialog-body-controls"
                  display-field="Name"
                  :map="!allowGuestOrders && (!isCustomAddress(address) && !useCustomAddress)"
                  @place-changed="(placeObj) => setAddressFromPlace(placeObj)"
                />
              </v-flex>

              <v-flex xs12>
                <v-img
                  v-if="isCustomAddress(address) || useCustomAddress"
                  height="300"
                  position="center center"
                  src="/img/map/map-placeholder.png"
                  style="border: 1px solid #e2e2e2;"
                  width="100%"
                />
              </v-flex>

              <v-flex
                v-if="acceptCustomAddresses"
                xs12
              >
                <v-switch
                  v-model="useCustomAddress"
                  :label="$t('Account.UserAddresses.AddEditDialog.UseCustom')"
                  class="pt-0 mt-0"
                  color="success"
                  hide-details
                />
              </v-flex>

              <template v-if="address.Formatted && !useCustomAddress && !acceptLatLngAddresses">
                <v-flex xs12>
                  <v-alert
                    :value="true"
                    color="orange darken-2"
                    icon="info"
                    outline
                  >
                    {{ $t('Checkout.Details.Notifications.Confirm') }}
                  </v-alert>
                </v-flex>
                <v-flex
                  lg6
                  xs12
                >
                  <v-map-autocomplete-service
                    v-model="selectedCity"
                    :geocode-provider="geocodeProvider.Name"
                    :geocode-access-token="geocodeProvider.Key"
                    :country="locationDeliveryCountriesStr"
                    :disabled="allowGuestOrders"
                    :error-messages="errorMessages.checkoutForm.City"
                    :hint="$t('Form.City.Hint')"
                    :placeholder="$t('Form.City.Label')"
                    :rules="validationRules.City"
                    :types="['city']"
                    :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                    display-field="Properties.City"
                    color="gfp-adv-pages-account-address-dialog-body-controls"
                    @place-changed="(placeObj) => setAddressFromPlace(placeObj)"
                  />
                </v-flex>
                <v-flex
                  lg6
                  xs12
                >
                  <v-map-autocomplete-service
                    v-model="selectedStreet"
                    :geocode-provider="geocodeProvider.Name"
                    :geocode-access-token="geocodeProvider.Key"
                    :country="locationDeliveryCountriesStr"
                    :disabled="allowGuestOrders"
                    :error-messages="errorMessages.checkoutForm.Street"
                    :hint="$t('Form.Street.Hint')"
                    :placeholder="$t('Form.Street.Label')"
                    :rules="validationRules.Street"
                    :types="['address']"
                    :dark="getContrastColor($vuetify.theme['gfp-adv-pages-account-address-dialog-body-background'].base) === 'white'"
                    display-field="Properties.Street"
                    color="gfp-adv-pages-account-address-dialog-body-controls"
                    @place-changed="(placeObj) => setAddressFromPlace(placeObj)"
                  />
                </v-flex>
                <v-flex
                  lg6
                  xs12
                >
                  <v-text-field
                    v-model="address.Postcode"
                    :disabled="allowGuestOrders"
                    :error-messages="errorMessages.checkoutForm.Postcode"
                    :hint="$t('Form.Postcode.Hint')"
                    :label="$t('Form.Postcode.Label')"
                    :rules="validationRules.Postcode"
                    prepend-icon="location_on"
                    @blur="getAddress"
                  />
                </v-flex>
                <v-flex
                  lg6
                  xs12
                >
                  <v-text-field
                    v-model="address.Number"
                    :disabled="allowGuestOrders"
                    :error-messages="errorMessages.checkoutForm.Number"
                    :hint="$t('Form.Number.Hint')"
                    :label="$t('Form.Number.Label')"
                    :rules="validationRules.Number"
                    prepend-icon="location_on"
                    @blur="getAddress"
                  />
                </v-flex>
              </template>

              <template v-else-if="useCustomAddress || acceptLatLngAddresses">
                <v-flex xs12>
                  <v-alert
                    :value="true"
                    color="orange darken-2"
                    icon="info"
                    outline
                  >
                    {{ acceptLatLngAddresses ? $t('Checkout.Details.Notifications.ConfirmLatLngAddress') : $t('Checkout.Details.Notifications.Confirm') }}
                  </v-alert>
                </v-flex>
                <v-flex
                  lg6
                  xs12
                >
                  <v-text-field
                    ref="map_area"
                    v-model="address.City"
                    :disabled="allowGuestOrders"
                    :error-messages="errorMessages.checkoutForm.City"
                    :hint="$t('Form.City.Hint')"
                    :label="$t('Form.City.Label')"
                    :rules="acceptLatLngAddresses ? [] : validationRules.City"
                    prepend-icon="location_on"
                    @blur="confirmAddress"
                  />
                </v-flex>
                <v-flex
                  lg6
                  xs12
                >
                  <v-text-field
                    ref="map_street"
                    v-model="address.Street"
                    :disabled="allowGuestOrders"
                    :error-messages="errorMessages.checkoutForm.Street"
                    :hint="$t('Form.Street.Hint')"
                    :label="$t('Form.Street.Label')"
                    :rules="acceptLatLngAddresses ? [] : validationRules.Street"
                    prepend-icon="location_on"
                    @blur="confirmAddress"
                  />
                </v-flex>
                <v-flex
                  lg6
                  xs12
                >
                  <v-text-field
                    v-model="address.Postcode"
                    :disabled="allowGuestOrders"
                    :error-messages="errorMessages.checkoutForm.Postcode"
                    :hint="$t('Form.Postcode.Hint')"
                    :label="$t('Form.Postcode.Label')"
                    :rules="acceptLatLngAddresses ? [] : validationRules.Postcode"
                    prepend-icon="location_on"
                    @blur="confirmAddress"
                  />
                </v-flex>
                <v-flex
                  lg6
                  xs12
                >
                  <v-text-field
                    v-model="address.Number"
                    :disabled="allowGuestOrders"
                    :error-messages="errorMessages.checkoutForm.Number"
                    :hint="$t('Form.Number.Hint')"
                    :label="$t('Form.Number.Label')"
                    :rules="acceptLatLngAddresses ? [] : validationRules.Number"
                    prepend-icon="location_on"
                    @blur="confirmAddress"
                  />
                </v-flex>
              </template>
            </template>

            <template v-if="dataStore.isAuthenticated && (user.Addresses.length <= 0 || !address.Lat)">
              <v-flex xs12>
                <div class="text-xs-center grey--text mb-3">
                  <v-icon
                    class="mb-3 grey--text text--lighten-2"
                    size="96"
                  >
                    location_off
                  </v-icon>
                  <div class="title mb-2">
                    {{ $t('Account.UserAddresses.NoDataText') }}
                  </div>
                  <div class="subheading">
                    {{ $t('Account.UserAddresses.NoDataSubText') }}
                  </div>
                  <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    class="elevation-0 mb-2 ml-1 mr-1"
                    color="success"
                    dark
                    @click="addAddress"
                  >
                    {{ $t('Buttons.AddAddress') | sanitizeDiacritics }}
                  </v-btn>
                </div>
              </v-flex>
            </template>

            <template v-else>
              <v-flex
                lg6
                xs12
              >
                <v-text-field
                  v-model="address.Floor"
                  :disabled="allowGuestOrders"
                  :error-messages="errorMessages.checkoutForm.Floor"
                  :hint="$t('Form.Floor.Hint')"
                  :label="$t('Form.Floor.Label')"
                  prepend-icon="domain"
                  @blur="confirmAddress"
                />
              </v-flex>
              <v-flex
                lg6
                xs12
              >
                <v-text-field
                  v-model="address.DoorBell"
                  :disabled="allowGuestOrders"
                  :error-messages="errorMessages.checkoutForm.DoorBell"
                  :hint="$t('Form.DoorBell.Hint')"
                  :label="$t('Form.DoorBell.Label')"
                  prepend-icon="notifications"
                  @blur="confirmAddress"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="address.Comment"
                  :disabled="allowGuestOrders"
                  :error-messages="errorMessages.checkoutForm.Comment"
                  :hint="$t('Form.AddressComments.Hint')"
                  :label="$t('Form.AddressComments.Label')"
                  auto-grow
                  prepend-icon="chat"
                  rows="1"
                  @blur="confirmAddress"
                />
              </v-flex>
            </template>
          </v-layout>
        </v-flex>

        <v-flex xs12>
          <cart-order-type />
        </v-flex>

        <v-flex
          v-if="dataStore.cart"
          xs12
        >
          <cart-order-preview :cart="dataStore.cart" />
        </v-flex>

        <!-- Tips -->
        <v-flex
          v-if="selectedPaymentMethodSupportsTips"
          xs12
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-subheader class="gfp-adv-pages-checkout-details-header-title--text gfp-adv-pages-checkout-details-header-background subheading">
                <v-icon
                  class="pr-2"
                  color="gfp-adv-pages-checkout-details-header-icon"
                >
                  {{ $t('Cart.Tip.Icon') }}
                </v-icon>
                {{ $t('Cart.Tip.Title') }}
              </v-subheader>
            </v-flex>

            <v-flex xs12>
              <delivery-tip />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex
          v-if="!orderTotalAmountValid"
          xs12
          class="text-xs-center pb-0 pt-4 font-weight-bold"
        >
          <div>{{ $t('Checkout.Error.PaymentMethodMinimumOrder.BodyAlt', {minimumOrderTotalDiff: $options.filters.currency(orderMinTotalAmountDiff)}) }}</div>
        </v-flex>

        <v-flex xs12>
          <v-btn
            ref="buyNowButton"
            :disabled="submitButton || allowGuestOrders"
            :loading="submitButton"
            block
            class="elevation-0 white--text"
            color="success"
            large
            @click="confirmData"
          >
            {{ $t('Buttons.Next') | sanitizeDiacritics }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>

    <account-add-edit-address-dialog
      v-if="!isQrOrder && isCartEnabled && !isCatalogOnly"
      :dialog-visible.sync="addEditAddressDialogVisible"
      @addressAdded="onAddressAdded"
    />
    <account-select-address-dialog
      v-if="!isQrOrder && isCartEnabled && !isCatalogOnly"
      :select-address-dialog-visible.sync="selectAddressDialogVisible"
      :selected-address.sync="address"
    />
  </v-container>
</template>

<script>
import AppData                     from '@/mixins/appdata'
import OrderTypeTime               from '@/mixins/orders/orderTypeTime'
import CartCommon                  from '@/mixins/orders/cartCommon'
import AnalyticsCommon             from '@/mixins/analytics/analyticsCommon'
import AccountAddEditAddressDialog from '@/components/account/AccountAddEditAddressDialog'
import AccountSelectAddressDialog  from '@/components/account/AccountSelectAddressDialog'
import CartOrderPreview            from '@/components/cart/CartOrderPreview'
import CartOrderType               from '@/components/cart/CartOrderType'
import DeliveryTip                 from '@/components/cart/DeliveryTip'
import DataEventService            from '@/lib/services/event/DataEventService'
import DataEventEnum               from '@/lib/services/enum/DataEventEnum'
import { getContrastColor }        from '@/lib/color'
import MapsCommon                  from '@/mixins/maps/mapsCommon'
import AddressCommon               from '@/mixins/maps/addressCommon'
import VMap                        from '@/components/common/maps/VMap.vue'
import VMapAutocompleteService     from '@/components/common/maps/VMapAutocompleteService.vue'

export default {
  name      : 'CheckoutDetails',
  components: {
    VMapAutocompleteService,
    VMap,
    DeliveryTip,
    CartOrderType,
    'account-add-edit-address-dialog': AccountAddEditAddressDialog,
    'account-select-address-dialog'  : AccountSelectAddressDialog,
    'cart-order-preview'             : CartOrderPreview
  },
  directives: {},
  mixins    : [AppData, CartCommon, MapsCommon, AddressCommon, AnalyticsCommon, OrderTypeTime],
  props     : [],
  data () {
    return {
      order                      : null,
      submitButton               : false,
      checkoutFormValid          : false,
      useCustomAddress           : false,
      addEditAddressDialogVisible: false,
      selectAddressDialogVisible : false,
      address                    : this.defaultEmptyAddress(),
      tmpUser                    : {
        Addresses: [],
        Info     : {
          Id        : '',
          Firstname : '',
          Lastname  : '',
          Email     : '',
          Telephone : '',
          Telephone2: '',
          Telephone3: ''
        }
      },
      user: {
        Addresses: [],
        Info     : {
          Id        : '',
          Firstname : '',
          Lastname  : '',
          Email     : '',
          Telephone : '',
          Telephone2: '',
          Telephone3: ''
        }
      },
      errorMessages: {
        checkoutForm: {
          Firstname: [],
          Lastname : [],
          Email    : [],
          Telephone: [],
          City     : [],
          Formatted: [],
          Lat      : [],
          Lng      : [],
          Number   : [],
          Floor    : [],
          DoorBell : [],
          Comment  : [],
          Postcode : [],
          Street   : []
        }
      }
    }
  },
  computed: {
    orderType: {
      get () {
        return this.dataStore.cart.OrderType
      },
      set (val) {
        if (val) {
          this.dataStore.cart.OrderType = val
        }
      }
    },

    validationRules () {
      if (this.dataStore.isAuthenticated || parseInt(this.dataStore.cart.OrderType) === 2 || this.isQrOrder) {
        if (this.isQrOrderWithCustomerDataRequired) {
          return {
            Firstname: [
              v => !!v || this.$t('Form.Firstname.Errors.Mandatory'),
              v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Firstname.Errors.Valid')
            ],
            Lastname: [
              v => !!v || this.$t('Form.Lastname.Errors.Mandatory'),
              v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Lastname.Errors.Valid')
            ],
            Telephone: [
              v => !!v || this.$t('Form.Telephone.Errors.Mandatory'),
              v => v && v.length >= 8 && v.length <= 16 || this.$t('Form.Telephone.Errors.Valid')
            ],
            Email: [
              v => !!v || this.$t('Form.Email.Errors.Mandatory'),
              v => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Form.Email.Errors.Valid')
            ]
          }
        }
      } else {
        return {
          Firstname: [
            v => !!v || this.$t('Form.Firstname.Errors.Mandatory'),
            v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Firstname.Errors.Valid')
          ],
          Lastname: [
            v => !!v || this.$t('Form.Lastname.Errors.Mandatory'),
            v => v && v.length >= 2 && v.length <= 32 || this.$t('Form.Lastname.Errors.Valid')
          ],
          Telephone: [
            v => !!v || this.$t('Form.Telephone.Errors.Mandatory'),
            v => v && v.length >= 8 && v.length <= 16 || this.$t('Form.Telephone.Errors.Valid')
          ],
          Email: [
            v => !!v || this.$t('Form.Email.Errors.Mandatory'),
            v => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v)) || this.$t('Form.Email.Errors.Valid')
          ],
          AutoComplete: this.useCustomAddress ? [] : [
            v => !!v || this.$t('Form.AutoComplete.Errors.Mandatory'),
            v => !!this.address.Formatted || this.$t('Form.AutoComplete.Errors.Valid')
          ],
          City: [
            v => v && v.length >= 2 && v.length <= 120 || this.$t('Form.City.Errors.Valid')
          ],
          Postcode: [
            v => v && v.length >= 2 && v.length <= 10 || this.$t('Form.Postcode.Errors.Valid')
          ],
          Street: [
            v => v && v.length >= 2 && v.length <= 120 || this.$t('Form.Street.Errors.Valid')
          ],
          Number: [
            v => v && v.length >= 1 && v.length <= 10 || this.$t('Form.Number.Errors.Valid')
          ]
        }
      }

      return {}
    }
  },
  watch: {
    useCustomAddress (newVal, oldVal) {
      if (newVal) {
        this.address.Lng = this.address.Lat = this.address.PlaceId = this.address.Formatted = ''
      }
    },

    'dataStore.isAuthenticated': function (newVal, oldVal) {
      if (parseInt(this.dataStore.cart.OrderType) === 1) {
        if (newVal) this.order = null
      }

      this.setCustomerAddress()
    },

    'address.Formatted': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(() => {
          this.clearApiErrorMessages()
          this.$refs.checkoutForm.validate()
        })
      }
    },

    'address.Lat': function (newVal, oldVal) {
      if (parseFloat(newVal) !== parseFloat(oldVal)) {
        this.$nextTick(() => {
          this.clearApiErrorMessages()
          this.$refs.checkoutForm.validate()
          this.confirmAddress()
        })
      }
    }
  },
  created () {
    this.$bus.$on('cart:order-type-updated', this.confirmAddress)
    this.order = this.dataStore.cart.Order || null

    if (this.dataStore.isAuthenticated) {
      this.setCustomerAddress()
    }

    this.beginGACheckout()
  },
  mounted () {},
  destroyed () {
    this.$bus.$off('cart:order-type-updated', this.confirmAddress)
  },
  methods: {
    getContrastColor,
    beginGACheckout () {
      if (this.order) return

      const items = []
      for (let i = 0; i < this.dataStore.cart.CategoryItems.length; i++) {
        const category = this.dataStore.cart.CategoryItems[i].Category
        const categoryItems = this.dataStore.cart.CategoryItems[i].Items
        for (let ii = 0; ii < categoryItems.length; ii++) {
          categoryItems[ii].CategoryName = category.Lang && category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Name || category.Name
          const item = this.menuItemToGAItem(categoryItems[ii])
          items.push(item)
        }
      }

      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.BEGIN_CHECKOUT,
        Payload: {
          items            : items,
          value            : parseFloat(this.dataStore?.cart?.CartTotals?.order_total?.amount) || 0.0,
          currency         : window?.AppConfig?.APP_SETTINGS?.Currency?.name || 'EUR',
          coupon           : this.dataStore?.cart?.CartTotals?.coupon?.code || '',
          cartTotalQuantity: this.dataStore?.cart?.CartTotalQuantity || 0
        }
      })
    },

    addAddress () {
      this.addEditAddressDialogVisible = true
    },

    selectAddress () {
      this.selectAddressDialogVisible = true
    },

    onAddressAdded () {
      this.user.Addresses = JSON.parse(JSON.stringify(this.dataStore.userInfo.Addresses))
      if (this.user.Addresses.length === 1) {
        this.address = this.user.Addresses[0]
      } else if (this.user.Addresses.length > 1) {
        this.user.Addresses.forEach((address) => {
          if (address.IsDefault) {
            this.address = address
          }
        })
        if (!this.address) {
          this.address = this.user.Addresses[0]
        }
      }
    },

    setCustomerAddress () {
      if (this.dataStore.isAuthenticated) {
        this.user = JSON.parse(JSON.stringify(this.dataStore.userInfo))
        if (this.user.Addresses.length > 0) this.address = this.user.Addresses.find(address => address.IsDefault) || this.user.Addresses[0]
      } else {
        this.user = JSON.parse(JSON.stringify(this.tmpUser))
        this.address = this.defaultEmptyAddress()
      }

      this.setCustomerAddressFromOrder(this.order, this.order?.Address)

      this.useCustomAddress = this.isCustomAddress(this.address)

      this.initMapCenter(this.address)
    },

    setCustomerAddressFromOrder (userObject, addressObject) {
      if (userObject) {
        this.user.Info = { ...this.user.Info, ...userObject }
      }

      if (addressObject) { // parseInt(this.dataStore.cart.OrderType) === 1
        this.address = { ...this.address, ...addressObject }
        this.autoCompleteModel = addressObject.Formatted
      }
    },

    async confirmAddress () {
      this.clearApiErrorMessages()
      if (parseInt(this.dataStore.cart.OrderType) === 2) return

      if (!this.isQrOrder && this.dataStore.isAuthenticated && parseInt(this.dataStore.cart.OrderType) === 1) {
        if (this.user.Addresses.length <= 0) {
          this.$bus.$emit('show-snackbar', {
            title: this.$t('Checkout.Error.Address.Select.Title'),
            body : this.$t('Checkout.Error.Address.Select.Body'),
            type : 'error',
            icon : ''
          })
          return
        }
      }

      if (!this.isQrOrder && this.address && this.address.Lat && this.address.Lng && parseInt(this.dataStore.cart.OrderType) === 1) {
        const result = await this.isPlaceInsideDeliveryAreas(this.addressToPlaceObject(this.address))
        if (!result) {
          this.$bus.$emit('show-snackbar', {
            title: this.$t('Checkout.Error.Address.OutOfBounds.Title'),
            body : this.$t('Checkout.Error.Address.OutOfBounds.Body'),
            type : 'error',
            icon : ''
          })
          return
        }
      }

      const Info = JSON.parse(JSON.stringify(this.user.Info))
      Info.Address = JSON.parse(JSON.stringify(this.address))

      this.submitButton = true
      this.CartPromiseQueue.push(() => window.API.post(window.APICall.checkoutAddress, Info)
        .then(response => {
          if (response.data && response.data.status === 200) {
            this.$set(this.dataStore, 'cart', response.data.data.Cart)
            this.order = this.dataStore.cart.Order || null
          }
        })
        .catch(e => {})
        .finally(() => {
          this.submitButton = false
        })
      )
    },

    async confirmData () {
      this.clearApiErrorMessages()
      this.$refs.checkoutForm.validate()

      if (!this.isQrOrder && this.checkoutFormValid && this.dataStore.isAuthenticated && parseInt(this.dataStore.cart.OrderType) === 1) {
        if (this.user.Addresses.length <= 0) {
          this.$bus.$emit('show-snackbar', {
            title: this.$t('Checkout.Error.Address.Select.Title'),
            body : this.$t('Checkout.Error.Address.Select.Body'),
            type : 'error',
            icon : ''
          })
          return
        }
      }

      if (!this.isQrOrder && this.address && this.address.Lat && this.address.Lng && parseInt(this.dataStore.cart.OrderType) === 1) {
        const result = await this.isPlaceInsideDeliveryAreas(this.addressToPlaceObject(this.address))
        if (!result) {
          this.$bus.$emit('show-snackbar', {
            title: this.$t('Checkout.Error.Address.OutOfBounds.Title'),
            body : this.$t('Checkout.Error.Address.OutOfBounds.Body'),
            type : 'error',
            icon : ''
          })
          return
        }
      }

      if (this.checkoutFormValid) {
        this.submitButton = true
        const Info = JSON.parse(JSON.stringify(this.user.Info))
        Info.Address = !this.isQrOrder ? JSON.parse(JSON.stringify(this.address)) : this.defaultEmptyAddress()

        if (this.useCustomAddress) {
          Info.Address.Formatted = `${ Info.Address.Street } ${ Info.Address.Number }, ${ Info.Address.City } ${ Info.Address.Postcode }`
          Info.Address.Lat = Info.Address.Lng = 0
        }

        this.CartPromiseQueue.push(() => window.API.post(window.APICall.checkoutDetails, Info)
          .then(response => {
            if (response.data && response.data.status === 200) {
              if (response.data.data.Messages.Success) {
                this.$set(this.dataStore, 'cart', response.data.data.Cart)
                this.$router.push({ name: 'CheckoutPayment' })
              } else {
                for (const key in response.data.data.Messages) {
                  if (response.data.data.Messages.hasOwnProperty(key) && this.errorMessages.checkoutForm.hasOwnProperty(key)) {
                    if (response.data.data.Messages[key].trim()) this.errorMessages.checkoutForm[key].push(response.data.data.Messages[key])
                  }
                }

                this.$bus.$emit('show-snackbar', {
                  title: this.$t('Checkout.Error.Generic.Title'),
                  body : this.$t('Checkout.Error.Generic.Body'),
                  type : 'error',
                  icon : ''
                })
              }
            }
          })
          .catch(e => {

          })
          .finally(() => {
            this.submitButton = false
          })
        )
      }
    },

    clearApiErrorMessages () {
      // Clear Previous API Error Messages
      for (const key in this.errorMessages.checkoutForm) {
        if (this.errorMessages.checkoutForm.hasOwnProperty(key)) {
          this.errorMessages.checkoutForm[key] = []
        }
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-stepper__step {
  padding : 16px;
}

@media only screen and (max-width : 959px) {
  /deep/ .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display : block;
  }
}
</style>
